import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { LeftSidebarComponent } from '../../left-sidebar.component';
import { ToasterService } from '../../../@theme/components/toaster/toaster.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { HelperService } from '../../../@theme/services/core-services/helper.service';

@Component({
  selector: 'ngx-user-update',
  templateUrl: './user-update.component.html',
  styleUrls: ['./user-update.component.scss']
})
export class UserUpdateComponent implements OnInit {
  @Output() updateCheckLogin = new EventEmitter<any>();
  @Output() tiggerCheckLogin = new EventEmitter<void>();
  @Output() getUpdatePassword = new EventEmitter<boolean>();
  @ViewChild(LeftSidebarComponent, { static: false }) leftSidebarComponent: LeftSidebarComponent;
  @ViewChild('setProfileImage') setProfileImage!: ElementRef;
  selectedImage: File | null = null;
  profileImageView: any = '';
  checkImage = false;
  uploadBtn = false;
  uploadingLoader = false;
  updatingLoader = false;
  isUpdateButtonEnabled = false;
  user = {
    name: '',
    last_name: '',
    email: '',
    phone: ''
  }
  userUpdateChecker = {
    name: '',
    last_name: '',
    email: '',
    phone: ''
  }
  getUserInfo = null;

  constructor(private http: HttpClient, private toastrService: ToasterService, private helperService: HelperService) { }

  ngOnInit(): void {
    this.checkProfileImage()
  }

  showSuccess(title: String, message: String) {
    this.toastrService.success(title, message);
  }

  showError(title: String, message: String) {
    this.toastrService.danger(title, message);
  }

  getUser(val: any){
    this.getUserInfo = val;
    this.user.name = val.name;
    this.user.last_name = val.last_name;
    this.user.email = val.email;
    this.user.phone = this.helperService.getFormattedPhoneNumber(val.phone);
    
    this.userUpdateChecker.name = val.name;
    this.userUpdateChecker.last_name = val.last_name;
    this.userUpdateChecker.email = val.email;
    this.userUpdateChecker.phone = this.helperService.getFormattedPhoneNumber(val.phone);
    if(val.profile_picture !== null && val.profile_picture !== ''){
      this.profileImageView = val.profile_picture;
      this.checkImage = false;
    }
    this.onInputChange()
  }

  updateUser(){
    this.updatingLoader = true;
    let unformatPhoneNumber = this.user.phone.replace(/-/g, '');
    if(unformatPhoneNumber && unformatPhoneNumber.length < 10){
      this.updatingLoader = false;
      this.showError('Error', 'Incorrect Number');
    } else {
      const formData = new FormData();
      if (this.selectedImage) {
        formData.append('picture', this.selectedImage);
      }
      if (this.user.name) {
        formData.append('name', this.user.name);
      }
      if (this.user.last_name) {
        formData.append('last_name', this.user.last_name);
      }
      if (this.user.email) {
        formData.append('email', this.user.email);
      }
      if (unformatPhoneNumber) {
        formData.append('phone', unformatPhoneNumber);
      }
      this.http.post<any>(environment.apiBaseUrl+'user', formData).subscribe(
        response => {
          if(response.success){
            this.showSuccess('User Updated','');
            this.getUserInfo = response.user;
            this.uploadBtn = false;
            this.updatingLoader = false;
            console.log("second", this.getUserInfo);
            this.sendUpdateUser(this.getUserInfo);
          } else {
            this.updatingLoader = false;
            this.showError('Error', response.message);
          }
        },
        error => {
          if(error.error.message){
            this.updatingLoader = false;
            this.showError('Error', error.error.message);
          } else {
            this.updatingLoader = false;
            this.showError('Error', error.statusText);
          }
        }
      );
    }
  }

  sendUpdateUser(data: any){
    this.getUser(data);
    this.updateCheckLogin.emit(data);
  }

  openUpdatePassword(){
    this.getUpdatePassword.emit(true);
  }

  allowDecimal(event: KeyboardEvent) {
    const currentValue = (<HTMLInputElement>event.target).value;
    if (event.key === '.') {
      if (currentValue.includes('.')) {
        event.preventDefault();
      }
    }

    if (!(event.key >= '0' && event.key <= '9') && event.key !== 'Backspace' && event.key !== '.') {
      event.preventDefault();
    }
  }

  openFileInput() {
    this.setProfileImage.nativeElement.click();
  }

  fileChangeEvent(event: any): void {
    if(event.target.files){
      this.selectedImage = event.target.files[0];
      this.uploadBtn = true;
      let reader = new FileReader();
      reader?.readAsDataURL(event.target.files[0]);
      reader.onload=(e: any)=>{
        this.profileImageView = e.target.result;
        this.checkImage = false;
      }
    }
  }

  onUploadProfile(): void {
    if (this.selectedImage) {
      this.uploadingLoader = true;
      const formData = new FormData();
      formData.append('picture', this.selectedImage);
      this.http.post<any>(environment.apiBaseUrl+'user/upload-profile-picture', formData)
        .subscribe(
          (response) => {
            console.log("first", response);
            this.showSuccess(response.message, '');
            if(response.success){
              this.uploadBtn = false;
              this.uploadingLoader = false;
              this.tiggerCheckLogin.emit();
            }
          },
          (error) => {
            this.showError('Error', error.error.message);
          }
        );
    }
  }

  checkProfileImage(){
    this.uploadBtn = false;
    if(this.profileImageView === ''){
      this.checkImage = true;
    } else {
      this.checkImage = false;
    }
  }

  onInputChange() {
    this.isUpdateButtonEnabled = Object.keys(this.user).some(key => this.user[key] !== this.userUpdateChecker[key]);
  }

}
