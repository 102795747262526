import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output, HostListener } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth-service';
import { environment } from '../../environments/environment'
import {TranslateService} from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TypeWriterTextService } from '../@theme/components/type-writer-text/type-writer-text.service';

@Component({
  selector: 'ngx-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss']
})

export class RightSidebarComponent implements OnInit, OnChanges {
  @Input() output: any = '';
  @Input() application: any = '';
  @Input() loggedInUpdaterValue: boolean = false;
  @Output() stepFourWarning = new EventEmitter<boolean>();
  private userSubscription: Subscription;
  // @Output() fireworkStatus = new EventEmitter<boolean>();
  

  constructor(private http: HttpClient, private authService: AuthService, translate: TranslateService, private typeWriterTextServices: TypeWriterTextService) {
    // translate.setDefaultLang('en');
    // translate.use('fr');
   }
  
  progressGDS: string = '0%';
  progressGDSSimple: string = '0%';
  progressGDSSimpleInt: number = 0;
  progressTDS: string = '100%'; 
  progressTDSSimple: string = '0%'; 
  progressTDSSimpleInt: number = 0; 
  rate: string = 'proposed';
  house: number = 1;
  inverseSimpleGds = '';
  inverseSimpleTds = '';
  advanced = false;
  switchAdvanced = false;
  mortgageCapacity = null;
  monthlyMortgageAmount = null;
  highlightMortgageCapacity: boolean = false;
  highlightMonthlyMortgageAmount: boolean = false;
  intialFireworkHandler = false;
  fireworkStatus = false;
  warningMessage = false;
  tabScreen = false;
  loggedIn = false;
  user: any = null;

  ngOnInit(): void {
    this.userSubscription = this.typeWriterTextServices.user$.subscribe((newUser) => {
      this.user = newUser;
      if(this.user && this.user.role.name === 'broker'){
        this.switchAdvanced = true;
        this.getAdvancedView();
      } else{
        this.switchAdvanced = false;
        this.getAdvancedView();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.output && !changes.output.firstChange) {
      this.progressGDS = this.getGDSwidth();
      this.progressGDSSimple = this.getGDSwidthSimple();
      this.progressTDS = this.getTDSwidthInverse();
      this.progressTDSSimple = this.getTDSwidthInverseSimple();
      this.checkFireworkStatus(this.progressGDSSimple, this.progressTDSSimple)
      console.log("Aanif "+this.progressGDSSimple, this.progressTDSSimple);
      this.getMortgageValues()
      this.checkMortgageValues()
    }

    if (changes.application && !changes.application.firstChange) {
      console.log("APPLICATION IN LEFT SIDEBAR", this.application);
    }

    if(changes.loggedInUpdaterValue){
      this.loggedIn = this.loggedInUpdaterValue;
    }

  }

  getMortgageValues(){
    if(this.loggedIn || true){
      this.mortgageCapacity = this.output.mortgageCapacity;
      this.monthlyMortgageAmount = this.output.monthlyMortgageAmount;
    } else{
      this.mortgageCapacity = '';
      this.monthlyMortgageAmount = '';
    }
  }

  checkFireworkStatus(progressGDSSimplepercent: any, progressTDSSimplePercent: any){
    progressGDSSimplepercent = parseFloat(progressGDSSimplepercent);
    progressTDSSimplePercent = parseFloat(progressTDSSimplePercent);
    if (progressGDSSimplepercent > 100 || progressTDSSimplePercent > 100) {
      this.intialFireworkHandler = true;
      this.warningMessage = true;
      this.stepFourWarning.emit(this.warningMessage)
    } else {
      this.warningMessage = false;
      this.stepFourWarning.emit(this.warningMessage)
    }
    if(this.intialFireworkHandler){
      if (progressGDSSimplepercent <= 100 && progressTDSSimplePercent <= 100) {
        this.fireworkStatus= true;
        this.intialFireworkHandler = false;
        setTimeout(() => {
          this.fireworkStatus = false;
        }, 3000);
      } else {
        this.fireworkStatus= false;
      }
    }
  }

  checkMortgageValues(){
    if (this.mortgageCapacity) {
        this.highlightMortgageCapacity = true;
  
        setTimeout(() => {
          this.highlightMortgageCapacity = false;
        }, 1000);
    }
  
    if (this.monthlyMortgageAmount) {
        this.highlightMonthlyMortgageAmount = true;

        setTimeout(() => {
            this.highlightMonthlyMortgageAmount = false;
        }, 1000);
    }
}

  getRateType(): string {
    if(this.output && this.output.rate){
      return this.output.rate;
    } else {
      return '';
    }
  }

  getHouseType(): number {
    if(this.output && this.output.house){
      return this.output.house;
    } else {
      return 0;
    }
  }

  getGDSwidth(): string {
    if (this.output && this.output.gds) {
      this.rate = this.getRateType();
      this.house = this.getHouseType();
      const dynamicGDS = (this.output.gds/39) * 100;
      return dynamicGDS.toString() + '%';
    } else {
      return '0%'; 
    }
  }

  getGDSwidthSimple(): string {
    if (this.output && this.output.gds) {
      this.rate = this.getRateType();
      this.house = this.getHouseType();
      const dynamicGDS = this.output.gds;
      const simpleCalc = (dynamicGDS/39) * 100;
      this.inverseSimpleGds = (100 - simpleCalc).toString()+'%';
      this.progressGDSSimpleInt = simpleCalc;
      return simpleCalc.toString() + '%';
    } else {
      return '0%'; 
    }
  }

  getGDSBackgroundColor(): string {
    // const gdsWidth = parseInt(this.getGDSwidth(), 10);
    const gdsWidth = parseFloat(this.getGDSwidth());
    const setGdsWidth = (gdsWidth  / 100) * 39;
    return setGdsWidth > 38.99 ? 'red' : 'linear-gradient(95.56deg, #FFEDB0 0%, #AF883D 52.63%, #FFEDB0 99.64%), #AF883D';
  }

  getGDSBackgroundColorSimple(): string {
    // const gdsWidth = parseInt(this.getGDSwidth(), 10);
    const gdsWidth = parseFloat(this.progressGDSSimple);
    return gdsWidth > 100 ? 'red' : 'linear-gradient(95.56deg, #FFEDB0 0%, #AF883D 52.63%, #FFEDB0 99.64%), #AF883D';
  }

  getGDSwidthInverse(): string {
    if (this.output && this.output.gds) {
      this.rate = this.getRateType();
      this.house = this.getHouseType();
      const dynamicGDS = 100 - ((this.output.gds/39) * 100);
      return dynamicGDS.toString()+'%';
    } else {
      return '0%'; 
    }
  }

  getTDSwidth(): string {
    if (this.output && this.output.tds) {
      const dynamicTDS = (this.output.tds/44) * 100;;
      return dynamicTDS.toString()+'%';
    } else {
      return '0%';
    }
  }

  getTDSBackgroundColor(): string {
    // const gdsWidth = parseInt(this.getTDSwidth(), 10);
    const tdsWidth = parseFloat(this.getTDSwidth());
    const setTdsWidth = (tdsWidth  / 100) * 44;
    return setTdsWidth > 44 ? 'red' : 'linear-gradient(95.56deg, #FFEDB0 0%, #AF883D 52.63%, #FFEDB0 99.64%), #AF883D';
  }

  getTDSBackgroundColorSimple(): string {
    // const gdsWidth = parseInt(this.getTDSwidth(), 10);
    const tdsWidth = parseFloat(this.progressTDSSimple);
    return tdsWidth > 100 ? 'red' : 'linear-gradient(95.56deg, #FFEDB0 0%, #AF883D 52.63%, #FFEDB0 99.64%), #AF883D';
  }

  getTDSwidthInverse(): string {
    if (this.output && this.output.tds) {
      const dynamicTDS = 100 - ((this.output.tds/44) * 100);
      return dynamicTDS.toString()+'%';
    } else {
      return '0%';
    }
  }

  getTDSwidthInverseSimple(): string {
    if (this.output && this.output.tds) {
      const dynamicTDS = this.output.tds;
      const simpleCalc = (dynamicTDS/44) * 100
      this.inverseSimpleTds = (100 - simpleCalc).toString()+'%';
      this.progressTDSSimpleInt = simpleCalc;
      return simpleCalc.toString()+'%';
    } else {
      return '0%';
    }
  }

  public makeAuthenticatedRequest(): void {
    const token = this.authService.getToken();
    
    // Create headers and set Authorization header with the token
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    
    // Make the authenticated request
    this.http.get<any>(environment.apiBaseUrl+'/me', { headers }).subscribe(
      response => {
        // Handle the response
        console.log('response '+response.name);

      },
      error => {
        console.error('Authenticated request error:', error);
      }
    );
  }

  
  @HostListener('window:load', ['$event'])
  onLoad(event: Event) {
    const screenWidth = window.innerWidth;
    if (screenWidth < 1200) {
      this.tabScreen = true;
    } else{
      this.tabScreen = false;
    }
  }
  

  getAdvancedView(){
    if(this.switchAdvanced){
      setTimeout(() => {
        this.advanced = this.switchAdvanced;
      }, 450);
    } else {
      this.advanced = this.switchAdvanced;
    }
  }

}
