import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { ToasterService } from '../../../../components/toaster/toaster.service';

@Component({
  selector: 'ngx-third-party-credentials',
  templateUrl: './third-party-credentials.component.html',
  styleUrls: ['./third-party-credentials.component.scss']
})
export class ThirdPartyCredentialsComponent implements OnInit {
  @Input() user: any = '';
  @Input() getCsvStatus: any = '';
  @Output() credsResponseStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
  credentials ={
    userId: 0,
    bossUsername: '',
    bossClientId: '',
    bossClientSecret: '',
    finmoUsername: '',
    finmoTeamId: '',
    finmoBrokerId: '',
    finmoToken: '',
  }

  getResponseStatus = false;
  credientailsType = '';

  constructor(private http: HttpClient, private toastrService: ToasterService) { }

  ngOnInit(): void {
  }

  showSuccess(title: String, message: String) {
    this.toastrService.success(title, message);
  }

  showError(title: String, message: String) {
    this.toastrService.danger(title, message);
  }

  getCredentialStatus(value: string){
    if(value === 'boss_application'){
      if(this.credentials.bossUsername !== '' && this.credentials.bossClientId !== '' && this.credentials.bossClientSecret !== ''){
        this.credentials.userId = this.user.id;
        this.credientailsType = value;
        this.sendCredentials();
      } else {
        this.showError('All Fields Required', '');
      }
    } else if (value === 'finmo_application'){
      if(this.credentials.finmoUsername !== '' && this.credentials.finmoTeamId !== '' && this.credentials.finmoBrokerId !== '' && this.credentials.finmoToken !== ''){
        this.credentials.userId = this.user.id;
        this.credientailsType = value;
        this.sendCredentials();
      } else {
        this.showError('All Fields Required', '');
      }
    }
  }

  sendCredentials(){
    if(this.user && this.user.role.name === 'broker'){
      this.http.post<any>(environment.apiBaseUrl+'third-party-credentials', { credentials: this.credentials }).subscribe(
        response => {
          this.credsResponseStatus.emit(true)
          if(this.credientailsType === 'boss_application'){
            this.showSuccess('Mortgage Boss credentials added', '');
          } else if(this.credientailsType === 'finmo_application'){
            this.showSuccess('Finmo credentials added', '');
          }
        },
        error => {
          console.error('Login error:', error);
          this.showError('Error', error.statusText);
          this.getResponseStatus = false;
        }
      );

    }
  }

}
