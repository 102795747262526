<div class="steps-inner c-mb-6">
  <div>
    <div class="mb-3 position-relative img-thumbnail profie-img-container">
      <span>
        <span (click)="openFileInput()">
          <i *ngIf="checkImage" class="fas fa-building user-img-icon"></i>
          <img *ngIf="!checkImage" [src]="profileImageView" class="profie-img" alt="Profile Image">
        </span>
        <span class="profie-img-edit" (click)="openFileInput()"><i class="fas fa-camera"></i></span>
        <input class="d-none" type="file" (change)="fileChangeEvent($event)" accept="image/*" #setBusinessLogo>
      </span>
    </div>
    <div>
      <p class="m-0 image-suggestion-text">* {{ 'IMG_SUGGESTION_SIZE' | translate }}</p>
      <p class="image-suggestion-text">* {{ 'IMG_SUGGESTION_UPLOAD' | translate }} <strong>"{{ 'IMG_SUGGESTION_LOGO' | translate }}"</strong>.</p>
    </div>
  </div>
    <div class="form-group mb-3">
      <label for="firstName">{{ 'BUSINESS_NAME' | translate }}</label>
      <input type="text" (input)="onInputChange()" class="form-control input-select input-white w-100" [(ngModel)]="business.name" placeholder="{{ 'BUSINESS_NAME' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
      <span *ngIf="nameValidator" class="error-text">*{{ 'BUSINESS_NAME_ERROR' | translate }}</span>
    </div>
    <div class="form-group mb-3">
      <label for="firstName">{{ 'BUSINESS_ADDRESS' | translate }}</label>
      <input type="text" (input)="onInputChange()" class="form-control input-select input-white w-100" [(ngModel)]="business.address" placeholder="{{ 'BUSINESS_ADDRESS' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
      <span *ngIf="addressValidator" class="error-text">*{{ 'BUSINESS_ADDRESS_ERROR' | translate }}</span>
    </div>
    <div class="form-group mb-3">
      <label for="profileEmail">{{ 'BUSINESS_EMAIL' | translate }}</label>
      <input type="email" (input)="onInputChange()" class="form-control input-select input-white w-100" [(ngModel)]="business.email" placeholder="{{ 'BUSINESS_EMAIL' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
    </div>
    <div class="form-group mb-3">
      <label for="exampleInputEmail1">{{ 'BUSINESS_PHONE' | translate }}</label>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text"><img src="../../assets/images/ca.png" alt="CA" height="10px" width="15px" style="margin-right: 5px;">+1</span>
        </div>
        <input type="text" (input)="onInputChange()" inputmode="decimal" maxlength="12" class="form-control input-select input-white" [(ngModel)]="business.phone" (keypress)="allowDecimal($event)" placeholder="{{ 'BUSINESS_PHONE' | translate }}" style="height: 42px" onkeypress="return event.charCode >= 48 && event.charCode <= 57" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxPhoneNumberFormatter>
      </div>
    </div>

    <button *ngIf="isUpdateButtonEnabled || uploadBtn" class="btn btn-outline-dark lsb-btn-font btn-sm  w-100 mt-4" [disabled]="updatingLoader || nameValidator || addressValidator"  (click)="updateBusinessInfo()">     
      <span *ngIf="!updatingLoader">{{ 'UPDATE' | translate }}</span>
     <span *ngIf="updatingLoader">
       <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
       {{ 'UPDATING' | translate }}
     </span>
   </button>
</div>
