import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'ngx-mobile-footer',
  templateUrl: './mobile-footer.component.html',
  styleUrls: ['./mobile-footer.component.scss']
})
export class MobileFooterComponent implements OnInit, OnChanges {
  @Input() outputData: any;
  @Input() application: any = '';
  @Input() scrollStepsValueChange: boolean;
  @Output() footerHeightChange = new EventEmitter<boolean>();
  gds = 0;
  gds_colorValue = 0;
  tds = 0;
  tds_colorValue = 0;
  gds_simple = 0;
  gds_simple_colorValue = 0;
  tds_simple_colorValue = 0;
  tds_simple = 0;
  chartOne: any;
  chartOneCopy: any;
  chartOneSimple: any;
  chartTwo: any;
  chartTwoCopy: any;
  chartTwoSimple: any;
  showStepsValue = false;
  mortgageCapacity = null;
  monthlyMortgageAmount = null;
  screenWidth: number;
  check_gds_simple = 0;
  check_tds_simple = 0;
  graphTdsCloseFooter = false;
  graphGdsCloseFooter = false;
  house = 1;
  highlightMortgageCapacity: boolean = false;
  highlightMonthlyMortgageAmount: boolean = false;
  intialFireworkHandler = false;
  fireworkStatus = false;
  constructor() { }

  ngOnInit(): void {
    this.chartOne = this.getChartOne(this.gds);
    this.chartOneCopy = this.getChartOneCopy(this.gds);
    this.chartOneSimple = this.getChartOneSimple(this.gds);
    this.chartTwo = this.getChartTwo(this.tds);
    this.chartTwoCopy = this.getChartTwoCopy(this.tds);
    this.chartTwoSimple = this.getChartTwoSimple(this.tds);
    this.checkRatioChart(this.tds_simple, this.gds_simple)
    this.screenWidth = window.innerWidth;
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.outputData && !changes.outputData.firstChange) {
        console.log('outputData changed:', this.outputData);
        this.getChartOne(this.outputData.gds);
        this.getChartOneCopy(this.outputData.gds);
        this.getChartOneSimple(this.outputData.gds);
        

        this.getChartTwo(this.outputData.tds);
        this.getChartTwoCopy(this.outputData.tds);
        this.getChartTwoSimple(this.outputData.tds);

        this.checkRatioChart(this.tds_simple, this.gds_simple);
        this.mortgageCapacity = this.outputData.mortgageCapacity;
        this.monthlyMortgageAmount = this.outputData.monthlyMortgageAmount;
        this.house = this.outputData.house;
        this.checkMortgageValues()
    }

    if (changes.application && !changes.application.firstChange) {
        console.log("APPLICATION IN MOBILE FOOTER", this.application);
    }
  }

  checkFireworkStatus(percentageValue: number){
    if (percentageValue <= 100) {
        this.fireworkStatus = true;
        this.intialFireworkHandler = false;
        setTimeout(() => {
            this.fireworkStatus = false;
        }, 4000);
    } else {
        this.fireworkStatus = false;
    }
  }

checkMortgageValues(){
    if (this.mortgageCapacity) {
        this.highlightMortgageCapacity = true;
  
        setTimeout(() => {
          this.highlightMortgageCapacity = false;
        }, 1000);
    }
  
    if (this.monthlyMortgageAmount) {
        this.highlightMonthlyMortgageAmount = true;

        setTimeout(() => {
            this.highlightMonthlyMortgageAmount = false;
        }, 1000);
    }
}

getChartOne(value: number): any {
    const percentageValue = (value/39) * 100;
    this.gds_simple = parseFloat(percentageValue.toFixed(0));
    const totalValue = 100;
    const remainingValue = totalValue - percentageValue;

    const chartCanvas = document.getElementById('doughnutChart') as HTMLCanvasElement;
    const ctx = chartCanvas.getContext('2d');

    if (this.chartOne) {
        this.chartOne.data.datasets[0].data = [percentageValue, remainingValue];
        this.chartOne.update();
        return this.chartOne;
    } else {
        return this.chartOne = new Chart(ctx, {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: [percentageValue, remainingValue],
                    backgroundColor: ['#d4b06d', '#F0F0F0'],
                    borderWidth: 0,
                }],
                labels: [`${percentageValue}%`]
            },
            options: {
                cutoutPercentage: '70',
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'none',
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem: any, data: any) {
                            const dataset = data.datasets[tooltipItem.datasetIndex];
                            const currentValue = dataset.data[tooltipItem.index];
                            return `${currentValue}%`;
                        }
                    }
                }
            }
        });
    }
}

getChartOneCopy(value: number): any {
    let setBackgroundColor: string[];
    const percentageValue = value;
    this.gds = parseFloat(value.toFixed(0));
    this.gds_colorValue = percentageValue;
    const totalValue = 100;
    const remainingValue = totalValue - percentageValue;

    if (percentageValue >= 39) {
        setBackgroundColor = ['#FF0000', '#FF0000'];
    } else {
        setBackgroundColor = ['#d4b06d', '#F0F0F0'];
    }

    const chartCanvas = document.getElementById('doughnutChartCopy') as HTMLCanvasElement;
    const ctx = chartCanvas.getContext('2d');

    if (this.chartOneCopy) {
        this.chartOneCopy.data.datasets[0].data = [percentageValue, remainingValue];
        this.chartOneCopy.data.datasets[0].backgroundColor = setBackgroundColor;
        this.chartOneCopy.update();
        return this.chartOneCopy;
    } else {
        return this.chartOneCopy = new Chart(ctx, {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: [percentageValue, remainingValue],
                    backgroundColor: setBackgroundColor,
                    borderWidth: 0,
                }],
                labels: [`${percentageValue}%`]
            },
            options: {
                cutoutPercentage: '70',
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'none',
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem: any, data: any) {
                            const dataset = data.datasets[tooltipItem.datasetIndex];
                            const currentValue = dataset.data[tooltipItem.index];
                            return `${currentValue}%`;
                        }
                    }
                }
            }
        });
    }
}

getChartOneSimple(value: number): any {
    let setBackgroundColor: string[];
    const percentageValue = (value/39) * 100;
    this.gds_simple = parseFloat(percentageValue.toFixed(0));
    this.gds_simple_colorValue = percentageValue;
    const totalValue = 100;
    const remainingValue = totalValue - percentageValue;
    
    if (percentageValue >= 100) {
        this.intialFireworkHandler = true;
        setBackgroundColor = ['#FF0000', '#FF0000'];
    } else {
        setBackgroundColor = ['#d4b06d', '#F0F0F0'];
    }
    console.log("FIRE TESdaaaadTER", this.intialFireworkHandler);
    if(this.intialFireworkHandler){
        this.checkFireworkStatus(this.gds_simple_colorValue)
    }
    const chartCanvas = document.getElementById('doughnutChartSimple1') as HTMLCanvasElement;
    const ctx = chartCanvas.getContext('2d');

    if (this.chartOneSimple) {
        this.chartOneSimple.data.datasets[0].data = [percentageValue, remainingValue];
        this.chartOneSimple.data.datasets[0].backgroundColor = setBackgroundColor;
        this.chartOneSimple.update();
        return this.chartOneSimple;
    } else {
        return this.chartOneSimple = new Chart(ctx, {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: [percentageValue, remainingValue],
                    backgroundColor: setBackgroundColor,
                    borderWidth: 0,
                }],
                labels: [`${percentageValue}%`]
            },
            options: {
                cutoutPercentage: '70',
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'none',
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem: any, data: any) {
                            const dataset = data.datasets[tooltipItem.datasetIndex];
                            const currentValue = dataset.data[tooltipItem.index];
                            return `${currentValue}%`;
                        }
                    }
                }
            }
        });
    }
}

  getChartTwo(value: number): any {
    const percentageValue = (value/44) * 100;
    this.tds_simple = parseFloat(percentageValue.toFixed(0));

    const totalValue = 100;
    const remainingValue = totalValue - percentageValue;

    const chartCanvas = document.getElementById('doughnutChartTwo') as HTMLCanvasElement;
    const ctx = chartCanvas.getContext('2d');

    if (this.chartTwo) {
        this.chartTwo.data.datasets[0].data = [percentageValue, remainingValue];
        this.chartTwo.update();
        return this.chartTwo;
    } else {
        return this.chartTwo = new Chart(ctx, {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: [percentageValue, remainingValue],
                    backgroundColor: ['#d4b06d', '#F0F0F0'],
                    borderWidth: 0,
                }],
                labels: [`${percentageValue}%`]
            },
            options: {
                cutoutPercentage: '70',
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'none',
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem: any, data: any) {
                            const dataset = data.datasets[tooltipItem.datasetIndex];
                            const currentValue = dataset.data[tooltipItem.index];
                            return `${currentValue}%`;
                        }
                    }
                }
            }
        });
    }
  }

  getChartTwoCopy(value: number): any {
    let setBackgroundColor: string[];
    const percentageValue = value;
    this.tds = parseFloat(value.toFixed(0));
    this.tds_colorValue = percentageValue;

    const totalValue = 100;
    const remainingValue = totalValue - percentageValue;

    if (percentageValue >= 44) {
        setBackgroundColor = ['#FF0000', '#FF0000'];
    } else {
        setBackgroundColor = ['#d4b06d', '#F0F0F0'];
    }

    const chartCanvas = document.getElementById('doughnutChartTwoCopy') as HTMLCanvasElement;
    const ctx = chartCanvas.getContext('2d');

    if (this.chartTwoCopy) {
        this.chartTwoCopy.data.datasets[0].data = [percentageValue, remainingValue];
        this.chartTwoCopy.data.datasets[0].backgroundColor = setBackgroundColor;
        this.chartTwoCopy.update();
        return this.chartTwoCopy;
    } else {
        return this.chartTwoCopy = new Chart(ctx, {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: [percentageValue, remainingValue],
                    backgroundColor: setBackgroundColor,
                    borderWidth: 0,
                }],
                labels: [`${percentageValue}%`]
            },
            options: {
                cutoutPercentage: '70',
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'none',
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem: any, data: any) {
                            const dataset = data.datasets[tooltipItem.datasetIndex];
                            const currentValue = dataset.data[tooltipItem.index];
                            return `${currentValue}%`;
                        }
                    }
                }
            }
        });
    }
  }

  getChartTwoSimple(value: number): any {
    let setBackgroundColor: string[];
    const percentageValue = (value/44) * 100;
    this.tds_simple = parseFloat(percentageValue.toFixed(0));
    this.tds_simple_colorValue = percentageValue;
    const totalValue = 100;
    const remainingValue = totalValue - percentageValue;

    if (percentageValue >= 100) {
        this.intialFireworkHandler = true;
        setBackgroundColor = ['#FF0000', '#FF0000'];
    } else {
        setBackgroundColor = ['#d4b06d', '#F0F0F0'];
    }
    if(this.intialFireworkHandler){
        this.checkFireworkStatus(this.tds_simple_colorValue)
    }
    const chartCanvas = document.getElementById('doughnutChartTwoSimple') as HTMLCanvasElement;
    const ctx = chartCanvas.getContext('2d');

    if (this.chartTwoSimple) {
        this.chartTwoSimple.data.datasets[0].data = [percentageValue, remainingValue];
        this.chartTwoSimple.data.datasets[0].backgroundColor = setBackgroundColor;
        this.chartTwoSimple.update();
        return this.chartTwoSimple;
    } else {
        return this.chartTwoSimple = new Chart(ctx, {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: [percentageValue, remainingValue],
                    backgroundColor: setBackgroundColor,
                    borderWidth: 0,
                }],
                labels: [`${percentageValue}%`]
            },
            options: {
                cutoutPercentage: '70',
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'none',
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem: any, data: any) {
                            const dataset = data.datasets[tooltipItem.datasetIndex];
                            const currentValue = dataset.data[tooltipItem.index];
                            return `${currentValue}%`;
                        }
                    }
                }
            }
        });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.showStepsValue = false;
    } else{
      this.showStepsValue = true;
    }
  }

  checkRatioChart(getTds_simple: number, getGds_simple: number){
    this.check_gds_simple = getGds_simple;
    this.check_tds_simple = getTds_simple;

    if(this.check_gds_simple > this.check_tds_simple){
        this.graphGdsCloseFooter = true;
        this.graphTdsCloseFooter = false;
    } else {
        this.graphTdsCloseFooter = true;
        this.graphGdsCloseFooter = false;
    }
  }

}
