import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Clipboard } from '@angular/cdk/clipboard';
import { ToasterService } from '../../../@theme/components/toaster/toaster.service';

@Component({
  selector: 'ngx-embedded-code',
  templateUrl: './embedded-code.component.html',
  styleUrls: ['./embedded-code.component.scss']
})
export class EmbeddedCodeComponent implements OnInit {
  embedded = {height: 900, width: 1600};
  embeddedHeight = false;
  embeddedWidth = false;
  perviousHeight = '';
  perviousWidth = '';
  newEmbeddedSource = null;
  embeddedSources = [];
  tooltipText: string = 'Copy to clipboard';
  textToCopy: string = 'Loading ...';
  initialString: string = '';

  constructor(private http: HttpClient, private toastrService: ToasterService, private clipboard: Clipboard) { }

  ngOnInit(): void {
  }

  showSuccess(title: String, message: String) {
    this.toastrService.success(title, message);
  }

  showError(title: String, message: String) {
    this.toastrService.danger(title, message);
  }

  copyToClipboard() {
    this.clipboard.copy(this.textToCopy);
    this.tooltipText = 'Copied!';

    setTimeout(() => {
      this.tooltipText = 'Copy to clipboard';
    }, 1500);
  }

  getUserEmbeddedSources(resources: any){
    this.embeddedSources = resources;
  }

  replaceCode() {
    this.textToCopy = this.initialString;
    if (this.embedded.height <= 1080) {
      this.textToCopy = this.textToCopy.replace(/#height/g, this.embedded.height.toString()+'px');
      this.perviousHeight = this.textToCopy;
      this.embeddedHeight = false;
    } else {
      this.embeddedHeight =true;
      this.textToCopy = this.perviousHeight;
    }
    if (this.embedded.width <= 1920) {
      this.textToCopy = this.textToCopy.replace(/#width/g, this.embedded.width.toString()+'px');
      this.perviousWidth = this.textToCopy;
      this.embeddedWidth = false;
    } else {
      this.embeddedWidth = true;
      this.textToCopy = this.perviousWidth;
    }
  }

  getEmbeddedCode() {
    this.http.get<any>(environment.apiBaseUrl+'get-embedded-code', { responseType: 'text' as 'json' }).subscribe(
      response => {
        console.log('>> ', response);
        this.textToCopy = response;
        this.initialString = response;
        this.replaceCode();
      },
      error => {
        console.error('error:', error);
        this.showError('Error', error.statusText);
      }
    );
  }

  setEmbeddedSource() {
    if(this.newEmbeddedSource !== '' && this.newEmbeddedSource !== null){
      this.http.post<any>(environment.apiBaseUrl+'set-embedded-source', { url: this.newEmbeddedSource}).subscribe(
        response => {
          this.showSuccess('Source Added Successfully','')
          this.newEmbeddedSource = null;
          this.embeddedSources = response;
        },
        error => {
           console.error('error:', error);
           this.showError('Error', error.statusText);
        }
      );
    } else {
      this.showError('Enter Source URL','');
    }
  }

  deleteEmbeddedSource(source_id: number) {
    this.http.get<any>(environment.apiBaseUrl+'delete-embedded-source/'+source_id).subscribe(
      response => {
        this.showSuccess('Source Deleted Successfully','')
        this.embeddedSources = response;
      },
      error => {
        console.error('error:', error);
        this.showError('Error', error.statusText);
      }
    );
  }

}
