<div
  ngxStripeCardGroup
  [formGroup]="stripeTest"
  [elementsOptions]="elementsOptions"
>
    <div class="row my-3">
        <div class="col-12">
            <label for="name" class="form-label">{{ 'CARD_NAME' | translate }}</label>
            <input class="sc-form-control" formControlName="name" placeholder="{{ 'CARD_NAME' | translate }}"/>
        </div>
         <div class="col-12">
            <label for="email" class="form-label">{{ 'EMAIL' | translate }}</label>
            <input class="sc-form-control" formControlName="email" placeholder="{{ 'EMAIL' | translate }}"/>
        </div> 
        <div class="col-12">
            <label for="creditCard" class="form-label">{{ 'CRIDIT_CARD' | translate }}</label>
            <ngx-stripe-card-number class="sc-form-control" [options]="cardOptions"></ngx-stripe-card-number>
        </div> 
        <div class="col-6">
            <label for="expiry" class="form-label">{{ 'EXPIRY' | translate }}</label>
            <ngx-stripe-card-expiry class="sc-form-control" [options]="cardOptions"></ngx-stripe-card-expiry>
        </div> 
        <div class="col-6">
            <label for="cvc" class="form-label">CVC</label>
            <ngx-stripe-card-cvc class="sc-form-control" [options]="cardOptions"></ngx-stripe-card-cvc>
        </div>
    </div>
    <button class="btn btn-sm gradient text-light" type="submit" (click)="createToken()">
      CREATE TOKEN
    </button>
</div>
