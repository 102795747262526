<div class="steps-inner c-mb-6">
  <div>
    <div class="mb-3 position-relative img-thumbnail profie-img-container">
      <span>
        <span (click)="openFileInput()">
          <i *ngIf="checkImage" class="fas fa-user user-img-icon"></i>
          <img *ngIf="!checkImage" [src]="profileImageView" class="profie-img" alt="Profile Image">
        </span>
        <span class="profie-img-edit" (click)="openFileInput()"><i class="fas fa-camera"></i></span>
        <input class="d-none" type="file" (change)="fileChangeEvent($event)" accept="image/*" #setProfileImage>
      </span>
    </div>
    <div>
      <p class="m-0 image-suggestion-text">* {{ 'IMG_SUGGESTION_SIZE' | translate }}</p>
      <p class="image-suggestion-text">* {{ 'IMG_SUGGESTION_UPLOAD' | translate }} <strong>"{{ 'IMG_SUGGESTION_PICTURE' | translate }}"</strong>.</p>
    </div>
  </div>
  <!-- <button [disabled]="!uploadBtn || uploadingLoader" class="btn btn-outline-dark lsb-btn-font btn-sm  w-100 mt-2 mb-3" (click)="onUploadProfile()">
    <span *ngIf="!uploadingLoader">{{ 'UPLOAD_IMAGE' | translate }}</span>
    <span *ngIf="uploadingLoader">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      {{ 'UPLOADING' | translate }}
    </span>
  </button> -->
  <div class="form-group mb-3">
    <label for="firstName">{{ 'FIRST_NAME' | translate }}</label>
    <input type="text" (input)="onInputChange()" class="form-control input-select input-white w-100" [(ngModel)]="user.name" placeholder="{{ 'FIRST_NAME' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
  </div>
  <div class="form-group mb-3">
    <label for="lastName">{{ 'LAST_NAME' | translate }}</label>
    <input type="text" (input)="onInputChange()" class="form-control input-select input-white w-100" [(ngModel)]="user.last_name" placeholder="{{ 'LAST_NAME' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
  </div>
  <div class="form-group mb-3">
    <label for="profileEmail">{{ 'EMAIL' | translate }}</label>
    <input type="email" (input)="onInputChange()" class="form-control input-select input-white w-100" [(ngModel)]="user.email" placeholder="{{ 'EMAIL_ADDRESS' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
  </div>
  <div class="form-group mb-3">
    <label for="exampleInputEmail1">{{ 'MOBILE' | translate }}</label>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text"><img src="../../assets/images/ca.png" alt="CA" height="10px" width="15px" style="margin-right: 5px;">+1</span>
      </div>
      <input type="text" inputmode="decimal" maxlength="12" (input)="onInputChange()" class="form-control input-select input-white" [(ngModel)]="user.phone" (keypress)="allowDecimal($event)" placeholder="{{ 'MOBILE' | translate }}" style="height: 42px" onkeypress="return event.charCode >= 48 && event.charCode <= 57" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxPhoneNumberFormatter>
    </div>
  </div>
  <div class="input-group my-3">
    <span class="sub-font c-pointer" style="text-decoration: underline" (click)="openUpdatePassword()">{{ 'UPDATE_PASSWORD' | translate }}</span>
  </div>
  <!-- <div class="form-group mb-3">
            <label for="exampleInputEmail1">{{ 'MODE_COMM' | translate }}</label>
            <select multiple class="form-select">
                <option selected value="Courriel">{{ 'EMAIL' | translate }}</option>
            </select>
        </div> -->

  <!-- <button class="btn btn-outline-dark lsb-btn-font btn-sm w-25"  (click)="createApplicant()"> Créer </button> -->
  <button *ngIf="isUpdateButtonEnabled || uploadBtn" class="btn btn-outline-dark lsb-btn-font btn-sm  w-100" [disabled]="updatingLoader"  (click)="updateUser()">     
      <span *ngIf="!updatingLoader">{{ 'UPDATE' | translate }}</span>
    <span *ngIf="updatingLoader">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      {{ 'UPDATING' | translate }}
    </span>
  </button>
</div>
