    <div class="col-12">
      <h5 class="c-text-color">{{ 'EMBEDDED_CODE' | translate }}</h5>
    </div>
    <div class="col-12">
      <p class="c-text-color">{{ 'CODE_USAGE' | translate }}</p>
    </div>
    <div class="col-12">
      <div class="form-group">
        <p>{{ 'HEIGHT' | translate }} (0px - 1080px)</p>
        <div class="input-group input-group-sm" [ngClass]="{'mb-4': !embeddedHeight}">
          <div class="input-group-prepend">
            <span class="input-group-text">{{ 'HEIGHT' | translate }}:</span>
          </div>
          <input type="text" class="form-control" aria-label="embeddedHeight" [(ngModel)]="embedded.height" (ngModelChange)="replaceCode()" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
          <div class="input-group-append">
            <span class="input-group-text">px</span>
          </div>
        </div>
        <small class="text-danger mt-1" *ngIf="embeddedHeight">*{{ 'HEIGHT_ERROR' | translate }}</small>
        <input type="range" class="c-range" min="0" max="1080" step="1" id="customRange3" [(ngModel)]="embedded.height" (ngModelChange)="replaceCode()">
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <p>{{ 'WIDTH' | translate }} (0px - 1920px)</p>
        <div class="input-group input-group-sm" [ngClass]="{'mb-4': !embeddedWidth}">
          <div class="input-group-prepend">
            <span class="input-group-text">{{ 'WIDTH' | translate }}:</span>
          </div>
          <input type="text" class="form-control" aria-label="embeddedWidth" [(ngModel)]="embedded.width" (ngModelChange)="replaceCode()" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
          <div class="input-group-append">
            <span class="input-group-text">px</span>
          </div>
        </div>
        <small class="text-danger mt-1" *ngIf="embeddedWidth">*{{ 'WIDTH_ERROR' | translate }}</small>
        <input type="range" class="c-range" min="0" max="1920" step="1" id="customRange3" [(ngModel)]="embedded.width" (ngModelChange)="replaceCode()">
      </div>
    </div>
    <div class="col-12">
      <div class="form-group c-text-area-bg pt-2">
        <button class="float-right c-tooltip border-0" (click)="copyToClipboard()"><i class="far fa-copy mx-3 mb-2"></i><span class="c-tooltiptext">{{ tooltipText }}</span></button>
        <textarea class="form-control c-text-area" rows="8" id="comment" readonly>{{ textToCopy }}</textarea>
      </div>
    </div>
    <div class="col-12">
      <div class="d-flex justify-content-between mt-1">
        <p class="mb-1"><strong>{{ 'CODE_SOURCE' | translate }}:</strong></p>
        <span class="float-right c-tooltip my-auto"><i class="fas fa-info-circle mx-3 mb-2"></i><span class="c-tooltiptext">{{ 'TOOLTIP_EMBEDDED_CODE' | translate }}</span></span>
      </div>
      <div class="input-group input-group-sm mb-3">
        <input type="text" class="form-control" placeholder="{{ 'SOURCE_URL' | translate }}" aria-describedby="basic-addon2" [(ngModel)]="newEmbeddedSource">
        <div class="input-group-append">
          <button class="btn btn-outline-dark" type="button" (click)="setEmbeddedSource()"><i class="fas fa-plus"></i></button>
        </div>
      </div>
      <ul class="list-group" >
        <li class="list-group-item d-flex justify-content-between p-2 mb-1" *ngFor="let embeddedSource of embeddedSources">
          <span class="source-url-list">{{ embeddedSource.url }}</span> 
          <span class="d-flex">
            <span class="badge badge-success" style="float: right" *ngIf="embeddedSource.status == 'added'">{{ 'ADDED' | translate }}</span>
            <span class="badge badge-warning" style="float: right" *ngIf="embeddedSource.status == 'requested'">{{ 'REQUESTED' | translate }}</span>
            <i class="fas fa-times my-auto c-pointer c-pl-1" (click)="deleteEmbeddedSource(embeddedSource.id)"></i>
          </span>
        </li>
      </ul>
    </div>

    <!-- <div class="col-12">
      <div class="text-center my-3">
        <i class="far fa-clock c-fs-100"></i>
      </div>
      <p>{{ 'IDENTITY_CONFIRMATION_IN_PROGRESS' | translate }}</p>
    </div>
    <div class="col-12">
      <p class="text-center c-fs-30 py-3"> . . . </p>
    </div>
    <div class="col-12">
      <div class="text-center my-3">
        <i class="far fa-check-circle c-fs-100 c-text-g-color"></i>
      </div>
      <p>{{ 'IDENTITY_VERIFIED' | translate }}</p>
    </div> -->
