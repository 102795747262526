import { Directive, HostListener, ElementRef  } from '@angular/core';
import { OneColumnLayoutComponent } from '../layouts/one-column/one-column.layout';

@Directive({
  selector: '[ngxThousandSeparator]'
})
export class ThousandSeparatorDirective {
  private typingTimeout: any;

  constructor(private el: ElementRef, private oneColumnComponent: OneColumnLayoutComponent) { }

  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    
    const cursorPosition = input.selectionStart;

    let value = input.value.replace(/\D/g, '');
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    const newValueLength = value.length;
    const cursorOffset = newValueLength - input.value.length;
  
    input.value = value;

    const newCursorPosition = cursorPosition + cursorOffset;
    input.setSelectionRange(newCursorPosition, newCursorPosition);
    this.resetTypingTimer();
  }

  private resetTypingTimer() {
    clearTimeout(this.typingTimeout);

    this.typingTimeout = setTimeout(() => {
      this.autoSave();
    }, 3000);
  }

  private autoSave() {
    this.oneColumnComponent.onFocusOut();
  }

}
