<ngx-fire-work-animation *ngIf="fireworkStatus && !tabScreen"></ngx-fire-work-animation>
<div style="z-index: 997">
  <img
    class="hide-onMobile"
    src="assets/images/house_0.png"
    *ngIf="house === 1"
    alt="logo"
    style="width: 100%"
  />
  <img
    class="hide-onMobile"
    src="assets/images/house_1.png"
    *ngIf="house === 2"
    alt="logo"
    style="width: 100%"
  />
  <img
    class="hide-onMobile"
    src="assets/images/house_2.png"
    *ngIf="house === 3"
    alt="logo"
    style="width: 100%"
  />
  <img
    class="hide-onMobile"
    src="assets/images/house_3.png"
    *ngIf="house === 4"
    alt="logo"
    style="width: 100%"
  />
  <img
    class="hide-onMobile"
    src="assets/images/house_4.png"
    *ngIf="house === 5"
    alt="logo"
    style="width: 100%"
    />
    <div class="hide-onMobile">
        <div clasa="loader"></div>
        <div style="text-align: center">
        <span
        class="span_mini"
        *ngIf="rate === 'proposed'"
        style="font-weight: 500; font-size: 15px"
        >{{ 'PROPOSED_RATE_TITLE' | translate }}</span
        >
        <span
        class="span_mini"
        *ngIf="rate === 'qualifying'"
        style="font-weight: 500; font-size: 15px"
        >{{ 'QUALIFYING_RATE_TITLE' | translate }}</span
        >
        </div>

      
      <div class="mt-3" [ngClass]="switchAdvanced ? 'c-change-view-height' : 'c-view-height'" >
          <!-- ADVANCED VIEW -->
          <div *ngIf="advanced">
            <span class="span_mini"> {{ 'RATIOS_DESCRIPTION' | translate }} </span><br /><br />
            <div class="row" style="height: 20px; width: 100%; padding-left: 5%; padding-right: 0%">
                <div [style.background]="getGDSBackgroundColor()" [style.width]="getGDSwidth()" style="text-align: center">
                    <span style="color: white; font-weight: 500; font-size: 13px">{{ output.gds | number : "1.3-3" }}%</span>
                </div>
                <div style="background: rgb(187, 186, 186)" [style.width]="getGDSwidthInverse()"></div>
            </div>
            <div class="row" style="height: 10px; width: 100%; padding-left: 5%; padding-right: 0%">
                <div class="col span_mini" style="text-align: left; border-top: 1px solid rgb(208, 207, 207)">
                    <span style="margin-left: -17px">0%</span>
                </div>
                <div class="col span_mini" style="text-align: center; border-top: 1px solid rgb(208, 207, 207)">
                    <span>20%</span>
                </div>
                <div class="col span_mini" style="text-align: right; border-top: 1px solid rgb(208, 207, 207)">
                    <span style="margin-right: -30px">39%</span>
                </div>
            </div>
            <br />
            <span class="span_mini"> {{ 'RATIOS_DESCRIPTION_2' | translate }} </span><br /><br />
            <div class="row" style="height: 20px; width: 100%; padding-left: 5%; padding-right: 0%">
                <div [style.background]="getTDSBackgroundColor()" [style.width]="getTDSwidth()" style="text-align: center">
                    <span style="color: white; font-weight: 500; font-size: 13px">{{ output.tds | number : "1.3-3" }}%</span>
                </div>
                <div style="background: rgb(187, 186, 186)" [style.width]="getTDSwidthInverse()"></div>
            </div>
        
            <div class="row" style="height: 10px; width: 100%; padding-left: 5%; padding-right: 0%">
                <div class="col span_mini" style="text-align: left; border-top: 1px solid rgb(208, 207, 207)">
                    <span style="margin-left: -17px">0%</span>
                </div>
                <div class="col span_mini" style="text-align: center; border-top: 1px solid rgb(208, 207, 207)">
                    <span>22%</span>
                </div>
                <div class="col span_mini" style="text-align: right; border-top: 1px solid rgb(208, 207, 207)">
                    <span style="margin-right: -30px">44%</span>
                </div>
            </div>
          </div>
          <!-- SIMPLE VIEW -->
          <div *ngIf="!advanced">
            <span class="span_mini"> {{ 'BORROWING_CAPACITY' | translate }} </span><br /><br />
            <div class="row" style="height: 20px; width: 100%; padding-left: 5%; padding-right: 0%" *ngIf="progressGDSSimpleInt >= progressTDSSimpleInt">
                <div [style.background]="getGDSBackgroundColorSimple()" [style.width]="progressGDSSimple" style="text-align: center">
                    <span style="color: white; font-weight: 500; font-size: 13px">{{ progressGDSSimpleInt | number : "1.3-3" }}%</span>
                </div>
                <div style="background: rgb(187, 186, 186)" [style.width]="inverseSimpleGds"></div>
            </div>
            <div class="row" style="height: 10px; width: 100%; padding-left: 5%; padding-right: 0%" *ngIf="progressGDSSimpleInt >= progressTDSSimpleInt">
                <div class="col span_mini" style="text-align: left; border-top: 1px solid rgb(208, 207, 207)">
                    <span style="margin-left: -17px">0%</span>
                </div>
                <div class="col span_mini" style="text-align: center; border-top: 1px solid rgb(208, 207, 207)">
                    <span>50%</span>
                </div>
                <div class="col span_mini" style="text-align: right; border-top: 1px solid rgb(208, 207, 207)">
                    <span style="margin-right: -30px">100%</span>
                </div>
            </div>
            <br />
            <div class="row" style="height: 20px; width: 100%; padding-left: 5%; padding-right: 0%" *ngIf="progressGDSSimpleInt < progressTDSSimpleInt">
                <div [style.background]="getTDSBackgroundColorSimple()" [style.width]="progressTDSSimple" style="text-align: center">
                    <span style="color: white; font-weight: 500; font-size: 13px">{{ progressTDSSimpleInt | number : "1.3-3" }}%</span>
                </div>
                <div style="background: rgb(187, 186, 186)" [style.width]="inverseSimpleTds"></div>
            </div>
        
            <div class="row" style="height: 10px; width: 100%; padding-left: 5%; padding-right: 0%" *ngIf="progressGDSSimpleInt < progressTDSSimpleInt">
                <div class="col span_mini" style="text-align: left; border-top: 1px solid rgb(208, 207, 207)">
                    <span style="margin-left: -17px">0%</span>
                </div>
                <div class="col span_mini" style="text-align: center; border-top: 1px solid rgb(208, 207, 207)">
                    <span>50%</span>
                </div>
                <div class="col span_mini" style="text-align: right; border-top: 1px solid rgb(208, 207, 207)">
                    <span style="margin-right: -30px">100%</span>
                </div>
            </div>
          </div>
      </div>
  </div>

  <div class="my-3 d-flex">
    <!-- <input type="checkbox" class="input-select" style="width: 18px" [(ngModel)]="advanced">  -->
    <div class="my-auto">
        <label class="switch m-0">
            <input type="checkbox" [(ngModel)]="switchAdvanced" (ngModelChange)="getAdvancedView();">
            <span class="slider round"></span>
        </label>  
    </div>
    <span class="span_mini my-auto mx-1">{{ 'SWITCH_ADVANCE_VIEW' | translate }}</span><br /><br />
  </div>

  <div class="my-1">
    <div class="row">
        <div class="d-flex flex-column my-auto col-12 py-1">
            <span class="c-text" *ngIf="application.type == 'preapproval'"><strong>{{ 'MORTGAGE_CAPACITY' | translate }}</strong></span>
            <span class="c-text" *ngIf="application.type == 'purchase' || application.type == 'refinance' || application.type == 'renewal'"><strong>{{ 'MORTGAGE_AMOUNT' | translate }}</strong></span>
            <span class="c-text" [ngClass]="{'highlight': highlightMortgageCapacity}">$ {{ mortgageCapacity | number:'1.2-2' }}</span>
        </div>
        <div class="d-flex flex-column my-auto col-12 py-1">
            <span class="c-text"><strong>{{ 'MONTHLY_PAYMENTS' | translate }}</strong></span>
            <span class="c-text" [ngClass]="{'highlight': highlightMonthlyMortgageAmount}">$ {{ monthlyMortgageAmount | number:'1.2-2' }}</span>
        </div>
    </div> 
  </div>

  <!-- Futher Code -->
  <div class="my-3">
    <ngx-type-writer-text [application]="application" [output]="output" [loggedInUpdaterValue]="loggedInUpdaterValue"></ngx-type-writer-text>
    <!-- <p class="c-text pt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent tortor libero, tristique sed semper a, rhoncus a neque. Sed commodo mauris et enim laoreet, commodo ultrices lorem congue. Etiam facilisis vel erat in scelerisque. Fusce metus urna, porttitor vitae maximus et, tincidunt sit amet nisi. Aliquam placerat aliquam condimentum.</p>
    <p class="c-text"> Aliquam non rutrum massa, vel scelerisque elit. Suspendisse ac euismod nulla. Morbi mattis mauris non massa imperdiet, sit amet congue dolor varius. Nullam eget hendrerit risus, at facilisis nulla.</p> -->
  </div>

</div>



