<ngx-toaster></ngx-toaster>
<nb-layout windowMode class="with-scroll">
    <nb-layout-header id="header" fixed class="white" [ngClass]="{'transparent-bg': getBbModalValue}">
        <ngx-header class="c-display-block" [headerSize] ="headerSize" [mobileScreen]="mobileScreen" [mobileViewer]="mobileViewer" [loggedInUpdaterValue]="loggedInUpdaterValue" [userData]="user" [brokerData]="broker" [bbModalValue]="getBbModalValue" (bbModalClose)="bbModalClose($event)"></ngx-header>
    </nb-layout-header>

    <nb-sidebar class="small-sc menu-sidebar menu-mobile bg-responsive" tag="menu-sidebar" style="background: url('assets/images/Rectangle.png');">
      <!--<ng-content select="nb-menu"></ng-content>-->
      <ngx-left-sidebar class="l-sidebar-position" (lockingEvent)="triggerLock()" (csvModalValue)="getCsvGenerateModal($event)" (applicantEvent)="receiveApplicant($event)" (loaderUpdater)="loaderUpdater($event)" (messageEvent)="receiveTheUser($event)" (brokerEvent)="receiveTheBroker($event)" (getActiveApplicant)="getSelectedApplicant($event)" (applicationEvent)="receiveTheApplication($event)" (kingstateNewsEvent)="newsModal($event)" (bbModalOpen)="bbModalOpen()" [appExist]="showUrl" (loggedInUpdater)="resetApplication($event);" (setGoToApplication)="goToApplication($event)"></ngx-left-sidebar> 
    </nb-sidebar>

    <nb-sidebar id="rightSideBar" class="menu-sidebar white back-ground sidebar-content footer-bar right align-items-center" responsive tag="menu-sidebar" style="background: url('assets/images/rightsidebar.png'); z-index: 1004;">
      <!--<ng-content select="nb-menu"></ng-content>-->
      <ngx-right-sidebar *ngIf="!mobileScreen" [output]="outputData" [application]="application" [loggedInUpdaterValue]="loggedInUpdaterValue" (stepFourWarning)="stepFourWarning($event)"></ngx-right-sidebar> 
    </nb-sidebar>
    

    <nb-layout-column class="black center-content p-0 c-flex-col justify-content-between">
        <!-- <div> -->
            <div>
                <p *ngIf="userStatus && !mobileScreen" class="m-0 py-1 user-status down-animation"><i class="fa fa-solid fa-eye text-danger c-blink"></i> <span>This application is being viewed by</span><strong class=" pl-1">{{ viewer }}</strong></p>
            </div>
            <div class="row" style="position: fixed;">
                <div class="col-12 c-px-10 top-btns-height" [ngClass]="{'top-btns-height-scroll': mobileTabBtnsScroll, 'c-opecity-0': (user?.role.name === 'broker' && applicationSection),'c-opecity-1': (user?.role.name === 'broker' && !applicationSection)}">
                    <div id="mobile-menu" class="mob-header-bg mobile-menu-active lg-menu-hide top-btns-height" [ngClass]="{'c-pe-none': (user?.role.name === 'applicant' && userStatus) || tempBlock, 'top-btns-height-scroll down-animation-scroll': mobileTabBtnsScroll, 'down-animation': !mobileTabBtnsScroll}">
                        <div #mobileMenu class="row">
                            <div class="col-3 mob-header p-2" [ngClass]="{'mob-header-scroll c-mob-menu-active-scroll': mobileTabBtnsScroll, 'c-mob-menu-active': !inActiveStepOne}" id="menuStepOne" (click)="scrollToHeight('stepOne')">
                                <i class="fas fa-exchange-alt mb-1 c-mob-menu-icon" [ngClass]="{'c-font-16': mobileTabBtnsScroll}"></i>
                                <span *ngIf="!mobileTabBtnsScroll" class="heading-font-mob">{{ 'APPLICATION_TYPE' | translate }}</span>
                            </div>
                            <div class="col-3 mob-header p-2" [ngClass]="{'mob-header-scroll  c-mob-menu-active-scroll': mobileTabBtnsScroll}" id="menuStepTwo" (click)="scrollToHeight('stepTwo');">
                                <i class="fas fa-user mb-1 c-mob-menu-icon" [ngClass]="{'c-font-16': mobileTabBtnsScroll}"></i>
                                <span *ngIf="!mobileTabBtnsScroll" class="heading-font-mob">{{ 'BUYER_PROFILE' | translate }}</span>
                            </div>
                            <div class="col-3 mob-header p-2" [ngClass]="{'mob-header-scroll  c-mob-menu-active-scroll': mobileTabBtnsScroll}" id="menuStepThree" (click)="scrollToHeight('stepThree');">
                                <i class="fas fa-university mb-1 c-mob-menu-icon" [ngClass]="{'c-font-16': mobileTabBtnsScroll}"></i>
                                <span *ngIf="!mobileTabBtnsScroll" class="heading-font-mob">{{ 'LOAN_INFORMATION' | translate }}</span>
                            </div>
                            <div class="col-3 mob-header p-2" [ngClass]="{'mob-header-scroll  c-mob-menu-active-scroll': mobileTabBtnsScroll}" id="menuStepFour" (click)="scrollToHeight('stepFour');">
                                <i class="fas fa-signal mb-1 c-mob-menu-icon" [ngClass]="{'c-font-16': mobileTabBtnsScroll}"></i>
                                <span *ngIf="!mobileTabBtnsScroll" class="heading-font-mob">{{ 'RESULT' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div *ngIf="stickyScenarioButton" class="c-scenario-btn-scroll down-animation">
                        <p class="p-1 m-0 text-light">{{ 'SCENARIO' | translate }} {{ activeScenario + 1 }}</p>
                    </div>
                </div>
                <div class="col-12" [ngClass]="{'mt-5': (user?.role?.name === 'applicant' && userStatus) || tempBlock || (userStatus && !mobileScreen)}">                
                    <div #mainBody (scroll)="onScrollSteps($event); onScrollStepsValue($event); getDivValuesOnScroll($event)" class="main-body c-overflow-x c-scrollbar" [ngClass]="{'overflow-hidden': (user?.role.name === 'broker' && applicationSection) || ((user?.role.name === 'applicant' && userStatus) || tempBlock)}">
                        <!-- <p *ngIf="userStatus" class="m-0 py-1 user-status down-animation"><i class="fa fa-solid fa-eye text-danger c-blink"></i><strong class=" pl-1">Account: </strong>Broker</p> -->
                        <div *ngIf="!mobileScreen" [ngclass]="{'my-2 px-3': application.application_name !== ''}">
                            <span *ngIf="application && application.id" class="px-3 p-lg-3 heading-font gradient-heading-color">{{ application.application_name }}</span>
                        </div>
                        <div *ngIf="loadSpinner" class="loader-box">
                            <div class="c-spinner">
                                <div class="blob blob-0"></div>
                            </div>
                        </div>
                        <span #idBreakPoint></span>
                        <!-- <div class="header-toaster" *ngIf="!user">
                            <span class="heading-font" style="display:inline-block; margin-left:10px">User is not logged In</span>
                        </div> -->
                        <!-- <div class="header-toaster" *ngIf="user" style="align-content: center">
                            <button class="normal-button" (click)="saveApplication()"> Save Application (temporary)</button>
                        </div> -->

                        
                        <div #mobileTabs class="px-3 p-lg-3 mb-md-15 main-step-w" [ngClass]="{'c-pe-none': (user?.role.name === 'applicant' && userStatus) || tempBlock, 'c-app-h': (user?.role.name === 'broker' && applicationSection)}">
                            <div *ngIf="applicationSection" [ngClass]="{'c-opecity-0': (user?.role.name === 'broker' && !applicationSection),'c-opecity-1 c-app-h position-relative': (user?.role.name === 'broker' && applicationSection)}">
                                <div class="scrollBar-hide section-division" [ngClass]="{'c-app-pos': (user?.role.name === 'broker' && applicationSection)}">
                                    <div class="scrollable px-md-4 py-4">
                                        <div class="">
                                            <div class="row">
                                                <div class="col-12">
                                                    <button type="button" class="btn gradient py-3 px-2 my-3 application-int-btns" (click)="newApplicantModel('open')" aria-label="Close">
                                                        {{ 'CREATE_NEW_APP_BTN' | translate }}
                                                    </button>
                                                </div>
                                                <div class="col-12">
                                                    <div class="my-3 d-flex justify-content-center">
                                                        <div class="c-separator-btn w-100 c-font-12">{{ 'OR' | translate }}</div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <button type="button" class="btn gradient py-3 px-2 my-3 application-int-btns" (click)="testApplicationBroker()" aria-label="Close">
                                                        {{ 'CREATE_TEST_APP_BTN' | translate }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div [ngClass]="{'c-opecity-0': (user?.role.name === 'broker' && applicationSection),'c-opecity-1': (user?.role.name === 'broker' && !applicationSection)}">
                                <!-- THIS IS THE FIRST STEP -->
                                <div #myDiv0 class="scrollBar-hide section-division" id="stepOne" [ngClass]="{'open': isOpen1}">
                                    <div class="row" (click)="toggleDiv1()">
                                        <div class="col-12 d-flex steps-bg">
                                            <div class="mobile-content">
                                                <span class="steps-icon" [ngClass]="{'steps-icon-active': isOpen1}">I</span>
                                            </div>
                                            <span class="heading-font ml-md-4 my-md-auto mobile-heading-color">{{ 'APPLICATION_TYPE_CAP' | translate }}</span>
                                        </div>
                                    </div>
                                    <div class="scrollable px-md-4 py-4">
                                        <div class="c-padding-tab">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="steps-inner">
                                                        <p class="normal-font b-padding mb-3">{{ 'APPLICATION_TYPE_DESCRIPTION' | translate }}</p>
                                                    </div>
                                                    <div id="applicationTypes" class="steps-inner">
                                                        <label class="label-font mb-2"  style="margin-bottom:-10px">{{ 'APPLICATION_TYPE' | translate }}:</label><br>
                                                        <select name="APPLICATION_TYPE" class="form-select one-col-dropdown APPLICATION_TYPE"  (focus)="getInputfield('applicationTypes','APPLICATION_TYPE')" [(ngModel)]="application.type" (ngModelChange)="reRunCalculations(); onFocusOut()">
                                                        <option value="purchase">{{ 'APPLICATION_TYPE_PURCHASE' | translate }}</option>
                                                        <!-- <option value="improvement">{{ 'APPLICATION_TYPE_IMPROVEMENT' | translate }}</option> -->
                                                        <option value="refinance">{{ 'APPLICATION_TYPE_REFINANCE' | translate }}</option>
                                                        <option value="renewal">{{ 'APPLICATION_TYPE_RENEWAL' | translate }}</option>
                                                        <option value="preapproval">{{ 'APPLICATION_TYPE_PREAPPROVAL' | translate }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="scrollBarDiv-Height-22">
                                    </div>
                                </div>
            
            
            
                                <!-- THIS IS THE SECOND STEP -->
            
            
                            
                                <div #myDiv class="scrollBar-hide section-division" id="stepTwo" [ngClass]="{'open': isOpen2}">
                                    <div *ngIf="mobileScreen" class="h-divider mb-3">
                                        <div class="shadow"></div>
                                    </div>
                                    <div class="row" (click)="toggleDiv2()">
                                        <div class="col-12 d-flex steps-bg">
                                            <div class="mobile-content">
                                                <span class="steps-icon" [ngClass]="{'steps-icon-active': isOpen2}">II</span>
                                            </div>
                                            <span class="heading-font ml-md-4 my-md-auto mobile-heading-color">{{ 'BUYER_INFORMATION_CAP' | translate }}</span>
                                        </div>
                                    </div>
                                    <div class="scrollable px-md-4 py-4">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="steps-inner c-padding-tab">
                                                    <span class="normal-font b-padding">{{ 'BUYER_INFORMATION_DESCRIPTION' | translate }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Add Applicant button -->
                                        <div class="row">
                                            <div id="addBorrower" class="col-12 pt-2" *ngIf="addButton === true">
                                                <button class="btn btn-outline-light ml-3 c-remove-btn-text c-add-btn-width" (click)="newApplicantModal('open')" style="float: right;">{{ 'ADD_BORROWER' | translate }} </button>
                                            </div>
                                        </div>
                                        <br>
                                        <!-- Applicants Nav Bar -->
                                        <div *ngIf="!mobileScreen" class="steps-inner c-padding-tab w-100">
                                            <div class="row px-3">
                                                <div class="col-lg-3 tab p-2 text-center text-light mb-2 getApplicants" style="padding-left: 0px; padding-right: 0px"  [ngClass]="{'currentlyActive': applicant.currentlyActive}" *ngFor="let applicant of application.applicants; let i = index" (click)="selectApplicant(i); delayedSumOfIncomes(); delayedSumOfLiabilities(); delayedCalculateMonthlyPropertyCost(); delayedSumOfRevenues();">
                                                    <span>{{applicant.first_name}} {{applicant.last_name}} <span (click)="newApplicantModal('open', 'editMode')" class="edit-btn-icon"><i class="far fa-edit"></i></span></span>
                                                    <!--  *ngIf="!applicant.buttonEdit" (click)=" getActiveApplicant(i);"<input class="c-btn-input" type="text" *ngIf="applicant.buttonEdit" [(ngModel)]="applicant.first_name"
                                                    (focusout)="onFocusOut(); closeEditApplicantButton(applicant, i)" (blur)="onFocusOut(); closeEditApplicantButton(applicant, i)" (keyup.enter)="onFocusOut(); closeEditApplicantButton(applicant, i)"> -->
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Applicants Dropdown Mobile -->
                                        <div *ngIf="mobileScreen" class="row">
                                            <div #mobApplicantsDropdown class="col-12">
                                                <div class="cm-dropdown" [ngClass]="{'active': applicantsDropdown}">
                                                    <p class="cm-textBox form-control input-select w-100 gradient text-light" (focus)="toggleDropdown('close')" (focusout)="onFocusOut()" (blur)="onFocusOut()">{{current.first_name}} {{current.last_name}} <span (click)="newApplicantModal('open', 'editMode')" class="edit-btn-icon"><i class="far fa-edit"></i></span></p>
                                                    <span class="arrow-dropdown px-2" (click)="toggleDropdown('open')"><i class="fas fa-chevron-down"></i></span>
                                                    <div class="cm-option">
                                                        <div [ngClass]="{'currentlyActive': applicant.currentlyActive}" *ngFor="let applicant of application.applicants; let i = index"  (click)="selectApplicant(i); delayedSumOfIncomes(); delayedSumOfLiabilities(); delayedCalculateMonthlyPropertyCost(); delayedSumOfRevenues(); toggleDropdown('close')">{{applicant.first_name}} {{applicant.last_name}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="steps-inner c-padding-tab">
                                            <hr style="margin-top: 0; border-color: #505050;">
                                        </div>
                                        <!-- <div class="row c-padding-tab">
                                            <div class="col">
                                                <button class="normal-button" (click)="removeApplicant()" style="text-decoration: none; border: 0px">Remove applicant</button>
                                            </div>
                                        </div> -->
                                        <!-- Show Hide Applicant Edit Field -->
                                        <div class="row c-padding-tab" *ngIf="editButton === true">
                                            <!-- <div class="col-6">
                                                <button class="normal-button" (click)="showEditField()" style="text-decoration: none; border: 0px">+ {{ 'EDIT_BORROWER_OPEN' | translate }}</button>
                                            </div> -->
                                            <div class="col-12 my-auto" *ngIf="removeApplicantButton === true">
                                                <button class="btn btn-outline-light btn-sm ml-3 mb-3 c-remove-btn-text normal-button c-add-btn-width float-right" (click)="removeApplicantModal('open')">{{ 'REMOVE_BORROWER' | translate }}</button>
                                            </div>
                                        </div>
                                        <div class="row c-padding-tab" *ngIf="editButton === false">
                                            <div class="col">
                                                <button class="normal-button" (click)="hideEditField()" style="text-decoration: none; border: 0px;">- {{ 'EDIT_BORROWER_CLOSE' | translate }}</button>
                                            </div>
                                        </div>
                                        <!-- Applicant information fields -->
                                        <!-- <div *ngIf="!mobileScreen" id="applicantEditForm" class="row c-padding-tab py-4">
                                            <div class="col-lg-6">
                                                <div class="steps-inner">
                                                    <label class="label-font">{{ 'BORROWER_FIRST_NAME' | translate }}</label><br>
                                                    <input name="BORROWER_FIRST_NAME" type="text" (focus)="getInputfield('applicantEditForm','BORROWER_FIRST_NAME')" [(ngModel)]="current.first_name" (focusout)="onFocusOut()" class="form-control input-select" style="height: 42px; width: 100%;" >
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="steps-inner">
                                                    <label class="label-font">{{ 'BORROWER_LAST_NAME' | translate }}</label><br>
                                                    <input name="BORROWER_LAST_NAME" type="text" (focus)="getInputfield('applicantEditForm','BORROWER_LAST_NAME')" [(ngModel)]="current.last_name" (focusout)="onFocusOut()" class="form-control input-select" style="height: 42px; width: 100%;" >
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="row c-padding-tab" style="padding-top:20px;" *ngIf="editButton === false">
                                            <div class="col-lg-6">
                                                <label class="label-font">{{ 'BORROWER_EMAIL' | translate }}</label><br>
                                                <input type="text" [(ngModel)]="current.email" (focusout)="onFocusOut()" class="form-control input-select" style="height: 42px; width: 100%;" >
                                            </div>
                                            <div class="col-lg-6">
                                                <label class="label-font">{{ 'BORROWER_PHONE' | translate }}</label><br>
                                                <div class="input-group mb-3">
                                                    <input type="text" inputmode="decimal" [(ngModel)]="current.phone" (focusout)="onFocusOut()" class="form-control input-select" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="steps-inner c-padding-tab" style="padding-top: 20px;" *ngIf="editButton === false">
                                            <hr style="border-color: #505050;">
                                        </div>
                                        <!-- Toggle Simple and Advanced Mode -->
                                        <div class="steps-inner c-padding-tab">
                                            <div class="tab">
                                                <button class="tablinks" (click)="openTab('simple')" [ngClass]="{'currentlyActive': isActive1}">{{ 'SIMPLE_ENTRY' | translate }}</button>
                                                <button class="tablinks" (click)="openTab('advanced')" [ngClass]="{'currentlyActive': isActive2}">{{ 'ADVANCED_ENTRY' | translate }}</button>
                                            </div>
                                        </div>
                                        <!-- Basic Source of income fields -->
                                        <ng-container *ngFor="let income of incomes; let i = index">
                                        <span textid="sourceIncome_txt"></span>
                                        <div [id]="'sourceIncome_' + i" class="row c-padding-tab" style="padding-top:20px;" *ngIf="(current.mode === 'simple' && income.mode_type === 'simple') || current.mode === 'advanced'">
                                            <!-- <div *ngIf="income.mode_type == current.mode"> -->
                                                <div class="col-12" *ngIf="incomes.length > 1 && current.mode === 'advanced'" style="padding-top: 25px;">
                                                    <span class="square-button float-right" (click)="removeIncome(i);"><i class="fas fa-trash-alt"></i></span>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="steps-inner">
                                                        <label class="label-font">{{ 'EMPLOYMENT_STATUS' | translate }}</label>                                             
                                                        <select [name]="'EMPLOYMENT_STATUS_' + i" class="form-select one-col-dropdown EMPLOYMENT_STATUS_{{i}}" (focus)="getInputfield('sourceIncome_' + i , 'EMPLOYMENT_STATUS_' + i)" [(ngModel)]="income.type" (ngModelChange)="reRunCalculations(); onFocusOut()">
                                                            <option value="salary">{{ 'EMPLOYEE' | translate }}</option>
                                                            <option value="commission">{{ 'COMMISSION' | translate }}</option>
                                                            <option value="self">{{ 'SELF_EMPLOYED' | translate }}</option>
                                                            <option value="other">{{ 'OTHER_EMPLOYMENT_INCOME' | translate }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-lg-6" *ngIf="current.mode === 'simple'">
                                                    <div class="steps-inner">
                                                        <label class="label-font">{{ 'EMPLOYMENT_STATUS' | translate }}</label>                                             
                                                        <select [name]="'EMPLOYMENT_STATUS_' + i" class="form-select one-col-dropdown" (focus)="getInputfield('sourceIncome_' + i , 'EMPLOYMENT_STATUS_' + i)" [(ngModel)]="income.type" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()">
                                                            <option value="salary">{{ 'EMPLOYEE' | translate }}</option>
                                                            <option value="commission">{{ 'COMMISSION' | translate }}</option>
                                                            <option value="self">{{ 'SELF_EMPLOYED' | translate }}</option>
                                                            <option value="other">{{ 'OTHER_EMPLOYMENT_INCOME' | translate }}</option>
                                                        </select>
                                                    </div>
                                                </div> -->
                                                <div class="col-lg-6">
                                                    <div class="steps-inner">
                                                        <!-- <label class="label-font" *ngIf="current.mode === 'advanced'">{{ 'ADD_INCOME_SOURCE' | translate }}</label> -->
                                                        <label class="label-font">{{ 'ADD_INCOME_SOURCE_ANNUAL' | translate }}</label>
                                                        <div class="input-group mb-3">
                                                            <div class="input-group-prepend gradient">
                                                            <span class="input-group-text"><span class="" style="color:white">$</span></span>
                                                            </div>
                                                            <input [name]="'ADD_INCOME_SOURCE_' + i" type="text" inputmode="decimal" (focus)="getInputfield('sourceIncome_' + i, 'ADD_INCOME_SOURCE_' + i)" [(ngModel)]="income.value" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()" class="form-control input-select ADD_INCOME_SOURCE_{{i}}" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-lg-6" *ngIf="current.mode === 'advanced'">
                                                    <div class="steps-inner">
                                                        <label class="label-font">{{ 'INCOME_PERIOD' | translate }}</label>
                                                        <select [name]="'INCOME_PERIOD_' + i" class="form-select one-col-dropdown" (focus)="getInputfield('sourceIncome_' + i, 'INCOME_PERIOD_' + i)" [(ngModel)]="income.frequency" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()">
                                                            <option value="monthly">{{ 'MONTHLY' | translate }}</option>
                                                            <option value="yearly">{{ 'YEARLY' | translate }}</option>
                                                        </select>
                                                    </div>
                                                </div> -->
                                            <!-- </div> -->
                                        </div>
                                        </ng-container>
                                        
                                        <!-- Add new basic source of income -->
                                        <div id="addSourceIncome" class="row pt-3 c-padding-tab" *ngIf="current.mode === 'advanced'">
                                            <div class="col-12 pt-2">
                                                <div class="steps-inner d-flex">
                                                    <div class="square-button-big py-2 px-3" (click)="addResourceModal('open', 'newIncome'); getInputfield('addSourceIncome', '')">
                                                        <span class="mx-1">+</span>
                                                        <span class="normal-font b-padding" style="font-size: 14px !important">{{ 'ADD_INCOME_FOR_APPLICANT' | translate }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="steps-inner c-padding-tab" style="padding-top: 20px;">
                                            <hr style="border-color: #505050;">
                                        </div>                            
                                        <div class="row" style="padding-top:10px;">
                                            <div class="col-lg-12">
                                                <div class="steps-inner c-padding-tab">
                                                    <!-- <div class="tab">
                                                        <button class="tablinks" (click)="openTab('simple'); onFocusOut()" [ngClass]="{'currentlyActive': isActive1}">{{ 'SIMPLE_ENTRY' | translate }}</button>
                                                        <button class="tablinks" (click)="openTab('advanced'); onFocusOut()" [ngClass]="{'currentlyActive': isActive2}">{{ 'ADVANCED_ENTRY' | translate }}</button>
                                                    </div> -->
                                                    <!-- Simple liability view -->
                                                    <div id="tab1" class="tabcontent" *ngIf="current.mode === 'simple'">
                                                        <div class="row" style="padding-top:10px;">
                                                            <div class="col-lg-6" style="padding-top: 0px;">
                                                                <div class="steps-inner">
                                                                    <label class="label-font">{{ 'TOTAL_MONTHLY_OBLIGATIONS' | translate }}</label><br>
                                                                    <span class="normal-font b-padding">$ {{+totalLiabilities | number:'1.2-2'}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="row" style="padding-top:20px"> -->
                                                        <ng-container *ngFor="let liability of liabilities; let i = index">
                                                            <span textid="advancedLiability_txt"></span>
                                                            <div [id]="'advancedLiability_' + i" style="padding-top:20px" *ngIf="current.mode == liability.mode_type">
                                                                <div class="row">
                                                                    <div class="col-lg-6">
                                                                        <div class="steps-inner">
                                                                            <label class="label-font">{{ 'CREDIT_BALANCE' | translate }}</label><br>
                                                                            <div class="input-group mb-3">
                                                                                <div class="input-group-prepend gradient">
                                                                                    <span class="input-group-text"><span class="" style="color:white">$</span></span>
                                                                                </div>
                                                                                <input type="text" [name]="'CREDIT_BALANCE_' + i" inputmode="decimal" (focus)="getInputfield('advancedLiability_' + i, 'CREDIT_BALANCE_' + i)" (focusout)="onFocusOut()" [(ngModel)]="liability.balance" (ngModelChange)="reRunCalculations()" class="form-control input-select CREDIT_BALANCE_{{i}}" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                                                <!-- <input type="text" class="form-control input-select" placeholder="3,000" style="height: 42px" [(ngModel)]="totalRevenues" (ngModelChange)="reRunCalculationsNoLibilityNoRevenue()" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;"> -->
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-lg-6">
                                                                        <div class="steps-inner">
                                                                            <label class="label-font">{{ 'RECURRING_DEBT_PAYMENTS' | translate }}</label><br>
                                                                            <div class="input-group mb-3">
                                                                                <div class="input-group-prepend gradient">
                                                                                    <span class="input-group-text"><span class="" style="color:white">$</span></span>
                                                                                </div>
                                                                                <!-- <input type="text" class="form-control input-select" placeholder="3,000" style="height: 42px" [(ngModel)]="totalLiabilities" (ngModelChange)="reRunCalculationsNoLibilityNoRevenue()" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;"> -->
                                                                                <input type="text" [name]="'RECURRING_DEBT_PAYMENTS_' + i" inputmode="decimal" (focus)="getInputfield('advancedLiability_' + i, 'RECURRING_DEBT_PAYMENTS_' + i)" (focusout)="onFocusOut()" [(ngModel)]="liability.payment" (ngModelChange)="reRunCalculations()" class="form-control input-select RECURRING_DEBT_PAYMENTS_{{i}}" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>                                        
                                                    <!-- Advance View -->
                                                    <!-- Advanced revenue -->
                                                    <div id="tab2" class="tabcontent" *ngIf="current.mode === 'advanced'">
                                                        <span textid="advancedRevenue_txt"></span>
                                                        <div [id]="'advancedRevenue_' + i" class="row" style="padding-top:20px;" *ngFor="let revenue of revenues; let i = index">
                                                            <div class="col-12" *ngIf="revenues.length > 1" style="padding-top: 25px;">
                                                                <span class="square-button float-right" (click)="removeRevenues(i);"><i class="fas fa-trash-alt"></i></span>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="steps-inner">
                                                                    <label class="label-font">{{ 'REVENUE_TYPE' | translate }}</label><br>
                                                                    <select [name]="'REVENUE_TYPE_' + i" class="form-select one-col-dropdown REVENUE_TYPE_{{i}}" (focus)="getInputfield('advancedRevenue_' + i, 'REVENUE_TYPE_' + i)" [(ngModel)]="revenue.type" (ngModelChange)="reRunCalculations(); onFocusOut()">
                                                                        <option value="salary">{{ 'INTEREST_INCOME' | translate }}</option>
                                                                        <option value="commission">{{ 'CHILD_PENSION' | translate }}</option>
                                                                        <option value="self">{{ 'PENSION' | translate }}</option>
                                                                        <option value="other">{{ 'ALIMONY' | translate }}</option>
                                                                        <option value="other">{{ 'OTHER' | translate }}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="steps-inner">
                                                                    <label class="label-font">{{ 'REVENUE_AMOUNT' | translate }}</label><br>
                                                                    <div class="input-group mb-3">
                                                                        <div class="input-group-prepend gradient">
                                                                            <span class="input-group-text"><span class="" style="color:white">$</span></span>
                                                                        </div>
                                                                        <input type="text" [name]="'REVENUE_AMOUNT_' + i" inputmode="decimal" (focus)="getInputfield('advancedRevenue_' + i, 'REVENUE_AMOUNT_' + i)" [(ngModel)]="revenue.value" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()" class="form-control input-select REVENUE_AMOUNT_{{i}}" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="steps-inner">
                                                                    <label class="label-font">{{ 'REVENUE_PERIOD' | translate }}<br></label><br>
                                                                    <select [name]="'REVENUE_PERIOD_' + i" class="form-select one-col-dropdown REVENUE_PERIOD_{{i}}" (focus)="getInputfield('advancedRevenue_' + i, 'REVENUE_PERIOD_' + i)" [(ngModel)]="revenue.frequency" (ngModelChange)="reRunCalculations(); onFocusOut()">
                                                                        <option value="monthly">{{ 'MONTHLY' | translate }}</option>
                                                                        <option value="yearly">{{ 'YEARLY' | translate }}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- Add revenue -->
                                                        <div id="addAdvancedRevenue" class="row pt-3" style="padding-top:40px">
                                                            <div class="col-12 pt-2">
                                                                <div class="steps-inner d-flex">
                                                                    <div class="square-button-big py-2 px-3" (click)="addResourceModal('open', 'newRevenue'); getInputfield('addAdvancedRevenue','')">
                                                                        <span class="mx-1">+</span>
                                                                        <span class="normal-font b-padding" style="font-size: 14px !important">{{ 'ADD_REVENUE_SOURCE' | translate }}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="steps-inner" style="padding-top: 20px;">
                                                            <hr style="border-color: #505050;">
                                                        </div>                                            
                                                        <!-- Advance liability View -->
                                                        <div class="row" style="padding-top:10px;">
                                                            <div class="col-lg-6" style="padding-top: 0px;">
                                                                <div class="steps-inner">
                                                                    <label class="label-font">{{ 'TOTAL_MONTHLY_OBLIGATIONS' | translate }}</label><br>
                                                                    <span class="normal-font b-padding">$ {{totalLiabilities | number:'1.2-2'}}</span>
                                                                </div>
                                                            </div>
                                                        </div>                                            
                                                        <ng-container *ngFor="let liability of liabilities; let i = index">
                                                        <div class="row" style="padding-top:20px" *ngIf="liability.mode_type === current.mode">
                                                            <!-- <div class="col-2 border-right" style="max-width: 60px;">
                                                                <div class="steps-inner checkbox-col-pos">
                                                                    <input type="checkbox" class="form-control input-select" style="width: 18px" (change)="onFocusOut()" (focus)="getInputfield('advancedLiability_' + i, '')" [(ngModel)]="liability.active" (ngModelChange)="calculateMonthly(i); reRunCalculations()" (focusout)="onFocusOut()">
                                                                </div>
                                                            </div> -->
                                                            <span textid="advancedLiability_txt"></span>
                                                            <div class="col-12">
                                                                <div [id]="'advancedLiability_a_' + i" class="row">
                                                                    <div class="col-12" *ngIf="liabilities.length > 1">
                                                                        <span class="square-button float-right" (click)="removeLiabilities(i);"><i class="fas fa-trash-alt"></i></span>
                                                                    </div>
                                                                    <div class="col-lg-6">
                                                                        <div class="steps-inner">
                                                                            <label class="label-font">{{ 'LIABILITY_TYPE' | translate }}<br></label><br>
                                                                            <select [name]="'LIABILITY_TYPE_' + i" class="form-select one-col-dropdown LIABILITY_TYPE_{{i}}" (focus)="getInputfield('advancedLiability_a_' + i, 'LIABILITY_TYPE_' + i)" [(ngModel)]="liability.type" (ngModelChange)="calculateMonthly(i); reRunCalculations(); onFocusOut()">
                                                                                <option value="creditcard">{{ 'CREDIT_CARD' | translate }}</option>
                                                                                <option value="personalloan">{{ 'PERSONAL_LOAN' | translate }}</option>
                                                                                <option value="carloan">{{ 'CAR_LOAN' | translate }}</option>
                                                                                <option value="carlease">{{ 'CAR_LEASE' | translate }}</option>
                                                                                <option value="unsecuredcredit">{{ 'UNSECURED_CREDIT' | translate }}</option>
                                                                                <option value="alimony">{{ 'ALIMONY' | translate }}</option>
                                                                                <option value="childsupport">{{ 'CHILD_SUPPORT' | translate }}</option>
                                                                                <option value="studentloan">{{ 'STUDENT_LOAN' | translate }}</option>
                                                                                <option value="salaryentry">{{ 'SALARY_ENTRY' | translate }}</option>
                                                                                <option value="securedcredit">{{ 'SECURED_CREDIT' | translate }}</option>
                                                                                <option value="incometax">{{ 'INCOM_TAX' | translate }}</option>
                                                                                <option value="wagegarnishment">{{ 'WAGE_GARNISHMENT' | translate }}</option>
                                                                                <option value="lease">{{ 'LEASE' | translate }}</option>
                                                                                <option value="other">{{ 'OTHER' | translate }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6" *ngIf="liability.type === 'creditcard' || liability.type === 'personalloan' || !liability.type === 'carloan' || liability.type === 'unsecuredcredit'">
                                                                        <div class="steps-inner">
                                                                            <label class="label-font">{{ 'LIMIT' | translate }}</label><br>
                                                                            <div class="input-group mb-3">
                                                                                <div class="input-group-prepend gradient">
                                                                                    <span class="input-group-text"><span class="" style="color:white">$</span></span>
                                                                                </div>
                                                                                <input [name]="'LIMIT_' + i" type="text" inputmode="decimal" (focus)="getInputfield('advancedLiability_a_' + i, 'LIMIT_' + i)" (ngModelChange)="calculateMonthly(i); reRunCalculations()" (focusout)="onFocusOut()" [(ngModel)]="liability.limit" class="form-control input-select LIMIT_{{i}}" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6" *ngIf="liability.type === 'creditcard' || liability.type === 'personalloan' || liability.type === 'carloan' || liability.type === 'unsecuredcredit' || liability.type === 'studentloan' || liability.type === 'other'">
                                                                        <div class="steps-inner">
                                                                            <label class="label-font">{{ 'BALANCE' | translate }}</label><br>
                                                                            <div class="input-group mb-3">
                                                                                <div class="input-group-prepend gradient">
                                                                                    <span class="input-group-text"><span class="" style="color:white">$</span></span>
                                                                                </div>
                                                                                <input [name]="'BALANCE_' + i" type="text" inputmode="decimal" (focus)="getInputfield('advancedLiability_a_' + i, 'BALANCE_' + i)" [(ngModel)]="liability.balance" class="form-control input-select BALANCE_{{i}}" placeholder="3,000" style="height: 42px" (ngModelChange)="calculateMonthly(i); reRunCalculations()" (focusout)="onFocusOut()" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                                            </div>
                                                                        </div>
                                                                    </div>                                                        
                                                                    <div class="col-lg-6">
                                                                        <div class="steps-inner">
                                                                            <label class="label-font">{{ 'CONSIDERED_AMOUNT' | translate }}</label><br>
                                                                            <div class="input-group mb-3">
                                                                                <div class="input-group-prepend gradient">
                                                                                    <span class="input-group-text"><span class="" style="color:white">$</span></span>
                                                                                </div>
                                                                                <input [name]="'CONSIDERED_AMOUNT_' + i" type="text" inputmode="decimal" (focus)="getInputfield('advancedLiability_a_' + i, 'CONSIDERED_AMOUNT_' + i)" [readonly]="liability.type === 'creditcard'" (ngModelChange)="calculateMonthly(i); reRunCalculations()" (focusout)="onFocusOut()" [(ngModel)]="liability.payment" class="form-control input-select CONSIDERED_AMOUNT_{{i}}" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                                            </div>
                                                                        </div>
                                                                    </div>                                                        
                                                                </div>
                                                                <div [id]="'advancedLiability_b_' + i" class="row">
                                                                    <div class="col-lg-6" *ngIf="liability.type !== 'unsecuredcredit' && liability.type !== 'creditcard'">
                                                                        <div class="steps-inner">
                                                                            <label class="label-font">{{ 'PAYMENT_FREQUENCY' | translate }}<br></label><br>
                                                                            <select [name]="'PAYMENT_FREQUENCY_' + i" class="form-select one-col-dropdown PAYMENT_FREQUENCY_{{i}}" (focus)="getInputfield('advancedLiability_b_' + i, 'PAYMENT_FREQUENCY_' + i)" [(ngModel)]="liability.frequency" (ngModelChange)="calculateMonthly(i); reRunCalculations(); onFocusOut()">
                                                                                <option value="weekly">{{ 'WEEKLY' | translate }}</option>
                                                                                <option value="biweekly">{{ 'BIWEEKLY' | translate }}</option>
                                                                                <option value="monthly">{{ 'MONTHLY' | translate }}</option>
                                                                                <option value="yearly">{{ 'YEARLY' | translate }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6" *ngIf="liability.type === 'creditcard' && user?.role.name === 'broker'">
                                                                        <div class="steps-inner">
                                                                            <div style="margin-bottom: 0.65rem">
                                                                                <label class="label-font d-flex position-absolute m-0">
                                                                                    <span>{{ 'APPLICABLE_PERCENTAGE' | translate }}</span>
                                                                                    <span class="c-tooltip mx-2" id="basic-addon2"><i class="fas fa-eye"></i><span class="c-tooltiptext">{{ 'BROKER_VIEW' | translate }}</span></span>
                                                                                </label><br>
                                                                            </div>
                                                                            <!-- <select [name]="'APPLICABLE_PERCENTAGE_' + i"class="form-select one-col-dropdown" (focus)="getInputfield('advancedLiability_b_' + i, 'APPLICABLE_PERCENTAGE_' + i)" [(ngModel)]="liability.minimum" (ngModelChange)="calculateMonthly(i); reRunCalculations()" (focusout)="onFocusOut()">
                                                                                <option value="3">3%</option>
                                                                                <option value="5">5%</option>
                                                                            </select> -->
                                                                            <div class="btn-group btn-group-toggle pt-1">
                                                                                <label class="btn c-toggle-btn" [ngClass]="{'gradient': liability.minimum === 3}">
                                                                                    <input type="radio" name="'APPLICABLE_PERCENTAGE_' + i" (click)="liability.minimum = 3; calculateMonthly(i); reRunCalculations(); onFocusOut()"> 3%
                                                                                </label>
                                                                                <label class="btn c-toggle-btn" [ngClass]="{'gradient': liability.minimum === 5}">
                                                                                    <input type="radio" name="'APPLICABLE_PERCENTAGE_' + i" (click)="liability.minimum = 5; calculateMonthly(i); reRunCalculations(); onFocusOut()"> 5%
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6" *ngIf="liability.type === 'unsecuredcredit' && user?.role.name === 'broker'">
                                                                        <div class="steps-inner">
                                                                            <div style="margin-bottom: 0.65rem">
                                                                                <label class="label-font d-flex position-absolute m-0">
                                                                                    <span>{{ 'APPLICABLE_PERCENTAGE' | translate }}</span>
                                                                                    <span class="c-tooltip mx-2" id="basic-addon2"><i class="fas fa-eye"></i><span class="c-tooltiptext">{{ 'BROKER_VIEW' | translate }}</span></span>
                                                                                </label><br>
                                                                            </div>
                                                                            <div class="input-group mb-3">
                                                                                <div class="input-group-prepend gradient"></div>
                                                                                <input [name]="'APPLICABLE_PERCENTAGE_' + i" type="text" inputmode="decimal" (focus)="getInputfield('advancedLiability_b_' + i, 'APPLICABLE_PERCENTAGE_' + i)" (ngModelChange)="calculateMonthly(i); reRunCalculations()" [readonly]="true" (focusout)="onFocusOut()" [(ngModel)]="default_minimum" class="form-control input-select APPLICABLE_PERCENTAGE_{{i}}" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6">
                                                                        <div class="steps-inner">
                                                                            <label class="label-font">{{ 'LIABILITIES_INCUDE_CALCULATION' | translate }}<br></label><br>
                                                                            <!-- <select [name]="'LIABILITIES_INCUDE_' + i" class="form-select one-col-dropdown" (change)="onFocusOut()" (focus)="getInputfield('advancedLiability_a_' + i, 'LIABILITIES_INCUDE_' + i)" [(ngModel)]="liability.active" (ngModelChange)="calculateMonthly(i); reRunCalculations()" (focusout)="onFocusOut()">
                                                                                <option [value]="true">{{ 'YES' | translate }}</option>
                                                                                <option [value]="false">{{ 'NO' | translate }}</option>
                                                                            </select> -->
                                                                            <!-- <div class="btn-group btn-group-toggle pt-1">
                                                                                <label class="btn c-toggle-btn" [ngClass]="{'gradient': liability.active}">
                                                                                    <input type="radio" name="'LIABILITIES_INCUDE_' + i" value="true" (click)="liability.active = true; calculateMonthly(i); reRunCalculations(); onFocusOut()"> {{ 'YES' | translate }}
                                                                                </label>
                                                                                <label class="btn c-toggle-btn" [ngClass]="{'gradient': !liability.active}">
                                                                                    <input type="radio" name="'LIABILITIES_INCUDE_' + i" value="false" (click)="liability.active = false; calculateMonthly(i); reRunCalculations(); onFocusOut()"> {{ 'NO' | translate }}
                                                                                </label>
                                                                            </div> -->
                                                                            <div class="mt-2">
                                                                                <label class="switch m-0">
                                                                                    <input type="checkbox" (change)="onFocusOut()" (focus)="getInputfield('advancedLiability_b_' + i, '')" [(ngModel)]="liability.active" (ngModelChange)="calculateMonthly(i); reRunCalculations()" (focusout)="onFocusOut()">
                                                                                    <span class="slider round"></span>
                                                                                </label>  
                                                                            </div>
                                                                        </div>
                                                                    </div>                                                        
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="steps-inner" style="padding-top: 20px;" *ngIf="i !== liabilities.length - 1 && liability.mode_type === 'advanced'">
                                                            <hr style="border-color: #505050; position: relative; width: 50%;">
                                                        </div>
                                                        </ng-container>
                                                        <!-- Add liability -->
                                                        <div id="addLiability" class="row pt-3" style="padding-top:40px">
                                                            <div class="col-12 pt-2">
                                                                <div class="steps-inner d-flex">
                                                                    <div class="square-button-big py-2 px-3" (click)="addResourceModal('open', 'newLiability'); getInputfield('addLiability','')">
                                                                        <span class="mx-1">+</span>
                                                                        <span class="normal-font b-padding" style="font-size: 14px !important">{{ 'ADD_LIABILITY' | translate }}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="steps-inner" style="padding-top: 20px;">
                                                            <hr style="border-color: #505050;">
                                                        </div>                                            
                                                    <!-- Advanced property -->
                
                                                        <!-- New stuff goes here -->
                                                        <!-- <div class="row">
                                                            <div class="col-2">
                                                                <input type="checkbox" class="form-control input-select" style="width: 19px" [(ngModel)]="application.applicants[activeApplicant].has_properties" (ngModelChange)="reRunCalculations(); onFocusOut();" >
                                                            </div>
                                                            <div class="col-10">
                                                                <p class="normal-button p-0" (click)="showProperyFields()" style="border: 0px; ; text-decoration: underline">{{ 'MORTGAGE_QUESTION' | translate }}</p>
                                                            </div>
                                                        </div>                                             -->
                                                        <div textid="property_txt">
                                                            <div [id]="'property_' + i" *ngFor="let property of application.applicants[activeApplicant].properties; let i = index">
                                                                <div class="row">
                                                                    <div class="col-lg-12" *ngIf="application.applicants[activeApplicant].properties.length > 1" style="padding-top: 25px;">
                                                                        <span class="square-button float-right" (click)="removeProperties(i);"><i class="fas fa-trash-alt"></i></span>
                                                                    </div>
                                                                </div>
                                                                <div [id]="'property_a_' + i" class="row" style="padding-top:40px;">
                                                                    <div class="col-lg-6">
                                                                        <div class="steps-inner">
                                                                            <label class="label-font">{{ 'OBLIGATION_TYPE' | translate }}</label><br>
                                                                            <input name="'OBLIGATION_TYPE_' + i" type="text" placeholder="{{ 'OBLIGATION_TYPE' | translate }}" (focus)="getInputfield('property_a_' + i, 'OBLIGATION_TYPE_' + i)" [(ngModel)]="property.address" (focusout)="onFocusOut()" class="form-control input-select OBLIGATION_TYPE_{{i}}" style="height: 42px; width: 100%;" >
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6">
                                                                        <div class="steps-inner">
                                                                            <label class="label-font">{{ 'OCCUPANCY' | translate }}</label><br>
                                                                            <select [name]="'OCCUPANCY_' + i" class="form-select one-col-dropdown OCCUPANCY_{{i}}" (focus)="getInputfield('property_a_' + i, 'OCCUPANCY_' + i)" [(ngModel)]="property.occupancy" (ngModelChange)="reRunCalculations(); onFocusOut()">
                                                                                <option value="owner">{{ 'OWNER_OCCUPIED' | translate }}</option>
                                                                                <option value="ownerRental">{{ 'OWNER_OCCUPIED_RENTAL' | translate }}</option>
                                                                                <option value="rental">{{ 'RENTAL' | translate }}</option>
                                                                                <option value="second">{{ 'SECOND_HOME' | translate }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6">
                                                                        <div class="steps-inner">
                                                                            <label class="label-font">{{ 'PROPERTY_VALUE' | translate }}</label><br>
                                                                            <div class="input-group mb-3">
                                                                                <div class="input-group-prepend gradient">
                                                                                <span class="input-group-text"><span class="" style="color:white">$</span></span>
                                                                                </div>
                                                                                <input [name]="'PROPERTY_VALUE_' + i" type="text" inputmode="decimal" (focus)="getInputfield('property_a_' + i, 'PROPERTY_VALUE_' + i)" [(ngModel)]="property.propertyValue" (ngModelChange)="onChangeMortgageProperty($event, property); updateMortgagePropertyTax(property); reRunCalculations()" (focusout)="onFocusOut()" class="form-control input-select PROPERTY_VALUE_{{i}}" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                                            </div>
                                                                        </div>
                                                                    </div>                 
                                                                    <div class="col-lg-6">
                                                                        <div class="steps-inner">
                                                                            <label class="label-font">{{ 'PAYMENT_FREQUENCY' | translate }}<br></label><br>
                                                                            <select [name]="'PAYMENT_FREQUENCY_' + i" class="form-select one-col-dropdown PAYMENT_FREQUENCY_{{i}}" (focus)="getInputfield('property_a_' + i, 'PAYMENT_FREQUENCY_' + i)" [(ngModel)]="property.frequency" (ngModelChange)="reRunCalculations(); onFocusOut()">
                                                                                <option value="weekly">{{ 'WEEKLY' | translate }}</option>
                                                                                <option value="biweekly">{{ 'BIWEEKLY' | translate }}</option>
                                                                                <option value="monthly">{{ 'MONTHLY' | translate }}</option>
                                                                                <option value="yearly">{{ 'YEARLY' | translate }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="mobileScreen" class="steps-inner w-100" style="padding-top: 20px;">
                                                                        <hr style="margin-top: 0; border-color: #505050;">
                                                                    </div>
                                                                </div>
                                                                <div [id]="'property_b_' + i" class="row">                                           
                                                                    <div class="col-lg-6">
                                                                        <div class="steps-inner">
                                                                            <label class="label-font">{{ 'PAYMENT_AMOUNT' | translate }}</label><br>
                                                                            <div class="input-group mb-3">
                                                                                <div class="input-group-prepend gradient">
                                                                                <span class="input-group-text"><span class="" style="color:white">$</span></span>
                                                                                </div>
                                                                                <input [name]="'PAYMENT_AMOUNT_' + i" type="text" inputmode="decimal" (focus)="getInputfield('property_b_' + i, 'PAYMENT_AMOUNT_' + i)" [(ngModel)]="property.payment" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()" class="form-control input-select PAYMENT_AMOUNT_{{i}}" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6">
                                                                        <div class="steps-inner">
                                                                            <label class="label-font">{{ 'ANNUAL_PROPERTY_TAX' | translate }}</label><br>
                                                                            <div class="input-group mb-3">
                                                                                <div class="input-group-prepend gradient">
                                                                                <span class="input-group-text"><span class="" style="color:white">$</span></span>
                                                                                </div>
                                                                                <input [name]="'ANNUAL_PROPERTY_TAX_' + i" type="text" inputmode="decimal" (focus)="getInputfield('property_b_' + i, 'ANNUAL_PROPERTY_TAX_' + i)" [(ngModel)]="property.annualpropertytax" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()" class="form-control input-select ANNUAL_PROPERTY_TAX_{{i}}" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6">
                                                                        <div class="steps-inner">
                                                                            <label class="label-font">{{ 'MONTHLY_ENERGY_COST' | translate }}</label><br> 
                                                                            <div class="input-group mb-3">
                                                                                <div class="input-group-prepend gradient">
                                                                                <span class="input-group-text"><span class="" style="color:white">$</span></span>
                                                                                </div>
                                                                                <input [name]="'MONTHLY_ENERGY_COST_' + i" type="text" inputmode="decimal" (focus)="getInputfield('property_b_' + i, 'MONTHLY_ENERGY_COST_' + i)" [(ngModel)]="property.monthlyenergycost" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()" class="form-control input-select MONTHLY_ENERGY_COST_{{i}}" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6">
                                                                        <div class="steps-inner">
                                                                            <label class="label-font">{{ 'MONTHLY_CONDO_FEE' | translate }}</label><br>
                                                                            <div class="input-group mb-3">
                                                                                <div class="input-group-prepend gradient">
                                                                                <span class="input-group-text"><span class="" style="color:white">$</span></span>
                                                                                </div>
                                                                                <input [name]="'MONTHLY_CONDO_FEE_' + i" type="text" inputmode="decimal" (focus)="getInputfield('property_b_' + i, 'MONTHLY_CONDO_FEE_' + i)" [(ngModel)]="property.monthlycondofee" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()" class="form-control input-select MONTHLY_CONDO_FEE_{{i}}" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="mobileScreen" class="steps-inner w-100" style="padding-top: 20px;" >
                                                                        <hr style="margin-top: 0; border-color: #505050;" [ngStyle]="{'border-width': (property.occupancy === 'rental' || property.occupancy === 'ownerRental') ? '1px' : '5px'}">
                                                                    </div>
                                                                </div>
                                                                <span textid="rentalIncome_txt"></span>
                                                                <div [id]="'property_c_' + i" class="row">
                                                                    <div class="col-lg-6" *ngIf="property.occupancy === 'rental' || property.occupancy === 'ownerRental'">
                                                                        <div class="steps-inner">
                                                                            <label class="label-font">{{ 'MONTHLY_RENTAL_INCOME' | translate }}</label><br>
                                                                            <div class="input-group mb-3">
                                                                                <div class="input-group-prepend gradient">
                                                                                <span class="input-group-text"><span class="" style="color:white">$</span></span>
                                                                                </div>
                                                                                <input [name]="'MONTHLY_RENTAL_INCOME_' + i" type="text" inputmode="decimal" (focus)="getInputfield('property_c_' + i, 'MONTHLY_RENTAL_INCOME_' + i)" [(ngModel)]="property.rentalincome" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()" class="form-control input-select MONTHLY_RENTAL_INCOME_{{i}}" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                
                                                                </div>
                                                                <div [id]="'property_d_' + i" class="row" *ngIf="property.occupancy === 'rental' || property.occupancy === 'ownerRental'">
                                                                    <div class="col-12">
                                                                        <div class="steps-inner">
                                                                            <label class="label-font">{{ 'RENTAL_INCOME_OPTIONS' | translate }}</label><br>
                                                                            <select [name]="'RENTAL_INCOME_OPTIONS_' + i" class="form-select one-col-dropdown RENTAL_INCOME_OPTIONS_{{i}}" (focus)="getInputfield('property_d_' + i, 'RENTAL_INCOME_OPTIONS_' + i)" [(ngModel)]="property.revenueOptions" (ngModelChange)="reRunCalculations(); onFocusOut()">
                                                                                <option value="none">{{ 'NONE_OPTION' | translate }}</option>
                                                                                <option value="addPercentage">{{ 'ADD_PERCENTAGE_OPTION' | translate }}</option>
                                                                                <option value="reduceAdd">{{ 'REDUCE_ADD_OPTION' | translate }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6" *ngIf="property.revenueOptions === 'addPercentage' || property.revenueOptions === 'reduceAdd'">
                                                                        <div class="steps-inner">
                                                                            <label class="label-font">{{ 'RENTAL_OFFSET_PERCENTAGE' | translate }}</label><br>
                                                                            <div class="input-group mb-3">
                                                                                <div class="input-group-prepend gradient">
                                                                                <span class="input-group-text"><span class="" style="color:white">%</span></span>
                                                                                </div>
                                                                                <input [name]="'RENTAL_OFFSET_PERCENTAGE_' + i" type="text" inputmode="decimal" class="form-control input-select RENTAL_OFFSET_PERCENTAGE_{{i}}" (focus)="getInputfield('property_d_' + i,'RENTAL_OFFSET_PERCENTAGE_' + i)" [(ngModel)]="property.rentalPercentage" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="mobileScreen" class="steps-inner w-100" style="padding-top: 20px;">
                                                                        <hr style="margin-top: 0; border-color: #505050;" [ngStyle]="{'border-width': (property.occupancy === 'rental' || property.occupancy === 'ownerRental') ? '5px' : '1px'}">
                                                                    </div>
                                                                </div>
                                                                <div [id]="'property_e_' + i" class="row" *ngIf="property.revenueOptions === 'reduceAdd2'" style="padding-top:20px;">
                                                                    <div class="col-lg-6">
                                                                        <div class="steps-inner">
                                                                            <label class="label-font">{{ 'ELECTRICITY_COST_MONTHLY' | translate }}</label><br>
                                                                            <div class="input-group mb-3">
                                                                                <div class="input-group-prepend gradient">
                                                                                <span class="input-group-text"><span class="" style="color:white">%</span></span>
                                                                                </div>
                                                                                <input [name]="'ELECTRICITY_COST_MONTHLY_' + i" type="text" inputmode="decimal" class="form-control input-select ELECTRICITY_COST_MONTHLY_{{i}}" (focus)="getInputfield('property_e_' + i, 'ELECTRICITY_COST_MONTHLY_' + i)" [(ngModel)]="property.hyrdoCost" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6">
                                                                        <div class="steps-inner">
                                                                            <label class="label-font">{{ 'MANAGEMENT_EXPENSE_MONTHLY' | translate }}</label><br>
                                                                            <div class="input-group mb-3">
                                                                                <div class="input-group-prepend gradient">
                                                                                <span class="input-group-text"><span class="" style="color:white">%</span></span>
                                                                                </div>
                                                                                <input [name]="'MANAGEMENT_EXPENSE_MONTHLY_' + i" type="text" inputmode="decimal" class="form-control input-select MANAGEMENT_EXPENSE_MONTHLY_{{i}}" (focus)="getInputfield('property_e_' + i, 'MANAGEMENT_EXPENSE_MONTHLY_' + i)" [(ngModel)]="property.managementExpense" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div [id]="'property_f_' + i" class="row" *ngIf="property.revenueOptions === 'reduceAdd2'" style="padding-top:20px;">
                                                                    <div class="col-lg-6">
                                                                        <div class="steps-inner">
                                                                            <label class="label-font">{{ 'REPAIR_COSTS' | translate }}</label><br>
                                                                            <div class="input-group mb-3">
                                                                                <div class="input-group-prepend gradient">
                                                                                <span class="input-group-text"><span class="" style="color:white">%</span></span>
                                                                                </div>
                                                                                <input [name]="'REPAIR_COSTS_' + i" type="text" inputmode="decimal" class="form-control input-select REPAIR_COSTS_{{i}}" (focus)="getInputfield('property_f_' + i, 'REPAIR_COSTS_' + i)" [(ngModel)]="property.repairCosts" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6">
                                                                        <div class="steps-inner">
                                                                            <label class="label-font">{{ 'GENERAL_EXPENSES_MONTHLY' | translate }}</label><br>
                                                                            <div class="input-group mb-3">
                                                                                <div class="input-group-prepend gradient">
                                                                                <span class="input-group-text"><span class="" style="color:white">%</span></span>
                                                                                </div>
                                                                                <input [name]="'GENERAL_EXPENSES_MONTHLY_' + i" type="text" inputmode="decimal" class="form-control input-select GENERAL_EXPENSES_MONTHLY_{{i}}" (focus)="getInputfield('property_f_' + i, 'GENERAL_EXPENSES_MONTHLY_' + i)" [(ngModel)]="property.generalExpense" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div [id]="'property_g_' + i" class="row" *ngIf="property.revenueOptions === 'reduceAdd2'" style="padding-top:20px;">
                                                                    <div class="col-lg-6">
                                                                        <div class="steps-inner">
                                                                            <label class="label-font">{{ 'HOME_INSURANCE_FEES_MONTHLY' | translate }}</label><br>
                                                                            <div class="input-group mb-3">
                                                                                <div class="input-group-prepend gradient">
                                                                                <span class="input-group-text"><span class="" style="color:white">%</span></span>
                                                                                </div>
                                                                                <input [name]="'HOME_INSURANCE_FEES_MONTHLY_' + i" type="text" inputmode="decimal" class="form-control input-select HOME_INSURANCE_FEES_MONTHLY_{{i}}" (focus)="getInputfield('property_g_' + i, 'HOME_INSURANCE_FEES_MONTHLY_' + i)" [(ngModel)]="property.homeInsurance" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6">
                                                                        <div class="steps-inner">
                                                                            <label class="label-font">{{ 'INTEREST_FEES_MONTHLY' | translate }}</label><br>
                                                                            <div class="input-group mb-3">
                                                                                <div class="input-group-prepend gradient">
                                                                                <span class="input-group-text"><span class="" style="color:white">%</span></span>
                                                                                </div>
                                                                                <input [name]="'INTEREST_FEES_MONTHLY_' + i" type="text" inputmode="decimal" class="form-control input-select INTEREST_FEES_MONTHLY_{{i}}" (focus)="getInputfield('property_g_' + i, 'INTEREST_FEES_MONTHLY_' + i)" [(ngModel)]="property.interestCharges" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="mobileScreen" class="steps-inner w-100" style="padding-top: 20px;">
                                                                        <hr style="margin-top: 0; border-color: #505050;">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id="addProperty">
                                                                <div class="row" style="padding-top:40px">
                                                                    <div class="col-lg-3" style="padding-top: 5px;">
                                                                        <img src="assets/images/addProperty.png" alt="logo"/>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12 pt-2" style="padding-top: 5px;">
                                                                        <div class="steps-inner d-flex">
                                                                            <div class="square-button-big py-2 px-3" (click)="addResourceModal('open', 'newProperty'); getInputfield('addProperty','')">
                                                                                <span class="mx-1">+</span>
                                                                                <span class="normal-font b-padding" style="font-size: 14px !important">{{ 'ADD_PROPERTY' | translate }}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="scrollBarDiv-Height-3"></div>
                                </div>
            
            
            
                                <!-- THIS IS THE THIRD STEP -->
            
            
            
                                <div #myDiv2 class="scrollBar-hide section-division" id="stepThree" [ngClass]="{'open': isOpen3}">
                                    <div *ngIf="mobileScreen" class="h-divider mb-3">
                                        <div class="shadow"></div>
                                    </div>
                                    <div class="row" (click)="toggleDiv3()">
                                        <div class="col-12 d-flex steps-bg">
                                            <div class="mobile-content">
                                                <span class="steps-icon" [ngClass]="{'steps-icon-active': isOpen3}">III</span>
                                            </div>
                                            <span class="heading-font ml-md-4 my-md-auto mobile-heading-color">{{ 'MORTGAGE_PROPERTY_INFO' | translate }}</span>
                                        </div>                            
                                    </div>
                                    <div class="scrollable px-md-4 py-4">
                                        <div id="propertyDetail_a" class="c-padding-tab" style="padding-top:20px;">
                                            <div *ngIf="application.type === 'preapproval' && totalIncome !== 0 && totalLiabilities !== 0" class="mb-5">
                                                <button class="btn btn-sm rounded-pill gradient approval-btn" (click)="getPreApprovalModal('open'); getInputfield('preApprovalOpen')">{{ 'HOW_MUCH_BORROW' | translate }}</button>
                                            </div>

                                            <!-- Add Scenario button -->
                                            <div class="row">
                                                <div id="addScenario" class="col-12 pt-2" *ngIf="addScenarioButton">
                                                    <button class="btn btn-outline-light ml-3 c-remove-btn-text c-add-btn-width" (click)="addScenario()" style="float: right;"><span class="mx-1">+</span>{{ 'ADD_SCENARIO' | translate }} </button>
                                                </div>
                                            </div>
                                            <br>
                                            <!-- Applicants Scenario Bar -->
                                            <div *ngIf="application.subjectproperty.length > 1" class="steps-inner w-100">
                                                <div class="row px-3">
                                                    <div class="col-3 tab c-scenario-btn text-center text-light mb-2 getApplicants" style="padding-left: 0px; padding-right: 0px" [ngClass]="{'currentlyActive': subjectproperty.active}"  *ngFor="let subjectproperty of application.subjectproperty; let i = index" (click)="selectScenario(i); delayedSumOfIncomes(); delayedSumOfLiabilities(); delayedCalculateMonthlyPropertyCost(); delayedSumOfRevenues();">
                                                        <span>{{ 'SCENARIO' | translate }} {{i + 1}}</span>
                                                        <!--  *ngIf="!applicant.buttonEdit" (click)=" getActiveApplicant(i);"<input class="c-btn-input" type="text" *ngIf="applicant.buttonEdit" [(ngModel)]="applicant.first_name" 
                                                        (focusout)="onFocusOut(); closeEditApplicantButton(applicant, i)" (blur)="onFocusOut(); closeEditApplicantButton(applicant, i)" (keyup.enter)="onFocusOut(); closeEditApplicantButton(applicant, i)"> -->
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="steps-inner">
                                                <hr style="margin-top: 0; border-color: #505050;">
                                            </div>

                                            <div class="row c-padding-tab">
                                                <div class="col-12 my-auto" *ngIf="removeScenarioButton">
                                                    <button class="btn btn-outline-light btn-sm ml-3 mb-3 c-remove-btn-text normal-button c-add-btn-width float-right" (click)="removeScenarioModal('open')">{{ 'REMOVE_SCENARIO' | translate }}</button>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-6 col-lg-4">
                                                    <div class="steps-inner">
                                                        <label class="label-font">{{ 'PROPERTY_TYPE' | translate }}</label><br>
                                                        <select name="PROPERTY_TYPE" class="form-select one-col-dropdown PROPERTY_TYPE" (focus)="getInputfield('propertyDetail_a', 'PROPERTY_TYPE')" [(ngModel)]="setSubjectProperty.type" (ngModelChange)="reRunCalculations(); recheckType(); onFocusOut()">
                                                            <!-- <option value="semiDetached">{{ 'SEMI_DETACHED' | translate }}</option>
                                                            <option value="row">{{ 'ROW_HOUSE' | translate }}</option>
                                                            <option value="apartmentLow">{{ 'APARTMENT_LOW_RISE' | translate }}</option>
                                                            <option value="apartmentHigh">{{ 'APARTMENT_HIGH_RISE' | translate }}</option>
                                                            <option value="mobile">{{ 'MOBILE_HOME' | translate }}</option>
                                                            <option value="stacked">{{ 'STACKED_HOME' | translate }}</option>
                                                            <option value="modularDetached">{{ 'MODULAR_DETACHED' | translate }}</option>
                                                            <option value="modularSemiDetached">{{ 'MODULAR_SEMI_DETACHED' | translate }}</option> -->

                                                            <option value="detached">{{ 'DETACHED' | translate }}</option>
                                                            <option value="condominium">{{ 'CONDOMINIUM' | translate }}</option>
                                                            <option value="singleFamily">{{ 'SINGLE_FAMILY' | translate }}</option>
                                                            <option value="duplexDetached">{{ 'DUPLEX_DETACHED' | translate }}</option>
                                                            <option value="duplexSemiDetached">{{ 'DUPLEX_SEMI_DETACHED' | translate }}</option>
                                                            <option value="triplexDetached">{{ 'TRIPLEX_DETACHED' | translate }}</option>
                                                            <option value="triplexSemiDetached">{{ 'TRIPLEX_SEMI_DETACHED' | translate }}</option>
                                                            <option value="fourplexDetached">{{ 'FOURPLEX_DETACHED' | translate }}</option>
                                                            <option value="fourplexSemiDetached">{{ 'FOURPLEX_SEMI_DETACHED' | translate }}</option>
                                                            <option value="other">{{ 'OTHER_PROPERTY' | translate }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div textid="subjectProperty_purchase_txt" class="col-md-6 col-lg-4">
                                                    <div class="steps-inner">
                                                        <label id="propertyValue" class="label-font" *ngIf="application.type == 'purchase'">{{ 'PURCHASE_PRICE' | translate }}</label>
                                                        <label id="propertyValue" class="label-font" *ngIf="application.type != 'purchase' && application.type != 'renewal' && application.type != 'refinance'">{{ 'PROPERTY_VALUE' | translate }}</label>
                                                        <label id="propertyValue" class="label-font" *ngIf="application.type == 'renewal' || application.type == 'refinance'">{{ 'OUTSTANDING' | translate }}</label><br>
                                                        <div class="input-group mb-3">
                                                            <div class="input-group-prepend gradient">
                                                                <span class="input-group-text"><span class="" style="color:white">$</span></span>
                                                            </div>
                                                            <input name="PURCHASE_PRICE" type="text" inputmode="decimal" class="form-control input-select PURCHASE_PRICE" (focus)="getInputfield('propertyDetail_a', 'PURCHASE_PRICE')" [(ngModel)]="setSubjectProperty.propertyValue" (ngModelChange)="onChangeSubjectProperty('propertyValue',$event); reRunCalculations(); checkDownPaymentTwentyPercent(); checkDownPaymentFivePercent(); updatePropertyTax(); insuranceRerun(); updateInferredEquity();" (focusout)="onFocusOut()" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div textid="subjectProperty_downPayment_txt" class="col-md-6 col-lg-4" *ngIf="application.type == 'purchase' || application.type == 'preapproval'">
                                                    <div class="steps-inner">
                                                        <label class="label-font">{{ 'MAX_DOWN_PAYMENT' | translate }}</label><br>
                                                        <div class="input-group mb-3">
                                                            <div #paymentSwitch class="input-group-prepend">
                                                                <span class="input-group-text gradient" data-value="dollar" (click)="getPaymentSwitch('dollar'); getInputfield('propertyDetail_a', 'MAX_DOWN_PAYMENT')"><span class="" style="color:white">$</span></span>
                                                                <span class="input-group-text" data-value="percent" (click)="getPaymentSwitch('percent'); getInputfield('propertyDetail_a', 'MAX_DOWN_PAYMENT')"><span class="" style="color:white">%</span></span>
                                                            </div>
                                                            <input name="MAX_DOWN_PAYMENT" type="text" inputmode="decimal" *ngIf="setSubjectProperty.downPayment_type == 'percent'" class="form-control input-select c-br-ry MAX_DOWN_PAYMENT" (focus)="getInputfield('propertyDetail_a', 'MAX_DOWN_PAYMENT')" [ngClass]="{'input-error': downPaymentMinCheckFive || downPaymentMinCheckTwenty, 'c-downpayment-w': downPaymentInput}" [(ngModel)]="setSubjectProperty.downPayment" (ngModelChange)="onChangeSubjectProperty('downPayment',$event); reRunCalculations(); checkDownPaymentTwentyPercent(); checkDownPaymentFivePercent(); insuranceRerun();" (focusout)="onFocusOut()" placeholder="30%" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                                                            <input name="MAX_DOWN_PAYMENT" type="text" inputmode="decimal" *ngIf="setSubjectProperty.downPayment_type == 'dollar'" class="form-control input-select c-br-ry MAX_DOWN_PAYMENT" (focus)="getInputfield('propertyDetail_a', 'MAX_DOWN_PAYMENT')" [ngClass]="{'input-error': downPaymentMinCheckFive || downPaymentMinCheckTwenty, 'c-downpayment-w': downPaymentInput}" [(ngModel)]="setSubjectProperty.downPayment" (ngModelChange)="onChangeSubjectProperty('downPayment',$event); reRunCalculations(); checkDownPaymentTwentyPercent(); checkDownPaymentFivePercent(); insuranceRerun();" (focusout)="onFocusOut()" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                            <span class="text-danger pt-1 opacity-0" [ngClass]="{'opacity-1': downPaymentMinCheckFive || downPaymentMinCheckTwenty}" style="font-size: 12px;" *ngIf="false">
                                                                <span *ngIf="downPaymentMinCheckFive">{{ 'DOWNPAYMENT_ERROR_5' | translate }}</span>
                                                                <span *ngIf="downPaymentMinCheckTwenty">{{ 'DOWNPAYMENT_ERROR_20' | translate }}</span>
                                                            </span>
                                                            <span class="text-danger pt-1 opacity-0" *ngIf="showMinError" [ngClass]="{'opacity-1': showMinError}" style="font-size: 12px;">
                                                                <span *ngIf="setSubjectProperty.downPayment_type == 'dollar'">${{ minimumDownPayment }} {{ 'DOWNPAYMENT_ERROR' | translate }}</span>
                                                                <span *ngIf="setSubjectProperty.downPayment_type == 'percent'">{{ minimumDownPaymentPercent | number:'1.2-2' }}% {{ 'DOWNPAYMENT_ERROR' | translate }}</span>
                                                            </span>
                                                            <span class="text-danger pt-1 opacity-0" *ngIf="downPaymentPercent" [ngClass]="{'opacity-1': downPaymentPercent}" style="font-size: 12px;">
                                                                <span>{{ 'DOWNPAYMENT_ERROR_PERCENT' | translate }}</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div textid="subjectProperty_downPayment_txt" class="col-md-6 col-lg-4" *ngIf="application.type == 'refinance'">
                                                    <div class="steps-inner">
                                                        <label class="label-font">{{ 'ETO' | translate }}</label><br>
                                                        <div class="input-group mb-3">
                                                            <div class="input-group-prepend gradient">
                                                                <span class="input-group-text"><span class="" style="color:white">$</span></span>
                                                            </div>
                                                            <input name="ETO" type="text" inputmode="decimal" (focus)="getInputfield('eto', 'ETO')" class="form-control input-select ETO" [(ngModel)]="setSubjectProperty.eto" (ngModelChange)="onChangeSubjectProperty('eto',$event); reRunCalculations(); updateInferredEquity();" (focusout)="onFocusOut()" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div textid="subjectProperty_downPayment_txt" class="col-md-6 col-lg-4" *ngIf="application.type == 'renewal' || application.type == 'refinance'">
                                                    <div class="steps-inner">
                                                        <label class="label-font">{{ 'ESTIMATED_VALUE' | translate }}</label><br>
                                                        <div class="input-group mb-3">
                                                            <div class="input-group-prepend gradient">
                                                                <span class="input-group-text"><span class="" style="color:white">$</span></span>
                                                            </div>
                                                            <input name="ESTIMATED_VALUE" type="text" inputmode="decimal" (focus)="getInputfield('estimated_value', 'ESTIMATED_VALUE')" class="form-control input-select ESTIMATED_VALUE" [(ngModel)]="setSubjectProperty.estimated_value" (ngModelChange)="onChangeSubjectProperty('estimated_value',$event); reRunCalculations(); updateInferredEquity();" (focusout)="onFocusOut()" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-4" *ngIf="application.type == 'renewal' || application.type == 'refinance'">
                                                    <div class="steps-inner">
                                                        <label class="label-font">{{ 'INFERRED_EQUITY' | translate }}</label><br>
                                                        <div class="input-group mb-3">
                                                            <div class="input-group-prepend gradient">
                                                                <span class="input-group-text"><span class="" style="color:white">$</span></span>
                                                            </div>
                                                            <input name="INFERRED_EQUITY" type="text" inputmode="decimal" [disabled]="true" (focus)="getInputfield('inferred_equity', 'INFERRED_EQUITY')" class="form-control input-select INFERRED_EQUITY" [(ngModel)]="setSubjectProperty.inferred_equity" (ngModelChange)="onChangeSubjectProperty('inferred_equity',$event); reRunCalculations()" (focusout)="onFocusOut()" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="steps-inner c-padding-tab" style="margin-top: -10px">
                                            <hr style="border-color: #505050;">
                                        </div>
                                        <div id="mortgageSection" class="row c-padding-tab">
                                            <div class="col-md-6 col-lg-4">
                                                <div class="steps-inner">
                                                    <label class="label-font">{{ 'PAYMENT_FREQUENCY' | translate }}</label>
                                                    <select name="PAYMENT_FREQUENCY" class="form-select one-col-dropdown PAYMENT_FREQUENCY" (focus)="getInputfield('mortgageSection', 'PAYMENT_FREQUENCY')" [(ngModel)]="setMortgage.frequency" (ngModelChange)="reRunCalculations(); onFocusOut()">
                                                        <option value="monthly">{{ 'MONTHLY' | translate }}</option>
                                                        <option value="yearly">{{ 'YEARLY' | translate }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div textid="mortgage_mortgageRate_txt" class="col-md-6 col-lg-4">
                                                <div [ngClass]="{'opacity-1': mortgageRatePopup, 'opacity-0': !mortgageRatePopup }">
                                                    <div class="text-light rate-info-clipboard">
                                                        <div class="d-flex justify-content-between">
                                                            <strong>{{ 'MORTGAGE_INTREST_RATE_HEADING' | translate }}</strong>
                                                            <i class="far fa-times-circle text-light fa-lg my-auto" (click)="mortgageRateInfo()"></i>
                                                        </div>
                                                        <div><span>{{ 'MORTGAGE_INTREST_RATE' | translate }}</span></div>
                                                    </div>
                                                </div>
                                                <div class="steps-inner">
                                                    <label class="label-font">{{ 'MORTGAGE_RATE' | translate }}</label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend gradient">
                                                            <span class="input-group-text"><span class="" style="color:white">%</span></span>
                                                        </div>
                                                        <input name="MORTGAGE_RATE" type="text" inputmode="decimal" inputmode="decimal" class="form-control input-select border-right-0 MORTGAGE_RATE" (focus)="getInputfield('mortgageSection', 'MORTGAGE_RATE')" [readonly]="activeRateType === 'qualifying'" [(ngModel)]="setMortgage.mortgageRate" (ngModelChange)="reRunCalculations(); resetTypingTimer()" (focusout)="onFocusOut()" placeholder="3,000" style="height: 42px" (keypress)="validateNumericInput($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text rate-input-info border-left-0" (click)="mortgageRateInfo()"><span class="" style="color:white"><i class="fa fa-info-circle" aria-hidden="true"></i></span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                
                                            <!-- <div class="col-12">
                                                <label class="label-font">Période d’amortissement</label>
                                            </div> -->
                
                
                                            
                
                
                                            <div textid="mortgage_mortgageTerm_txt" class="col-md-6 col-lg-4">
                                                <div class="steps-inner">
                                                    <label class="label-font">{{ 'TERM' | translate }}</label>
                                                    <select name="TERM" class="form-select one-col-dropdown TERM" (focus)="getInputfield('mortgageSection', 'TERM')" [(ngModel)]="setMortgage.term" (ngModelChange)="reRunCalculations(); onFocusOut()">
                                                        <option *ngFor="let term of termsList" [value]="term">{{ term }} {{ 'YEARS' | translate }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div textid="mortgage_amortizationYears_txt" class="col-md-6 col-lg-4 mb-lg-3">
                                                <div class="steps-inner">
                                                    <label class="label-font">{{ 'AMORTIZATION_PERIOD' | translate }}</label>
                                                    <select name="AMORTIZATION_PERIOD" class="form-select one-col-dropdown AMORTIZATION_PERIOD" (focus)="getInputfield('mortgageSection', 'AMORTIZATION_PERIOD')" [(ngModel)]="setMortgage.amortizationYears" (ngModelChange)="reRunCalculations(); onFocusOut()">
                                                        <option *ngFor="let period of amortizationPeriods" [value]="period">{{ period }} {{ 'YEARS' | translate }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <!-- 
                                            <div class="col-lg-6">
                                                <div class="steps-inner">
                                                    <label class="label-font">{{ 'AMORTIZATION_MONTHS' | translate }}</label>
                                                    <select class="form-select one-col-dropdown" [(ngModel)]="setMortgage.amortizationMonths" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()">
                                                        <option *ngFor="let month of months" [value]="month">{{ month }} {{ 'MONTHS' | translate }}</option>
                                                    </select>
                                                </div>
                                            </div> 
                                            -->
                                        </div>
                                        <div id="propertyDetail_b" class="row c-padding-tab" style="padding-top:20px;">
                                            <div class="col-md-6 col-lg-4">
                                                <div class="steps-inner">
                                                    <label class="label-font">{{ 'LOAN_INSURANCE' | translate }}</label><br> 
                                                    <span class="normal-font b-padding" style="font-size: 20px;">$ {{ insurance_premium | number:'1.2-2' }} </span>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-4" *ngIf="false">
                                                <!-- <div class="col-md-6 col-lg-4" *ngIf="insurance_premium_enabled"> -->
                                                <div class="steps-inner">
                                                    <label class="label-font">{{ 'LOAN_INSURANCE' | translate }}</label><br>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend gradient">
                                                            <span class="input-group-text"><span class="" style="color:white">$</span></span>
                                                        </div>
                                                        <input name="LOAN_INSURANCE" type="text" class="form-control input-select LOAN_INSURANCE" (focus)="getInputfield('propertyDetail_b', 'LOAN_INSURANCE')" [(ngModel)]="insurance_premium" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()" placeholder="0" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                              
                                        <div class="steps-inner c-padding-tab" style="margin-top: -10px">
                                            <hr style="border-color: #505050;">
                                        </div>
                                        <div id="annualPropertyTax" class="row c-padding-tab" style="padding-top:20px;">    
                                            <div textid="subjectProperty_propertyTax_txt" class="col-md-6 col-lg-4">
                                                <div class="steps-inner">
                                                    <label class="label-font">{{ 'ANNUAL_PROPERTY_TAX' | translate }}</label><br>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend gradient">
                                                            <span class="input-group-text"><span class="" style="color:white">$</span></span>
                                                        </div>
                                                        <input name="ANNUAL_PROPERTY_TAX" type="text" inputmode="decimal" (focus)="getInputfield('annualPropertyTax', 'ANNUAL_PROPERTY_TAX')" class="form-control input-select ANNUAL_PROPERTY_TAX" [(ngModel)]="setSubjectProperty.annualPropertyTax" (ngModelChange)="onChangeSubjectProperty('annualPropertyTax',$event); reRunCalculations()" (focusout)="onFocusOut()" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                    </div>
                                                </div>
                                            </div>
                                            <div textid="subjectProperty_heatingCost_txt" class="col-md-6 col-lg-4" *ngIf="user?.role.name === 'broker'">
                                                <div class="steps-inner">
                                                    <div style="margin-bottom: 0.65rem">
                                                        <label class="label-font d-flex position-absolute m-0">
                                                            <span>{{ 'MONTHLY_ENERGY_COST' | translate }}</span>
                                                            <span class="c-tooltip mx-2" id="basic-addon2"><i class="fas fa-eye"></i><span class="c-tooltiptext">{{ 'BROKER_VIEW' | translate }}</span></span>
                                                        </label><br>
                                                    </div>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend gradient">
                                                            <span class="input-group-text"><span class="" style="color:white">$</span></span>
                                                        </div>
                                                        <input name="MONTHLY_ENERGY_COST" type="text" inputmode="decimal" class="form-control input-select MONTHLY_ENERGY_COST" (focus)="getInputfield('annualPropertyTax', 'MONTHLY_ENERGY_COST')" [(ngModel)]="setSubjectProperty.monthlyEnergyCost" (ngModelChange)="onChangeSubjectProperty('monthlyEnergyCost',$event); reRunCalculations()" (focusout)="onFocusOut()" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                    </div>
                                                </div>
                                            </div>        
                                            <div class="col-md-6 col-lg-4" *ngIf="setSubjectProperty.type == 'condominium'">
                                                <div class="steps-inner">
                                                    <label class="label-font">{{ 'MONTHLY_CONDO_FEE' | translate }}</label><br>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend gradient">
                                                            <span class="input-group-text"><span class="" style="color:white">$</span></span>
                                                        </div>
                                                        <input name="MONTHLY_CONDO_FEE" type="text" inputmode="decimal" class="form-control input-select MONTHLY_CONDO_FEE" (focus)="getInputfield('annualPropertyTax', 'MONTHLY_CONDO_FEE')" [(ngModel)]="setSubjectProperty.monthlyCondoFee" (ngModelChange)="onChangeSubjectProperty('monthlyCondoFee',$event); reRunCalculations()" (focusout)="onFocusOut()" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                    </div>
                                                </div>
                                            </div>                                                    
                                        </div>
                                                                
                                        <div id="occupancySection" class="row c-padding-tab" style="padding-top:20px;">
                                            <div class="col-12 mb-lg-3">
                                                <div class="steps-inner">
                                                    <label class="label-font">{{ 'OCCUPANCY' | translate }}</label><br>
                                                    <select name="OCCUPANCY" class="form-select one-col-dropdown OCCUPANCY" (focus)="getInputfield('occupancySection', 'OCCUPANCY')" [(ngModel)]="setSubjectProperty.occupancy" (ngModelChange)="reRunCalculations(); onFocusOut()">
                                                        <option value="owner">{{ 'OWNER_OCCUPIED' | translate }}</option>
                                                        <option value="ownerRental">{{ 'OWNER_OCCUPIED_RENTAL' | translate }}</option>
                                                        <option value="rental">{{ 'RENTAL' | translate }}</option>
                                                        <option value="second">{{ 'SECOND_HOME' | translate }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="steps-inner" *ngIf="setSubjectProperty.occupancy === 'ownerRental' || setSubjectProperty.occupancy === 'rental'">
                                                    <label class="label-font">{{ 'MONTHLY_RENTAL_INCOME' | translate }}</label><br>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend gradient">
                                                            <span class="input-group-text"><span class="" style="color:white">$</span></span>
                                                        </div>
                                                        <input name="MONTHLY_RENTAL_INCOME" type="text" inputmode="decimal" class="form-control input-select MONTHLY_RENTAL_INCOME" (focus)="getInputfield('occupancySection', 'MONTHLY_RENTAL_INCOME')" [(ngModel)]="setSubjectProperty.monthlyRentalIncome" (ngModelChange)="onChangeSubjectProperty('monthlyRentalIncome',$event); reRunCalculations()" (focusout)="onFocusOut()" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="steps-inner" *ngIf="setSubjectProperty.occupancy === 'ownerRental' || setSubjectProperty.occupancy === 'rental'">
                                                    <label class="label-font">{{ 'RENTAL_REVENUE_OPTIONS' | translate }}</label><br>
                                                    <select name="RENTAL_REVENUE_OPTIONS" class="form-select one-col-dropdown RENTAL_REVENUE_OPTIONS" (focus)="getInputfield('occupancySection', RENTAL_REVENUE_OPTIONS)" [(ngModel)]="setSubjectProperty.revenueOptions" (ngModelChange)="reRunCalculations(); onFocusOut()">
                                                        <option value="none">{{ 'NONE' | translate }}</option>
                                                        <option value="addPercentage">{{ 'ADD_PERCENTAGE' | translate }}</option>
                                                        <option value="reduceAdd">{{ 'REDUCE_ADD' | translate }}</option>
                                                    </select>
                                                </div>
                                            </div>                                
                                            <div class="col-md-6 col-lg-4" *ngIf="(setSubjectProperty.occupancy === 'ownerRental' || setSubjectProperty.occupancy === 'rental') && (setSubjectProperty.revenueOptions === 'addPercentage' || setSubjectProperty.revenueOptions === 'reduceAdd')">
                                                <div class="steps-inner">
                                                    <label class="label-font">{{ 'RENTAL_COMPENSATION_PERCENTAGE' | translate }}</label><br>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend gradient">
                                                            <span class="input-group-text"><span class="" style="color:white">%</span></span>
                                                        </div>
                                                        <input name="RENTAL_COMPENSATION_PERCENTAGE" type="text" inputmode="decimal" (focus)="getInputfield('occupancySection', 'RENTAL_COMPENSATION_PERCENTAGE')" class="form-control input-select RENTAL_COMPENSATION_PERCENTAGE" [(ngModel)]="setSubjectProperty.rentalPercentage" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()" placeholder="10%" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                                                    </div>
                                                </div>
                                            </div>                                
                                        </div>
                                        <div id="occupancyDetailOptions_a" class="row c-padding-tab" *ngIf="(setSubjectProperty.occupancy === 'ownerRental' || setSubjectProperty.occupancy === 'rental') && setSubjectProperty.revenueOptions === 'reduceAdd' && false" style="padding-top:20px;">
                                            <div class="col-md-6 col-lg-4">
                                                <div class="steps-inner">
                                                    <label class="label-font">{{ 'ELECTRICITY_COST_MONTHLY' | translate }}</label><br>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend gradient">
                                                            <span class="input-group-text"><span class="" style="color:white">%</span></span>
                                                        </div>
                                                        <input name="ELECTRICITY_COST_MONTHLY" type="text" inputmode="decimal" class="form-control input-select ELECTRICITY_COST_MONTHLY" (focus)="getInputfield('occupancyDetailOptions_a', 'ELECTRICITY_COST_MONTHLY')" [(ngModel)]="setSubjectProperty.hyrdoCost" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-4">
                                                <div class="steps-inner">
                                                    <label class="label-font">{{ 'MANAGEMENT_COST_MONTHLY' | translate }}</label><br>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend gradient">
                                                            <span class="input-group-text"><span class="" style="color:white">%</span></span>
                                                        </div>
                                                        <input name="MANAGEMENT_COST_MONTHLY" type="text" inputmode="decimal" class="form-control input-select MANAGEMENT_COST_MONTHLY" (focus)="getInputfield('occupancyDetailOptions_a', 'MANAGEMENT_COST_MONTHLY')" [(ngModel)]="setSubjectProperty.managementExpense" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                    </div>
                                                </div>
                                            </div>                                
                                            <div class="col-md-6 col-lg-4">
                                                <div class="steps-inner">
                                                    <label class="label-font">{{ 'REPAIR_COSTS' | translate }}</label><br>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend gradient">
                                                            <span class="input-group-text"><span class="" style="color:white">%</span></span>
                                                        </div>
                                                        <input name="REPAIR_COSTS" type="text" inputmode="decimal" class="form-control input-select REPAIR_COSTS" (focus)="getInputfield('occupancyDetailOptions_a', 'REPAIR_COSTS')" [(ngModel)]="setSubjectProperty.repairCosts" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="occupancyDetailOptions_b" class="row c-padding-tab" *ngIf="(setSubjectProperty.occupancy === 'ownerRental' || setSubjectProperty.occupancy === 'rental') && setSubjectProperty.revenueOptions === 'reduceAdd' && false" style="padding-top:20px;">
                                            <div class="col-md-6 col-lg-4">
                                                <div class="steps-inner">
                                                    <label class="label-font">{{ 'OVERHEAD_COSTS_MONTHLY' | translate }}</label><br>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend gradient">
                                                            <span class="input-group-text"><span class="" style="color:white">%</span></span>
                                                        </div>
                                                        <input name="OVERHEAD_COSTS_MONTHLY" type="text" inputmode="decimal" class="form-control input-select OVERHEAD_COSTS_MONTHLY" (focus)="getInputfield('occupancyDetailOptions_b', 'OVERHEAD_COSTS_MONTHLY')" [(ngModel)]="setSubjectProperty.generalExpense" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                    </div>
                                                </div>
                                            </div>                                
                                            <div class="col-md-6 col-lg-4">
                                                <div class="steps-inner">
                                                    <label class="label-font">{{ 'HOME_INSURANCE_FEES_MONTHLY' | translate }}</label><br>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend gradient">
                                                            <span class="input-group-text"><span class="" style="color:white">%</span></span>
                                                        </div>
                                                        <input name="APPLICATION_TYPE" type="text" inputmode="decimal" class="form-control input-select APPLICATION_TYPE" (focus)="getInputfield('occupancyDetailOptions_b')" [(ngModel)]="setSubjectProperty.homeInsurance" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-4">
                                                <div class="steps-inner">
                                                    <label class="label-font">{{ 'INTEREST_FEES_MONTHLY' | translate }}</label><br>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend gradient">
                                                            <span class="input-group-text"><span class="" style="color:white">%</span></span>
                                                        </div>
                                                        <input name="HOME_INSURANCE_FEES_MONTHLY" type="text" inputmode="decimal" class="form-control input-select HOME_INSURANCE_FEES_MONTHLY" (focus)="getInputfield('occupancyDetailOptions_b', 'HOME_INSURANCE_FEES_MONTHLY')" [(ngModel)]="setSubjectProperty.interestCharges" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()" placeholder="3,000" style="height: 42px" (keypress)="allowDecimal($event)" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxThousandSeparator>
                                                    </div>
                                                </div>
                                            </div>                                
                                        </div>
                                        <div class="steps-inner c-padding-tab" style="margin-top: -10px">
                                            <hr style="border-color: #505050;">
                                        </div>
                                        <div #stepThreeDivRef></div>
                                        
                                        <!-- <div class="row c-padding-tab" style="padding-top:20px;">
                                            <div class="col-lg-12">
                                                <div class="tab">
                                                    <button class="tablinks" (click)="switchToProposed()" [ngClass]="{'currentlyActive': isActive12}">{{ 'PROPOSED_RATE' | translate }}</button>
                                                    <button class="tablinks" (click)="switchToQualifying()" [ngClass]="{'currentlyActive': isActive22}">{{ 'QUALIFYING_RATE' | translate }}</button>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div id="resultStepThree" *ngIf="false">
                                            <div class="row c-padding-tab" style="padding-top:20px;">
                                                <div class="col-12">
                                                    <span class="heading-font" style="display:inline-block; font-size: 30px;">{{ 'YOUR_RESULTS' | translate }} 
                                                        <!-- (<span *ngIf="activeRateType === 'proposed'">{{ 'PROPOSED_RATE' | translate }}</span>
                                                        <span *ngIf="activeRateType === 'qualifying'">{{ 'QUALIFYING_RATE' | translate }}</span>) -->
                                                    </span>
                                                </div>
                                            </div>                 
                                            <div class="row c-padding-tab" style="padding-top:20px;">
                                                <div class="col-lg-6">
                                                    <span class="heading-font" style="display:inline-block; font-size: 24px; font-family: Roboto;">{{ 'MORTGAGE_CAPACITY' | translate }}</span><br><br>
                                                    <span class="heading-font" style="display:inline-block; font-size: 24px; font-family: Roboto; font-weight: 600;">$ {{ mortgageCapacity | number:'1.2-2' }}</span>
                                                </div>
                                                <div class="col-lg-6">
                                                    <span class="heading-font" style="display:inline-block; font-size: 24px; font-family: Roboto;">{{ 'MONTHLY_PAYMENTS' | translate }}</span><br><br>
                                                    <span class="heading-font" style="display:inline-block; font-size: 24px; font-family: Roboto; font-weight: 600;">$ {{ monthlyMortgageAmount | number:'1.2-2' }}</span>
                                                </div>
                                                <!-- <div class="col-12 pt-4 with-scroll">
                                                    <button class="btn c-mob-menu-active btn-sm" type="button" style="width:30%"  (click)="tableModal('open')">{{ 'AMORTIZATION_TABLE' | translate }}</button>
                                                </div> -->
                                                <div #stepThreeDivRefEnd class="col-12 pt-4">
                                                    <span class="normal-font b-padding" style="font-weight: 200">
                                                        {{ 'LOAN_ELIGIBILITY_INFO' | translate }}
                                                    </span>
                                                </div>
                                                <div class="col-lg-6 pt-4">
                                                    <button class="normal-button" style="width: 100%; padding: 14px 20px;" (click)="tableModal('open'); getPaymentDetail()">{{ 'PAYMENT_DETAILS' | translate }}</button>
                                                </div>                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="scrollBarDiv-Height-3"></div>
                                </div>
            
            
            
                                <!-- THIS IS THE FOURTH STEP -->
            
            
                                <div #myDiv3 class="scrollBar-hide section-division" id="stepFour" [ngClass]="{'open': isOpen4}">
                                    <div *ngIf="mobileScreen" class="h-divider mb-3">
                                        <div class="shadow"></div>
                                    </div>
                                    <div class="row" (click)="toggleDiv4()">
                                        <div class="col-12 d-flex steps-bg">
                                            <div class="mobile-content">
                                                <span class="steps-icon" [ngClass]="{'steps-icon-active': isOpen4}">IV</span>
                                            </div>
                                            <span class="heading-font ml-md-4 my-md-auto mobile-heading-color">{{ 'RESULTS_CAP' | translate }}</span>
                                        </div>                            
                                    </div>
                                    <div class="px-md-4 py-4">
                                        <!-- Applicants Scenario Bar -->
                                        <div class="c-padding-tab" style="padding-top:20px;">
                                        <div *ngIf="application.subjectproperty.length > 1" class="steps-inner w-100">
                                            <div class="row px-3">
                                                <div class="col-3 tab c-scenario-btn text-center text-light mb-2 getApplicants" style="padding-left: 0px; padding-right: 0px" [ngClass]="{'currentlyActive': subjectproperty.active}"  *ngFor="let subjectproperty of application.subjectproperty; let i = index" (click)="selectScenario(i); delayedSumOfIncomes(); delayedSumOfLiabilities(); delayedCalculateMonthlyPropertyCost(); delayedSumOfRevenues();">
                                                    <span>{{ 'SCENARIO' | translate }} {{i + 1}}</span>
                                                    <!--  *ngIf="!applicant.buttonEdit" (click)=" getActiveApplicant(i);"<input class="c-btn-input" type="text" *ngIf="applicant.buttonEdit" [(ngModel)]="applicant.first_name" 
                                                    (focusout)="onFocusOut(); closeEditApplicantButton(applicant, i)" (blur)="onFocusOut(); closeEditApplicantButton(applicant, i)" (keyup.enter)="onFocusOut(); closeEditApplicantButton(applicant, i)"> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="application.subjectproperty.length > 1" class="steps-inner">
                                            <hr style="margin-top: 0; border-color: #505050;">
                                        </div>
                                        </div>

                                        <div class="row c-padding-tab" style="padding-top:20px;">
                                            <div class="col-12">
                                                <span class="heading-font gradient-text" style="display:inline-block; font-size: 30px; font-family: Roboto;">{{ 'RESULTS' | translate }}</span>
                                                <p id="resultDescription"  class="normal-font mt-3" style="font-weight: 200">{{ 'RESULTS_DESCRIPTION' | translate }}</p>
                                            </div>
                                        </div>
                                        <div *ngIf="!mobileScreen && warningMessage" class="row c-padding-tab" style="padding-top:20px;">
                                            <div #stepFourDivRef class="col-lg-11">
                                                <div class="container-notice">
                                                    <div class="icon-notice">
                                                    </div>
                                                    <div class="content-notice">
                                                        <span  id="resultWarning" class="normal-font b-padding" style="font-weight: 200">{{ 'NOT_ENOUGH_BORROW_AMOUNT' | translate }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="row c-padding-tab" style="padding-top:20px;">
                                            <div class="col-lg-12">
                                                <div class="tab">
                                                    <button class="tablinks" (click)="switchToProposed()" [ngClass]="{'currentlyActive': isActive12}">{{ 'PROPOSED_RATE' | translate }}</button>
                                                    <button class="tablinks" (click)="switchToQualifying()" [ngClass]="{'currentlyActive': isActive22}">{{ 'QUALIFYING_RATE' | translate }}</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row c-padding-tab" style="padding-top:20px;">
                                            <div class="col-lg-6">
                                                <span class="heading-font" style="font-size: 24px; font-family: Roboto;" *ngIf="activeRateType === 'proposed'">{{ 'PROPOSED_RATE_TITLE' | translate }}</span>
                                                <span class="heading-font" style="font-size: 24px; font-family: Roboto;" *ngIf="activeRateType === 'qualifying'">{{ 'QUALIFYING_RATE_TITLE' | translate }}</span>
                                            </div>
                                        </div> -->
                                        <div id="resultStepFour" class="row c-padding-tab" style="padding-top:20px;">
                                            <div textid="subjectProperty_mortgageCapacity_txt" class="col-lg-6">
                                                <span class="heading-font" style="display:inline-block; font-size: 24px; font-family: Roboto;" *ngIf="application.type == 'preapproval'">{{ 'MORTGAGE_CAPACITY' | translate }}</span>
                                                <span class="heading-font" style="display:inline-block; font-size: 24px; font-family: Roboto;" *ngIf="application.type == 'purchase' || application.type == 'refinance' || application.type == 'renewal'">{{ 'MORTGAGE_AMOUNT' | translate }}</span><br><br>
                                                <span class="heading-font" style="display:inline-block; font-size: 24px; font-family: Roboto; font-weight: 600;">$ {{ mortgageCapacity | number:'1.2-2' }}</span>
                                            </div>
                                            <div textid="subjectProperty_monthlyMortgageAmount_txt" class="col-lg-6">
                                                <span class="heading-font" style="display:inline-block; font-size: 24px; font-family: Roboto;">{{ 'MONTHLY_PAYMENTS' | translate }}</span><br><br>
                                                <span class="heading-font" style="display:inline-block; font-size: 24px; font-family: Roboto; font-weight: 600;">$ {{ monthlyMortgageAmount | number:'1.2-2' }}</span>
                                            </div>
                                        </div>                            
                                        <div id="resultStepFour" class="row c-padding-tab" style="padding-top:20px;" *ngIf="user && user.id">
                                            <div class="col-lg-6 pb-4">
                                                <button class="normal-button" style="width: 100%; padding: 14px 20px;" (click)="tableModal('open'); getPaymentDetail(); getInputfield('paymentDetailOpen')">{{ 'PAYMENT_DETAILS' | translate }}</button>
                                            </div>   
                                        </div>
                                        <div id="resultOptimization" class="row c-padding-tab" style="padding-top: 10px; padding-bottom: 10px;">
                                        </div>
                                        <div *ngIf="!loggedInUpdaterValue" class="row c-padding-tab" [ngClass]="{'w-100' : mobileScreen, 'w-50' : !mobileScreen}">
                                            <div class="col-12 main-bg-light footer-bigBtn-padding pt-1">
                                                <button class="normal-button footer-big_button" (click)="specialistContactModal('open')">
                                                    {{ 'LOGIN_TO_PORTAL' | translate }}
                                                </button>
                                            </div>
                                            <!-- <div class="row pl-3">
                                                <div class="col-md-6 col-xl-4 py-xl-3 main-bg-light"></div>
                                                <div class="col-md-8">
                                                    <span class="normal-font b-padding" style="font-weight: 200">
                                                        {{ 'ALTERNATIVE_OPTION' | translate }}
                                                    </span>
                                                    <br>
                                                    <span class="normal-font b-padding" style="font-weight: 200; text-decoration: underline">
                                                        {{ 'SAVE_APPLICATION' | translate }}.
                                                    </span>
                                                </div>                                
                                            </div> -->
                                            <div class="scrollBarDiv-Height-30"></div>
                                        </div>
                                        <div *ngIf="loggedInUpdaterValue && checkUserEmail" class="row c-padding-tab" [ngClass]="{'w-100' : mobileScreen, 'w-50' : !mobileScreen}">
                                            <div class="col-12 main-bg-light footer-bigBtn-padding pt-1">
                                                <button class="normal-button footer-big_button" (click)="specialistContactModal('open')">
                                                    {{ 'MODIFY_MY_PROFILE' | translate }}
                                                </button>
                                            </div>
                                            <div class="scrollBarDiv-Height-30"></div>
                                        </div>
                                        <div id="resultOptimization" class="row c-padding-tab" style="padding-top:40px;" *ngIf="false">
                                            <div class="col-lg-5">
                                                <div class="row">
                                                    <div #stepFourDivRefEnd class="col">
                                                        <span class="heading-font gradient-text" style="display:inline-block; font-size: 24px; font-family: Roboto;">{{ 'FINANCIAL_OPTIMIZATION' | translate }}</span>
                                                    </div>
                                                </div>
                                                <div class="row" style="padding-top: 10px">
                                                    <div class="col">
                                                        <span class="normal-font b-padding" style="font-weight: 200">{{ 'INCREASE_BORROWING_CAPACITY' | translate }}</span>
                                                    </div>
                                                </div>
                                                <div class="row" style="padding-top: 10px">
                                                    <div class="col-1">
                                                        <input type="checkbox" class="form-control input-select" style="width: 16px">
                                                    </div>
                                                    <div class="col-6" style="padding-top: 7px">
                                                        <span class="normal-font b-padding" style="font-weight: 200; font-size: 14px;">{{ 'ELIMINATE_CMHC' | translate }}</span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-1">
                                                        <input type="checkbox" class="form-control input-select" style="width: 16px">
                                                    </div>
                                                    <div class="col-6" style="padding-top: 7px">
                                                        <span class="normal-font b-padding" style="font-weight: 200; font-size: 14px;">{{ 'SELF_PAID' | translate }}</span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col">
                                                        <span class="heading-font gradient-text" style="display:inline-block; font-size: 24px; font-family: Roboto;">{{ 'RECOMMENDED' | translate }}</span>
                                                    </div>
                                                </div>
                                                <div class="row" style="padding-top: 10px">
                                                    <div class="col">
                                                        <span class="normal-font b-padding" style="font-weight: 200">{{ 'CAPITAL_OPTIMIZATION' | translate }}</span>
                                                    </div>
                                                </div>
                                                <div class="row" style="padding-top: 10px">
                                                    <div class="col-1">
                                                        <input type="checkbox" class="form-control input-select" style="width: 16px">
                                                    </div>
                                                    <div class="col-6" style="padding-top: 7px">
                                                        <span class="normal-font b-padding" style="font-weight: 200; font-size: 14px;">{{ 'MINIMAL_DOWNPAYMENT_AND_INVESTMENTS' | translate }}</span>
                                                    </div>
                                                </div>
                                            </div>                                
                                            <div class="col-lg-7">
                                            </div>
                                        </div>
                                        <div id="optimizeButton" class="row c-padding-tab" style="padding-top:40px;" *ngIf="false">
                                            <div class="col-lg-5">
                                                <button class="normal-button" style="width: 100%; padding: 14px 20px; font-weight: 600;">
                                                    {{ 'OPTIMIZE_MY_CAPITAL' | translate }}
                                                </button>
                                            </div>
                                        </div>
                                        <div *ngIf="!mobileScreen" class="row float-right" [ngclass]="{'my-2 px-3': application.application_name !== ''}">
                                            <span *ngIf="application && application.id" style="font-size: 8px; color: #ffffff;">application id: {{ application.id }}</span>
                                        </div>
                                        <div *ngIf="!mobileScreen" class="row c-padding-tab" style="padding-top:20px; opacity: 0;">
                                            <div class="col-lg-11">
                                                <div class="container-notice">
                                                    <div class="icon-notice">
                                                    </div>
                                                    <div class="content-notice">
                                                        <span class="normal-font b-padding" style="font-weight: 200">{{ 'NOT_ENOUGH_BORROW_AMOUNT' | translate }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="row c-padding-tab" style="padding-top:20px; padding-bottom: 20px;">
                                            <div class="col-lg-5">
                                                <button class="normal-button" style="width: 100%; padding: 14px 20px;">{{ 'CONTACT_SPECIALIST' | translate }}</button>
                                            </div>
                                            <div class="col-lg-5">
                                                <span class="normal-font b-padding" style="font-weight: 200; font-size: 14px;">
                                                    {{ 'ALSO_CAN' | translate }}
                                                </span>
                                                <br>
                                                <span class="normal-font b-padding" style="font-weight: 200; font-size: 14px; text-decoration: underline">
                                                    {{ 'SAVE_REQUEST' | translate }}
                                                </span>
                                            </div>
                                        </div>                             -->
                                    </div>
                                    <div class="scrollBarDiv-Height-30"></div>
                                </div>
                            </div>
                        </div>
                        
                        
                        <!-- <div id="myDiv" [ngClass]="{'open': isOpen}" (click)="toggleDiv()">
                            <p>Some content here...</p>
                        </div> -->
                        
                        <!-- <button class="basic-button"><span class="font-style ">Testing</span></button> -->
                    </div>
                </div>
            </div>

            <div class="mobile-footer-render">
                <div *ngIf="user?.role.name === 'applicant' && !user?.broker" class="footer-btn-postion" style="width: 95%;">
                    <div class="row pl-3">
                        <div class="col-md-6 col-xl-4 py-xl-3 main-bg-light footer-bigBtn-padding">
                            <button class="normal-button footer-big_button" [disabled]="brokerRequestStatus !== ''" [ngClass]="{'c-opacity-50': brokerRequestStatus !== ''}" (click)="requestedBrokerModel('open')">
                                <span *ngIf="!user.request_broker && brokerRequestStatus === ''">{{ 'REQUEST_BROKER' | translate }}</span>
                                <span *ngIf="user.request_broker && user.request_broker.status == 'requested'">{{ brokerRequestStatus }}</span>
                            </button>
                        </div>
                        <div class="col-md-6 col-xl-4 py-xl-3 main-bg-light"></div>
                    </div>
                </div>
                <!-- <div *ngIf="loggedInUpdaterValue && checkUserEmail" class="footer-btn-postion" style="width: 95%;">
                    <div class="row pl-3">
                        <div class="col-md-6 col-xl-4 py-xl-3 main-bg-light footer-bigBtn-padding">
                            <button class="normal-button footer-big_button" (click)="specialistContactModal('open')">
                                {{ 'MODIFY_MY_PROFILE' | translate }}
                            </button>
                        </div>
                        <div class="col-md-6 col-xl-4 py-xl-3 main-bg-light"></div>
                    </div>
                </div> -->
                <button type="button" class="px-4 py-1 border-0 currentlyActive footer-btn-d footer-btn-full" (click)="getFullFooter('openFullFooter') "><i class="fas fa-chevron-up text-light"></i></button>
                <nb-layout-footer *ngIf="mobileScreen || tabScreen" class="test" [ngClass]="{'mobile-full-footer': footer , 'down-ani c-footer-position  footer-height-100': !footer , 'up-ani':!scrollStepsValueChange}">
                    <div class="w-100">
                        <span *ngIf="footerIcon" class="c-pointer c-d-none" (click)="getFullFooter('closeFullFooter')" [ngClass]="{'c-action-btn d-flex justify-content-end': footerIcon , 'c-d-none': !footerIcon}">
                            <button type="button" class="px-4 py-1 border-0 currentlyActive"><i class="fas fa-chevron-down text-light"></i></button>
                        </span>
                        <div class="c-w-footer footer-overflow scrollBar-hide">
                            <ngx-mobile-footer [outputData]="outputData" [application]="application" [scrollStepsValueChange]="scrollStepsValueChange" ></ngx-mobile-footer>
                            <div [ngClass]="{'d-block': mobileSideBarContent , 'd-none': !mobileSideBarContent}">
                                <!-- Futher Code -->
                                <div class="my-3">
                                    <ngx-type-writer-text [application]="application" [output]="outputData"></ngx-type-writer-text>
                                    <!-- <p class="c-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent tortor libero, tristique sed semper a, rhoncus a neque. Sed commodo mauris et enim laoreet, commodo ultrices lorem congue. Etiam facilisis vel erat in scelerisque. Fusce metus urna, porttitor vitae maximus et, tincidunt sit amet nisi. Aliquam placerat aliquam condimentum.</p>
                                    <p class="c-text"> Aliquam non rutrum massa, vel scelerisque elit. Suspendisse ac euismod nulla. Morbi mattis mauris non massa imperdiet, sit amet congue dolor varius. Nullam eget hendrerit risus, at facilisis nulla.</p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </nb-layout-footer>
            </div>


            <!-- Modal -->
            <div *ngIf="dataTableModal" class="modal fade modal-bg show d-block" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered table-modal-w" role="document">
                <div class="modal-content table-modal-h">
                    <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalCenterTitle">Payment Details</h5>
                    <button type="button" class="close" (click)="tableModal('close'); getInputfield('paymentDetailClose')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-body overflow-auto">
                        <h6 class="font-weight-light">Amortization Schedule (First 12 month)</h6>
                        <table class="c-table">
                            <thead>
                                <tr class="c-table-heading">
                                    <th>{{ 'MONTH' | translate }}</th>
                                    <th scope="col">{{ 'PAID' | translate }}</th>
                                    <th scope="col">{{ 'PAID_INTEREST' | translate }}</th>
                                    <th scope="col">{{ 'PAID_PRINCIPAL' | translate }}</th>
                                    <th scope="col">{{ 'REMAINING_PRINCIPAL' | translate }}</th>
                                    <th scope="col">{{ 'INTEREST_PAID' | translate }}</th>
                                    <th scope="col">{{ 'PRINCIPAL_PAID' | translate }}</th>
                                    <th scope="col">{{ 'TOTAL_PAID' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="text-dark text-center" *ngFor="let item of monthlyData; let i = index">
                                    <td>{{ i+1 }}</td>
                                    <td>{{ item.paid }}</td>
                                    <td>{{ item.paid_interest }}</td>
                                    <td>{{ item.paid_principal }}</td>
                                    <td>{{ item.remaining_principal }}</td>
                                    <td>{{ item.interest_paid }}</td>
                                    <td>{{ item.principal_paid }}</td>
                                    <td>{{ item.total_paid }}</td>
                                </tr>
                            </tbody>
                        </table>

                        <h6 class="font-weight-light">Amortization Schedule (Annually)</h6>
                        <table class="c-table">
                            <thead>
                                <tr class="c-table-heading">
                                    <th>{{ 'YEAR' | translate }}</th>
                                    <th>{{ 'REMAINING_PRINCIPAL' | translate }}</th>
                                    <th>{{ 'ANNUAL_INTEREST' | translate }}</th>
                                    <th>{{ 'ANNUAL_PRINCIPAL' | translate }}</th>
                                    <th>{{ 'ANNUAL_PAID' | translate }}</th>
                                    <th>{{ 'INTEREST_PAID' | translate }}</th>
                                    <th>{{ 'PRINCIPAL_PAID' | translate }}</th>
                                    <th>{{ 'TOTAL_PAID' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="text-dark text-center" *ngFor="let item of annualData; let i = index">
                                    <td>{{ i+1 }}</td>
                                    <td>{{ item.remaining_principal }}</td>
                                    <td>{{ item.annual_interest }}</td>
                                    <td>{{ item.annual_principal }}</td>
                                    <td>{{ item.annual_paid }}</td>
                                    <td>{{ item.interest_paid }}</td>
                                    <td>{{ item.principal_paid }}</td>
                                    <td>{{ item.total_paid }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="tableModal('close'); getInputfield('paymentDetailClose')">Close</button>
                    <!-- <button type="button" class="btn gradient text-light">Save changes</button> -->
                    </div>
                </div>
                </div>
            </div>
            <!-- kingstateNewsModal -->
            <div *ngIf="kingstateNewsModal" class="modal fade modal-bg show d-block" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered table-modal-w m-auto" role="document">
                    <div class="modal-content">
                        <div class="modal-header d-block c-payment-header-bg">
                            <div class="d-flex justify-content-between">
                                <h5 class="modal-title" id="exampleModalCenterTitle" *ngIf="user?.account_request && (user.account_request.status === 'pending' || user.account_request.status === 'onhold' || user.account_request.status === 'requested')">{{ 'PENDING_ACCOUNT_TITLE' | translate }}</h5>
                                <h5 class="modal-title" id="exampleModalCenterTitle" *ngIf="user?.account_request && (user.account_request.status === 'rejected')">{{ 'REJECT_ACCOUNT_TITLE' | translate }}</h5>
                                <h5 class="modal-title" id="exampleModalCenterTitle" *ngIf="!user?.account_request || user.account_request.status === 'approved'">{{ 'REQUEST_ACCOUNT_TITLE' | translate }}</h5>
                                <button type="button" class="close close-payment-modal" (click)="newsModal('close')" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="c-payment-text">
                                <div class="modal-body overflow-auto pl-0 pb-0 pt-1" *ngIf="user?.account_request && (user.account_request.status === 'pending' || user.account_request.status === 'onhold' || user.account_request.status === 'requested')">
                                    {{ 'PENDING_ACCOUNT_DESCRIPTION' | translate }}
                                </div>
                                <div class="modal-body overflow-auto pl-0 pb-0 pt-1" *ngIf="user?.account_request && (user.account_request.status === 'rejected')">
                                    {{ 'REJECT_ACCOUNT_DESCRIPTION' | translate }}<br>
                                    <i>"{{ user.account_request.comment }}"</i><br>
                                    {{ 'REJECT_ACCOUNT_DESCRIPTION_2' | translate }}
                                </div>
                                <div class="modal-body overflow-auto pl-0 pb-0 pt-1" *ngIf="!user?.account_request || user.account_request.status === 'approved'">
                                    {{ 'REQUEST_ACCOUNT_DESCRIPTION' | translate }}
                                </div>
                            </div>
                        </div>
                        <!-- <div class="modal-footer">
                            <button type="button" class="btn btn-dark" (click)="newsModal('close')">{{ 'CANCEL' | translate }}</button>
                            <button type="button" class="btn gradient text-light" (click)="requestBrokerAccount()" *ngIf="!user?.account_request || user.account_request.status === 'rejected' || user.account_request.status === 'approved'">{{ 'CONFIRM' | translate }}</button>
                        </div> -->
                        <div class="my-4">
                            <ng-container *ngIf="user?.email">
                                <stripe-pricing-table [attr.pricing-table-id]="price_id"
                                [attr.publishable-key]="stripe_key"
                                [attr.customer-email]="user?.email"
                                >
                                </stripe-pricing-table>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <!-- removeBorrowerModal -->
            <div *ngIf="removeBorrowerModal" class="modal fade modal-bg show d-block" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered table-modal-w m-auto" role="document">
                <div class="modal-content table-modal-h">
                    <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalCenterTitle">{{ 'REMOVE_BORROWER' | translate }}</h5>
                    <button type="button" class="close" (click)="removeApplicantModal('close')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-body overflow-auto">
                        <p>{{ 'BORROWER_DISCRIPTION' | translate }} <strong>{{ removeBorrowerName }}</strong></p>
                    </div>
                    <div class="modal-footer">
                    <button type="button" class="btn btn-dark" (click)="removeApplicantModal('close')">{{ 'CANCEL' | translate }}</button>
                    <button type="button" class="btn gradient text-light" (click)="removeApplicant()">{{ 'CONFIRM' | translate }}</button>
                    </div>
                </div>
                </div>
            </div>
            
            <!-- createApplicantModal -->
            <div *ngIf="createApplicantModal" class="modal fade modal-bg show d-block" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered table-modal-w m-auto" role="document">
                <div class="modal-content table-modal-h">
                    <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalCenterTitle">{{ 'CREATE_APPLICANT' | translate }}</h5>
                    <button type="button" class="close" (click)="newApplicantModel('close')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-body overflow-auto">
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-md-6 col-lg-12" style="padding-top: 0px">
                                      <div class="steps-inner">
                                        <div class="form-group mb-3">
                                          <label for="firstName">{{ 'FIRST_NAME' | translate }}</label>
                                          <input type="text" class="form-control input-white w-100 c-br-one-third" [(ngModel)]="applicantInfo.name" placeholder="{{ 'FIRST_NAME' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                                        </div>
                                        <div class="form-group mb-3">
                                          <label for="lastName">{{ 'LAST_NAME' | translate }}</label>
                                          <input type="text" class="form-control input-white w-100 c-br-one-third" [(ngModel)]="applicantInfo.last_name" placeholder="{{ 'LAST_NAME' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                                        </div>
                                        <div class="form-group mb-3">
                                          <label for="profileEmail">{{ 'EMAIL' | translate }}</label>
                                          <input type="email" class="form-control input-white w-100 c-br-one-third" [(ngModel)]="applicantInfo.email" placeholder="{{ 'EMAIL_ADDRESS' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                                        </div>
                                        <div class="form-group mb-3">
                                          <label for="exampleInputEmail1">{{ 'MOBILE' | translate }}</label>
                                          <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                              <span class="input-group-text"><img src="../../assets/images/ca.png" alt="CA" height="10px" width="15px" style="margin-right: 5px;">+1</span>
                                            </div>
                                            <input type="text" inputmode="decimal" maxlength="12" class="form-control input-white" [(ngModel)]="applicantInfo.phone" (keypress)="allowDecimal($event)" placeholder="{{ 'MOBILE' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxPhoneNumberFormatter>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                    <button type="button" class="btn btn-dark" (click)="newApplicantModel('close')">{{ 'CANCEL' | translate }}</button>
                    <button type="button" class="btn gradient text-light" (click)="getNewApplicantData()">{{ 'CONFIRM' | translate }}</button>
                    </div>
                </div>
                </div>
            </div>
            
            <!-- removeScenarioModal -->
            <div *ngIf="scenarioModal" class="modal fade modal-bg show d-block" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered table-modal-w m-auto" role="document">
                <div class="modal-content table-modal-h">
                    <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalCenterTitle">{{ 'REMOVE_SCENARIO' | translate }}</h5>
                    <button type="button" class="close" (click)="removeScenarioModal('close')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-body overflow-auto">
                        <p>{{ 'BORROWER_DISCRIPTION' | translate }} <strong>{{ 'SCENARIO' | translate }} {{ removeScenarioName }}</strong></p>
                    </div>
                    <div class="modal-footer">
                    <button type="button" class="btn btn-dark" (click)="removeScenarioModal('close')">{{ 'CANCEL' | translate }}</button>
                    <button type="button" class="btn gradient text-light" (click)="removeScenario()">{{ 'CONFIRM' | translate }}</button>
                    </div>
                </div>
                </div>
            </div>

            <!-- CSVModal -->
            <div *ngIf="csvModal" class="modal fade modal-bg show d-block" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered table-modal-w m-auto" role="document">
                <div class="modal-content table-modal-h" style="width: 500px;">
                    <div class="modal-header">
                    <h5 *ngIf="getCsvStatus === 'applicants' || getCsvStatus === 'applicant'" class="modal-title" id="exampleModalCenterTitle">{{ 'APPLICANT_CSV' | translate }}</h5>
                    <h5 *ngIf="getCsvStatus !== 'applicants' && getCsvStatus !== 'applicant'" class="modal-title" id="exampleModalCenterTitle">{{ 'APPLICANT_OTHER' | translate }}</h5>
                    <button type="button" class="close" (click)="csvGenerateModal('close')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-body overflow-auto">
                        <div *ngIf="!checkBossCredentails && !checkFinmoCredentails && !bossMailSection">
                            <div class="pb-3">
                                <div class="p-2 mb-3">
                                    <p class="mb-2">{{ 'APPLICANTS_CSV_DESC' | translate }}: <strong>{{csvOptionName}}</strong></p>
                                    <p class="mb-0" *ngIf="user.third_party_credential && ((csvOptionName === 'Mortgage Boss' && user.third_party_credential.boss_username) || (csvOptionName === 'Finmo' && user.third_party_credential.finmo_username))">
                                        {{ 'CREDENTIALS_TEXT' | translate }}
                                        <strong *ngIf="csvOptionName === 'Mortgage Boss'">( {{user.third_party_credential.boss_username}} )</strong>
                                        <strong *ngIf="csvOptionName === 'Finmo'">{{user.third_party_credential.finmo_username}}</strong> 
                                        {{csvOptionName}} {{ 'CREDENTIALS_TEXT_2' | translate }}
                                    </p>
                                    <p class="mb-0" *ngIf="user.third_party_credential">
                                        <span *ngIf="csvOptionName === 'Mortgage Boss' && !user.third_party_credential.boss_username">{{ 'CREDENTIALS_REQUIRED' | translate }}  {{csvOptionName}}.</span>
                                        <span *ngIf="csvOptionName === 'Finmo' && !user.third_party_credential.finmo_username">{{ 'CREDENTIALS_REQUIRED' | translate }}  {{csvOptionName}}.</span>
                                    </p>
                                    <p class="mb-0" *ngIf="(csvOptionName === 'Mortgage Boss' || csvOptionName === 'Finmo') && !user.third_party_credential">
                                        <strong>*</strong> {{ 'CREDENTIALS_REQUIRED' | translate }}  {{csvOptionName}}.
                                    </p>
                                </div>
                                <div #csvShareableLinks class="row">
                                    <div *ngIf="csvStatus" class="col-3 p-0">
                                        <div id="csv" class="csv-modal-icon-box" (click)="getCsvShareableLinks('csv')">
                                            <div class="m-auto">
                                                <img src="assets/images/csv-icon.png" alt="CSV" class="csv-modal-icon">
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!csvStatus" class="col-3 p-0">
                                        <div id="boss_application" class="csv-modal-icon-box" (click)="getCsvShareableLinks('boss_application')">
                                            <div class="m-auto">
                                                <img src="assets/images/boss.png" alt="boss" class="csv-modal-icon">
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!csvStatus" class="col-3 p-0">
                                        <div id="finmo_application" class="csv-modal-icon-box" (click)="getCsvShareableLinks('finmo_application')">
                                            <div class="m-auto">
                                                <img src="assets/images/finmo.png" alt="boss" class="csv-modal-icon">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-3 p-0">
                                        <div id="google" class="csv-modal-icon-box" (click)="getCsvShareableLinks('google')">
                                            <div class="m-auto">
                                                <i class="fab fa-google-plus-g"></i> 
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!checkBossCredentails && !checkFinmoCredentails && bossMailSection">
                            <div class="row">
                                <div class="col-md-6 col-lg-12" style="padding-top: 0px">
                                    <p><strong>*{{ 'EMAIL_REQUIRED_BOSS' | translate }}</strong></p>
                                    <div class="form-group mb-3">
                                        <label for="profileEmail">{{ 'EMAIL' | translate }}</label>
                                        <input type="email" class="form-control input-white w-100 c-br-one-third" [(ngModel)]="bossApplication.user.email" placeholder="{{ 'EMAIL_ADDRESS' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div *ngIf="checkBossCredentails || checkFinmoCredentails" class="row">
                                <div class="col-md-6 col-lg-12" style="padding-top: 0px">
                                    <ngx-third-party-credentials [user]="user" [getCsvStatus]="getCsvStatus" (credsResponseStatus)="getUpdateBossRessponse($event)"></ngx-third-party-credentials>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                    <button *ngIf="(getCsvStatus !== 'applicant' && getCsvStatus !== 'applicants') && checkBossAccount && user.third_party_credential && ((csvOptionName === 'Mortgage Boss' && user.third_party_credential.boss_username) || (csvOptionName === 'Finmo' && user.third_party_credential.finmo_username))" type="button" class="btn btn-dark" (click)="changeThirdPartyAccount('boss_credentials')"><span *ngIf="!changeBossAccount">{{ 'CHANGE_ACCOUNT' | translate }}</span><span *ngIf="changeBossAccount">{{ 'CANCEL' | translate }}</span></button>
                    <button *ngIf="!checkBossCredentails && !checkFinmoCredentails && !bossMailSection" type="button" class="btn gradient text-light" (click)="generateCsvFile(getCsvStatus)">{{ 'CONFIRM' | translate }}</button>
                    <button *ngIf="checkBossCredentails || checkFinmoCredentails" type="button" class="btn gradient text-light" (click)="sendThirdPartyCreds()">{{ 'ADD_CREDENTIALS' | translate }}</button>
                    <button *ngIf="bossMailSection" class="btn gradient text-light" (click)="updateSelectedApplicantEmail()"> {{ 'UPDATE' | translate }} </button>
                    </div>
                </div>
                </div>
            </div>
            
            <!-- resourceModal -->
            <div *ngIf="resourceModal" class="modal fade modal-bg show d-block" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered table-modal-w m-auto" role="document">
                <div class="modal-content table-modal-h">
                    <div class="modal-header">
                        <h5 *ngIf="newIncome" class="modal-title" id="exampleModalCenterTitle">{{ 'ADD_INCOME_FOR_APPLICANT' | translate }}</h5>
                        <h5 *ngIf="newRevenue" class="modal-title" id="exampleModalCenterTitle">{{ 'ADD_REVENUE_SOURCE' | translate }}</h5>
                        <h5 *ngIf="newLiability" class="modal-title" id="exampleModalCenterTitle">{{ 'ADD_LIABILITY' | translate }}</h5>
                        <h5 *ngIf="newProperty" class="modal-title" id="exampleModalCenterTitle">{{ 'ADD_PROPERTY' | translate }}</h5>
                        <button type="button" class="close" (click)="addResourceModal('close')" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body overflow-auto">
                        <div *ngIf="newIncome" class="col-12">
                            <div class="steps-inner">
                                <label class="label-font text-dark">{{ 'EMPLOYMENT_STATUS' | translate }}</label>                                             
                                <select  class="form-select modal-select"  [(ngModel)]="createNewResource.income">
                                    <option value="salary">{{ 'EMPLOYEE' | translate }}</option>
                                    <option value="commission">{{ 'COMMISSION' | translate }}</option>
                                    <option value="self">{{ 'SELF_EMPLOYED' | translate }}</option>
                                    <option value="other">{{ 'OTHER_EMPLOYMENT_INCOME' | translate }}</option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="newRevenue" class="col-12">
                            <div class="steps-inner">
                                <label class="label-font text-dark">{{ 'REVENUE_TYPE' | translate }}</label><br>
                                <select class="form-select modal-select" [(ngModel)]="createNewResource.revenue">
                                    <option value="salary">{{ 'INTEREST_INCOME' | translate }}</option>
                                    <option value="commission">{{ 'CHILD_PENSION' | translate }}</option>
                                    <option value="self">{{ 'PENSION' | translate }}</option>
                                    <option value="other">{{ 'ALIMONY' | translate }}</option>
                                    <option value="other">{{ 'OTHER' | translate }}</option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="newLiability" class="col-12">
                            <div class="steps-inner">
                                <label class="label-font text-dark">{{ 'LIABILITY_TYPE' | translate }}</label><br>
                                <select class="form-select modal-select" [(ngModel)]="createNewResource.liability">
                                    <option value="creditcard">{{ 'CREDIT_CARD' | translate }}</option>
                                    <option value="personalloan">{{ 'PERSONAL_LOAN' | translate }}</option>
                                    <option value="carloan">{{ 'CAR_LOAN' | translate }}</option>
                                    <option value="unsecuredcredit">{{ 'UNSECURED_CREDIT' | translate }}</option>
                                    <option value="alimony">{{ 'ALIMONY' | translate }}</option>
                                    <option value="childsupport">{{ 'CHILD_SUPPORT' | translate }}</option>
                                    <option value="studentloan">{{ 'STUDENT_LOAN' | translate }}</option>
                                    <option value="salaryentry">{{ 'SALARY_ENTRY' | translate }}</option>
                                    <option value="other">{{ 'OTHER' | translate }}</option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="newProperty">
                            <div class="col-12">
                                <div class="steps-inner">
                                    <label class="label-font text-dark">{{ 'OBLIGATION_TYPE' | translate }}</label><br>
                                    <input type="text" placeholder="{{ 'OBLIGATION_TYPE' | translate }}" [(ngModel)]="createNewResource.property_obligation_address" class="form-control" style="height: 42px; width: 100%; border: 1px solid;" >
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="steps-inner">
                                    <label class="label-font text-dark">{{ 'OCCUPANCY' | translate }}</label><br>
                                    <select class="form-select modal-select" [(ngModel)]="createNewResource.property_occupancy">
                                        <option value="owner">{{ 'OWNER_OCCUPIED' | translate }}</option>
                                        <option value="ownerRental">{{ 'OWNER_OCCUPIED_RENTAL' | translate }}</option>
                                        <option value="rental">{{ 'RENTAL' | translate }}</option>
                                        <option value="second">{{ 'SECOND_HOME' | translate }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                    <button type="button" class="btn btn-dark" (click)="addResourceModal('close')">{{ 'CANCEL' | translate }}</button>
                    <button type="button" class="btn gradient text-light" (click)="addResource()">{{ 'CONFIRM' | translate }}</button>
                    </div>
                </div>
                </div>
            </div>
            
            <!-- addApplicantModal -->
            <div *ngIf="addApplicantModal" class="modal fade modal-bg show d-block" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered table-modal-w m-auto" role="document">
                <div class="modal-content table-modal-h">
                    <div class="modal-header">
                    <h5 *ngIf="!editApplicant" class="modal-title" id="exampleModalCenterTitle">{{ 'ADD_BORROWER' | translate }}</h5>
                    <h5 *ngIf="editApplicant" class="modal-title" id="exampleModalCenterTitle">{{ 'EDIT_BORROWER' | translate }}</h5>
                    <button type="button" class="close" (click)="newApplicantModal('close')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-body overflow-auto">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="steps-inner">
                                    <label class="label-font text-dark">{{ 'BORROWER_FIRST_NAME' | translate }}</label><br>
                                    <input *ngIf="!editApplicant" name="BORROWER_FIRST_NAME" type="text" (focus)="getInputfield('applicantEditForm','BORROWER_FIRST_NAME')" [(ngModel)]="newApplicant.firstName" (focusout)="onFocusOut()" class="form-control" style="height: 42px; width: 100%;" >
                                    <input *ngIf="editApplicant" name="BORROWER_FIRST_NAME" type="text" (focus)="getInputfield('applicantEditForm','BORROWER_FIRST_NAME')" [(ngModel)]="editApplicantData.firstName" (focusout)="onFocusOut()" class="form-control" style="height: 42px; width: 100%;" >
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="steps-inner">
                                    <label class="label-font text-dark">{{ 'BORROWER_LAST_NAME' | translate }}</label><br>
                                    <input *ngIf="!editApplicant" name="BORROWER_LAST_NAME" type="text" (focus)="getInputfield('applicantEditForm','BORROWER_LAST_NAME')" [(ngModel)]="newApplicant.lastName" (focusout)="onFocusOut()" class="form-control" style="height: 42px; width: 100%;" >
                                    <input *ngIf="editApplicant" name="BORROWER_LAST_NAME" type="text" (focus)="getInputfield('applicantEditForm','BORROWER_LAST_NAME')" [(ngModel)]="editApplicantData.lastName" (focusout)="onFocusOut()" class="form-control" style="height: 42px; width: 100%;" >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                    <button type="button" class="btn btn-dark" (click)="newApplicantModal('close')">{{ 'CANCEL' | translate }}</button>
                    <button *ngIf="!editApplicant" type="button" class="btn gradient text-light" (click)="addApplicant()">{{ 'CONFIRM' | translate }}</button>
                    <button *ngIf="editApplicant" type="button" class="btn gradient text-light" (click)="editApplicantName()">{{ 'CONFIRM' | translate }}</button>
                    </div>
                </div>
                </div>
            </div>
            
            <!-- brokerRequestModal -->
             <div *ngIf="brokerRequestModal" class="modal fade modal-bg show d-block" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered table-modal-w m-auto" role="document">
                <div class="modal-content table-modal-h">
                    <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalCenterTitle">{{ 'REQUEST_BROKER' | translate }}</h5>
                    <button type="button" class="close" (click)="requestedBrokerModel('close')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-body overflow-auto">
                        <p>{{ 'REQUEST_BROKER_DESCRIPTION' | translate }}</p>
                    </div>
                    <div class="modal-footer">
                    <button type="button" class="btn btn-dark" (click)="requestedBrokerModel('close')">{{ 'CANCEL' | translate }}</button>
                    <button type="button" class="btn gradient text-light" (click)="requestBroker()">{{ 'CONFIRM' | translate }}</button>
                    </div>
                </div>
                </div>
            </div>

            <!-- preApprovalModal -->
             <div *ngIf="preApprovalModal" class="modal fade modal-bg show d-block" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered table-modal-w m-auto" role="document">
                <div class="modal-content table-modal-h">
                    <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalCenterTitle">{{ 'PRE_APPROVAL_OPTIONS' | translate }}</h5>
                    <button type="button" class="close" (click)="getPreApprovalModal('close'); getInputfield('preApprovalClose')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-body overflow-auto my-3">
                        <!-- <h6 class="font-weight-light"></h6> -->
                        <p class="text-center">{{ 'MAX_DOWN_PAYMENT' | translate }} (%)</p>
                        <div #preApprovalBtns class="row justify-content-center my-3">
                            <div class="col-3 px-2">
                                <button id="preApprovalFive" class="btn approval-btn-modal w-100 c-fs-5" (click)="setSubjectProperty.downPayment = '5'; getPreApprovalBtnActive('preApprovalFive','5')">5%</button>
                            </div>
                            <div class="col-3 px-2">
                                <button id="preApprovalTen" class="btn approval-btn-modal w-100 c-fs-5" (click)="setSubjectProperty.downPayment = '10'; getPreApprovalBtnActive('preApprovalTen','10')">10%</button>
                            </div>
                            <div class="col-3 px-2">
                                <button id="preApprovalFifteen" class="btn approval-btn-modal w-100 c-fs-5" (click)="setSubjectProperty.downPayment = '15'; getPreApprovalBtnActive('preApprovalFifteen','15')">15%</button>
                            </div>
                            <div class="col-3 px-2">
                                <button id="preApprovalTwenty" class="btn approval-btn-modal w-100 c-fs-5" (click)="setSubjectProperty.downPayment = '20'; getPreApprovalBtnActive('preApprovalTwenty','20')">20%</button>
                            </div>
                        </div>
                        <div class="mb-3 mt-5">
                            <label for="mortgage_rate">{{ 'CUSTON_DOWN_PAYMENT' | translate }} (%)</label>
                            <input type="text" class="form-control input-white c-br-one-third" [(ngModel)]="setSubjectProperty.downPayment" (ngModelChange)="onChangeSubjectProperty('downPayment',$event); reRunCalculations(); checkDownPaymentTwentyPercent(); checkDownPaymentFivePercent(); insuranceRerun();" (focusout)="onFocusOut()" (keypress)="allowDecimal($event)"  [placeholder]="'CUSTON_DOWN_PAYMENT' | translate" style="height: 42px">
                        </div>
                        <div class="mb-3 mt-5">
                            <label for="mortgage_rate">{{ 'MORTGAGE_RATE' | translate }} (%)</label>
                            <input type="text" class="form-control input-white c-br-one-third" [(ngModel)]="setMortgage.mortgageRate" (ngModelChange)="reRunCalculations()" (focusout)="onFocusOut()" (keypress)="allowDecimal($event)"  [placeholder]="'MORTGAGE_RATE' | translate" style="height: 42px">
                        </div>
                        <div class="text-center">
                            <button type="button" class="btn rounded-pill gradient text-light w-50" (click)="getPreApprovalData()">{{ 'CONFIRM' | translate }}</button>
                        </div>
                    </div>
                </div>
                </div>
            </div>

            <!-- authModal -->
             <div *ngIf="authModal" class="modal fade modal-bg show d-block"  [ngClass]="{'modal-BB ': !getBbModalValue, 'modal-BB-index ': getBbModalValue}" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered table-modal-w m-auto" role="document">
                <div class="modal-content login-modal-h">
                    <div class="modal-header">
                    <h5  *ngIf="loginActive && !registerActive" class="modal-title" id="exampleModalCenterTitle">{{ 'LOGIN' | translate }}</h5>
                    <h5  *ngIf="!loginActive && registerActive" class="modal-title" id="exampleModalCenterTitle">{{ 'CREATE_ACCOUNT' | translate }}</h5>
                    <h5  *ngIf="!loginActive && !registerActive && checkUserEmail" class="modal-title" id="exampleModalCenterTitle">{{ 'MODIFY_MY_PROFILE' | translate }}</h5>
                    <h5  *ngIf="!loginActive && !registerActive && !checkUserEmail && setNewPassword" class="modal-title" id="exampleModalCenterTitle">{{ 'UPDATE_PASSWORD' | translate }}</h5>
                    <button *ngIf="!getBbModalValue" type="button" class="close" (click)="specialistContactModal('close')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-body overflow-auto">
                        <div class="row">
                            <div class="col-12">
                                <!-- LOGIN PAGE -->
                                <div class="pt-4" *ngIf="loginActive && !registerActive">
                                    <button class="btn btn-outline-dark lsb-btn-font w-100 mb-1 rounded-pill" (click)="bbModalOpen(true)">
                                        <div>
                                            <img class="social-media-icons" src="assets/images/icons-facebook.png" alt="Social Media">
                                            <img class="social-media-icons" src="assets/images/icons-windows.png">
                                            <img class="social-media-icons" src="assets/images/icon-apple.png">
                                            <img class="social-media-icons" src="assets/images/icons-linkedin.png">
                                            <img class="social-media-icons" src="assets/images/icons-google.png">
                                        </div>
                                    </button>
                                    <div class="my-3">
                                    <div class="c-separator c-font-12">OR</div>
                                    </div>
                                    <div class="mb-3">
                                        <input type="email" class="form-control input-white c-br-one-third" [(ngModel)]="userInfo.email" placeholder="Adresse e-mail" (keyup.enter)="getTriggerLogin()">
                                    </div>
                                    <div class="mb-3">
                                        <input type="password" class="form-control input-white c-br-one-third" [(ngModel)]="userInfo.password" placeholder="Mot de passe" (keyup.enter)="getTriggerLogin()">
                                    </div>
                                    
                                    <div class="input-group mt-1 mb-3">
                                        <span class="sub-font c-font-12">New to Kingstate?&nbsp;</span>
                                        <span class="sub-font c-font-12 c-pointer" style="text-decoration: underline" (click)="openRegister()">{{ 'CREATE_ACCOUNT' | translate }}</span>
                                    </div>
                                    <button class="btn btn-outline-dark lsb-btn-font w-100 mb-3 rounded-pill" (click)="getTriggerLogin()" (keyup.enter)="getTriggerLogin()">{{ 'LOGIN' | translate }}</button>
                                </div>
                            
                                <!-- REGISTER PAGE -->
                                <div class="pt-4" *ngIf="!loginActive && registerActive">
                                    <button class="btn btn-outline-dark lsb-btn-font w-100 mb-1 rounded-pill" (click)="bbModalOpen(true)">
                                        <div>
                                            <img class="social-media-icons" src="assets/images/icons-facebook.png" alt="Social Media">
                                            <img class="social-media-icons" src="assets/images/icons-windows.png">
                                            <img class="social-media-icons" src="assets/images/icon-apple.png">
                                            <img class="social-media-icons" src="assets/images/icons-linkedin.png">
                                            <img class="social-media-icons" src="assets/images/icons-google.png">
                                        </div>
                                    </button>
                                    <div class="my-3">
                                    <div class="c-separator c-font-12">OR</div>
                                    </div>
                                    <div class="input-group mb-3">
                                        <span class="sub-font">{{ 'CREATE_ACCOUNT_DETAILS' | translate }}</span>
                                    </div>
                                    <div class="mb-3">
                                        <input type="text" class="form-control input-white c-br-one-third" [(ngModel)]="userInfo.username" [placeholder]="'NAME' | translate" style="height: 42px">
                                    </div>
                                    <div class="mb-3">
                                        <input type="email" class="form-control input-white c-br-one-third" [(ngModel)]="userInfo.email" [placeholder]="'EMAIL_ADDRESS' | translate" style="height: 42px">
                                    </div>
                                    <div class="mb-3">
                                        <input type="password" class="form-control input-white c-br-one-third" [(ngModel)]="userInfo.password" [placeholder]="'PASSWORD' | translate" style="height: 42px">
                                    </div>
                                    <div class="input-group my-3">
                                        <span class="sub-font c-pointer c-font-12" style="text-decoration: underline" (click)="openLogin()">{{ 'EXISTING_ACCOUNT_LINK' | translate }}</span>
                                    </div>
                                    <button class="btn btn-outline-dark lsb-btn-font w-100 mb-3 rounded-pill" (click)="getTriggerRegister()">{{ 'REGISTER_BUTTON' | translate }}</button>
                                </div>

                                <!-- EDIT PAGE -->
                                <div *ngIf="!loginActive && !registerActive && checkUserEmail && !setNewPassword" class="row">
                                    <div class="col-md-6 col-lg-12" style="padding-top: 0px">
                                        <button class="btn btn-outline-dark lsb-btn-font w-100 mb-1 rounded-pill" (click)="bbModalOpen(true)">
                                            <div>
                                                <img class="social-media-icons" src="assets/images/icons-facebook.png" alt="Social Media">
                                                <img class="social-media-icons" src="assets/images/icons-windows.png">
                                                <img class="social-media-icons" src="assets/images/icon-apple.png">
                                                <img class="social-media-icons" src="assets/images/icons-linkedin.png">
                                                <img class="social-media-icons" src="assets/images/icons-google.png">
                                            </div>
                                        </button>
                                        <div class="my-3">
                                        <div class="c-separator c-font-12">OR</div>
                                        </div>
                                      <div class="steps-inner">
                                        <div class="form-group mb-3">
                                          <label for="firstName">{{ 'FIRST_NAME' | translate }}</label>
                                          <input type="text" class="form-control input-white w-100 c-br-one-third" [(ngModel)]="user.name" placeholder="{{ 'FIRST_NAME' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                                        </div>
                                        <div class="form-group mb-3">
                                          <label for="lastName">{{ 'LAST_NAME' | translate }}</label>
                                          <input type="text" class="form-control input-white w-100 c-br-one-third" [(ngModel)]="user.last_name" placeholder="{{ 'LAST_NAME' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                                        </div>
                                        <div class="form-group mb-3">
                                          <label for="profileEmail">{{ 'EMAIL' | translate }}</label>
                                          <input type="email" class="form-control input-white w-100 c-br-one-third" [(ngModel)]="user.email" placeholder="{{ 'EMAIL_ADDRESS' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                                        </div>
                                        <div class="form-group mb-3">
                                          <label for="exampleInputEmail1">{{ 'MOBILE' | translate }}</label>
                                          <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                              <span class="input-group-text"><img src="../../assets/images/ca.png" alt="CA" height="10px" width="15px" style="margin-right: 5px;">+1</span>
                                            </div>
                                            <input type="text" inputmode="decimal" maxlength="12" class="form-control input-white" [(ngModel)]="user.phone" (keypress)="allowDecimal($event)" placeholder="{{ 'MOBILE' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxPhoneNumberFormatter>
                                          </div>
                                        </div>
                                        <div class="input-group my-3">
                                            <span class="sub-font c-pointer c-font-12" style="text-decoration: underline" (click)="toggleNewPassword()">{{ 'UPDATE_PASSWORD' | translate }}</span>
                                        </div>
                                
                                        <button class="btn btn-outline-dark lsb-btn-font w-100 mb-3 rounded-pill" (click)="getTriggerEdit()"> {{ 'UPDATE' | translate }} </button>
                                      </div>
                                    </div>
                                </div>

                                <!-- NEW PASSWORD PAGE -->
                                <div *ngIf="setNewPassword" class="row">
                                    <div class="col-12">
                                        <span class="sub-font c-pointer">
                                          <i class="fas fa-arrow-left c-pointer" style="margin-right: 10px" (click)="toggleNewPassword()"></i>
                                        </span>
                                    </div>
                                    <div class="col-md-6 col-lg-12" style="padding-top: 0px">
                                      <div class="steps-inner">
                                        <div class="form-group mb-3">
                                          <label for="firstName">{{ 'NEW_PASSWORD' | translate }}</label>
                                          <input type="password" class="form-control input-select input-white w-100 c-br-one-third" [(ngModel)]="updatePassword.newPassword" placeholder="{{ 'NEW_PASSWORD' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                                        </div>
                                        <div class="form-group mb-3">
                                          <label for="lastName">{{ 'NEW_PASSWORD_CONFIRM' | translate }}</label>
                                          <input type="password" class="form-control input-select input-white w-100 c-br-one-third" [(ngModel)]="updatePassword.newPasswordConfirm" placeholder="{{ 'NEW_PASSWORD_CONFIRM' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                                        </div>
                                        <button class="btn btn-outline-dark lsb-btn-font btn-sm w-100 rounded-pill"  (click)="getTriggerNewPassword()"> {{ 'UPDATE' | translate }} </button>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <!-- termConditionsModal -->
            <div *ngIf="termConditions" class="modal fade modal-bg show d-block" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered table-modal-w m-auto tc-modal-w" role="document">
                <div class="modal-content table-modal-h tc-modal-h">
                    <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalCenterTitle">{{ 'TERMS_CONDITIONS' | translate }}</h5>
                    </div>
                    <div class="modal-body overflow-auto">
                        <ngx-terms-conditions></ngx-terms-conditions>
                    </div>
                    <div class="modal-footer">
                    <button type="button" class="btn gradient text-light"(click)="termConditionsModal('close')">{{ 'CONFIRM' | translate }}</button>
                    </div>
                </div>
                </div>
            </div>

        <!-- </div> -->
      <!-- <ng-content select="router-outlet">
      </ng-content> -->


    </nb-layout-column>

    <!-- <nb-layout-footer fixed class="black">
      <ngx-footer></ngx-footer>
    </nb-layout-footer>
  </nb-layout> -->