import { Component, OnInit, Renderer2 } from '@angular/core';
import { TypeWriterTextService } from '../../../../components/type-writer-text/type-writer-text.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ngx-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {
  currentLanguage: string = 'fr';
  private languageSubscription: Subscription;

  constructor(private renderer: Renderer2, private typeWriterTextServices: TypeWriterTextService) {}

  ngOnInit(): void {
    this.languageSubscription = this.typeWriterTextServices.setLanguage$.subscribe((language) => {
      this.currentLanguage = language;
    });
    const script = this.renderer.createElement('script');
    script.src = 'https://app.getterms.io/dist/js/embed.js';
    script.type = 'text/javascript';
    script.async = true;
    this.renderer.appendChild(document.body, script);
  }

  getLanguage(): string {
    return this.currentLanguage === 'en' ? 'en-us' : 'fr';
  }

}
