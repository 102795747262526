import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { LeftSidebarComponent } from '../../left-sidebar.component';
import { ToasterService } from '../../../@theme/components/toaster/toaster.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { HelperService } from '../../../@theme/services/core-services/helper.service';

@Component({
  selector: 'ngx-business-information',
  templateUrl: './business-information.component.html',
  styleUrls: ['./business-information.component.scss']
})
export class BusinessInformationComponent implements OnInit {
  @Output() updateCheckLogin = new EventEmitter<any>();
  @Output() tiggerCheckLogin = new EventEmitter<void>();
  @ViewChild(LeftSidebarComponent, { static: false }) leftSidebarComponent: LeftSidebarComponent;
  @ViewChild('setBusinessLogo') setBusinessLogo!: ElementRef;
  selectedImage: File | null = null;
  profileImageView: any = '';
  checkImage = false;
  uploadBtn = false;
  uploadingLoader = false;
  updatingLoader = false;
  isUpdateButtonEnabled = false;
  nameValidator = false;
  addressValidator = false;
  businessLogo:any;
  business = {
    name: '',
    address: '',
    email: '',
    phone: ''
  }
  businessUpdateChecker = {
    name: '',
    address: '',
    email: '',
    phone: ''
  }
  getBusinessInfo = null;

  constructor(private http: HttpClient, private toastrService: ToasterService, private helperService: HelperService) { }

  ngOnInit(): void {
    this.checkProfileImage()
  }

  showSuccess(title: String, message: String) {
    this.toastrService.success(title, message);
  }

  showError(title: String, message: String) {
    this.toastrService.danger(title, message);
  }

  getBusinessInfomation(val: any){
    this.getBusinessInfo = val;
    if(val.business_information){
      this.business.name = val.business_information.display_name;
      this.business.address = val.business_information.address;
      this.business.email = val.business_information.email;
      this.business.phone = this.helperService.getFormattedPhoneNumber(val.business_information.phone);
      
      this.businessUpdateChecker.name = val.business_information.display_name;
      this.businessUpdateChecker.address = val.business_information.address;;
      this.businessUpdateChecker.email = val.business_information.email;
      this.businessUpdateChecker.phone = this.helperService.getFormattedPhoneNumber(val.business_information.phone);
      
      if(val.business_information.logo !== null && val.business_information.logo !== ''){
        this.profileImageView = val.business_information.logo;
        this.checkImage = false;
      }
      this.onInputChange()
    }
  }

  updateBusinessInfo(){
    this.updatingLoader = true;
    let unformatPhoneNumber = this.business.phone.replace(/-/g, '');
    if(unformatPhoneNumber && unformatPhoneNumber.length < 10){
      this.updatingLoader = false;
      this.showError('Error', 'Incorrect Number');
    } else {
      const formData = new FormData();
      if (this.selectedImage) {
        formData.append('logo', this.selectedImage);
      }
      if (this.business.name) {
        formData.append('display_name', this.business.name);
      }
      if (this.business.address) {
        formData.append('address', this.business.address);
      }
      if (this.business.email) {
        formData.append('email', this.business.email);
      }
      if (unformatPhoneNumber) {
        formData.append('phone', unformatPhoneNumber);
      }
      this.http.post<any>(environment.apiBaseUrl+'businessInformation', formData).subscribe(
        response => {
          if(response.success){
            this.showSuccess(response.message,'');
            this.getBusinessInfo = response.user;
            // console.log("second", response);
            // console.log("second", this.getBusinessInfo);
            this.updatingLoader = false;
            this.uploadBtn = false;
            this.sendUpdateUser(this.getBusinessInfo);
          } else {
            this.updatingLoader = false;
            this.showError('Error', response.message);
          }
        },
        error => {
          if(error.error.message){
            this.updatingLoader = false;
            this.showError('Error', error.error.message);
          } else {
            this.updatingLoader = false;
            this.showError('Error', error.statusText);
          }
        }
      );
    }
  }

  sendUpdateUser(data: any){
    this.getBusinessInfomation(data);
    this.updateCheckLogin.emit(data);
  }

  allowDecimal(event: KeyboardEvent) {
    const currentValue = (<HTMLInputElement>event.target).value;
    if (event.key === '.') {
      if (currentValue.includes('.')) {
        event.preventDefault();
      }
    }

    if (!(event.key >= '0' && event.key <= '9') && event.key !== 'Backspace' && event.key !== '.') {
      event.preventDefault();
    }
  }

  openFileInput() {
    this.setBusinessLogo.nativeElement.click();
  }

  fileChangeEvent(event: any): void {
    if(event.target.files){
      this.selectedImage = event.target.files[0];
      this.uploadBtn = true;
      let reader = new FileReader();
      reader?.readAsDataURL(event.target.files[0]);
      reader.onload=(e: any)=>{
        this.profileImageView = e.target.result;
        this.checkImage = false;
      }
    }
  }

  // onUploadProfile(): void {
  //   if (this.selectedImage) {
  //     this.uploadingLoader = true;
  //     const formData = new FormData();
  //     formData.append('logo', this.selectedImage);

  //     this.http.post<any>(environment.apiBaseUrl+'businessInformation/upload-logo', formData)
  //       .subscribe(
  //         (response) => {
  //           console.log("first", response);
  //           this.showSuccess(response.message, '');
  //           if(response.success){
  //             this.uploadBtn = false;
  //             this.uploadingLoader = false;
  //             this.tiggerCheckLogin.emit();
  //           }
  //         },
  //         (error) => {
  //           this.showError('Error', error.error.message);
  //           // console.error('Error uploading profile picture:', error);
  //         }
  //       );
  //   }
  // }

  checkProfileImage(){
    this.uploadBtn = false;
    if(this.profileImageView === ''){
      this.checkImage = true;
    } else {
      this.checkImage = false;
    }
  }

  onInputChange() {
    this.isUpdateButtonEnabled = Object.keys(this.business).some(key => this.business[key] !== this.businessUpdateChecker[key]);

    if(this.business.name !== null && this.business.name !== ''){
      if(this.business.name.length > 30){
        this.nameValidator = true;
      } else{
        this.nameValidator = false
      }
    }
    
    if(this.business.address !== null && this.business.address !== ''){
      if(this.business.address.length > 60){
        this.addressValidator = true;
      } else{
        this.addressValidator = false
      }
    }
  }

}
