import { Directive, HostListener, ElementRef, Renderer2, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  selector: '[ngxPhoneNumberFormatter]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PhoneNumberFormatterDirective),
    multi: true
  }]
})
export class PhoneNumberFormatterDirective implements ControlValueAccessor {
  private onChange: (value: string) => void;
  private onTouched: () => void;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const inputElement = this.el.nativeElement;
    const rawInput = inputElement.value.replace(/\D/g, '').substring(0, 10);
    const oldValue = inputElement.value;
    const cursorPosition = inputElement.selectionStart;

    // Count the number of digits before the cursor
    let digitsBeforeCursor = oldValue.slice(0, cursorPosition).replace(/\D/g, '').length;

    // Apply formatting to the raw input value
    let formattedInput = rawInput;
    if (rawInput.length > 3) {
      formattedInput = rawInput.substring(0, 3) + '-' + rawInput.substring(3);
    }
    if (rawInput.length > 6) {
      formattedInput = formattedInput.substring(0, 7) + '-' + formattedInput.substring(7);
    }

    // Calculate the new cursor position
    let formattedDigitsBeforeCursor = 0;
    for (let i = 0, digitCount = 0; i < formattedInput.length; i++) {
      if (/\d/.test(formattedInput[i])) {
        digitCount++;
      }
      if (digitCount === digitsBeforeCursor) {
        formattedDigitsBeforeCursor = i + 1;
        break;
      }
    }

    // Update the phone number in the model
    this.onChange(formattedInput);

    // Set the new value and restore cursor position
    this.renderer.setProperty(inputElement, 'value', formattedInput);
    inputElement.setSelectionRange(formattedDigitsBeforeCursor, formattedDigitsBeforeCursor);
  }

  writeValue(value: string): void {
    if (value) {
      const rawInput = value.replace(/\D/g, '').substring(0, 10);
      let formattedInput = rawInput;
      if (rawInput.length > 3) {
        formattedInput = rawInput.substring(0, 3) + '-' + rawInput.substring(3);
      }
      if (rawInput.length > 6) {
        formattedInput = formattedInput.substring(0, 7) + '-' + formattedInput.substring(7);
      }
      this.renderer.setProperty(this.el.nativeElement, 'value', formattedInput);
    } else {
      this.renderer.setProperty(this.el.nativeElement, 'value', '');
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
  }
}
