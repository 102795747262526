import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private tokenKey = 'auth_token';

  constructor() {}

  private interceptedResponseSubject = new BehaviorSubject<HttpResponse<any> | HttpErrorResponse | null>(null);

  setInterceptedResponse(response: HttpResponse<any> | HttpErrorResponse) {
    this.interceptedResponseSubject.next(response);
  }

  getInterceptedResponse() {
    return this.interceptedResponseSubject.asObservable();
  }

  public saveToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
  }

  public getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  public removeToken(): void {
    localStorage.removeItem(this.tokenKey);
  }
  
  public isTokenExpired(): boolean {
    const token = this.getToken();
    if (!token) {
      return true;
    }

    // Parse the token to extract the expiration date
    const tokenData = JSON.parse(atob(token.split('.')[1]));
    const expiration = tokenData.exp * 1000; // Convert to milliseconds

    // Check if the token has expired
    return Date.now() > expiration;
  }
}