import { Component, OnInit } from '@angular/core';
import { ToasterService } from './toaster.service';

@Component({
  selector: 'ngx-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent implements OnInit {

  constructor(public toastService: ToasterService) { }

  ngOnInit(): void {
  }

}
