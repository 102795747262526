import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  getFormattedPhoneNumber(value: string) {
    let input = value.replace(/\D/g, '').substring(0, 10);
    if (input.length > 3) {
      input = input.substring(0, 3) + '-' + input.substring(3);
    }
    if (input.length > 7) {
      input = input.substring(0, 7) + '-' + input.substring(7);
    }
    return input
  }
  
}
