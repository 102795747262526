import { Component, ViewChild, ElementRef, OnInit, EventEmitter, HostListener, Output, Renderer2, AfterViewInit  } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import Pusher from 'pusher-js';
import { NbToastRef } from '@nebular/theme';
import { throws } from 'assert';
import {TranslateService} from '@ngx-translate/core';
import { LeftSidebarComponent } from './../../../left-sidebar/left-sidebar.component';
import { Subscription } from 'rxjs';
import { HeaderComponent } from '../../components';
import { ThousandSeparatorService } from '../../services/thousandSeparator/thousand-separator.service';
import { ToasterService } from '../../components/toaster/toaster.service';
import { TypeWriterTextService } from '../../components/type-writer-text/type-writer-text.service';
import { cloneDeep } from 'lodash';
import { ThirdPartyCredentialsComponent } from './one-column-components/third-party-credentials/third-party-credentials.component';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  templateUrl: './one-column.layout.html',
})

export class OneColumnLayoutComponent implements OnInit, AfterViewInit{
  @Output() setScrollingValue = new EventEmitter<boolean>();
  @ViewChild(LeftSidebarComponent, { static: false }) childComponent: LeftSidebarComponent;
  @ViewChild(HeaderComponent, { static: false }) headerComponent: HeaderComponent;
  @ViewChild(ThirdPartyCredentialsComponent, { static: false }) thirdPartyCredentialsComponent: ThirdPartyCredentialsComponent;
  private textIncomingSubscription: Subscription;
  private applicationChangeSubscription: Subscription;
  showUrl: number = 0;
  pusher;
  private httpSubscription: Subscription | null = null;
  // private toastrRefs: NbToastRef[] = [];
  screenWidth: number;
  screenHeight: number;
  ngOnInit(): void {

    if (typeof document.hidden !== 'undefined') {
      document.addEventListener('visibilitychange', () => this.handleVisibilityChange());
    }
    // Call the function here
    this.selectApplicant(0);
    
    Pusher.logToConsole = true;
    this.pusher = new Pusher( environment.pusher_key, {
      cluster: 'us2',
    });

    this.screenWidth = window.innerWidth;
    this.applicationChangeSubscription = this.typeWriterTextServices.applicationChange$.subscribe((change) => {
      if(change){
        this.textIdChecker = '';
      }
    });

    this.textIncomingSubscription = this.typeWriterTextServices.setTextIncomingId$.subscribe(({ textId, stepId, getApplicant }) => {
      if(this.activeApplicant !== getApplicant && getApplicant !== null){
        this.getTextApplicant(getApplicant);
        this.textIdChecker = '';
        if(textId !== 'defaultTextIncomingId' && this.textIdChecker !== textId){
          this.textIdChecker = textId;
          setTimeout(() => {
            this.triggerStepChange(stepId);
            this.scrollToDiv(stepId, null, textId);
          }, 200);
        }
      } else{
        if(textId !== 'defaultTextIncomingId' && this.textIdChecker !== textId){
          this.textIdChecker = textId;
          setTimeout(() => {
            this.triggerStepChange(stepId);
            this.scrollToDiv(stepId, null, textId);
          }, 200);
        }
      }
    });

  }

  getTextApplicant(index: number){
    this.selectApplicant(index); 
    this.delayedSumOfIncomes(); 
    this.delayedSumOfLiabilities(); 
    this.delayedCalculateMonthlyPropertyCost(); 
    this.delayedSumOfRevenues(); 
  }

  triggerChildFunction() {
    if (this.childComponent) {
      this.childComponent.checkLoggedIn();
    }
  }

  handleVisibilityChange() {
    if (document.hidden) {
      this.stopViewership();
    } else {
      this.startViewership();
    }
  }

  textIdChecker = '';
  amortizationPeriods: number[] = Array.from({ length: 31 }, (_, index) => index);
  months: number[] = Array.from({ length: 12 }, (_, index) => index);
  isOpen1 = false;
  isOpen2 = false;
  isOpen3 = false;
  isOpen4 = false;
  isActive1 = true;
  isActive2 = false;
  isActive12 = true;
  isActive22 = false;
  stripe_key = environment.stripePublishKey;
  price_id = environment.stripePriceId;
  private toastrRefs: NbToastRef;
  downpaymentValueType = 'dollar';
  showNonSubjectproperty = false;
  activeRateType = 'proposed'
  showMinError = false;
  addButton = true;
  editButton = true;
  totalLiabilities = 0;
  totalRevenues = 0;
  additionalRevenue = 0;
  additionalNonSubjectRevenue = 0;
  triggeredViewership = false;
  subjectpropertyParameters = 0;
  monthlyPropertyCost = 0;
  minimumDownPayment = null;
  minimumDownPaymentPercent = null;
  totalIncome = 0;
  monthlyMortgageAmount = 0;
  monthlyMortgageAmount_q = 0;
  insurance_premium = 0;
  user = null;
  tds = 0;
  gds = 0;
  removeApplicantButton = false;
  temp_totalLiabilities = 0;
  temp_totalRevenues = 0;
  temp_liabilities = [];
  maximumMonthlyPayments = 0;
  mortgageCapacity = 0;
  application_id = null;
  outputData = {gds: 0, tds: 0, rate: 'proposed', house: 0, monthlyMortgageAmount: null, mortgageCapacity: null};
  @ViewChild('mainBody') mainBody: ElementRef;
  @ViewChild('myDiv0') myDiv0: ElementRef;
  @ViewChild('myDiv') myDiv: ElementRef;
  @ViewChild('myDiv2') myDiv2: ElementRef;
  @ViewChild('myDiv3') myDiv3: ElementRef;
  @ViewChild('mobileMenu') mobileMenu: ElementRef;
  @ViewChild('preApprovalBtns') preApprovalBtns: ElementRef;
  @ViewChild('csvShareableLinks') csvShareableLinks: ElementRef;
  @ViewChild('mobileTabs') mobileTabs: ElementRef;
  @ViewChild('paymentSwitch') paymentSwitch: ElementRef;
  @ViewChild('mobApplicantsDropdown') mobApplicantsDropdown: ElementRef;
  @ViewChild('mobScenarioDropdown') mobScenarioDropdown: ElementRef;
  @ViewChild('ApplicantsDropdownInput') ApplicantsDropdownInput: ElementRef;
  @ViewChild('stepThreeDivRef') stepThreeDivRef!: ElementRef;
  @ViewChild('stepThreeDivRefEnd') stepThreeDivRefEnd!: ElementRef;
  @ViewChild('stepFourDivRef') stepFourDivRef!: ElementRef;
  @ViewChild('stepFourDivRefEnd') stepFourDivRefEnd!: ElementRef;
  @ViewChild('idBreakPoint') idBreakPoint!: ElementRef;
  dataTableModal = false;
  kingstateNewsModal = false;
  removeBorrowerModal = false;
  scenarioModal = false;
  removeScenarioName = 0
  activeScenarioName = 0
  brokerRequestModal = false;
  authModal = false;
  footer = false;
  footerIcon = false;
  mobileSideBarContent = false;
  removeBorrowerName: string;
  activeApplicant = 0;
  activeScenario = 0;
  activeMortgage = 0;
  current = null;
  insurance_premium_enabled = false;
  applicant_id = 0;
  broker = null;
  default_minimum = '5%';
  monthlyData = [];
  annualData = [];
  scrollTabValue = '';
  getHeightStepValues = 0;
  temp_incomes = [];
  getHeightStepValuesEnd = 0;
  heightOfStepOne = 0;
  heightOfStepTwo = 0;
  heightOfStepThree = 0;
  heightOfStepFour = 0;
  heightOfMobTabs = 0;
  stepThreeValueHeight = 0;
  stepThreeValueHeightEnd = 0;
  stepFourValueHeight = 0;
  stepFourValueHeightEnd = 0;
  scrollStepsValueChange = false;
  mobileScreen = false;
  tabScreen = false;
  userStatus = false;
  viewer = null;
  allDivIds = [];
  stepTwoDivIds = [];
  stepThreeDivIds = [];
  stepFourDivIds = [];
  currentDivId ='';
  currentFieldName ='';
  tabsHeight = 0;
  getIdBreakPoint = 0;
  activeStep = null;
  getBbModalValue = false;
  buttonEdit = false;
  loadSpinner =false;
  new_appl_status = null;
  new_ran = false;
  mortgageRatePopup = false
  tempBlock = false;
  registerActive = false;
  loginActive = false;
  loggedInUpdaterValue = false;
  termConditions = false;
  downPaymentMinCheckFive = false;
  downPaymentMinCheckTwenty = false;
  currentlyActiveStepId = '';
  checkUserEmail = false;
  setNewPassword = false;
  applicantsDropdown = false;
  scenariosDropdown = false;
  setScrollableDiv = false;
  applicationResponse = false;
  addNewApplicant = false;
  brokerRequestStatus = '';
  reduced = 0;
  reduced_nsp = 0;
  lastScrollTop = 0;
  headerSize = false;
  checkStepId = '';
  selectedInputName = '';
  mobileTabBtnsScroll = false;
  inActiveStepOne = false;
  preApprovalModal = false;
  addApplicantModal = false;
  resourceModal = false;
  editApplicant = false;
  newIncome = false;
  newRevenue = false;
  newLiability = false;
  newProperty = false;
  addScenarioButton = true;
  removeScenarioButton = false;
  stickyScenarioButton = false;
  activeScenarioText = false;
  downPaymentInput = false;
  downPaymentPercent = false;
  createApplicantModal =false;
  applicationSection =false;
  NewApplicationApplicant =false;
  warningMessage = false;
  newApplicationData = false;
  csvModal = false;
  csvStatus = false;
  getCsvStatus = '';
  csvOption = false;
  csvOptionName = '';
  applicantEmail = null;
  bossMailSection = false;
  checkBossCredentails = false;
  checkFinmoCredentails = false;
  changeBossAccount = false;
  checkBossAccount = false;
  bossApplication = null;
  checkerOnLogin = false;
  setTotalLiabilities = 0;
  getActiveApplicant: any = {};
  typingTimeout: any;
  setSubjectProperty = { active: true, propertyValue: 0, downPayment: 0, downPayment_type: 'dollar', estimated_value: null, inferred_equity: null, eto: null, insurance: 0, annualPropertyTax: 0, type: 'detached', monthlyCondoFee: 0, monthlyEnergyCost: 100, monthlyRentalIncome: 0, revenueOptions: 'none', rentalPercentage: 50, occupancy: 'owner', hyrdoCost: 0, managementExpense: 0, repairCosts: 0, generalExpense: 0, homeInsurance: 0, interestCharges: 0}
  setMortgage = {active: true, frequency: 'monthly', amortizationYears: 25, amortizationMonths: 0, mortgageRate:5.25, term: 5}
  newApplicant = {
    firstName: 'emprunteur 1',
    lastName: '',
  }
  editApplicantData = {
    firstName: '',
    lastName: '',
  }
  userInfo = {
    email: '',
    password: '',
    username: ''
  }
  applicantInfo = {
    name:'',
    last_name: '',
    email: '',
    phone: ''
  }
  mobileViewer = {
    userStatus: false,
    mobileScreen: false,
    viewer: null,
  }
  updatePassword = {
    newPassword: '',
    newPasswordConfirm: '',
  } 
  createNewResource = {
    income: '',
    revenue: '',
    liability: '',
    property_obligation_address: '',
    property_occupancy: '',
  }

  application = {
                id: null,
                type: 'purchase',
                applicants: [
                    {
                      id: null,
                      first_name: 'emprunteur '+1, last_name: null , phone: null, email: null,
                      liabilities: [{ id: null , active: true , type: 'other' , balance: 0, limit: 0, payment: 0 , frequency: 'monthly', minimum: 5, mode_type: 'simple'}, 
                                    { id: null , active: true , type: 'creditcard' , balance: 0, limit: 0, payment: 0 , frequency: 'monthly', minimum: 3, mode_type: 'advanced'}, 
                                    { id: null , active: true , type: 'other' , balance: 0, limit: 0, payment: 0 , frequency: 'monthly', minimum: 5, mode_type: 'advanced'}],
                      incomes: [{ id: null , type: 'salary' , value: 0, frequency: 'yearly', mode_type: 'simple'}], revenues: [], 
                      properties: [],
                      currentlyActive: null,
                      mode: 'simple'
                    }
                  ],
                subjectproperty: [{ active: true, propertyValue: 0, downPayment: 0, downPayment_type: 'dollar', estimated_value: null, inferred_equity: null, eto: null, insurance: 0, annualPropertyTax: 0, type: 'detached', monthlyCondoFee: 0, monthlyEnergyCost: 100, monthlyRentalIncome: 0, revenueOptions: 'none', rentalPercentage: 50, occupancy: 'owner', hyrdoCost: 0, managementExpense: 0, repairCosts: 0, generalExpense: 0, homeInsurance: 0, interestCharges: 0}],
                mortgage: [{active: true, frequency: 'monthly', amortizationYears: 25, amortizationMonths: 0, mortgageRate:5.25, term: 5}]
              };
  new_application = {
                    id: null,
                    type: 'purchase',
                    applicants: [
                        {
                          id: null,
                          first_name: 'emprunteur '+1, last_name: null , phone: null, email: null,
                          liabilities: [{ id: null , active: true , type: 'other' , balance: 0, limit: 0, payment: 0 , frequency: 'monthly', minimum: 5, mode_type: 'simple'}, 
                                        { id: null , active: true , type: 'creditcard' , balance: 0, limit: 0, payment: 0 , frequency: 'monthly', minimum: 3, mode_type: 'advanced'}, 
                                        { id: null , active: true , type: 'other' , balance: 0, limit: 0, payment: 0 , frequency: 'monthly', minimum: 5, mode_type: 'advanced'}], 
                          incomes: [{ id: null , type: 'salary' , value: 0, frequency: 'yearly', mode_type: 'simple'}], revenues: [], 
                          properties: [],
                          currentlyActive: null,
                          mode: 'simple'
                        }
                      ],
                    subjectproperty: [{ active: true, propertyValue: 0, downPayment: 0, downPayment_type: 'dollar', estimated_value: null, inferred_equity: null, eto: null, insurance: 0, annualPropertyTax: 0, type: 'detached', monthlyCondoFee: 0, monthlyEnergyCost: 100, monthlyRentalIncome: 0, revenueOptions: 'none', rentalPercentage: 50, occupancy: 'owner', hyrdoCost: 0, managementExpense: 0, repairCosts: 0, generalExpense: 0, homeInsurance: 0, interestCharges: 0}],
                    mortgage: [{active: true, frequency: 'monthly', amortizationYears: 25, amortizationMonths: 0, mortgageRate:5.25, term: 5}]
                  };
  // termsList: number[] = Array.from({ length: this.application.mortgage.amortizationYears }, (_, index) => index + 1);
  termsList: number[] = [1,2,3,4,5,7,10];

  constructor(private http: HttpClient, translate: TranslateService, private toastrService: ToasterService, private renderer: Renderer2, private el: ElementRef, private thousandSeparatorService: ThousandSeparatorService, private typeWriterTextServices: TypeWriterTextService) {
    // translate.setDefaultLang('en');
    // translate.use('en');
  }
  receiveTheUser(message: string) {
    this.user = message;
    if(this.user && this.user.role.name === 'applicant'){
      this.applicationSection = false;
      this.mainBody.nativeElement.style = '';
    } else{
      if(!this.checkerOnLogin){
        this.checkerOnLogin = true;
        this.applicationSection = true;
      }
      this.getApplicationBtnPosition();
    }
    this.typeWriterTextServices.getUser(this.user);
    if(this.user && this.user.id){
      this.loggedInUpdaterValue = true;
      if(!this.user.terms_and_condition){
        this.termConditionsModal("open");
      }
      if(this.user.request_broker && this.user.request_broker.status === 'requested'){
        this.brokerRequestStatus = 'Broker Requested';
      }
    }
    if(this.user && this.user.role.name == 'applicant'){
      this.application.applicants[this.activeApplicant].first_name = this.user.name;
      this.application.applicants[this.activeApplicant].last_name = this.user.last_name;
      this.application.applicants[this.activeApplicant].phone = this.user.phone;
      this.application.applicants[this.activeApplicant].email = this.user.email;
    }
    console.log('here logout');
    if(!this.user){
      console.log('here logout 2');
      this.checkerOnLogin = false;
      this.loggedInUpdaterValue = false;
      this.applicationSection = false;
      this.brokerRequestStatus = '';
      this.getUserStatus();
      this.checkScenario();
    }
    this.userEmailStatus();
  }
  
  receiveTheBroker(message) {
    this.broker = message;
  }

  receiveTheApplication(app_id: number) {
    this.new_appl_status = app_id;
    if(app_id == 0){
      this.application = this.new_application;
      this.selectApplicant(0);
      if(this.user && this.user.role.name === 'broker'){
        this.application.applicants[this.activeApplicant].first_name = this.getActiveApplicant.name;
        this.application.applicants[this.activeApplicant].last_name = this.getActiveApplicant.last_name;
        if(!this.applicationSection && this.NewApplicationApplicant){
          this.applicationSection = true;
          this.mainBody.nativeElement.style = '';
          this.loadSpinner = false;
        } else{
          this.applicationSection = false;
          this.mainBody.nativeElement.style = '';
          this.newApplicationData = true;
        }
      } else if(this.user && this.user.role.name == 'applicant') {
        this.application.applicants[this.activeApplicant].first_name = this.user.name;
        this.application.applicants[this.activeApplicant].last_name = this.user.last_name;
      }
      this.incomes = this.application.applicants[this.activeApplicant].incomes;
      this.liabilities = this.application.applicants[this.activeApplicant].liabilities;
      this.revenues = this.application.applicants[this.activeApplicant].revenues;
      this.properties = this.application.applicants[this.activeApplicant].properties;
      this.subjectproperty = this.application.subjectproperty;
      this.mortgage = this.application.mortgage;
      // this.showUrl = 0;
      this.reRunCalculations();
      if(this.applicant_id){
        this.new_ran = true;
        this.saveNewApplicationWithUpdate();
      }
      if(this.user){
        this.toggleDiv1();
      }
    } else {
      this.loadSpinner = true;
      if(this.application_id != app_id){
        if(this.user && this.user.role.name === 'broker' && !this.applicationSection && this.NewApplicationApplicant && !this.newApplicationData){
          this.applicationSection = true;
          this.mainBody.nativeElement.style = '';
        } else{
          this.applicationSection = false;
          this.mainBody.nativeElement.style = '';
        }
        if(this.pusher){
          this.pusher.unsubscribe('notification-'+this.application_id);
          this.pusher.unsubscribe('viewer-update-'+this.application_id);
          if(this.application_id){
            this.triggerViewershipSwitch(this.application_id, app_id);
          }
          // this.toastrService.clear();
          if(this.toastrRefs != undefined){
            // this.toastrRefs.close();
            this.toastrRefs = undefined;
          }  
          this.getUserStatus();
          this.triggeredViewership = false;
        }
        this.application_id = app_id;
        this.selectApplicant(0);
        console.log('this is the culprit 1');
        this.getApplicationInfoBe(false);
        var channel = this.pusher.subscribe('notification-'+this.application_id);
        var channel_view = this.pusher.subscribe('viewer-update-'+this.application_id);
        channel.bind('notification', (data) => { 
          if(data.user_id !== this.user.id){
            console.log('this is the culprit 2 '+this.user.id+' . '+data.user_id+' - '+data.extra);
            if(data.extra == 'removeApplicant'){
              this.getApplicationInfo(true); 
            } else {
              this.getApplicationInfo(false); 
            }
          }
        });
        channel_view.bind('viewer', (data) => { 
          if(this.user && this.user.id != data.data.user_id){
            if(data.data.action == 'show'){
              if(this.toastrRefs == undefined){
                this.getUserStatus(data.data.name, true);
                if(!this.triggeredViewership){
                  this.triggerViewerShip(); 
                  this.triggeredViewership = true;
                  this.closeAllDivsReset();
                  this.toggleDiv1();
                  this.scrollToHeight('stepOne');
                } else if(data.data.view_type == 'backend'){
                  console.log('viewership triggered by again '+this.user.id+' - '+this.user.role.name);
                  this.triggerViewerShip(); 
                  this.closeAllDivsReset();
                  this.toggleDiv1();
                  this.scrollToHeight('stepOne');
                }
              } 
            } else if(data.data.action == 'scroll') {
              console.log('scroll to step '+data.data.step+', and div '+data.data.div);
              if(this.user.role.name == 'applicant'){
                if(data.data.applicant !== null){
                  this.selectApplicant(data.data.applicant);
                  console.log('applicant change detected');
                } else {
                  this.scrollToDiv(data.data.step, data.data.div, null);
                }
              }
            } else if(data.data.action == 'click') {
              console.log('scroll to step '+data.data.step+', and div '+data.data.div+',inputName '+data.data.inputName);
              if(this.user.role.name == 'applicant'){
                if(data.data.applicant !== null){
                  this.selectApplicant(data.data.applicant);
                  console.log('applicant change detected');
                } else {
                  this.scrollToInput(data.data.step, data.data.div, data.data.inputName);
                }
              }
            } else {
              if(this.toastrRefs != undefined){
                this.pusher.unsubscribe('viewer-update-'+this.application_id);
                this.toastrRefs = undefined;
              }
              this.getUserStatus();
              this.triggeredViewership = false;
            }
          }
        });
      } else {
        if(this.user && this.user.role.name === 'broker' && !this.applicationSection && this.NewApplicationApplicant && !this.newApplicationData){
          this.applicationSection = true;
          this.mainBody.nativeElement.style = '';
        } else{
          this.applicationSection = false;
          this.mainBody.nativeElement.style = '';
        }
        this.application_id = app_id;
        console.log('this is the culprit 3');
        this.getApplicationInfo(false);
      }
    }
  }

  receiveApplicant(applicant_id: number){
    this.applicant_id = applicant_id;
    if(!this.new_ran && applicant_id && !this.new_appl_status){
      this.saveNewApplicationWithUpdate();
      this.toggleDiv1();
    }
  }

  testApplicationBroker(){
    if(this.user && this.user.role.name === 'broker' && this.applicationSection) {
      this.applicationSection = false;
    }
  }

  getSelectedApplicant(applicant: any) {
    if(this.user.role.name === 'broker'){
      this.getActiveApplicant = applicant
      this.application.applicants[this.activeApplicant].first_name = this.getActiveApplicant.name;
      this.application.applicants[this.activeApplicant].last_name = this.getActiveApplicant.last_name;
    }
  }

  triggerLock(){
    this.tempBlock = true;
    this.typeWriterTextServices.getTempBlock(this.tempBlock);
    setTimeout(() => {
      this.tempBlock = false;
      this.typeWriterTextServices.getTempBlock(this.tempBlock);
    }, 3000);
  }
  incomes = this.application.applicants[this.activeApplicant].incomes;
  liabilities = this.application.applicants[this.activeApplicant].liabilities;
  revenues = this.application.applicants[this.activeApplicant].revenues;
  properties = this.application.applicants[this.activeApplicant].properties;
  subjectproperty = this.application.subjectproperty;
  mortgage = this.application.mortgage;

  async reRunCalculations() {
    setTimeout(() => {
      let subjectPropertyValue = this.application.subjectproperty[this.activeScenario].propertyValue;
      if(subjectPropertyValue !== null){
        subjectPropertyValue = parseFloat(subjectPropertyValue.toString().replace(/\s/g, ''));
      }
      this.delayedSumOfIncomes();
      this.delayedSumOfLiabilities();
      this.delayedCalculateMonthlyPropertyCost();
      this.delayedSumOfRevenues();
      this.delayedCalculateTotalPrincipalAmount();
      this.delayedMortgageCalculation();
      this.outputData.rate = this.activeRateType;
      if(subjectPropertyValue < 500000){
        this.outputData.house = 1;
      } else if(subjectPropertyValue >= 500000 && subjectPropertyValue < 1000000) {
        this.outputData.house = 2;
      } else if(subjectPropertyValue >= 1000000 && subjectPropertyValue < 3000000) {
        this.outputData.house = 3;
      } else if(subjectPropertyValue >= 3000000 && subjectPropertyValue < 5000000) {
        this.outputData.house = 4;
      } else if(subjectPropertyValue >= 5000000) {
        this.outputData.house = 5;
      }
      console.log('calculating',subjectPropertyValue);
      setTimeout(() => {
        this.calculateGDS();
        this.calculateTDS();
        this.termsList = [1,2,3,4,5,7,10];
      });
    });
    
  }

  async reRunCalculationsNoLibilityNoRevenue() {
    setTimeout(() => {
      console.log('calculating');
      this.delayedMortgageCalculation();
      this.delayedSumOfIncomes();
      this.delayedCalculateMonthlyPropertyCost();
      this.delayedCalculateTotalPrincipalAmount();
      setTimeout(() => {
        this.calculateGDS();
        this.calculateTDS();
      });
    });
    
  }

  async insuranceRerun() {
    setTimeout(() => {
      this.reRunCalculations();
    }, 500);
  }


  calculateGDS(): void {
    const mortgagePayment = this.monthlyMortgageAmount_q;
    // const propertyCost = this.monthlyPropertyCost;
    const subjectpropertyParameters = this.subjectpropertyParameters;
    const totalIncomes = this.totalIncome + this.totalRevenues + this.additionalRevenue;
    const gds = ((mortgagePayment + subjectpropertyParameters - this.reduced) / totalIncomes ) * 100;//Here things will change
    this.gds = gds;
    console.log('Mortgage payment: '+mortgagePayment);
    console.log('Total Income: '+this.totalIncome);
    console.log('Subject property parameter: '+this.subjectpropertyParameters);
    console.log('Total Revenue: '+this.totalRevenues);
    console.log('Additional Revenue: '+this.additionalRevenue);
    console.log('Additional non subject Revenue: '+this.additionalNonSubjectRevenue);
    console.log('GDS '+this.gds);
    this.outputData = { ...this.outputData, gds: this.gds };

  }

  calculateTDS(): void {
    const subjectpropertyParameters = this.subjectpropertyParameters;
    const mortgagePayment = this.monthlyMortgageAmount_q;
    const propertyCost = this.monthlyPropertyCost;
    const totalLiabilities = this.setTotalLiabilities;
    const totalIncomes = this.totalIncome + this.totalRevenues + this.additionalRevenue + this.additionalNonSubjectRevenue;
    const tds = ((mortgagePayment + subjectpropertyParameters + propertyCost + totalLiabilities - this.reduced - this.reduced_nsp) / totalIncomes ) * 100;//Here things will change
    this.tds = tds;
    console.log('propertyCost: '+ propertyCost);
    console.log('Total Liabilities: '+ totalLiabilities);
    console.log('TDS '+this.tds);
    console.log('---------------------------');
    this.outputData = { ...this.outputData, tds: this.tds };
    this.highlightInput(this.selectedInputName);

  }
  

  validateNumericInput(event: KeyboardEvent): void {
    const charCode = event.charCode;
    const inputCharacter = String.fromCharCode(charCode);
    const numericRegex = /^[0-9.]+$/;
  
    // Allow numeric characters and the dot (.) character
    if (!numericRegex.test(inputCharacter)) {
      event.preventDefault();
    }
  }

  calculateLTV(): number {
    const loanAmount = this.getPrincipalAmount(); 
    let propertyValue = this.application.subjectproperty[this.activeScenario].propertyValue;
    if(propertyValue !== null){
       propertyValue = parseFloat(propertyValue.toString().replace(/\s/g, ''));
    }
    const ltv = (loanAmount / propertyValue) * 100;
    return ltv;
  }
  
  calculatePremiumOnTotalLoan(ltv: number): number {
    let premiumPercentage: number;
  
    if (ltv <= 65) {
      premiumPercentage = 0.6;
    } else if (ltv <= 75) {
      premiumPercentage = 1.7;
    } else if (ltv <= 80) {
      premiumPercentage = 2.4;
    } else if (ltv <= 85) {
      premiumPercentage = 2.8;
    } else if (ltv <= 90) {
      premiumPercentage = 3.1;
    } else if (ltv <= 95) {
      premiumPercentage = 4.0;
    } else {
      // LTV is greater than 95, return a custom value or handle accordingly
      premiumPercentage = 0;
    }
    const premium = (premiumPercentage / 100) * this.getPrincipalAmount(); // Replace with your actual mortgage amount
    return premium;
  }

  delayedMortgageCalculation() {
    setTimeout(() => {
      this.calculateMortgageAmount();
    });
  }

  calculateMortgageAmount(): void {
    this.additionalRevenue = 0;
    this.subjectpropertyParameters = 0;
    const monthlyInterestRate = this.getMonthlyInterestRate();
    const numberOfPayments = this.getTotalNumberOfPayments();
    console.log('number of months '+numberOfPayments);

    // const numerator = monthlyInterestRate * Math.pow((1 + monthlyInterestRate), numberOfPayments);
    // const denominator = Math.pow((1 + monthlyInterestRate), numberOfPayments) - 1;
  
    const principal = this.getPrincipalAmount();
    console.log("principal",principal)
    const ltv = this.calculateLTV();
    let subjectPropertyDownPayment = this.application.subjectproperty[this.activeScenario].downPayment;
    let subjectPropertyValue = this.application.subjectproperty[this.activeScenario].propertyValue;
    let subPropertyAnnualTax= this.application.subjectproperty[this.activeScenario].annualPropertyTax;
    let subjectPropertyMonthlyCondoFee = this.application.subjectproperty[this.activeScenario].monthlyCondoFee;
    let subjectPropertyMonthlyEnergyCost = this.application.subjectproperty[this.activeScenario].monthlyEnergyCost;
    let subjectPropertyMonthlyRentalIncome = this.application.subjectproperty[this.activeScenario].monthlyRentalIncome;
    let subjectPropertyHyrdoCost = this.application.subjectproperty[this.activeScenario].hyrdoCost;
    let subjectPropertyManagementExpense = this.application.subjectproperty[this.activeScenario].managementExpense;
    let subjectPropertyRepairCosts = this.application.subjectproperty[this.activeScenario].repairCosts;
    let subjectPropertyGeneralExpense = this.application.subjectproperty[this.activeScenario].generalExpense;
    let subjectPropertyHomeInsurance = this.application.subjectproperty[this.activeScenario].homeInsurance;
    let subjectPropertyInterestCharges = this.application.subjectproperty[this.activeScenario].interestCharges;

    if(subjectPropertyDownPayment !== null && subjectPropertyValue !== null && subPropertyAnnualTax !== null && subjectPropertyMonthlyCondoFee !== null && subjectPropertyMonthlyEnergyCost !== null && subjectPropertyMonthlyRentalIncome !== null
      && subjectPropertyHyrdoCost !== null && subjectPropertyManagementExpense !== null && subjectPropertyRepairCosts !== null && subjectPropertyGeneralExpense !== null && subjectPropertyHomeInsurance !== null
      && subjectPropertyInterestCharges !== null){
        subjectPropertyDownPayment = parseFloat(subjectPropertyDownPayment.toString().replace(/\s/g, ''));
        subjectPropertyValue = parseFloat(subjectPropertyValue.toString().replace(/\s/g, ''));
        subPropertyAnnualTax = parseFloat(subPropertyAnnualTax.toString().replace(/\s/g, ''));
        subjectPropertyMonthlyCondoFee = parseFloat(subjectPropertyMonthlyCondoFee.toString().replace(/\s/g, ''));
        subjectPropertyMonthlyEnergyCost = parseFloat(subjectPropertyMonthlyEnergyCost.toString().replace(/\s/g, ''));
        subjectPropertyMonthlyRentalIncome = parseFloat(subjectPropertyMonthlyRentalIncome.toString().replace(/\s/g, ''));
        subjectPropertyHyrdoCost = parseFloat(subjectPropertyHyrdoCost.toString().replace(/\s/g, ''));
        subjectPropertyManagementExpense = parseFloat(subjectPropertyManagementExpense.toString().replace(/\s/g, ''));
        subjectPropertyRepairCosts = parseFloat(subjectPropertyRepairCosts.toString().replace(/\s/g, ''));
        subjectPropertyGeneralExpense = parseFloat(subjectPropertyGeneralExpense.toString().replace(/\s/g, ''));
        subjectPropertyHomeInsurance = parseFloat(subjectPropertyHomeInsurance.toString().replace(/\s/g, ''));
        subjectPropertyInterestCharges = parseFloat(subjectPropertyInterestCharges.toString().replace(/\s/g, ''));
      }

    let check = 0;
    if(this.application.subjectproperty[this.activeScenario].downPayment_type == 'dollar'){
      check = (Number(subjectPropertyDownPayment)/Number(subjectPropertyValue)) * 100;
    } else {
      const calcDP = Number(subjectPropertyValue) * (Number(subjectPropertyDownPayment)/100);
      check = (Number(calcDP)/Number(subjectPropertyValue)) * 100;
    }
    let insurance_premium = 0;
    if(check < 20 && subjectPropertyValue < 1000000){
      insurance_premium = this.calculatePremiumOnTotalLoan(ltv);
      this.insurance_premium_enabled = false;
    } else if(check > 20 && subjectPropertyValue < 1000000) {
      if(!this.insurance_premium_enabled){
        // insurance_premium = this.calculatePremiumOnTotalLoan(ltv);
      } else {
        // insurance_premium = this.insurance_premium;
        insurance_premium = 0;
      }
      this.insurance_premium_enabled = true;
    }
    else {
      insurance_premium = 0;
      this.insurance_premium_enabled = false;
    }
    // const insurance_premium = 0;
    this.insurance_premium = insurance_premium;

    console.log('Mortgage Rate Testing '+(Number(this.application.mortgage[this.activeMortgage].mortgageRate) + 2));
    const rE1 = (1 + (((Number(this.application.mortgage[this.activeMortgage].mortgageRate))/100)/2));
    const rE1_q = (1 + (((Number(this.application.mortgage[this.activeMortgage].mortgageRate) + 2)/100)/2));
    const rE = Math.pow(rE1, 2) - 1;
    const rE_q = Math.pow(rE1_q, 2) - 1;

    const rm1 = Math.pow((1 + rE), (1/12));
    const rm1_q = Math.pow((1 + rE_q), (1/12));
    const rm = rm1 - 1;
    const rm_q = rm1_q - 1;
    const numerator = rm * (principal + insurance_premium);
    const numerator_q = rm_q * (principal + insurance_premium);
    const denom1 = Math.pow((1 + rm), (-1 * numberOfPayments));
    const denom1_q = Math.pow((1 + rm_q), (-1 * numberOfPayments));
    const denominator = 1 - denom1;
    const denominator_q = 1 - denom1_q;
    const pm = numerator / denominator;
    const pm_q = numerator_q / denominator_q;
    // this.monthlyMortgageAmount = (principal+insurance_premium) * (numerator / denominator);
    this.monthlyMortgageAmount = pm;
    this.monthlyMortgageAmount_q = pm_q;
    const subjectpropertyParameters = (Number(subPropertyAnnualTax)/12) + Number(subjectPropertyMonthlyCondoFee) + Number(subjectPropertyMonthlyEnergyCost);
    console.log('parameters '+ subjectpropertyParameters);
    this.monthlyMortgageAmount = this.monthlyMortgageAmount;
    this.outputData = { ...this.outputData, monthlyMortgageAmount: this.monthlyMortgageAmount };
    this.subjectpropertyParameters = Number(subjectpropertyParameters);
    let rentalincome = 0;
    this.reduced = 0;
    if(this.application.subjectproperty[this.activeScenario].occupancy == 'rental' || this.application.subjectproperty[this.activeScenario].occupancy == 'ownerRental'){
      if(this.application.subjectproperty[this.activeScenario].revenueOptions == 'addPercentage'){
        rentalincome = Number(subjectPropertyMonthlyRentalIncome) * (Number(this.application.subjectproperty[this.activeScenario].rentalPercentage) / 100);
        // this.monthlyMortgageAmount = this.monthlyMortgageAmount - rentalincome;
      } else if(this.application.subjectproperty[this.activeScenario].revenueOptions == 'reduceAdd'){
        rentalincome = 0;
        this.reduced = Number(subjectPropertyMonthlyRentalIncome) * (Number(this.application.subjectproperty[this.activeScenario].rentalPercentage) / 100);
        // rentalincome = subjectPropertyMonthlyRentalIncome - subjectPropertyHyrdoCost - subjectPropertyManagementExpense - subjectPropertyRepairCosts - subjectPropertyGeneralExpense - subjectPropertyHomeInsurance - subjectPropertyInterestCharges
        // this.monthlyMortgageAmount = this.monthlyMortgageAmount - rentalincome;
      } else {
        // this.monthlyMortgageAmount = this.monthlyMortgageAmount - Number(this.application.subjectproperty.monthlyRentalIncome);
      }
    }
    this.additionalRevenue = this.additionalRevenue + rentalincome;
  }

  delayedCalculateTotalPrincipalAmount() {
    setTimeout(() => {
      this.calculateTotalPrincipalAmount();
    });
  }

  calculateTotalPrincipalAmount(): void {
    const monthlyInterestRate = this.getMonthlyInterestRate();
    const numberOfPayments = this.getTotalNumberOfPayments();
    const monthlyPaymentTDS = this.calculateMonthlyPaymentBasedOnTDs(); // Assuming you have a function to get the monthly mortgage payment
  
    const numeratorTDS = monthlyPaymentTDS * (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);
    const denominatorTDS = monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments);
    
    const totalPrincipalAmountTDS = numeratorTDS / denominatorTDS;

    const monthlyPaymentGDS = this.calculateMonthlyPaymentBasedOnGDs(); // Assuming you have a function to get the monthly mortgage payment
  
    const numeratorGDS = monthlyPaymentGDS * (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);
    const denominatorGDS = monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments);
    
    const totalPrincipalAmountGDS = numeratorGDS / denominatorGDS;

    let subjectPropertyDownPayment = this.application.subjectproperty[this.activeScenario].downPayment;
    let subjectPropertyPropertyValue = this.application.subjectproperty[this.activeScenario].propertyValue;
    let subjectPropertyEto = this.application.subjectproperty[this.activeScenario].eto;
    if(subjectPropertyDownPayment !== null &&  subjectPropertyPropertyValue !== null){
      subjectPropertyDownPayment = parseFloat(subjectPropertyDownPayment.toString().replace(/\s/g, ''));
      subjectPropertyPropertyValue = parseFloat(subjectPropertyPropertyValue.toString().replace(/\s/g, ''));
    }
    if(subjectPropertyEto !== null){
      subjectPropertyEto = parseFloat(subjectPropertyEto.toString().replace(/\s/g, ''));

    }

    if(denominatorTDS > denominatorGDS){
      console.log("THE MORTG 1");
      if(this.application.type == 'purchase'){
        let down_calc_amount = 0;
        if(this.application.subjectproperty[this.activeScenario].downPayment_type == 'dollar'){
          down_calc_amount = subjectPropertyDownPayment;
        } else {
          down_calc_amount = (subjectPropertyDownPayment/100) * subjectPropertyPropertyValue
        }
        this.mortgageCapacity = (subjectPropertyPropertyValue - down_calc_amount) + this.insurance_premium;
      } else if(this.application.type == 'renewal') {
        this.mortgageCapacity = subjectPropertyPropertyValue + this.insurance_premium;
      } else if(this.application.type == 'refinance') {
        this.mortgageCapacity = subjectPropertyPropertyValue + subjectPropertyEto + this.insurance_premium;
      }
       else {
        this.mortgageCapacity  = totalPrincipalAmountGDS
      }
    } else {
      if(this.application.type == 'purchase'){
        let down_calc_amount = 0;
        if(this.application.subjectproperty[this.activeScenario].downPayment_type == 'dollar'){
          down_calc_amount = subjectPropertyDownPayment;
        } else {
          down_calc_amount = (subjectPropertyDownPayment/100) * subjectPropertyPropertyValue
        }
        this.mortgageCapacity = (subjectPropertyPropertyValue - down_calc_amount) + this.insurance_premium;
      } else if(this.application.type == 'renewal') {
        this.mortgageCapacity = subjectPropertyPropertyValue + this.insurance_premium;
      } else if(this.application.type == 'refinance') {
        this.mortgageCapacity = subjectPropertyPropertyValue + subjectPropertyEto + this.insurance_premium;
      }
      else {
        console.log("THE MORTG 22");
        this.mortgageCapacity  = totalPrincipalAmountTDS
      }
    }
    console.log("THE MORTGAGE CAP =", this.mortgageCapacity);
    console.log("THE MORTGAGE CAP 1=", this.application.subjectproperty[this.activeScenario].propertyValue);
    console.log("THE MORTGAGE CAP 2=", this.application.subjectproperty[this.activeScenario].downPayment);
    this.outputData = { ...this.outputData, mortgageCapacity: this.mortgageCapacity };
  }

  calculateMonthlyPaymentBasedOnTDs(): number {
    const propertyCost = this.monthlyPropertyCost;
    const totalLiabilities = this.totalLiabilities;
    const tds = 44;
    const totalIncomes = this.totalIncome + this.totalRevenues + this.additionalRevenue + this.additionalNonSubjectRevenue;
    const maxMontly = ((tds * totalIncomes)/100) - propertyCost - totalLiabilities;
    return maxMontly;
  }

  calculateMonthlyPaymentBasedOnGDs(): number {
    const propertyCost = this.monthlyPropertyCost;
    const totalLiabilities = this.totalLiabilities;
    const gds = 39;
    const totalIncomes = this.totalIncome + this.totalRevenues + this.additionalRevenue + this.additionalNonSubjectRevenue;
    const maxMontly = ((gds * totalIncomes)/100) - propertyCost - totalLiabilities;
    return maxMontly;
  }

  getMonthlyInterestRate(): number {
    const annualInterestRate = this.application.mortgage[this.activeMortgage].mortgageRate / 100; // Convert the percentage to a decimal
    const monthlyInterestRate = annualInterestRate / 12;
    console.log("interest rate " + monthlyInterestRate)
    return monthlyInterestRate;
  }
  
  getTotalNumberOfPayments(): number {
    const { amortizationYears, amortizationMonths } = this.application.mortgage[this.activeMortgage];
    const totalMonths = (Number(amortizationYears) * 12) + Number(amortizationMonths);
    return totalMonths;
  }
  

  //IMPORTANT FOR REFI AND RENEWAL
  getPrincipalAmount(): number {
    const { propertyValue, downPayment, eto } = this.application.subjectproperty[this.activeScenario];
    let setPropertyValue = propertyValue;
    let setDownPayment =  downPayment;
    let setEto = eto;
    if(setPropertyValue !== null && setDownPayment !== null){
      setPropertyValue = parseFloat(setPropertyValue.toString().replace(/\s/g, ''));
      setDownPayment =  parseFloat(setDownPayment.toString().replace(/\s/g, ''));
    }
    if(eto !== null){
      setEto = parseFloat(setEto.toString().replace(/\s/g, ''));
    }

    let principal = 0;
    console.log('this.application.subjectproperty;.downPayment_type ' + this.application.subjectproperty[this.activeScenario].downPayment_type);
    console.log('this.application.subjectproperty;.propertyValue' + setPropertyValue);
    console.log('this.application.subjectproperty;.downPayment' + setDownPayment);
    if(this.application.type == 'renewal'){
      principal = setPropertyValue;

    } else if(this.application.type == 'refinance') {
      principal = setPropertyValue + setEto;

    } else {
      if(this.application.subjectproperty[this.activeScenario].downPayment_type == 'dollar'){
        principal = setPropertyValue - setDownPayment;
      } else {
        const calc_dp = (setDownPayment/100) * setPropertyValue
        principal = setPropertyValue - calc_dp;
      }
    }
    
    console.log('this is the principal inside '+ principal);
    return principal;
  }

  addIncome() {
    if(this.user){
      this.loadSpinner = true;
    }
    this.application.applicants[this.activeApplicant].incomes.push({ id: null , type: 'salary', value: null, frequency: 'yearly', mode_type: 'advanced'});
    this.unFormattedVuluesUpdate(this.application);
  }

  addRevenue() {
    if(this.user){
      this.loadSpinner = true;
    }
    this.application.applicants[this.activeApplicant].revenues.push({ id: null , type: 'salary' , value: null, frequency: 'monthly', mode_type: 'advanced'});
    this.unFormattedVuluesUpdate(this.application);
  }

  addLiability() {
    if(this.user){
      this.loadSpinner = true;
    }
    this.application.applicants[this.activeApplicant].liabilities.push({ id: null , active: true , type: 'other' , balance: null, limit: null, payment: null , frequency: 'monthly', minimum: 5, mode_type: 'advanced'});
    this.unFormattedVuluesUpdate(this.application);
  }

  delayedSumOfIncomes() {
    setTimeout(() => {
      this.sumOfIncomes();
    });
  }
//////////////////////////////////////////DONE
  sumOfIncomes() {
    this.totalIncome = 0;
    for(let i = 0; i < this.application.applicants.length; i++){
      let sumOfIncomes = 0;
      for (let j = 0; j < this.application.applicants[i].incomes.length; j++) {
        if((this.application.applicants[i].incomes[j].mode_type === 'simple' && this.application.applicants[i].mode === 'simple') || this.application.applicants[i].mode === 'advanced'){
        const income = this.application.applicants[i].incomes[j];
        if (income.frequency === 'monthly') {
          if(income.value !== null){
            const setSumOfIncome = parseFloat(income.value.toString().replace(/\s/g, ''));
            sumOfIncomes +=  Number(setSumOfIncome);
          }
        } else {
          if(income.value !== null){
            const setSumOfIncome = parseFloat(income.value.toString().replace(/\s/g, ''));
          sumOfIncomes +=  Number(setSumOfIncome)/12;
          }
        }
       }
      }
      this.totalIncome = this.totalIncome + sumOfIncomes;
    }
  }

  delayedSumOfLiabilities() {
    setTimeout(() => {
      this.sumOfLiabilities();
    });
  }
  
  sumOfLiabilities() {
    this.totalLiabilities = 0;
    console.log('Mode '+this.current.mode)
    for(let i = 0; i < this.application.applicants.length; i++){
      let totalPayments = 0;
      if(this.current.mode == 'advanced'){
        for (let j = 0; j < this.application.applicants[this.activeApplicant].liabilities.length; j++) {
          if(this.application.applicants[this.activeApplicant].liabilities[j].mode_type === this.application.applicants[this.activeApplicant].mode){
            const liability = this.application.applicants[this.activeApplicant].liabilities[j];
            if (liability.payment != null && liability.active) {
              let setLiabilityPayment = null;
              if(liability.payment !== null) { setLiabilityPayment = parseFloat(liability.payment.toString().replace(/\s/g, '')) }
              const payment = setLiabilityPayment; 
              if (!isNaN(payment)) { 
                if (liability.frequency === 'yearly') {
                  totalPayments += payment / 12;
                } else if(liability.frequency === 'weekly') {
                  totalPayments += (payment * 52) / 12;
                } else if(liability.frequency === 'biweekly'){
                  totalPayments += (payment * 26) / 12;
                } else {
                  totalPayments += payment;
                }
              }
            }
          }
        }
      } else {
        for (let j = 0; j < this.application.applicants[this.activeApplicant].liabilities.length; j++) {
          if(this.application.applicants[this.activeApplicant].liabilities[j].mode_type == this.application.applicants[this.activeApplicant].mode){
            let setLiabilityPayment = null;
            let setLiabilityBalance = null;
            let getLiabilityPayment = this.application.applicants[this.activeApplicant].liabilities[j].payment;
            let getLiabilityBalance =this.application.applicants[this.activeApplicant].liabilities[j].balance;
            if(getLiabilityPayment !== null) { setLiabilityPayment = parseFloat(getLiabilityPayment.toString().replace(/\s/g, '')) }
            if(getLiabilityBalance !== null) { setLiabilityBalance = parseFloat(getLiabilityBalance.toString().replace(/\s/g, '')) }
            totalPayments = Number(setLiabilityPayment) + (Number(setLiabilityBalance) * 0.03);
          }
        }
      }
      
      this.totalLiabilities = Number(totalPayments);
      this.applicantsSumOfLiabilities();
    }
  }

  applicantsSumOfLiabilities(){
    let totalPayments = 0;
    for( let applicant of this.application.applicants){
      if(applicant.mode == 'advanced'){
        for( let liability of applicant.liabilities){
          if(liability.mode_type === 'advanced'){
            if (liability.payment != null && liability.active) {
              let setLiabilityPayment = null;
              if(liability.payment !== null) { setLiabilityPayment = parseFloat(liability.payment.toString().replace(/\s/g, '')) }
              const payment = setLiabilityPayment; 
              if (!isNaN(payment)) { 
                if (liability.frequency === 'yearly') {
                  totalPayments += payment / 12;
                } else if(liability.frequency === 'weekly') {
                  totalPayments += (payment * 52) / 12;
                } else if(liability.frequency === 'biweekly'){
                  totalPayments += (payment * 26) / 12;
                } else {
                  totalPayments += payment;
                }
              }
            }
          }
        }
      } else{
        for( let liability of applicant.liabilities){
          if(liability.mode_type === 'simple'){
            let setLiabilityPayment = null;
            let setLiabilityBalance = null;
            let getLiabilityPayment = liability.payment;
            let getLiabilityBalance = liability.balance;
            if(getLiabilityPayment !== null) { setLiabilityPayment = parseFloat(getLiabilityPayment.toString().replace(/\s/g, '')) }
            if(getLiabilityBalance !== null) { setLiabilityBalance = parseFloat(getLiabilityBalance.toString().replace(/\s/g, '')) }
            totalPayments += Number(setLiabilityPayment) + (Number(setLiabilityBalance) * 0.03);
          }
        }
      }
      this.setTotalLiabilities = Number(totalPayments);
    }
  }

  delayedCalculateMonthlyPropertyCost() {
    setTimeout(() => {
      this.calculateMonthlyPropertyCost();
    });
  }

  calculateMonthlyPropertyCost() {
    this.monthlyPropertyCost = 0;
    this.additionalNonSubjectRevenue = 0;
    let setPropertyAnnualpropertytax = null;
    let setPropertyMonthlyenergycost = null;
    let setPropertyMonthlycondofee = null;
    let setPropertyRentalincome = null;
    let setPropertyHyrdoCost = null;
    let setPropertyManagementExpense = null;
    let setPropertyRepairCosts = null;
    let setPropertyGeneralExpense = null;
    let setPropertyHomeInsurance = null;
    let setPropertyInterestCharges = null;
    this.reduced_nsp = 0;
    for(let i = 0; i < this.application.applicants.length; i++){
      for (let j = 0; j < this.application.applicants[i].properties.length; j++) {
        if(this.application.applicants[i].properties[j].mode_type == this.application.applicants[i].mode){
          let monthlyPropertyCost = 0;
          const property = this.application.applicants[i].properties[j];
          let monthlyMortgage = 0;
          // Calculate monthly property cost
          if (property.frequency === 'yearly') {
            if(property.payment !== null){
              const setPropertypayment = parseFloat(property.payment.toString().replace(/\s/g, ''));
              monthlyMortgage = Number(setPropertypayment) / 12;
            }
          } else if (property.frequency === 'biweekly') {
            if(property.payment !== null){
              const setPropertypayment = parseFloat(property.payment.toString().replace(/\s/g, ''));
              monthlyMortgage = (Number(setPropertypayment) * 26) / 12;
            }
          } else if (property.frequency === 'weekly') {
            if(property.payment !== null){
              const setPropertypayment = parseFloat(property.payment.toString().replace(/\s/g, ''));
              monthlyMortgage = (Number(setPropertypayment) * 52) / 12;
            }
          }
           else if (property.frequency === 'monthly') {
            if(property.payment !== null){
              const setPropertypayment = parseFloat(property.payment.toString().replace(/\s/g, ''));
              monthlyMortgage = Number(setPropertypayment);
            }
          }
          if(property.annualpropertytax !== null) { setPropertyAnnualpropertytax = parseFloat(property.annualpropertytax.toString().replace(/\s/g, '')) }
          if(property.monthlyenergycost !== null) { setPropertyMonthlyenergycost = parseFloat(property.monthlyenergycost.toString().replace(/\s/g, '')) }
          if(property.monthlycondofee !== null) { setPropertyMonthlycondofee = parseFloat(property.monthlycondofee.toString().replace(/\s/g, '')) }
          if(property.rentalincome !== null) { setPropertyRentalincome = parseFloat(property.rentalincome.toString().replace(/\s/g, '')) }
          if(property.hyrdoCost !== null) { setPropertyHyrdoCost = parseFloat(property.hyrdoCost.toString().replace(/\s/g, '')) }
          if(property.managementExpense !== null) { setPropertyManagementExpense = parseFloat(property.managementExpense.toString().replace(/\s/g, '')) }
          if(property.repairCosts !== null) { setPropertyRepairCosts = parseFloat(property.repairCosts.toString().replace(/\s/g, '')) }
          if(property.generalExpense !== null) { setPropertyGeneralExpense = parseFloat(property.generalExpense.toString().replace(/\s/g, '')) }
          if(property.homeInsurance !== null) { setPropertyHomeInsurance = parseFloat(property.homeInsurance.toString().replace(/\s/g, '')) }
          if(property.interestCharges !== null) { setPropertyInterestCharges = parseFloat(property.interestCharges.toString().replace(/\s/g, '')) }

          monthlyPropertyCost = (Number(setPropertyAnnualpropertytax)/12) + Number(setPropertyMonthlyenergycost) + (Number(setPropertyMonthlycondofee)/2);
          // if (monthlyMortgage > 0 && monthlyMortgage !== 0){
          //   monthlyPropertyCost = (Number(setPropertyAnnualpropertytax)/12) + Number(setPropertyMonthlyenergycost) + Number(setPropertyMonthlycondofee) + monthlyMortgage;
          // } else{
          //   monthlyPropertyCost = monthlyMortgage;
          // }
          console.log("Monthly property cost: "+monthlyPropertyCost);
          // Deduct rental income based on occupancy
          let rentalincome = 0;
          if (property.occupancy === 'rental' || property.occupancy === 'ownerRental') {
            if(property.revenueOptions == 'addPercentage'){
              rentalincome = Number(setPropertyRentalincome) * (Number(property.rentalPercentage) / 100);
              // monthlyPropertyCost = monthlyPropertyCost - rentalincome;
            } else if(property.revenueOptions == 'reduceAdd'){
              this.reduced_nsp = this.reduced_nsp + (setPropertyRentalincome * (Number(property.rentalPercentage) / 100));
              // rentalincome = setPropertyRentalincome - setPropertyHyrdoCost - setPropertyManagementExpense - setPropertyRepairCosts - setPropertyGeneralExpense - setPropertyHomeInsurance - setPropertyInterestCharges
              // monthlyPropertyCost = monthlyPropertyCost - rentalincome;
            } else {
              // monthlyPropertyCost = monthlyPropertyCost - Number(property.rentalincome);
              rentalincome = Number(setPropertyRentalincome);
            }
          }
          this.monthlyPropertyCost += Number(monthlyPropertyCost);
          this.additionalNonSubjectRevenue = this.additionalNonSubjectRevenue + rentalincome;
        }
      }
    }
    console.log('reduced '+this.reduced_nsp);
  }

  delayedSumOfRevenues() {
    setTimeout(() => {
      this.sumOfRevenues();
    });
  }

  sumOfRevenues() {
    for(let i = 0; i < this.application.applicants.length; i++){
      let sumOfRevenues = 0;
      for (let j = 0; j < this.application.applicants[this.activeApplicant].revenues.length; j++) {
        if(this.application.applicants[this.activeApplicant].revenues[j].mode_type == this.application.applicants[i].mode){
          const revenue = this.application.applicants[this.activeApplicant].revenues[j];
          if(revenue.frequency == 'monthly'){
            if(revenue.value !== null){
              const setSumOfRevenues = parseFloat(revenue.value.toString().replace(/\s/g, ''));
              sumOfRevenues += Number(setSumOfRevenues);
            }
          } else {
            if(revenue.value !== null){
              const setSumOfRevenues = parseFloat(revenue.value.toString().replace(/\s/g, ''));
              sumOfRevenues += Number(setSumOfRevenues)/12;
            }
          }
        }
      }
      this.totalRevenues = sumOfRevenues;
    }
  }
  

  removeIncome(index: number, id: number) {
    if(this.user){
      this.loadSpinner = true;
    }
    if(this.application.applicants[this.activeApplicant].incomes[index]){
      this.http.get<any>(environment.apiBaseUrl+'remove-incomes/'+this.application.applicants[this.activeApplicant].incomes[index].id).subscribe(
        response => {
          // this.application = response;
          // this.onFocusOut();
          this.checkSimpleIncome(index);
          this.loadSpinner = false;
          this.reRunCalculations();
        },
        error => {
          // this.showError('Unauthorized', 'Invalid email or password');
        }
      );
    }
  }

  checkSimpleIncome(index: number){
    this.application.applicants[this.activeApplicant].incomes.splice(index, 1);
    this.incomes = this.application.applicants[this.activeApplicant].incomes;
    if(this.incomes[0].mode_type !== 'simple'){
      this.incomes[0].mode_type = 'simple';
    }
  }

  removeAllIncomes(): Promise<void> {
    return new Promise<void>((resolve) => {
      // this.temp_incomes = JSON.parse(JSON.stringify(this.incomes));
      this.application.applicants[this.activeApplicant].incomes = [];
      this.incomes = this.application.applicants[this.activeApplicant].incomes;
      this.application.applicants[this.activeApplicant].incomes.push({ id: null , type: 'salary', value: null, frequency: 'yearly', mode_type: 'advanced'});
      this.incomes = this.application.applicants[this.activeApplicant].incomes;
      if(this.application.applicants[this.activeApplicant].id){
        this.http.get<any>(environment.apiBaseUrl+'remove-all-incomes/'+this.application.applicants[this.activeApplicant].id).subscribe(
          response => {
            // this.application = response;
            // this.onFocusOut();
            // this.incomes.push({ id: null , type: 'salary', value: null, frequency: 'yearly'});]
            console.log(this.temp_incomes);
          },
          error => {
            // this.showError('Unauthorized', 'Invalid email or password');
          }
        );
      }
      resolve();
    });
  }
  
  removeAllLiabilities(): Promise<void> {
    return new Promise<void>((resolve) => {
      // this.temp_liabilities = JSON.parse(JSON.stringify(this.liabilities));
      this.application.applicants[this.activeApplicant].liabilities = [];
      this.liabilities = this.application.applicants[this.activeApplicant].liabilities;
      this.application.applicants[this.activeApplicant].liabilities.push({ id: null , active: true , type: 'other' , balance: null, limit: null, payment: null , frequency: 'monthly', minimum: 5, mode_type: 'advanced'});
      this.liabilities = this.application.applicants[this.activeApplicant].liabilities;
      if(this.application.applicants[this.activeApplicant].id){
        this.http.get<any>(environment.apiBaseUrl+'remove-all-liabilities/'+this.application.applicants[this.activeApplicant].id).subscribe(
          response => {
            // this.application = response;
            // this.onFocusOut();
            // this.liabilities.push({ id: null , type: 'salary', value: null, frequency: 'yearly'});]
            console.log(this.temp_liabilities);
          },
          error => {
            // this.showError('Unauthorized', 'Invalid email or password');
          }
        );
      }
      resolve();
    });
  }

  recheckType() {
    if(this.application.subjectproperty[this.activeScenario].type != 'condominium'){
      this.application.subjectproperty[this.activeScenario].monthlyCondoFee = 0;
    }
  }

  removeProperties(index: number) {
    if(this.user){
      this.loadSpinner = true;
    }
    if(this.application.applicants[this.activeApplicant].properties[index]){
      this.http.get<any>(environment.apiBaseUrl+'remove-property/'+this.application.applicants[this.activeApplicant].properties[index].id).subscribe(
        response => {
          // this.application = response;
          // this.onFocusOut();
          this.application.applicants[this.activeApplicant].properties.splice(index, 1);
          this.properties = this.application.applicants[this.activeApplicant].properties;
          this.loadSpinner = false;
          this.reRunCalculations();
        },
        error => {
          // this.showError('Unauthorized', 'Invalid email or password');
        }
      );
    }
  }

  removeLiabilities(index: number) {
    if(this.user){
      this.loadSpinner = true;
    }
    const removeIdReplace = this.application.applicants[this.activeApplicant].liabilities[index].id;
    if(this.application.applicants[this.activeApplicant].liabilities[index]){
      this.http.get<any>(environment.apiBaseUrl+'remove-liability/'+this.application.applicants[this.activeApplicant].liabilities[index].id).subscribe(
        response => {
          // this.application = response;
          // this.onFocusOut();
          this.checkSimpleLiability(index, removeIdReplace);
          this.loadSpinner = false;
          this.reRunCalculations();
        },
        error => {
          // this.showError('Unauthorized', 'Invalid email or password');
        }
      );
    }
  }

  checkSimpleLiability(index: number, id: number){
    this.application.applicants[this.activeApplicant].liabilities.splice(index, 1);
    this.liabilities = this.application.applicants[this.activeApplicant].liabilities;
  }

  removeRevenues(index: number) {
    if(this.user){
      this.loadSpinner = true;
    }
    if(this.application.applicants[this.activeApplicant].revenues[index]){
      this.http.get<any>(environment.apiBaseUrl+'remove-revenue/'+this.application.applicants[this.activeApplicant].revenues[index].id).subscribe(
        response => {
          // this.application = response;
          // this.onFocusOut();
          this.application.applicants[this.activeApplicant].revenues.splice(index, 1);
          this.revenues = this.application.applicants[this.activeApplicant].revenues;
          this.loadSpinner = false;
          this.reRunCalculations();
        },
        error => {
          // this.showError('Unauthorized', 'Invalid email or password');
        }
      );
    }
  }

  removeApplicant() {
    if(this.user){
      this.loadSpinner = true;
    }
    if(this.application.applicants[this.activeApplicant]){
      this.http.get<any>(environment.apiBaseUrl+'remove-applicant/'+this.application.applicants[this.activeApplicant].id).subscribe(
        response => {
          // this.application = response;
          // this.onFocusOut();
          this.loadSpinner = false;
        },
        error => {
          // this.showError('Unauthorized', 'Invalid email or password');
        }
      );
    }
    this.application.applicants.splice(this.activeApplicant, 1);
    this.selectLastApplicant(this.application.applicants.length-1);
    this.updateRemoveButton();
    this.removeApplicantModal('close');
    this.reRunCalculations();
    this.onFocusOut();
  }
  
  removeScenario() {
    if(this.user){
      this.loadSpinner = true;
    }
    if(this.application){
      this.http.get<any>(environment.apiBaseUrl+'remove-scenario/'+this.application.id).subscribe(
        response => {
          // this.application = response;
          // this.onFocusOut();
          this.loadSpinner = false;
        },
        error => {
          // this.showError('Unauthorized', 'Invalid email or password');
        }
      );
    }
    this.selectScenario(0);
    this.removeScenarioModal('close');
    this.reRunCalculations();
  }

  addProperty() {
    if(this.user){
      this.loadSpinner = true;
    }
    this.application.applicants[this.activeApplicant].properties.push({ id: null , type: 'mortgage', frequency: 'monthly', occupancy: 'owner', payment: null, annualpropertytax: null, monthlyenergycost: 0, monthlycondofee: null, rentalincome: null, revenueOptions: 'none', rentalPercentage: 50, hyrdoCost: null, managementExpense: null, repairCosts: null, generalExpense: null, homeInsurance: null, interestCharges: null, mode_type: 'advanced', address: '' });
    this.unFormattedVuluesUpdate(this.application);
  }

  calculateMonthly(index: number) {
    let setLiabilitiesBalance = null;

    if(this.application.applicants[this.activeApplicant].liabilities[index].type == 'creditcard' || this.application.applicants[this.activeApplicant].liabilities[index].type == 'unsecuredcredit'){
      if(this.application.applicants[this.activeApplicant].liabilities[index].frequency == 'monthly'){
        var frequency = 1;
      } else {
        var frequency = 12;
      }
      if(this.application.applicants[this.activeApplicant].liabilities[index].balance !== null) { setLiabilitiesBalance = parseFloat(this.application.applicants[this.activeApplicant].liabilities[index].balance.toString().replace(/\s/g, '')) }
      
      this.application.applicants[this.activeApplicant].liabilities[index].payment = setLiabilitiesBalance * (this.application.applicants[this.activeApplicant].liabilities[index].minimum/100) * frequency;
      this.application.applicants[this.activeApplicant].liabilities[index].payment = Math.floor(this.application.applicants[this.activeApplicant].liabilities[index].payment);

      this.liabilitiesFormatting(this.application, index);
    }
  }

  liabilitiesFormatting(getApplication: any, index: number){
    const subjectProperty = getApplication.applicants[this.activeApplicant].liabilities[index];
    if(subjectProperty !== null) { 
      subjectProperty.payment = subjectProperty.payment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
  }

  selectApplicant(index: number) {
    if(this.application.applicants[this.activeApplicant]){
      this.application.applicants[this.activeApplicant].currentlyActive = false;
    }
    this.activeApplicant = index;
    this.application.applicants[this.activeApplicant].currentlyActive = true;
    this.incomes = this.application.applicants[this.activeApplicant].incomes;
    this.liabilities = this.application.applicants[this.activeApplicant].liabilities;
    this.revenues = this.application.applicants[this.activeApplicant].revenues;
    this.current = this.application.applicants[this.activeApplicant];
    this.editApplicantData.firstName = this.current.first_name;
    this.editApplicantData.lastName = this.current.last_name ;
    this.openTab(this.current.mode, true);
    this.triggerApplicantChange(index);
  }

  applicantModeData(getApplication: any){
    let setIncomes = 0;
    let getIncomeIndex = 0;
    let simpleModeIncome = 0;
    let separateIncomeValue = 0;
    let setBalance = 0;
    let setPayment = 0;
    let getLiabilityIndex = 0;
    let simpleModeBalance = 0;
    let simpleModePayment = 0;
    let separateBalanceValue = 0;
    let separatePaymentValue = 0;
    if(this.application && this.application.applicants[this.activeApplicant]){
      if(this.current.mode === 'simple'){
        if(getApplication.applicants[this.activeApplicant].incomes.length >= 1){
          getApplication = this.thousandSeparatorService.unFormattedVuluesUpdate(getApplication);
          for(let income of getApplication.applicants[this.activeApplicant].incomes){
            if(income.value !== null){
              setIncomes += income.value;
            }
          }
          for(let [index, income] of this.incomes.entries()){
            if(income.mode_type === 'simple'){
              getIncomeIndex = index;
            }
          }
          this.application.applicants[this.activeApplicant].incomes[getIncomeIndex].value = setIncomes;
        }


        if(getApplication.applicants[this.activeApplicant].liabilities.length >= 1){
          getApplication = this.thousandSeparatorService.unFormattedVuluesUpdate(getApplication);
          for(let liability of getApplication.applicants[this.activeApplicant].liabilities){
            if(liability.balance !== null && liability.mode_type === 'advanced' && (liability.type === 'creditcard' || liability.type === 'unsecuredcredit')){
              setBalance += liability.balance;
            } else if(liability.payment !== null && liability.mode_type === 'advanced') {
              setPayment += liability.payment;
            }
          }
          for(let [index, liability] of this.liabilities.entries()){
            if(liability.mode_type === 'simple'){
              getLiabilityIndex = index;
            }
          }
          this.application.applicants[this.activeApplicant].liabilities[getLiabilityIndex].balance = setBalance;
          this.application.applicants[this.activeApplicant].liabilities[getLiabilityIndex].payment = setPayment;
        }

        getApplication = this.unFormattedVuluesUpdate(getApplication);
      } else if(this.current.mode === 'advanced'){
        if(getApplication.applicants[this.activeApplicant].incomes.length >= 1){
          getApplication = this.thousandSeparatorService.unFormattedVuluesUpdate(getApplication);
          for(let income of getApplication.applicants[this.activeApplicant].incomes){
            if(income.value !== null && income.mode_type === 'advanced'){
              setIncomes += income.value;
            }
          }
          for(let [index, income] of this.incomes.entries()){
            if(income.mode_type === 'simple'){
              getIncomeIndex = index;
              simpleModeIncome = income.value; 
            }
          }
          if(simpleModeIncome > setIncomes){
            separateIncomeValue = simpleModeIncome - setIncomes;
          } else if(simpleModeIncome < setIncomes){
            separateIncomeValue = simpleModeIncome;
          }
          this.application.applicants[this.activeApplicant].incomes[getIncomeIndex].value = separateIncomeValue;
        }
        
        if(getApplication.applicants[this.activeApplicant].liabilities.length >= 1){
          getApplication = this.thousandSeparatorService.unFormattedVuluesUpdate(getApplication);
          for(let [index, liability] of getApplication.applicants[this.activeApplicant].liabilities.entries()){
            if(liability.balance !== null && liability.mode_type === 'simple'){
              simpleModeBalance = liability.balance;
              simpleModePayment = liability.payment;
            }
            if((liability.balance !== null && liability.mode_type === 'advanced' && (liability.type === 'creditcard' || liability.type === 'unsecuredcredit'))){
              if(index !== 1){
                 setBalance += liability.balance;
              }
            } else if(liability.payment !== null && liability.mode_type === 'advanced') {
              if(index !== 2){
                setPayment += liability.payment;
              }
            }
          }
          for(let [index, liability] of getApplication.applicants[this.activeApplicant].liabilities.entries()){
            if((liability.balance !== null && liability.mode_type === 'advanced' && (liability.type === 'creditcard' || liability.type === 'unsecuredcredit'))){
              if(index === 1){
                if(simpleModeBalance < setBalance){
                  liability.balance = 0;
                } else {
                  liability.balance = simpleModeBalance - setBalance;
                }
              }
              this.calculateMonthly(index); 
            } else if(liability.payment !== null && liability.mode_type === 'advanced') {
              if(index === 2){
                if(simpleModePayment < setPayment){
                  liability.payment = 0;
                } else {
                  liability.payment = simpleModePayment - setPayment;
                }
              }
            }
          }
          this.reRunCalculations();
        }

        getApplication = this.unFormattedVuluesUpdate(getApplication);
      }
    }
  }

  selectScenario(index: number) {
    if(this.activeScenario !== index && this.activeMortgage !== index){
      if (index >= 0 && index < this.application.subjectproperty.length) {
        this.activeScenario = index;
        this.activeMortgage = index;
  
        for (let i = 0; i < this.application.subjectproperty.length; i++) {
          this.application.subjectproperty[i].active = i === index;
          this.application.mortgage[i].active = i === index;
        }
      }
      this.activeScenarioName = this.activeScenario + 1;
      this.addNewApplicant = true;
      this.unFormattedVuluesUpdate(this.application);
      this.setSubjectProperty = this.application.subjectproperty[index];
      this.setMortgage = this.application.mortgage[index];
      this.activeScenarioText = true;
      this.typeWriterTextServices.setActiveScenarioText(this.activeScenarioText);
    }
  }

  addScenario(){
    if(this.user){
      this.loadSpinner = true;
    }
    if(this.application.subjectproperty.length == 3){
      this.addScenarioButton = false;
    }

    const lastSubjectPropertyIndex = this.application.subjectproperty.length - 1;
    const lastSubjectProperty = this.application.subjectproperty[lastSubjectPropertyIndex];
    
    const lastMortgageIndex = this.application.mortgage.length - 1;
    const lastMortgage = this.application.mortgage[lastMortgageIndex];

    this.application.subjectproperty.push({ active: true, propertyValue: lastSubjectProperty.propertyValue, downPayment: lastSubjectProperty.downPayment, downPayment_type: lastSubjectProperty.downPayment_type, estimated_value: lastSubjectProperty.estimated_value, 
                                            inferred_equity: lastSubjectProperty.inferred_equity, eto: lastSubjectProperty.eto, insurance: lastSubjectProperty.insurance, annualPropertyTax: lastSubjectProperty.annualPropertyTax, type: lastSubjectProperty.type, monthlyCondoFee: lastSubjectProperty.monthlyCondoFee, 
                                            monthlyEnergyCost: lastSubjectProperty.monthlyEnergyCost, monthlyRentalIncome: lastSubjectProperty.monthlyRentalIncome, revenueOptions: lastSubjectProperty.revenueOptions, rentalPercentage: lastSubjectProperty.rentalPercentage, occupancy: lastSubjectProperty.occupancy, 
                                            hyrdoCost: lastSubjectProperty.hyrdoCost, managementExpense: lastSubjectProperty.managementExpense, repairCosts: lastSubjectProperty.repairCosts, generalExpense: lastSubjectProperty.generalExpense, homeInsurance: lastSubjectProperty.homeInsurance, interestCharges: lastSubjectProperty.interestCharges })
    this.application.mortgage.push({active: true, frequency: lastMortgage.frequency, amortizationYears: lastMortgage.amortizationYears, amortizationMonths: lastMortgage.amortizationMonths, mortgageRate: lastMortgage.mortgageRate, term: lastMortgage.term});
    this.getHighestIndexActive(this.application.subjectproperty);
    this.getHighestIndexActive(this.application.mortgage);
    this.addNewApplicant = true;
    this.unFormattedVuluesUpdate(this.application);
    this.updateRemoveButtonScenario();
    this.checkScenario();
    
  }

  getHighestIndexActive(array: any){
    let highestIndex = array.length - 1;

    for (let i = array.length - 1; i >= 0; i--) {
        if (array[i].active) {
            highestIndex = i;
            break;
        }
    }

    for (let i = 0; i < array.length; i++) {
      array[i].active = i === highestIndex;
    }
  }

  checkScenario(){
    const subjectProperties = this.application.subjectproperty;
    const mortgages = this.application.mortgage;
    for(let [index, subjectProperty] of subjectProperties.entries()){
      if(subjectProperty.active){
        this.activeScenario = index;
        this.setSubjectProperty = subjectProperty;
      }
    }
    for(let [index, mortgage] of mortgages.entries()){
      if(mortgage.active){
        this.activeMortgage = index;
        this.setMortgage = mortgage;
      }
    }
    this.activeScenarioName = this.activeScenario + 1;
    this.getPaymentSwitch(this.application.subjectproperty[this.activeScenario].downPayment_type, false);
    this.updateRemoveButtonScenario();
  }

  selectLastApplicant(index: number) {
    this.activeApplicant = index;
    this.application.applicants[this.activeApplicant].currentlyActive = true;
    this.incomes = this.application.applicants[this.activeApplicant].incomes;
    this.liabilities = this.application.applicants[this.activeApplicant].liabilities;
    this.revenues = this.application.applicants[this.activeApplicant].revenues;
    this.current = this.application.applicants[this.activeApplicant];
    this.editApplicantData.firstName = this.current.first_name;
    this.editApplicantData.lastName = this.current.last_name ;
    this.openTab(this.current.mode, true);
    this.triggerApplicantChange(index);
  }

  addApplicant(){
    if(this.user){
      this.loadSpinner = true;
    }
    if(this.application.applicants.length == 3){
      this.addButton = false;
    }
    if(this.newApplicant.firstName !== '' || this.newApplicant.lastName !== ''){
      this.application.applicants.push(
          {
            id: null,
            first_name: this.newApplicant.firstName, last_name: this.newApplicant.lastName , phone: null, email: null,
            liabilities: [{ id: null , active: true , type: 'other' , balance: 0, limit: 0, payment: 0 , frequency: 'monthly', minimum: 5, mode_type: 'simple'}, 
                          { id: null , active: true , type: 'creditcard' , balance: 0, limit: 0, payment: 0 , frequency: 'monthly', minimum: 3, mode_type: 'advanced'}, 
                          { id: null , active: true , type: 'other' , balance: 0, limit: 0, payment: 0 , frequency: 'monthly', minimum: 5, mode_type: 'advanced'}], 
            incomes: [{ id: null , type: 'salary' , value: null, frequency: 'yearly', mode_type: 'simple'}], revenues: [], 
            properties: [],
            currentlyActive: null,
            mode: 'simple'
          }
        );
      this.addNewApplicant = true;
      this.unFormattedVuluesUpdate(this.application);
      // this.selectApplicant(this.application.applicants.length-1);
      this.updateRemoveButton();
      this.newApplicantModal('close');
      this.getHighestIndexApplicant(this.application.applicants);
      this.newApplicant.firstName = 'emprunteur 1';
    } else{
      this.showError('Error', 'Name is required');
      this.loadSpinner = false;
    }
  }

  getHighestIndexApplicant(array: any){
    let highestIndex = array.length - 1;

    for (let i = array.length - 1; i >= 0; i--) {
        if (array[i].active) {
            highestIndex = i;
            break;
        }
    }

    for (let i = 0; i < array.length; i++) {
      array[i].currentlyActive = i === highestIndex;
    }

    this.selectApplicant(highestIndex);
  }

  editApplicantName(){
    if((this.editApplicantData.firstName !== '' && this.editApplicantData.firstName !== null) || (this.editApplicantData.lastName !== '' && this.editApplicantData.lastName !== null)){
      this.current.first_name = this.editApplicantData.firstName;
      this.current.last_name  = this.editApplicantData.lastName;
      this.newApplicantModal('close', '')
      this.onFocusOut();
    } else{
      this.showError('Error', 'Name is required');
      this.loadSpinner = false;
      this.editApplicantData.firstName = this.current.first_name;
      this.editApplicantData.lastName = this.current.last_name ;
    }
  }

  showEditField(){
    this.editButton = false;
  }

  hideEditField(){
    this.editButton = true;
    // this.applicants[this.activeApplicant].name = this.current.name;
    // this.applicants[this.activeApplicant].email = this.current.email;
    // this.applicants[this.activeApplicant].phone = this.current.phone;
  }


  requestBrokerAccount() {
    this.http.get<any>(environment.apiBaseUrl+'request-broker-account').subscribe(
      response => {
        this.showSuccess('Request Sent','');
        this.newsModal('close');
        this.triggerChildFunction();
      },
      error => {
        this.showError('Error', error);
      }
    );
  }

  checkDownPaymentFivePercent() {
    this.getMinimumDownPayment();
    let subjectPropertyDownPayment = this.application.subjectproperty[this.activeScenario].downPayment;
    let subjectPropertyPropertyValue = this.application.subjectproperty[this.activeScenario].propertyValue;
    if(subjectPropertyDownPayment !== null &&  subjectPropertyPropertyValue !== null){
      subjectPropertyDownPayment = parseFloat(subjectPropertyDownPayment.toString().replace(/\s/g, ''));
      subjectPropertyPropertyValue = parseFloat(subjectPropertyPropertyValue.toString().replace(/\s/g, ''));
    }
    if(subjectPropertyPropertyValue < 1000000){
      if(this.application.subjectproperty[this.activeScenario].downPayment_type == 'percent'){
        if(subjectPropertyDownPayment < 5){
          this.downPaymentMinCheckFive = true;
        } else {
          this.downPaymentMinCheckFive = false;
        }
      } else if(this.application.subjectproperty[this.activeScenario].downPayment_type == 'dollar'){
        if(this.checkIfLessThanPercent(5)){
          this.downPaymentMinCheckFive = true;
        } else {
          this.downPaymentMinCheckFive = false;
        }
      }
    } else {
      this.downPaymentMinCheckFive = false;
    }
  }

  checkDownPaymentTwentyPercent() {
    this.getMinimumDownPayment();
    let subjectPropertyDownPayment = this.application.subjectproperty[this.activeScenario].downPayment;
    let subjectPropertyPropertyValue = this.application.subjectproperty[this.activeScenario].propertyValue;
    if(subjectPropertyDownPayment !== null &&  subjectPropertyPropertyValue !== null){
      subjectPropertyDownPayment = parseFloat(subjectPropertyDownPayment.toString().replace(/\s/g, ''));
      subjectPropertyPropertyValue = parseFloat(subjectPropertyPropertyValue.toString().replace(/\s/g, ''));
    }
    if(subjectPropertyPropertyValue >= 1000000){
      if(this.application.subjectproperty[this.activeScenario].downPayment_type == 'percent'){
        if(subjectPropertyDownPayment < 20){
          this.downPaymentMinCheckTwenty = true;
        } else {
          this.downPaymentMinCheckTwenty = false;
        }
      } else if(this.application.subjectproperty[this.activeScenario].downPayment_type == 'dollar'){
        if(this.checkIfLessThanPercent(20)){
          this.downPaymentMinCheckTwenty = true;
        } else {
          this.downPaymentMinCheckTwenty = false;
        }
      }
    } else {
      this.downPaymentMinCheckTwenty = false;
    }
  }

  updatePropertyTax() {
    this.application.subjectproperty[this.activeScenario].propertyValue = parseFloat(this.application.subjectproperty[this.activeScenario].propertyValue.toString().replace(/\s/g, ''));
    this.application.subjectproperty[this.activeScenario].annualPropertyTax = Math.floor(Number(this.application.subjectproperty[this.activeScenario].propertyValue) * 0.0075);
    this.propertyTaxFormatting(this.application);
  }

  updateMortgagePropertyTax(property: any) {
    property.propertyValue = parseFloat(property.propertyValue.toString().replace(/\s/g, ''));
    property.annualpropertytax = Math.floor(Number(property.propertyValue) * 0.0075);
    this.formattedValues(this.application);
  }

  updateInferredEquity() {
    if((this.application.type == 'renewal' || this.application.type == 'refinance') && this.application.subjectproperty[this.activeScenario].estimated_value){
      const subjectProperty = this.application.subjectproperty
      let subjectPropertyPropertyValue = this.application.subjectproperty[this.activeScenario].propertyValue;
      let subjectPropertyEstimatedValue = this.application.subjectproperty[this.activeScenario].estimated_value;
      subjectPropertyPropertyValue = parseFloat(subjectPropertyPropertyValue.toString().replace(/\s/g, ''));
      subjectPropertyEstimatedValue = parseFloat(subjectPropertyEstimatedValue.toString().replace(/\s/g, ''));
      subjectProperty[this.activeScenario].inferred_equity = subjectPropertyEstimatedValue - subjectPropertyPropertyValue;
      subjectProperty[this.activeScenario].inferred_equity = subjectProperty[this.activeScenario].inferred_equity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
  }


  propertyTaxFormatting(getApplication: any){
    const subjectProperty = getApplication.subjectproperty[this.activeScenario];
    subjectProperty.annualPropertyTax = subjectProperty.annualPropertyTax.toString().replace(/\s/g, '');
    subjectProperty.annualPropertyTax = subjectProperty.annualPropertyTax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    subjectProperty.propertyValue = subjectProperty.propertyValue.toString().replace(/\s/g, '');
    subjectProperty.propertyValue = subjectProperty.propertyValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  checkIfLessThanPercent(percent){
    let subjectPropertyDownPayment = this.application.subjectproperty[this.activeScenario].downPayment;
    let subjectPropertyPropertyValue = this.application.subjectproperty[this.activeScenario].propertyValue;
    if(subjectPropertyDownPayment !== null &&  subjectPropertyPropertyValue !== null){
      subjectPropertyDownPayment = parseFloat(subjectPropertyDownPayment.toString().replace(/\s/g, ''));
      subjectPropertyPropertyValue = parseFloat(subjectPropertyPropertyValue.toString().replace(/\s/g, ''));
    }
    const calculated_percentage = Number(subjectPropertyDownPayment / subjectPropertyPropertyValue) * 100;
    if(calculated_percentage < percent){
      return true;
    } else {
      return false;
    }
  }

  showSuccess(title: String, message: String) {
    this.toastrService.success(title, message);
  }

  showError(title: String, message: String) {
    this.toastrService.danger(title, message);
  }

  switchToQualifying() {
    this.isActive12 = false;
    this.isActive22 = true;
    if(this.activeRateType == 'proposed'){
      this.mortgage[this.activeMortgage].mortgageRate = Number(this.application.mortgage[this.activeMortgage].mortgageRate) + 2;
      this.reRunCalculations();
      this.activeRateType = 'qualifying';
    }
  }

  switchToProposed() {
    this.isActive12 = true;
    this.isActive22 = false;
    if(this.activeRateType == 'qualifying'){
      this.mortgage[this.activeMortgage].mortgageRate = Number(this.application.mortgage[this.activeMortgage].mortgageRate) - 2;
      this.reRunCalculations();
      this.activeRateType = 'proposed';
    }
  }

  openTab(tabName: string, first: boolean = false) {
    this.typeWriterTextServices.getTabName(tabName);
    if(this.current.mode != tabName){
      if(!first){
        if(this.user){
          this.loadSpinner = true;
        }
      }
      this.current.mode = tabName;
      
      this.isActive2 = !this.isActive2;
      this.isActive1 = !this.isActive1;
      if(!first){
        console.log('focusing out');
        // this.onFocusOut();
        this.unFormattedVuluesUpdate(this.application);
      }
      this.reRunCalculations();
      this.applicantModeData(this.application);
    } else {
      if(this.current.mode == 'simple'){
        this.isActive1 = true;
        this.isActive2 = false;
      } else if(this.current.mode == 'advanced'){
        this.isActive1 = false;
        this.isActive2 = true;
      } else {
        this.current.mode = 'simple';
        this.isActive1 = true;
        this.isActive2 = false;
      }
    }
    
  }

  removeIncomes() {
    this.incomes = [];
    this.application.applicants[this.activeApplicant].incomes = [];
    console.log('here removing');
    // this.addIncome();
  }

  triggerStepChange(stepId) {
    if(this.user && this.triggeredViewership){
      this.http.post<any>(environment.apiBaseUrl+'trigger-scroll/'+this.application_id, {div: null, activeStep: stepId, action: 'scroll'}).subscribe();
    }
  }

  triggerApplicantChange(index) {
    if(this.user && this.triggeredViewership){
      this.http.post<any>(environment.apiBaseUrl+'trigger-scroll/'+this.application_id, {div: null, activeStep: null, applicant: index, action: 'scroll'}).subscribe();
    }
  }

  toggleDiv1() {
    if(!this.isOpen1){
      this.getMobileMenu('menuStepOne');
    } else{
      this.myDiv0.nativeElement.children[1].classList.remove("down-animation");
    }
    this.textIdChecker = ''
    this.checkStepId = '';
    this.stickyScenarioButton = false;
    this.isOpen1 = !this.isOpen1;
    this.isOpen2 = false;
    this.isOpen3 = false;
    this.isOpen4 = false;
    this.myDiv0.nativeElement.scrollTop = 2;
    this.myDiv.nativeElement.scrollTop = 2;
    this.myDiv2.nativeElement.scrollTop = 2;
    this.myDiv2.nativeElement.scrollTop = 2;
    if(!this.mobileScreen){
      this.myDiv.nativeElement.classList.remove("h-auto");
      this.myDiv2.nativeElement.classList.remove("h-auto");
      this.myDiv3.nativeElement.classList.remove("h-auto");
    }
    this.triggerStepChange('stepOne') 
    this.activeStep = 'stepOne';
    this.sendStepValues()
  }

  toggleDiv2() {
    if(!this.isOpen2){
      this.getMobileMenu('menuStepTwo');
    } else{
      this.myDiv.nativeElement.classList.remove("h-auto");
      this.myDiv.nativeElement.children[1].classList.remove("down-animation");
    }
    this.textIdChecker = ''
    this.checkStepId = '';
    this.stickyScenarioButton = false;
    this.isOpen2 = !this.isOpen2;
    this.isOpen1 = false;
    this.isOpen3 = false;
    this.isOpen4 = false;
    this.myDiv0.nativeElement.scrollTop = 2;
    this.myDiv.nativeElement.scrollTop = 2;
    this.myDiv2.nativeElement.scrollTop = 2;
    this.myDiv3.nativeElement.scrollTop = 2;
    if(!this.mobileScreen){
      this.myDiv2.nativeElement.classList.remove("h-auto");
      this.myDiv3.nativeElement.classList.remove("h-auto");
    }
    setTimeout(() => {
      if(!this.mobileScreen && this.isOpen2){
        this.myDiv.nativeElement.classList.add("h-auto");
      }
    }, 700);
    this.triggerStepChange('stepTwo');
    this.activeStep = 'stepTwo';
    this.sendStepValues()
  }

  toggleDiv3() {
    if(!this.isOpen3){
      this.getMobileMenu('menuStepThree');
    } else{
      this.myDiv2.nativeElement.classList.remove("h-auto");
      this.myDiv2.nativeElement.children[1].classList.remove("down-animation");
    }
    this.textIdChecker = ''
    this.checkStepId = '';
    this.isOpen3 = !this.isOpen3;
    this.isOpen2 = false;
    this.isOpen1 = false;
    this.isOpen4 = false;
    this.myDiv0.nativeElement.scrollTop = 2;
    this.myDiv.nativeElement.scrollTop = 2;
    this.myDiv2.nativeElement.scrollTop = 2;
    this.myDiv3.nativeElement.scrollTop = 2;
    if(!this.mobileScreen){
      this.myDiv.nativeElement.classList.remove("h-auto");
      this.myDiv3.nativeElement.classList.remove("h-auto");
    }
    setTimeout(() => {
      if(!this.mobileScreen && this.isOpen3){
        this.myDiv2.nativeElement.classList.add("h-auto")
      }
    }, 700);
    this.triggerStepChange('stepThree')
    this.activeStep = 'stepThree';
    this.sendStepValues()
  }

  toggleDiv4() {
    if(!this.isOpen4){
      this.getMobileMenu('menuStepFour');
    } else{
      this.myDiv3.nativeElement.classList.remove("h-auto");
      this.myDiv3.nativeElement.children[1].classList.remove("down-animation");
    }
    this.textIdChecker = ''
    this.checkStepId = '';
    this.stickyScenarioButton = false;
    this.isOpen4 = !this.isOpen4;
    this.isOpen2 = false;
    this.isOpen3 = false;
    this.isOpen1 = false;
    this.myDiv0.nativeElement.scrollTop = 2;
    this.myDiv.nativeElement.scrollTop = 2;
    this.myDiv2.nativeElement.scrollTop = 2;
    this.myDiv3.nativeElement.scrollTop = 2;
    if(!this.mobileScreen){
      this.myDiv.nativeElement.classList.remove("h-auto");
      this.myDiv2.nativeElement.classList.remove("h-auto");
    }
    setTimeout(() => {
      if(!this.mobileScreen && this.isOpen4){
        this.myDiv3.nativeElement.classList.add("h-auto");
      }
    }, 700);
    this.triggerStepChange('stepFour')
    this.activeStep = 'stepFour';
    this.sendStepValues()
  }

  sendStepValues(){
    const stepData = {
      isOpen1: this.isOpen1,
      isOpen2: this.isOpen2,
      isOpen3: this.isOpen3,
      isOpen4: this.isOpen4,
    }
    this.typeWriterTextServices.getStepValue(stepData);
  }

  closeAllDivs() {
    this.isOpen4 = false;
    this.isOpen2 = false;
    this.isOpen3 = false;
    this.isOpen1 = false;
    this.myDiv0.nativeElement.scrollTop = 0;
    this.myDiv.nativeElement.scrollTop = 0;
    this.myDiv2.nativeElement.scrollTop = 0;
    this.myDiv3.nativeElement.scrollTop = 0;
    this.activeStep = 0;
  }

  closeAllDivsReset() {
    this.isOpen2 = false;
    this.isOpen3 = false;
    this.isOpen1 = false;
    this.isOpen4 = false;
    this.myDiv0.nativeElement.scrollTop = 2;
    this.myDiv.nativeElement.scrollTop = 2;
    this.myDiv2.nativeElement.scrollTop = 2;
    this.myDiv3.nativeElement.scrollTop = 2;
    if(!this.mobileScreen){
      this.myDiv.nativeElement.classList.remove("h-auto");
      this.myDiv2.nativeElement.classList.remove("h-auto");
      this.myDiv3.nativeElement.classList.remove("h-auto");
    }
    setTimeout(() => {
      if(!this.mobileScreen && this.isOpen4){
        this.myDiv3.nativeElement.classList.add("h-auto");
      }
    }, 700);
  }

  showProperyFields() {
    this.showNonSubjectproperty = !this.showNonSubjectproperty;
  }

  onFocusOut() {
    if(!this.downPaymentPercent){
      this.unFormattedVulues(this.application);
    }
    // setTimeout(() => {
      // this.saveApplication();
    // }, 1000);
  }
  
  formattedValues(originalData: any) {
    originalData = this.thousandSeparatorService.convertValueToString(originalData);
    this.application = originalData;
    this.application = {...this.application};
    this.includeCalculation()
    console.log("ORIGINAL DATA_------>>>>>",this.application);
  }

  unFormattedVulues(getApplication: any){
    getApplication = this.thousandSeparatorService.unFormattedVulues(getApplication);
    this.application = getApplication;
    console.log("ORINAL_------>>>>>",this.application); 
    this.saveApplication();
    this.formattedValues(this.application)
  }
  
  unFormattedVuluesUpdate(getApplication: any){
    getApplication = this.thousandSeparatorService.unFormattedVuluesUpdate(getApplication);
    this.application = getApplication;
    if(this.addNewApplicant){
      this.addNewApplicant = false;
      this.saveApplicationWithUpdate(true);
    } else {
      this.saveApplicationWithUpdate();
    }
    console.log("ORINAL_------>>>>>",this.application); 
    this.formattedValues(this.application)
  }

  runFormattedValues(){
    if(this.applicationResponse){
      this.applicationResponse = false;
      this.formattedValues(this.application)
    }
  }

  saveApplication() {
    if(this.user && this.user.id){
      this.http.post<any>(environment.apiBaseUrl+'application', { application: this.application, user_id: this.applicant_id }).subscribe(
        response => {
          if(!response.report){
            this.bossApplication = response.data;
            this.application = response.data;
            if(this.application.id){
              this.showUrl = this.application.id;
            }
            this.selectApplicant(0);
            this.triggerViewerShip()
          }
          if(response){
            this.applicationResponse = true;
            this.runFormattedValues();
            this.checkScenario();
          }
          console.log(response);
        },
        error => {
          // this.showError('Unauthorized', 'Invalid email or password');
        }
      );
    }
  }

  saveApplicationWithUpdate(active: any = false, updates: any = false) {
    if(this.user && this.user.id){
      this.http.post<any>(environment.apiBaseUrl+'application', { application: this.application, user_id: this.applicant_id }).subscribe(
        response => {
          this.bossApplication = response.data;
          this.application = response.data;
          this.loadSpinner = false;
          this.reRunCalculations();
          if(this.application.id){
            this.showUrl = this.application.id;
          }
          // if(active){
          //   this.selectApplicant(this.application.applicants.length-1);
          // } else {
          this.selectApplicant(this.activeApplicant);
          this.checkScenario();
          // }
          // this.selectApplicant(0);
          console.log(response);
          if(updates){
            this.incomes = this.application.applicants[this.activeApplicant].incomes;
            this.liabilities = this.application.applicants[this.activeApplicant].liabilities;
          }
          if(response){
            this.applicationResponse = true;
            this.runFormattedValues();
          }
          this.triggerViewerShip()
        },
        error => {
          // this.showError('Unauthorized', 'Invalid email or password');
        }
      );
    }
  }

  saveNewApplicationWithUpdate(){
    if(this.user && this.user.id){
      this.http.post<any>(environment.apiBaseUrl+'application', { application: this.application, user_id: this.applicant_id }).subscribe(
        response => {
          this.bossApplication = response.data;
          this.application = response.data;
          this.loadSpinner = false;
          this.reRunCalculations();
          if(this.application.id){
            this.showUrl = this.application.id;
          }
          this.selectApplicant(this.activeApplicant);
          this.checkScenario();
          console.log(response);
          this.triggerViewerShip()
          this.receiveTheApplication(this.application.id);
        },
        error => {
        }
      );
    }
  }

  getApplicationInfo(close: any = true) {
    this.http.get<any>(environment.apiBaseUrl+'application/'+ this.application_id).subscribe(
      response => {
        this.bossApplication = response;
        this.application = response;
        if(this.activeApplicant > 0 && close){
          this.selectApplicant(this.application.applicants.length-1);
        } else if(this.activeApplicant > 0){
          this.selectApplicant(this.activeApplicant);
        } else {
          this.selectApplicant(0);
        }
        this.checkScenario();
        this.getPaymentSwitch(this.application.subjectproperty[this.activeScenario].downPayment_type, false);
        this.reRunCalculations();
        this.updateRemoveButton();
        this.updateRemoveButtonScenario();
        this.checkDownPaymentFivePercent();
        this.checkDownPaymentTwentyPercent();
        this.loadSpinner = false;
        this.formattedValues(response);
        if(this.NewApplicationApplicant){
          this.NewApplicationApplicant = false;
          this.toggleDiv1();
        }
      },
      error => {
        // this.showError('Unauthorized', 'Invalid email or password');
      }
    );
  }

  getApplicationInfoBe(close: any = true) {
    this.http.get<any>(environment.apiBaseUrl+'application-backend/'+ this.application_id).subscribe(
      response => {
        this.bossApplication = response;
        this.application = response;
        if(this.activeApplicant > 0 && close){
          this.selectApplicant(this.application.applicants.length-1);
        } else if(this.activeApplicant > 0){
          this.selectApplicant(this.activeApplicant);
        } else {
          this.selectApplicant(0);
        }
        this.checkScenario();
        this.getPaymentSwitch(this.application.subjectproperty[this.activeScenario].downPayment_type, false);
        this.reRunCalculations();
        this.updateRemoveButton();
        this.updateRemoveButtonScenario();
        this.checkDownPaymentFivePercent();
        this.checkDownPaymentTwentyPercent();
        this.loadSpinner = false;
        this.formattedValues(response);
      },
      error => {
        // this.showError('Unauthorized', 'Invalid email or password');
      }
    );
  }

  getMinimumDownPayment() {
    let propertyValue = Number(parseFloat(this.application.subjectproperty[this.activeScenario].propertyValue.toString().replace(/\s/g, '')));
    if(propertyValue > 500000 && propertyValue < 1000000){
      this.minimumDownPayment = (500000 * 0.05) + ((propertyValue - 500000) * 0.1);
    } else if(propertyValue >= 1000000) {
      this.minimumDownPayment = propertyValue * 0.2;
    } else {
      this.minimumDownPayment = propertyValue * 0.05;
    }
    if(this.application.subjectproperty[this.activeScenario].downPayment_type == 'dollar'){
      if(this.minimumDownPayment > Number(parseFloat(this.application.subjectproperty[this.activeScenario].downPayment.toString().replace(/\s/g, '')))){
        this.showMinError = true;
      } else {
        this.showMinError = false;
      }
    } else {
      if(this.minimumDownPayment > Number(parseFloat(this.application.subjectproperty[this.activeScenario].downPayment.toString().replace(/\s/g, '')))/100 * propertyValue){
        this.showMinError = true;
      } else {
        this.showMinError = false;
      }
    }
    this.minimumDownPaymentPercent = this.minimumDownPayment/propertyValue * 100;
  }

  updateRemoveButton() {
    if(this.application.applicants.length > 1){
      this.removeApplicantButton = true;
      if(this.application.applicants.length < 4){
        this.addButton = true;
      }
    } else {
      this.removeApplicantButton = false;
    }
  }
  
  updateRemoveButtonScenario() {
    if(this.application.subjectproperty.length > 1){
      this.removeScenarioButton = true;
      if(this.application.subjectproperty.length < 4){
        this.addScenarioButton = true;
      }
    } else {
      this.removeScenarioButton = false;
    }
  }

  getMobileMenu(value:string) {
    const linkValue = value;
    let menuLinks = this.mobileMenu.nativeElement.children;
    for (let link of menuLinks) {
      const linkId = link.id;
      if(this.mobileTabBtnsScroll){
        link.classList.remove("c-mob-menu-active");
        if (linkValue === linkId) {
          link.children[0].classList.add("active-tab-scroll");
        } else {
          link.children[0].classList.remove("active-tab-scroll");
        }
      } else {
        link.children[0].classList.remove("active-tab-scroll");
        if (linkValue === linkId) {
          if(linkId === 'menuStepOne'){
            this.inActiveStepOne = false;
          } else {
            this.inActiveStepOne = true;
          }
          link.classList.add("c-mob-menu-active");
        } else {
          link.classList.remove("c-mob-menu-active");
        }
      }
    }
 }

 getFullFooter(value:string) {
   let actionValue = value;
   if(actionValue === 'openFullFooter'){
    this.footer = true;
    this.footerIcon = true;
    this.mobileSideBarContent = true;
   } else {
    this.footer = false;
    this.footerIcon = false;
    this.mobileSideBarContent = false;
   }
 }



 getPaymentSwitch(value:string = 'dollar', first: boolean = true ){
  let subjectPropertyDownPayment = this.application.subjectproperty[this.activeScenario].downPayment;
  let subjectPropertyPropertyValue = this.application.subjectproperty[this.activeScenario].propertyValue;
  if(subjectPropertyDownPayment !== null &&  subjectPropertyPropertyValue !== null){
    subjectPropertyDownPayment = parseFloat(subjectPropertyDownPayment.toString().replace(/\s/g, ''));
    subjectPropertyPropertyValue = parseFloat(subjectPropertyPropertyValue.toString().replace(/\s/g, ''));
  }
  if(this.application.subjectproperty[this.activeScenario].downPayment_type != value){
    if(value == 'dollar'){
      this.application.subjectproperty[this.activeScenario].downPayment = subjectPropertyPropertyValue * (subjectPropertyDownPayment / 100);
    } else {
      this.application.subjectproperty[this.activeScenario].downPayment = (subjectPropertyDownPayment / subjectPropertyPropertyValue) * 100
    }
  }
  const switchValue = value;
  this.application.subjectproperty[this.activeScenario].downPayment_type = value;
  this.downpaymentValueType = value;
  if(this.paymentSwitch){
    const setType = this.paymentSwitch.nativeElement.children;
    for(let type of setType){
      if (type.dataset.value === switchValue) {
        type.classList.add("gradient")
      } else {
        type.classList.remove("gradient")
      }
    }
  }
  this.reRunCalculations();
  if(first){
    this.onFocusOut();
  }
 }

 tableModal(value:string) {
  const action = value;
  if(action === "open"){
    this.dataTableModal = true;
  }
  else{
    this.dataTableModal = false
  }
  
 }

 newsModal(value:string) {
  const action = value;
  if(action === "open"){
    this.kingstateNewsModal = true;
  }
  else{
    this.kingstateNewsModal = false;
  }
 }

 removeApplicantModal(value:string){
   const action = value;
   if(action === "open"){
    this.removeBorrowerModal = true;
    if(this.application.applicants[this.activeApplicant].last_name){
      this.removeBorrowerName = this.application.applicants[this.activeApplicant].first_name + ' ' + this.application.applicants[this.activeApplicant].last_name;
    } else {
      this.removeBorrowerName = this.application.applicants[this.activeApplicant].first_name;
    }
  }
  else{
    this.removeBorrowerModal =false;
  }
 } 
 
 removeScenarioModal(value:string){
   const action = value;
   if(action === "open"){
    this.scenarioModal = true;
    this.removeScenarioName = this.activeScenario + 1;
  }
  else{
    this.scenarioModal =false;
  }
 }
 
 
 getCsvGenerateModal(event: { arg1: string, arg2: string }){
  if(event.arg2 === 'applicants' || event.arg2 === 'applicant'){
    this.csvStatus = true;
    this.getCsvStatus = event.arg2;
  } else {
    this.csvStatus = false;
    this.getCsvStatus = event.arg2;
  }
  const action = event.arg1;
  this.csvGenerateModal(action);
 }
 
 csvGenerateModal(value: string){
   const action = value;
   if(action === "open"){
    this.csvModal = true;
    }
    else{
      setTimeout(()=>{
        this.csvModal = false;
        this.csvOption = false;
        this.csvOptionName = '';
        this.bossMailSection = false;
        this.changeBossAccount = false;
        this.checkBossAccount = false;
        this.checkBossCredentails = false;
        this.checkFinmoCredentails = false;
      },100);
    }
  } 
 
 newApplicantModal(value:string, status: string = ''){
  const action = value;
  if(action === "open"){
    this.addApplicantModal = true;
    if(status === 'editMode'){
      this.editApplicant = true;
    } else {
      this.editApplicant = false;
    }
  }
  else{
    this.addApplicantModal =false;
    if(status !== 'editMode' && this.newApplicant.firstName === ''){
      this.newApplicant.firstName = 'emprunteur 1';
    }
  }
 } 

 addResourceModal(value:string, resource:string = ''){
  const action = value;
  if(action === "open"){
    this.resourceModal = true;
    if(resource === 'newIncome'){
      this.createNewResource.income = 'salary';
      this.newIncome = true;
      this.newRevenue = false;
      this.newLiability = false;
      this.newProperty = false;
    } else if(resource === 'newRevenue'){
      this.createNewResource.revenue = 'salary';
      this.newIncome = false;
      this.newRevenue = true;
      this.newLiability = false;
      this.newProperty = false;
    } else if(resource === 'newLiability'){
      this.createNewResource.liability = 'other';
      this.newIncome = false;
      this.newRevenue = false;
      this.newLiability = true;
      this.newProperty = false;
    } else if(resource === 'newProperty'){
      this.createNewResource.property_occupancy = 'owner';
      this.createNewResource.property_obligation_address = '';
      this.newIncome = false;
      this.newRevenue = false;
      this.newLiability = false;
      this.newProperty = true;
    }
  }
  else{
    this.resourceModal =false;
    this.newIncome = false;
    this.newRevenue = false;
    this.newLiability = false;
    this.newProperty = false;
    this.createNewResource = {
      income: '',
      revenue: '',
      liability: '',
      property_obligation_address: '',
      property_occupancy: '',
    }
  }
 }
 
 addResource(){
  if(this.newIncome && this.createNewResource.income !== ''){
    if(this.user){
      this.loadSpinner = true;
    }
    this.application.applicants[this.activeApplicant].incomes.push({ id: null , type: this.createNewResource.income, value: null, frequency: 'yearly', mode_type: 'advanced'});
    this.addResourceModal('close');
    this.unFormattedVuluesUpdate(this.application);
    this.createNewResource.income = '';
  } else if(this.newRevenue && this.createNewResource.revenue !== ''){
    if(this.user){
      this.loadSpinner = true;
    }
    this.application.applicants[this.activeApplicant].revenues.push({ id: null , type: this.createNewResource.revenue , value: null, frequency: 'monthly', mode_type: 'advanced'});
    this.addResourceModal('close');
    this.unFormattedVuluesUpdate(this.application);
    this.createNewResource.revenue = '';
  } else if(this.newLiability && this.createNewResource.liability !== ''){
    if(this.user){
      this.loadSpinner = true;
    }
    this.application.applicants[this.activeApplicant].liabilities.push({ id: null , active: true , type: this.createNewResource.liability , balance: null, limit: null, payment: null , frequency: 'monthly', minimum: 5, mode_type: 'advanced'});
    this.addResourceModal('close');
    this.unFormattedVuluesUpdate(this.application);
    this.createNewResource.liability = '';
  } else if(this.newProperty && (this.createNewResource.property_occupancy !== '' || this.createNewResource.property_obligation_address !== '')){
    if(this.user){
      this.loadSpinner = true;
    }
    this.application.applicants[this.activeApplicant].properties.push({ id: null , type: 'mortgage', frequency: 'monthly', occupancy: this.createNewResource.property_occupancy, payment: null, annualpropertytax: null, monthlyenergycost: 0, monthlycondofee: null, rentalincome: null, revenueOptions: 'none', rentalPercentage: 50, hyrdoCost: null, managementExpense: null, repairCosts: null, generalExpense: null, homeInsurance: null, interestCharges: null, mode_type: 'advanced', address: this.createNewResource.property_obligation_address  });
    this.addResourceModal('close');
    this.unFormattedVuluesUpdate(this.application);
    this.createNewResource.property_occupancy = '';
    this.createNewResource.property_obligation_address = '';
  }
 }
 
 requestedBrokerModel(value:string){
   const action = value;
   if(action === "open"){
    this.brokerRequestModal = true;
  }
  else{
    this.brokerRequestModal =false;
  }
 }
 
 newApplicantModel(value:string){
   const action = value;
   if(action === "open"){
     this.createApplicantModal = true;
  }
  else{
    this.createApplicantModal = false;
    this.NewApplicationApplicant = false;
    this.applicantInfo = {
      name:'',
      last_name: '',
      email: '',
      phone: ''
    }
  }
 }
 
 getPreApprovalModal(value:string){
   const action = value;
   if(action === "open"){
    this.getPaymentSwitch('percent');
    this.preApprovalModal = true;
  }
  else{
    this.preApprovalModal =false;
  }
 }

 specialistContactModal(value:string) {
  const action = value;
  if(action === "open"){
    this.authModal = true;
    if (!this.loggedInUpdaterValue){
      this.openLogin();
    } else{
      this.openEditUser();
    }
  }
  else{
    this.authModal = false;
    this.loginActive = false;
    this.registerActive = false;
    this.userInfo = {
      email: '',
      password: '',
      username: ''
    }
  }
 }

 termConditionsModal(value:string){
  const action = value;
  if(action === "open"){
   this.termConditions = true;
  }  
  else{
    this.http.get<any>(environment.apiBaseUrl+'accept-terms-and-condition').subscribe();
    this.termConditions =false;
  }
}

  stopViewership(){
    if(this.application_id){
      this.http.get<any>(environment.apiBaseUrl+'trigger-viewer-switch/'+this.application_id+'/switch/'+0).subscribe();
    }
  }

  startViewership(){
    if(this.application_id){
      this.http.get<any>(environment.apiBaseUrl+'trigger-viewer/'+this.application_id).subscribe();
      this.closeAllDivsReset();
    }
  }

  getPaymentDetail(){
    const getApplication = this.thousandSeparatorService.unFormattedVulues(this.application);
    this.application = getApplication;
    this.paymentDetail();
    this.formattedValues(this.application)
  }

 paymentDetail() {
    if(this.user && this.user.id){
    this.http.post<any>(environment.apiBaseUrl+'application', { application: this.application, user_id: this.applicant_id }).subscribe(
      response => {
        if(response.report){
          this.monthlyData = response.report.monthlyArray;
          this.annualData = response.report.finalArray;
        }
        console.log(response.report.monthlyArray);
        console.log(response.report.finalArray);
      },
      error => {
        // this.showError('Unauthorized', 'Invalid email or password');
      }
    );
  }
 }

 requestBroker(){
  if(this.user && this.user.id){
    this.http.post<any>(environment.apiBaseUrl+'request-broker', { user_id: this.user.id }).subscribe(
      response => {
        if(response){
          if(response.request){
            if(response.user.request_broker && response.user.request_broker.status === 'requested'){
              this.user = response.user
              this.brokerRequestStatus = 'Broker Requested';
            }
            this.showSuccess(response.message,'');
          } else {
            this.showError(response.message,'');
          }
          this.requestedBrokerModel('close');
        }
      },
      error => {
        // this.showError('Unauthorized', 'Invalid email or password');
        this.showError('Error', error);
      }
    );
  }
 }

//  checkrequestBroker(value: boolean){
//   if (this.user && this.user.id){
//     this.http.get<any>(environment.apiBaseUrl+'check-request-broker/'+ this.user.id).subscribe(
//       response => {
//         if(response){
//           if(!response.request){
//             this.brokerRequestStatus = response.message;
//           }
//         }
//       },
//       error => {
//         // this.showError('Unauthorized', 'Invalid email or password');
//         this.showError('Error', error);
//       }
//     );
//   }
//  }

  getDivHeight(){
    const stepOnerDiv = this.myDiv0.nativeElement;
    this.heightOfStepOne = stepOnerDiv.getBoundingClientRect().top;

    const stepTwoDiv = this.myDiv.nativeElement;
    this.heightOfStepTwo = stepTwoDiv.getBoundingClientRect().top;

    const stepThreeDiv = this.myDiv2.nativeElement;
    this.heightOfStepThree = stepThreeDiv.getBoundingClientRect().top;

    const stepFourDiv = this.myDiv3.nativeElement;
    this.heightOfStepFour = stepFourDiv.getBoundingClientRect().top;

    const mobileTabsDiv = this.mobileTabs.nativeElement;
    this.heightOfMobTabs = mobileTabsDiv.getBoundingClientRect().top;

    const stepFourValueDiv = this.stepFourDivRef?.nativeElement;
    const stepFourValueDivEnd = this.stepFourDivRefEnd?.nativeElement;
    this.stepFourValueHeight = stepFourValueDiv?.getBoundingClientRect().top;
    this.stepFourValueHeightEnd = stepFourValueDivEnd?.getBoundingClientRect().top;

    const stepThreeValueDiv = this.stepThreeDivRef?.nativeElement;
    const stepThreeValueDivEnd = this.stepThreeDivRefEnd?.nativeElement;
    this.stepThreeValueHeight = stepThreeValueDiv?.getBoundingClientRect().top;
    this.stepThreeValueHeightEnd = stepThreeValueDivEnd?.getBoundingClientRect().top;
    
  }

  stepValueToggle(value: string){
    if (value === 'stepThreeActive') {
      this.getHeightStepValues = this.stepThreeValueHeight;
      this.getHeightStepValuesEnd = this.stepThreeValueHeightEnd;
    } else if (value === 'stepFourActive') {
      this.getHeightStepValuesEnd = this.stepFourValueHeightEnd;
      this.getHeightStepValues = this.stepFourValueHeight;
      }
  }

  scrollToHeight(value: string) {
    if (value === 'stepOne'){
      const heightOfOne  = (this.heightOfStepOne - this.heightOfMobTabs) +1;
      this.mainBody.nativeElement.scrollTop = heightOfOne;
    } else if (value === 'stepTwo') {
      const heightOfTwo = (this.heightOfStepTwo - this.heightOfMobTabs) + 1;
      this.mainBody.nativeElement.scrollTop = heightOfTwo;
    } else if (value === 'stepThree') {
      const heightOfThree = (this.heightOfStepThree - this.heightOfMobTabs) + 1;
      this.mainBody.nativeElement.scrollTop = heightOfThree;
    } else if (value === 'stepFour') {
      const heightOfFour = (this.heightOfStepFour - this.heightOfMobTabs) + 1;
      this.mainBody.nativeElement.scrollTop = heightOfFour;
    }
    this.triggerStepChange(value);
  }

  @HostListener('scroll', ['$event'])
    onScrollSteps(event: any) {
      this.headerOnSroll();
      let scrolling =  event.target.scrollTop + this.heightOfMobTabs;
      if (this.mobileScreen){
        if (scrolling <= this.heightOfStepTwo) {
          this.getMobileMenu('menuStepOne');
          this.currentlyActiveStepId = 'stepOne'
          this.typeWriterTextServices.getStepName(this.currentlyActiveStepId);
        } else if (scrolling >= this.heightOfStepTwo && scrolling <= this.heightOfStepThree) {
          this.getMobileMenu('menuStepTwo');
          this.currentlyActiveStepId = 'stepTwo'
          this.typeWriterTextServices.getStepName(this.currentlyActiveStepId);
        } else if (scrolling >= this.heightOfStepThree && scrolling <= this.heightOfStepFour) {
          this.stepValueToggle("stepThreeActive")
          this.getMobileMenu('menuStepThree');
          this.currentlyActiveStepId = 'stepThree'
          this.typeWriterTextServices.getStepName(this.currentlyActiveStepId);
        } else if (scrolling >= this.heightOfStepFour) {
          this.stepValueToggle("stepFourActive")
          this.getMobileMenu('menuStepFour');
          this.currentlyActiveStepId = 'stepFour' 
        }
      }
    }
    onScrollStepsValue(event: any) {
      let scrolling = event.target.scrollTop + this.heightOfMobTabs;
      let start = this.getHeightStepValues;
      let end = this.getHeightStepValuesEnd;
      if (this.mobileScreen){
        if(scrolling >= start && scrolling <= end && this.getHeightStepValues >= 2){
          this.scrollStepsValueChange = true;
        } else if(scrolling >= end && this.getHeightStepValues >= 2){
          this.scrollStepsValueChange = false;
        } else if(scrolling <= start && this.getHeightStepValues >= 2){
          this.scrollStepsValueChange = false;
        }
      }
    }

    headerOnSroll(){
      const currentScrollTop = (event.target as HTMLElement).scrollTop;
      const isScrollingUp = currentScrollTop < this.lastScrollTop;
      this.lastScrollTop = currentScrollTop;
      if(this.mobileScreen){
        if (isScrollingUp || this.lastScrollTop === 0) {
          // this.headerScrollDown();
          // this.headerSize = false;
          this.mobileTabBtnsScroll = false;
        } else if (this.lastScrollTop >= 0) {
          // this.headerScrollUp();
          // this.headerSize = true;
          this.mobileTabBtnsScroll = true;
        }
      }
    }

    getDivValuesOnScroll(event: any = null) {
      this.getDivHeight()
      let divs = [];
      if(this.mobileScreen){
        this.textIdChecker = '';
        divs = this.allDivIds; 
      } else {
        if(this.isOpen2){
          this.textIdChecker = '';
          divs = this.stepTwoDivIds;
        } else if(this.isOpen3){
          this.textIdChecker = '';
          divs = this.stepThreeDivIds;
        } else if(this.isOpen3){
          this.textIdChecker = '';
          divs = this.stepFourDivIds;
        }
      }
      const blockedIds = ['stepOne', 'stepTwo', 'stepThree', 'stepFour', 'tab2'];
      this.ngAfterViewInit();
      divs.forEach((div: any) => {
        const mainBodyHeight = this.tabsHeight;
        const rect = div.getBoundingClientRect().top;
        if (rect <= mainBodyHeight && rect >= this.getIdBreakPoint){
          if(this.currentDivId !== div.id){
            if((!this.mobileScreen || this.tabScreen) && !blockedIds.includes(div.id)){
              this.currentDivId = div.id;
              console.log("<<<<<<<test>>>>>>>", this.currentDivId);
              if(this.tabScreen){
                this.checkStickyScenarioButton(this.currentDivId)
              }
              if(this.user && this.userStatus){
                if(this.currentDivId == 'stepOne' || this.currentDivId == 'stepTwo' || this.currentDivId == 'stepThree' || this.currentDivId == 'stepFour'){
                  this.http.post<any>(environment.apiBaseUrl+'trigger-scroll/'+this.application_id, {div: null, activeStep: this.currentDivId, action: 'scroll'}).subscribe();
                } else {
                  this.http.post<any>(environment.apiBaseUrl+'trigger-scroll/'+this.application_id, {div: this.currentDivId, activeStep: null, action: 'scroll'}).subscribe();
                }
              }
            } else if(this.mobileScreen && div.id !== 'tab2' ){
              this.currentDivId = div.id;
              console.log("<<<<<<<test>>>>>>>", this.currentDivId);
              this.checkStickyScenarioButton(this.currentDivId)
              if(this.user && this.userStatus){
                if(this.currentDivId == 'stepOne' || this.currentDivId == 'stepTwo' || this.currentDivId == 'stepThree' || this.currentDivId == 'stepFour'){
                  this.http.post<any>(environment.apiBaseUrl+'trigger-scroll/'+this.application_id, {div: null, activeStep: this.currentDivId, action: 'scroll'}).subscribe();
                } else {
                  this.http.post<any>(environment.apiBaseUrl+'trigger-scroll/'+this.application_id, {div: this.currentDivId, activeStep: this.currentlyActiveStepId, action: 'scroll'}).subscribe();
                }
              }
            }
          }
        }     
      });
    }

    checkStickyScenarioButton(value: string){
      const blockedIds = ['stepOne', 'stepTwo', 'stepThree', 'stepFour', 'addScenario'];
      const acceptIds = ['propertyValue', 'mortgageSection', 'propertyDetail_b', 'occupancySection', 'annualPropertyTax'];
      if(this.application.subjectproperty.length > 1){
        if(acceptIds.includes(value)){
          this.stickyScenarioButton = true;
        } else if(blockedIds.includes(value) || !acceptIds.includes(value)){
          this.stickyScenarioButton = false;
        }
      }
    }

    allowDecimal(event: KeyboardEvent) {
      const currentValue = (<HTMLInputElement>event.target).value;
      if (event.key === '.') {
        if (currentValue.includes('.')) {
          event.preventDefault();
        }
      }

      if (event.key === 'Tab') {
        event.preventDefault();
        return;
      }
  
      if (!(event.key >= '0' && event.key <= '9') && event.key !== 'Backspace' && event.key !== '.' && event.key !== 'Tab') {
        event.preventDefault();
      }
    }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const getNativeElement = this.childComponent.elementRef.nativeElement.contains(event.target);
    if (event.key === 'Tab' && !getNativeElement) {
      event.preventDefault();
    }
  }

  getApplicationBtnPosition(){
    if(this.screenWidth < 1201 && this.applicationSection && this.user.role.name === 'broker'){
      if(this.screenWidth < 768){
        this.screenHeight = this.screenHeight - 305;
      } else {
        this.screenHeight = this.screenHeight - 185;
      }
      this.mainBody.nativeElement.style.height = this.screenHeight + 'px';
    } else {
      this.mainBody.nativeElement.style = '';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    if (this.screenWidth < 768) {
      this.mobileScreen = true;
      this.scrollStepsValueChange = false;
    } else if(this.screenWidth > 768 && this.screenWidth < 1201) {
      this.mobileScreen = false;
      this.tabScreen = true;
      this.scrollStepsValueChange = true;
    } else{
      this.mobileScreen = false;
      this.tabScreen = false;
      this.scrollStepsValueChange = true;
    }

    if(this.screenWidth > 280 &&  this.screenWidth < 768){
      this.downPaymentInput = true;
    } else{
      this.downPaymentInput = false;
    }

    this.getApplicationBtnPosition();
  }

  @HostListener('window:load', ['$event'])
  onLoad(event: Event) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.getIdBreakPoint = this.idBreakPoint.nativeElement.getBoundingClientRect().bottom;
    if (this.screenWidth < 768) {
      this.tabsHeight = this.mobileTabs.nativeElement.getBoundingClientRect().top + 20;
      this.mobileScreen = true;
      this.scrollStepsValueChange = false;
      this.getDivHeight()
    } else if(this.screenWidth > 768 && this.screenWidth < 1201) {
      this.tabsHeight = this.mobileTabs.nativeElement.getBoundingClientRect().top + 45;
      this.mobileScreen = false;
      this.tabScreen = true;
      this.scrollStepsValueChange = true;
    } else {
      this.tabsHeight = this.mobileTabs.nativeElement.getBoundingClientRect().top + 45;
      this.mobileScreen = false;
      this.tabScreen = false;
      this.scrollStepsValueChange = true;
    }

    if(this.screenWidth > 280 && this.screenWidth < 768){
      this.downPaymentInput = true;
    } else{
      this.downPaymentInput = false;
    }

    this.getApplicationBtnPosition();

    this.userEmailStatus();

    // Remove the scroll bar width in rightSideBar

    const elements = document.getElementById('rightSideBar');
    const element = elements.children[0].children[0] as HTMLElement;
    element.setAttribute('style', '');
    (element.style as any).scrollbarWidth = 'none';
    element.style.overflow = 'overlay';
  }

  ngAfterViewInit() {
    this.allDivIds = this.mobileTabs.nativeElement.querySelectorAll('*[id]');
    this.stepTwoDivIds = this.myDiv.nativeElement.querySelectorAll('*[id]');
    this.stepThreeDivIds = this.myDiv2.nativeElement.querySelectorAll('*[id]');
    this.stepFourDivIds = this.myDiv3.nativeElement.querySelectorAll('*[id]');
  }

  triggerViewerShip(){
    this.http.get<any>(environment.apiBaseUrl+'trigger-viewer/'+this.application_id).subscribe();
  }
 
  triggerViewershipSwitch(prev: any, new_id: any){
    this.http.get<any>(environment.apiBaseUrl+'trigger-viewer-switch/'+prev+'/switch/'+new_id).subscribe();
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if(this.user){
      this.http.get<any>(environment.apiBaseUrl+'trigger-viewer-switch/'+this.application_id+'/switch/'+0).subscribe();
      if(this.application_id){
        $event.returnValue = false ; 
      } 
    } else if (!this.user){
      if (this.application){
        $event.returnValue = false ;
      }
    }
  }

  getUserStatus(viewer: any = null, status = false){
    this.userStatus = status;
    this.typeWriterTextServices.getUserStatus(this.userStatus);
    this.viewer = viewer;
    if(this.mobileScreen){
      this.mobileViewer.mobileScreen = this.mobileScreen;
      this.mobileViewer.userStatus = this.userStatus;
      this.mobileViewer.viewer = this.viewer;
    }
    if(!this.userStatus && this.user && this.user.role.name === 'applicant' && this.selectedInputName !== ''){
      this.disableHighlightScrolling(this.selectedInputName);
    }
  }
  
  stepTopReset(){
    console.log("first");
    this.mainBody.nativeElement.scrollTop = 2;
    this.myDiv0.nativeElement.scrollTop = 2;
    this.myDiv.nativeElement.scrollTop = 2;
    this.myDiv2.nativeElement.scrollTop = 2;
    this.myDiv3.nativeElement.scrollTop = 2;
  }

  getStepActive(value: string){
    if (value === 'stepOne'){
      if(!this.isOpen1){
        this.isOpen1 = true;
        this.isOpen2 = false;
        this.isOpen3 = false;
        this.isOpen4 = false;
        this.stepTopReset();
        if(!this.mobileScreen){
          this.myDiv.nativeElement.classList.remove("h-auto");
        }
      }
    } else if (value === 'stepTwo') {
      if(!this.isOpen2){
        this.isOpen1 = false;
        this.isOpen2 = true;
        this.isOpen3 = false;
        this.isOpen4 = false;
        this.stepTopReset();
        setTimeout(() => {
          if(!this.mobileScreen && this.isOpen2){
            this.myDiv.nativeElement.classList.add("h-auto");
            this.myDiv2.nativeElement.classList.remove("h-auto");
            this.myDiv3.nativeElement.classList.remove("h-auto");
          }
        }, 700);
      }
    } else if (value === 'stepThree') {
      if(!this.isOpen3){
        this.isOpen1 = false;
        this.isOpen2 = false;
        this.isOpen3 = true;
        this.isOpen4 = false;
        this.stepTopReset();
        if(!this.mobileScreen){
          this.myDiv.nativeElement.classList.remove("h-auto");
          this.myDiv2.nativeElement.classList.add("h-auto");
          this.myDiv3.nativeElement.classList.remove("h-auto");
        }
      }
    } else if (value === 'stepFour') {
      if(!this.isOpen4){
        this.isOpen1 = false;
        this.isOpen2 = false;
        this.isOpen3 = false;
        this.isOpen4 = true;
        this.stepTopReset();
        if(!this.mobileScreen){
          this.myDiv.nativeElement.classList.remove("h-auto");
          this.myDiv2.nativeElement.classList.remove("h-auto");
        }
      }
    }
  }

  scrollToDiv(stepId: string, divId: string, textId: string) {
    console.log("step "+stepId+" --- divId "+divId);
    if(stepId){
      const stepElement = document.getElementById(stepId).classList.contains('open');
    }
    if (this.mobileScreen) {
      if(this.checkStepId !== stepId){
        this.checkStepId = stepId;
        if(stepId !== 'stepFour'){
          this.scrollToHeight(stepId);
        } else if(stepId === 'stepFour'){
          const divElement = document.getElementById('resultStepFour');
          divElement.scrollIntoView({ behavior: 'smooth' });
          this.typeWriterTextServices.getStepName(stepId);
        }
        if(stepId === 'stepOne' || stepId === 'stepTwo' || stepId === 'stepThree'){
          this.typeWriterTextServices.getStepName(stepId);
        }
      }
      if(divId){
        const divElement = document.getElementById(divId);
        const divElementHeight = divElement.getBoundingClientRect();
        const isBefore = divElementHeight.top > 0;
        if(!this.setScrollableDiv){
          if(isBefore){
            divElement.scrollIntoView({ behavior: 'smooth' });
            this.setScrollableDiv = true
            if(this.selectedInputName !== ''){
              this.disableHighlightScrolling(this.selectedInputName);
            }
          }
        } else if(this.setScrollableDiv){
          divElement.scrollIntoView({ behavior: 'smooth' });
          if(this.selectedInputName !== ''){
            this.disableHighlightScrolling(this.selectedInputName);
          }
        }
      } else if(textId){
        const divElement = document.querySelector(`[textid="${textId}"]`);
        console.log("GET ELEMENTS", divElement);
        const divElementHeight = divElement.getBoundingClientRect();
        const isBefore = divElementHeight.top > 0;
        if(!this.setScrollableDiv){
          if(isBefore){
            divElement.scrollIntoView({ behavior: 'smooth' });
            this.setScrollableDiv = true
            if(this.selectedInputName !== ''){
              this.disableHighlightScrolling(this.selectedInputName);
            }
          }
        } else if(this.setScrollableDiv){
          divElement.scrollIntoView({ behavior: 'smooth' });
          if(this.selectedInputName !== ''){
            this.disableHighlightScrolling(this.selectedInputName);
          }
        }
      }
    } else {
      if(!this.mobileScreen){
        if(stepId && this.checkStepId !== stepId){
          this.checkStepId = stepId;
          this.getStepActive(stepId);
          this.sendStepValues()
        }
        if(divId){
          setTimeout(() => {
            const divElement = document.getElementById(divId);
            const divElementHeight = divElement.getBoundingClientRect();
            const isBefore = divElementHeight.top > 0;
            if(!this.setScrollableDiv){
              if(isBefore){
                divElement.scrollIntoView({ behavior: 'smooth' });
                this.setScrollableDiv = true
                if(this.selectedInputName !== ''){
                  this.disableHighlightScrolling(this.selectedInputName);
                }
              }
            } else if(this.setScrollableDiv){
              divElement.scrollIntoView({ behavior: 'smooth' });
              if(this.selectedInputName !== ''){
                this.disableHighlightScrolling(this.selectedInputName);
              }
            }
          }, 700);
        } else if(textId){
          setTimeout(() => {
            const divElement = document.querySelector(`[textid="${textId}"]`);
            const divElementHeight = divElement.getBoundingClientRect();
            const isBefore = divElementHeight.top > 0;
            if(!this.setScrollableDiv){
              if(isBefore){
                divElement.scrollIntoView({ behavior: 'smooth' });
                this.setScrollableDiv = true
                if(this.selectedInputName !== ''){
                  this.disableHighlightScrolling(this.selectedInputName);
                }
              }
            } else if(this.setScrollableDiv){
              divElement.scrollIntoView({ behavior: 'smooth' });
              if(this.selectedInputName !== ''){
                this.disableHighlightScrolling(this.selectedInputName);
              }
            }
          }, 700);
        }
      }   
    }
  }

  scrollToInput(stepId: string, divId: string, inputName: string) {
    console.log("step "+stepId+" --- divId "+divId);
    if(stepId){
      const stepElement = document.getElementById(stepId).classList.contains('open');
    }
    if(divId === 'paymentDetailOpen'){
      this.tableModal('open'); 
      this.getPaymentDetail();
    } else if(divId === 'paymentDetailClose'){
      this.tableModal('close'); 
      this.getPaymentDetail();
    } else if(divId === 'preApprovalOpen'){
      this.getPreApprovalModal('open'); 
    } else if(divId === 'preApprovalClose'){
      this.getPreApprovalModal('close'); 
    } else {
      if (this.mobileScreen) {
        if(this.checkStepId !== stepId){
          this.checkStepId = stepId;
          this.scrollToHeight(stepId);
        }
        if(divId){
          const divElement = document.getElementById(divId);
          divElement.scrollIntoView({ behavior: 'smooth' });
          this.setScrollableDiv = false;
          this.highlightInput(inputName);
        }
      } else {
        if(!this.mobileScreen){
          if(stepId && this.checkStepId !== stepId){
            this.checkStepId = stepId;
            this.getStepActive(stepId);
          }
          if(divId){
            setTimeout(() => {
              const divElement = document.getElementById(divId);
              divElement.scrollIntoView({ behavior: 'smooth' });
              this.setScrollableDiv = false;
              this.highlightInput(inputName);
            }, 700);
          }
        }   
      }
    }
  }

  disableHighlightScrolling(inputName: string){
    if(inputName){
      const selectedElement = document.querySelector('.' + inputName);
      if (selectedElement) {
          selectedElement.classList.remove('get-active');
          this.selectedInputName = '';
      }
    }
  }

  highlightInput(inputName: string){
    this.selectedInputName = inputName;
    const previouslyHighlighted = document.querySelector('.get-active');
    if (previouslyHighlighted) {
        previouslyHighlighted.classList.remove('get-active');
    }
    if(inputName){
      const selectedElement = document.querySelector('.' + inputName);
      if (selectedElement) {
          selectedElement.classList.add('get-active');
          this.selectedInputName = inputName;
      }
    }
  }
  
  bbModalOpen() {
    this.headerComponent.callBreadButter();
  }
  bbModalClose(newValue: boolean) {
    this.getBbModalValue = newValue;
  }

  // getActiveApplicant(index: number){
  //   const activeApplicant = this.application.applicants[index];
  //   if (activeApplicant.currentlyActive){
  //     this.editApplicantButton(activeApplicant);
  //   }
  // }

  // editApplicantButton(applicant: any) {
  //   applicant.buttonEdit = true;
  //   if (applicant.buttonEdit) {
  //     setTimeout(() => {
  //       const inputField = this.myDiv.nativeElement.querySelector('.c-btn-input');
  //       inputField.focus();
  //     }, 100);
  //   }
  // }

  // closeEditApplicantButton(applicant: any, index: number) {
  //   applicant.buttonEdit = false;
  //   this.selectApplicant(index);
  // }

  mortgageRateInfo(){
    this.mortgageRatePopup = !this.mortgageRatePopup;
  }

  getTriggerLogin() {
    this.childComponent.setUserLogin(this.userInfo.email, this.userInfo.password);
    this.specialistContactModal('close')
  }

  getTriggerRegister() {
    this.childComponent.setUserRegister(this.userInfo.username, this.userInfo.email, this.userInfo.password);
    this.specialistContactModal('close')
  }

  getTriggerEdit() {
    this.childComponent.setUserEdit(this.user.name, this.user.last_name, this.user.email, this.user.phone);
    this.specialistContactModal('close')
  }
  
  getNewApplicantData() {
    const emailPattern =  /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(this.applicantInfo.name !== '' || this.applicantInfo.last_name !== '' || this.applicantInfo.email !== '' || this.applicantInfo.phone !== '') {
      let unformatPhoneNumber = this.applicantInfo.phone.replace(/-/g, '');
      if(this.applicantInfo.name === ''){
        this.showError('Error', 'Enter the name');
      } else if(this.applicantInfo.email === '' && this.applicantInfo.phone === '') {
        this.showError('Error', 'Enter email / phone');
      } else if(unformatPhoneNumber.length < 10 && unformatPhoneNumber.length > 0){
        this.showError('Error', 'Incorrect Number');
      } else if(!emailPattern.test(this.applicantInfo.email)){
        this.showError('Error', 'Incorrect Email');
      } else  {
        this.loadSpinner = true;
        this.childComponent.getNewApplicantData(this.applicantInfo);
        this.newApplicantModel('close');
        this.NewApplicationApplicant = true;
      }
    } else {
      this.showError('Error', 'Enter applicant information');
    }
  }

  getTriggerNewPassword() {
    if(this.updatePassword.newPassword !== '' && this.updatePassword.newPasswordConfirm !== '') {
      if(this.updatePassword.newPassword === this.updatePassword.newPasswordConfirm){
        this.childComponent.setUserNewPassword(this.updatePassword.newPassword, this.updatePassword.newPasswordConfirm);
        this.specialistContactModal('close')
      } else{
        this.showError('Error', "Password Doesn't Match");
      }
    } else {
      this.showError('Error', 'Input Feild Required');
    }
  }

  openRegister() {
    this.registerActive = true;
    this.loginActive = false;
  }

  openLogin() {
    this.loginActive = true;
    this.registerActive = false;
  }
  openEditUser() {
    this.loginActive = false;
    this.registerActive = false;
    this.userEmailStatus();
  }

  toggleNewPassword(){
    this.setNewPassword = !this.setNewPassword;
  }

  // setLoggedInUpdater(value: boolean) {
  //   this.loggedInUpdaterValue = value;
  //   if (this.loggedInUpdaterValue) {
  //     this.specialistContactModal('close')
  //   }
  // }

  userEmailStatus() {
    if(this.user){
      if (this.user.email !== null){
        this.checkUserEmail = false;
      } else {
        this.checkUserEmail = true;
      }
    }
  }

  toggleDropdown(value: string){
    if(value === 'open'){
      this.applicantsDropdown = !this.applicantsDropdown;
    } else if(value === 'close') {
      this.applicantsDropdown = false;
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if (!this.mobApplicantsDropdown?.nativeElement.contains(event.target)) {
      this.applicantsDropdown = false
    }
  }

  resetApplication(value: boolean) {
    if(!value){
      this.application_id = null;
      this.outputData = {gds: 0, tds: 0, rate: 'proposed', house: 0, monthlyMortgageAmount: null, mortgageCapacity: null};
      this.setSubjectProperty = { active: true, propertyValue: 0, downPayment: 0, downPayment_type: 'dollar', estimated_value: null, inferred_equity: null, eto: null, insurance: 0, annualPropertyTax: 0, type: 'detached', monthlyCondoFee: 0, monthlyEnergyCost: 100, monthlyRentalIncome: 0, revenueOptions: 'none', rentalPercentage: 50, occupancy: 'owner', hyrdoCost: 0, managementExpense: 0, repairCosts: 0, generalExpense: 0, homeInsurance: 0, interestCharges: 0}
      this.setMortgage = {active: true, frequency: 'monthly', amortizationYears: 25, amortizationMonths: 0, mortgageRate:5.25, term: 5};
      this.selectScenario(0);
      this.receiveTheApplication(0);
    }
  }

  getInputfieldName(name: string){
    console.log("FIELD NAME", name);
    this.http.post<any>(environment.apiBaseUrl+'trigger-scroll/'+this.application_id, {div: null, activeStep: null, action: 'click', inputName: name}).subscribe();
  }

  getInputfield(name: string, selectedField: string){
    const blockedIds = ['stepOne', 'stepTwo', 'stepThree', 'stepFour', 'tab2'];
    if(name || selectedField){
      if(this.currentDivId !== name || this.currentFieldName !== selectedField){
        if(!this.mobileScreen && !blockedIds.includes(name)){
          this.currentDivId = name;
          console.log("<<<<<<<test>>>>>>>", this.currentDivId);
          if(this.user){
            if(this.currentDivId == 'stepOne' || this.currentDivId == 'stepTwo' || this.currentDivId == 'stepThree' || this.currentDivId == 'stepFour'){
              this.http.post<any>(environment.apiBaseUrl+'trigger-scroll/'+this.application_id, {div: null, activeStep: this.currentDivId, action: 'click', inputName: null}).subscribe();
            } else {
              this.http.post<any>(environment.apiBaseUrl+'trigger-scroll/'+this.application_id, {div: this.currentDivId, activeStep: null, action: 'click', inputName: selectedField}).subscribe();
            }
          }
        } else if(this.mobileScreen && name !== 'tab2' ){
          this.currentDivId = name;
          console.log("<<<<<<<test>>>>>>>", this.currentDivId);
          if(this.user){
            if(this.currentDivId == 'stepOne' || this.currentDivId == 'stepTwo' || this.currentDivId == 'stepThree' || this.currentDivId == 'stepFour'){
              this.http.post<any>(environment.apiBaseUrl+'trigger-scroll/'+this.application_id, {div: null, activeStep: this.currentDivId, action: 'click', inputName: null}).subscribe();
            } else {
              this.http.post<any>(environment.apiBaseUrl+'trigger-scroll/'+this.application_id, {div: this.currentDivId, activeStep: this.currentlyActiveStepId, action: 'click', inputName: selectedField}).subscribe();
            }
          }
        }
      }
    }
  }

  onChangeSubjectProperty(fieldName: string, newValue: any) {
    if (!newValue && fieldName !== 'downPayment') {
      this.setSubjectProperty[fieldName] = 0;
    } else if(newValue && fieldName === 'downPayment'){
      const currentValueAsString = this.setSubjectProperty[fieldName].toString();
      if (currentValueAsString.startsWith('0') && currentValueAsString.length > 1) {
        this.setSubjectProperty[fieldName] = parseFloat(currentValueAsString.substring(1));
      }
      if(this.setSubjectProperty[fieldName] > 100){
        this.downPaymentPercent = true;
      } else{
        this.downPaymentPercent = false;
      }
    } else {
      this.setSubjectProperty[fieldName] = newValue;
    }
  }

  onChangeMortgageProperty(newValue: any, property:any) {
    if (property) {
      if (!newValue) {
        property.propertyValue = 0;
      }
    }
  }

  headerScrollUp() {
    const getHeader = document.getElementById('header');
    const layoutContainer = document.getElementsByClassName("layout-container");
    const firstChild = getHeader.children[0] as HTMLElement;
    firstChild.style.cssText = 'height: 10px; padding:0.75rem 1.2rem';
    firstChild.style.transition = '0.5s ease-in-out';
    const getLayoutContainer = layoutContainer[0] as HTMLElement;
    getLayoutContainer.style.paddingTop = '24px';
    getLayoutContainer.style.transition = 'padding-top 0.3s ease-in-out';
  }
  
  headerScrollDown() {
    const getHeader = document.getElementById('header');
    const layoutContainer = document.getElementsByClassName("layout-container");
    const firstChild = getHeader.children[0] as HTMLElement;
    firstChild.style.cssText = '';
    firstChild.style.transition = '0.5s ease-in-out';
    const getLayoutContainer = layoutContainer[0] as HTMLElement;
    getLayoutContainer.style.paddingTop = '';
    getLayoutContainer.style.transition = 'padding-top 1s ease-in-out';
  }

  goToApplication(value: boolean){
    if(!value){
      this.headerComponent.goToApplication(value);
    }
  }

  includeCalculation(){
    const applicants = this.application.applicants
    for(let applicant of applicants){
      const liabilities = applicant.liabilities;
      for(let liability of liabilities){
        liability.active = Boolean(liability.active);
      }
    }
  }

  getPreApprovalBtnActive(valueName:string, value: any) {
    const linkValue = valueName;
    let getPreApprovalBtns = this.preApprovalBtns.nativeElement.children;
    for (let preApprovalBtn of getPreApprovalBtns) {
      const btnId = preApprovalBtn.children[0].id;
      const getBtn = preApprovalBtn.children[0];
      if (linkValue === btnId) {
        getBtn.classList.add("approval-btn-active");
        this.onChangeSubjectProperty('downPayment',value); 
        this.reRunCalculations(); 
        this.checkDownPaymentTwentyPercent(); 
        this.checkDownPaymentFivePercent(); 
        this.insuranceRerun();
        this.onFocusOut();
      } else {
        getBtn.classList.remove("approval-btn-active");
      }
    }
  }

  getPreApprovalData(){
    let preApproval = {
      downPaymentPercent: 0,
      mortgageIntrestRate: 0,
    }
    preApproval.downPaymentPercent = this.application.subjectproperty[this.activeScenario].downPayment;
    preApproval.mortgageIntrestRate = this.application.mortgage[this.activeMortgage].mortgageRate;
    const cloneDownPayment = cloneDeep(this.application.subjectproperty[this.activeScenario].downPayment);
    const setCloneDownPayment = parseFloat(cloneDownPayment.toString().replace(/\s/g, ''));
    const cloneMortgageRate = cloneDeep(preApproval.mortgageIntrestRate);
    const setCloneMortgageRate = parseFloat(cloneMortgageRate.toString().replace(/\s/g, ''));
    if((setCloneMortgageRate === 0 || preApproval.mortgageIntrestRate.toString() === '') && (setCloneDownPayment === 0 || preApproval.downPaymentPercent.toString() === '')) {
      this.showError('Error', 'Enter the data');
    } else if(setCloneDownPayment === 0 || preApproval.downPaymentPercent.toString() === '') {
      this.showError('Error', 'Select / Enter the down payment');
    } else if(setCloneMortgageRate === 0 || preApproval.mortgageIntrestRate.toString() === '') {
      this.showError('Error', 'Enter the mortgage intrest rate');
    } else if(setCloneMortgageRate > 100 || setCloneDownPayment > 100) {
      this.showError('Error', 'Enter the value between 1 - 100');
    } else{
      this.application.subjectproperty[this.activeScenario].downPayment_type = 'percent';
      this.getPaymentSwitch(this.application.subjectproperty[this.activeScenario].downPayment_type, true);
      this.getInputfield('preApprovalClose', '')
      this.getPreApprovalModal('close');
      this.toggleDiv4();

    }
  }

  stepFourWarning(event: any){
    if(this.user){
      const getEvent = event;
      setTimeout(()=>{
        this.warningMessage = getEvent;
      },100)
    }
  }

  loaderUpdater(event: boolean){
    if(!event){
      this.loadSpinner = event;
      this.applicantInfo = {
        name:'',
        last_name: '',
        email: '',
        phone: ''
      }
    }
  }

  generateCsvFile(value: string){
    if(this.csvOption){
      if(value === 'boss_application'){
        if(this.user.third_party_credential && this.user.third_party_credential.boss_username){
          this.checkBossCredentails = false;
          if(this.bossApplication.user.email){
            this.childComponent.sendApplicationToBoss(this.application.id);
            this.bossMailSection = false;
            this.csvGenerateModal('close')
          } else {
            this.bossMailSection = true;
          }
        } else {
          this.checkBossCredentails = true;
        }
      } else if(value === 'finmo_application'){
        if(this.user.third_party_credential && this.user.third_party_credential.finmo_username){
          this.checkFinmoCredentails = false;
          if(this.bossApplication.user.email){
            this.childComponent.sendApplicationToFinmo(this.application.id);
            this.bossMailSection = false;
            this.csvGenerateModal('close')
          } else {
            this.bossMailSection = true;
          }
        } else {
          this.checkFinmoCredentails = true;
        }
      } else {
        this.childComponent.generateCsvFile(value);
        this.csvGenerateModal('close')
      }
      this.csvOptionName = '';
      this.csvOption = false;
    } else{
      this.showError('Select option','To export select option.')
    }
  }

  changeThirdPartyAccount(value: string){
    if(value === 'boss_credentials' && !this.changeBossAccount){
      this.checkBossCredentails = true;
      this.checkFinmoCredentails = false;
      this.changeBossAccount = true;
    } else if(value === 'finmo_credentials' && !this.changeBossAccount){
      this.checkBossCredentails = false;
      this.checkFinmoCredentails = true;
      this.changeBossAccount = true;
    } else if(value === 'boss_credentials' && this.changeBossAccount){
      this.checkBossCredentails = false;
      this.changeBossAccount = false;
      this.checkBossAccount = false;
      this.getCsvStatus  = '';
      this.csvOptionName = '';
      this.csvOption = false;
    }
  }

  updateSelectedApplicantEmail(){
    if(this.bossApplication.user.email !== '' && this.bossApplication.user.email !== null){
      this.childComponent.updateSelectedApplicantEmail(this.bossApplication.user.email);
      this.bossMailSection = false;
    } else {
      this.showError('Email required','')
    }
  }

  sendThirdPartyCreds(){
    if(this.checkBossCredentails){
      this.thirdPartyCredentialsComponent.getCredentialStatus(this.getCsvStatus);
    } else if(this.checkFinmoCredentails){
      this.thirdPartyCredentialsComponent.getCredentialStatus(this.getCsvStatus);
    }
  }

  getUpdateBossRessponse(value: any){
    if(value){
      this.childComponent.checkLoggedIn();
      this.changeBossAccount = false;
      this.checkBossAccount = false;
      this.getCsvStatus  = '';
      this.csvOptionName = '';
      this.csvOption = false;
      setTimeout(()=>{
        this.checkBossCredentails = false;
        this.checkFinmoCredentails = false;
      },100)
    }
  }

  getCsvShareableLinks(valueName:string) {
    const linkValue = valueName;
    let optionName = ''
    if(linkValue === 'boss_application'){
      this.getCsvStatus = linkValue;
      optionName = "Mortgage Boss";
    } else if(linkValue === 'finmo_application'){
      this.getCsvStatus = linkValue;
      optionName = "Finmo";
    } else {
      optionName = linkValue;
    }
    let shareableLinks = this.csvShareableLinks.nativeElement.children;
    for (let shareableLink of shareableLinks) {
      const btnId = shareableLink.children[0].id;
      const getBtn = shareableLink.children[0];
      if (linkValue  === btnId) {
        getBtn.classList.add("csv-modal-icon-box-active");
        this.csvOptionName = optionName;
        this.csvOption = true;
        this.checkBossAccount = true;
      } else {
        getBtn.classList.remove("csv-modal-icon-box-active");
      }
    }
  }

  resetTypingTimer() {
    clearTimeout(this.typingTimeout);

    this.typingTimeout = setTimeout(() => {
      this.onFocusOut();
    }, 3000);
  }

}


  
