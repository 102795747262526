

<div class="row">
  <div class="col-4 m-auto p-0 flex-column business-info-font">
    <div class="d-flex">
      <a *ngIf="!setHeaderSize" class="c-pe-none profile-ml" href="#" (click)="navigateHome()"><img [src]="getBrokerProfile" [ngClass]="checkBrokerProfile ? 'c-img-thumbnail down-animation' : 'header-img'" alt="logo"/></a>
      <a *ngIf="setHeaderSize" class="c-pe-none profile-ml" href="#" (click)="navigateHome()"><img [src]="getBrokerProfile" [ngClass]="checkBrokerProfile ? 'c-img-thumbnail-mob down-animation' : 'header-img-mob'" alt="logo"/></a>
      <div *ngIf="loggedIn && businessInfoCheck" class="business-info d-flex flex-column p-0">
        <span *ngIf="businessInformation.name" class="text-position-mobile name-pos"><strong>{{ businessInformation.name }}</strong></span>
        <span *ngIf="businessInformation.phone" class="business-info text-position-mobile phone-pos" [ngClass]="businessInformation.name ? 'phone-pos' : 'name-pos'"><i class="fas fa-mobile-alt pr-1"  style="font-size: 12px"></i>{{ businessInformation.phone }}</span>
        <span *ngIf="businessInformation.email" class="business-info text-position-mobile" [ngClass]="businessInformation.phone ? 'email-pos' : 'phone-pos'"><i class="fas fa-envelope pr-1"  style="font-size: 12px"></i>{{ businessInformation.email }}</span>
        <span *ngIf="businessInformation.address" [ngStyle]="{
          'padding-left': (!businessInformation.email && !businessInformation.phone) || (businessInformation.email && !businessInformation.phone) || (!businessInformation.email && businessInformation.phone) ? '0.5rem' : ''
        }" class="text-position-mobile" [ngClass]="{
          'address-pos': businessInformation.email && businessInformation.phone,
          'phone-pos': !businessInformation.email && !businessInformation.phone,
          'email-pos': (businessInformation.email && !businessInformation.phone) || (!businessInformation.email && businessInformation.phone)
        }"><i class="fas fa-map-marker-alt pr-1"  style="font-size: 12px"></i>{{ businessInformation.address }}</span>
      </div>
    </div>
  </div>

  <div class="col-4 m-auto px-1">
    <!-- <p *ngIf="setMobileViewer.userStatus && setMobileViewer.mobileScreen && !setHeaderSize" class="m-auto py-1 user-status-mobile text-center down-animation" [ngClass]="{ 'down-animation': setMobileViewer.mobileScreen && setMobileViewer.userStatus, 'up-animation': !setMobileViewer.mobileScreen || !setMobileViewer.userStatus }"><span><i class="fa fa-solid fa-eye text-danger c-blink"></i><strong class=" pl-1">{{ setMobileViewer.viewer | slice:0:9  }}<span *ngIf="setMobileViewer.viewer.length >= 9">...</span></strong></span></p> -->
    <p *ngIf="setMobileViewer.userStatus && setMobileViewer.mobileScreen" class="m-auto py-1 text-center"><span><i class="fa fa-solid fa-eye text-danger c-blink"></i></span></p>
  </div>
  <div class="col-4 m-auto">
    <div class="d-flex float-right">
      <div class="language-switcher-pos">
        <!-- <img src="{{ currentFlag }}" class="language-switcher" style="right: 9rem; width: 2.6rem;"> -->
        <select *ngIf="!setMobileScreen" [(ngModel)]="currentTheme" (ngModelChange)="changeLanguage($event)" class="language-switcher" [ngStyle]="{'font-size': setHeaderSize ? '65%' : '100%'}" [ngClass]="{'transparent-bg': bbModalValue}">
            <option *ngFor="let theme of languages" [value]="theme.value"> 
              <!-- <img src="{{ theme.flag }}" width="16px"> &nbsp;&nbsp; -->
              {{ theme.name }}
            </option>
        </select> 
        <select *ngIf="setMobileScreen" [(ngModel)]="currentTheme" (ngModelChange)="changeLanguage($event)" class="language-switcher" [ngStyle]="{'font-size': setHeaderSize ? '65%' : '100%'}" [ngClass]="{'transparent-bg': bbModalValue}">
            <option *ngFor="let theme of languages" [value]="theme.value"> 
              <!-- <img src="{{ theme.flag }}" width="16px"> &nbsp;&nbsp; -->
              {{ theme.value }}
            </option>
        </select> 
      </div>
      <div class="py-2 my-auto only-mobile">
        <a (click)="toggleSidebar()" class="sidebar-toggle c-pointer c-header-bar"  [ngClass]="{'c-icon-size': !setHeaderSize, 'c-icon-size-sm': setHeaderSize}" id="menuIcon">
        <i class="fa fa-solid fa-bars"></i>
        </a>
      </div>
    </div>
  </div>
</div>
<div *ngIf="(!loggedIn || (loggedIn && (user && !user.email))) && showSocialLogin && scriptLoaded">
  <span class="bb-signin opacity-0" id="bb-signin-close" (click)="closeTab()" [ngClass]="{'opacity-1': closed }"><i class="fas fa-times"></i></span>
  <div id="bb-signin-widget" class="opacity-0 bb-pos position-fixed" [ngStyle]="{'z-index': closed ? '1100' : '-2'}" [ngClass]="{'opacity-1': closed }"></div>
</div>



<!-- <div class="header-container">
  <div class="logo-container">
    <div class="only-mobile">
    </div> -->
  <!-- <nb-actions size="small">

    <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions> -->
<!-- </div> -->
