import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeftSidebarComponent } from '../left-sidebar/left-sidebar.component';
import { RightSidebarComponent } from '../right-sidebar/right-sidebar.component';
import { MobileFooterComponent } from '../mobile-footer/mobile-footer.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';

import {
  FooterComponent,
  HeaderComponent,
  SearchInputComponent,
  TinyMCEComponent,
} from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
} from './pipes';
import {
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { DARK_THEME } from './styles/theme.dark';
import { StripeElementComponent } from './components/stripe-element/stripe-element.component';
import { NgxStripeModule } from 'ngx-stripe';
import { ThousandSeparatorDirective } from './directives/thousand-separator.directive';
import { EmbeddedCodeComponent } from '../left-sidebar/components/embedded-code/embedded-code.component';
import { UserUpdateComponent } from '../left-sidebar/components/user-update/user-update.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { BusinessInformationComponent } from '../left-sidebar/components/business-information/business-information.component';
import { FireWorkAnimationComponent } from './components/fire-work-animation/fire-work-animation.component';
import "./components/fire-work-animation/firework-prototype";
import { TypeWriterTextComponent } from './components/type-writer-text/type-writer-text.component';
import { TermsConditionsComponent } from './layouts/one-column/one-column-components/terms-conditions/terms-conditions.component';
import { PhoneNumberFormatterDirective } from './directives/phone-number-formatter/phone-number-formatter.directive';
import { ThirdPartyCredentialsComponent } from './layouts/one-column/one-column-components/third-party-credentials/third-party-credentials.component';


const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  FormsModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  TranslateModule,
  NgxStripeModule.forRoot('pk_test_51NiiQrLNlWfhOv2L3Qeg7GxbFqQEwL6NLuy8mTObbiP5WSsZVLHYnSHrGKdI4ju6tsNs5efW0q2GpIirkH5BtRe600ucdfTLKv'),
  ReactiveFormsModule,
  ImageCropperModule
  // TranslateService
];
const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  TinyMCEComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  LeftSidebarComponent,
  RightSidebarComponent,
  MobileFooterComponent,
  StripeElementComponent,
  ThousandSeparatorDirective,
  EmbeddedCodeComponent,
  UserUpdateComponent,
  BusinessInformationComponent,
  ToasterComponent,
  FireWorkAnimationComponent,
  TypeWriterTextComponent,
  TermsConditionsComponent,
  PhoneNumberFormatterDirective
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
];

@NgModule({
  imports: [CommonModule, ...NB_MODULES],
  exports: [CommonModule, ...PIPES, ...COMPONENTS],
  declarations: [...COMPONENTS, ...PIPES, ThirdPartyCredentialsComponent],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [ DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME ],
        ).providers,
      ],
    };
  }
}
