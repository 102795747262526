import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TypeWriterTextService {

  constructor() { }
  
  private stepDataSubject = new BehaviorSubject<any>({
    isOpen1: false,
    isOpen2: false,
    isOpen3: false,
    isOpen4: false,
  });

  private initialApplicationLoad: boolean;
  private activeScenarioText: boolean;

  private tabOpen = new BehaviorSubject<string>('defaultTabName');
  private userStatus = new BehaviorSubject<boolean>(false);
  private tempBlock = new BehaviorSubject<boolean>(false);
  private applicationChange = new BehaviorSubject<boolean>(false);
  private user = new BehaviorSubject<any>('');
  private getStep = new BehaviorSubject<string>('defaultStepName');
  private setLanguage = new BehaviorSubject<string>('defaultLanguageName');
  private setTextIncomingId = new BehaviorSubject<{ textId: string, stepId: string, getApplicant: number }>({ textId: 'defaultTextIncomingId', stepId: 'stepId', getApplicant: 0 });

  stepData$ = this.stepDataSubject.asObservable();

  tabName$: Observable<string> = this.tabOpen.asObservable();
  userStatus$: Observable<boolean> = this.userStatus.asObservable();
  tempBlock$: Observable<boolean> = this.tempBlock.asObservable();
  applicationChange$: Observable<boolean> = this.applicationChange.asObservable();
  user$: Observable<any> = this.user.asObservable();
  stepName$: Observable<string> = this.getStep.asObservable();
  setLanguage$: Observable<string> = this.setLanguage.asObservable();
  setTextIncomingId$: Observable<{ textId: string, stepId: string, getApplicant: number }> = this.setTextIncomingId.asObservable();

  getStepValue(data: any) {
    this.stepDataSubject.next(data);
  }
  
  getTabName(data: any) {
    this.tabOpen.next(data);
  }
  
  getUserStatus(data: any) {
    this.userStatus.next(data);
  }
  
  getTempBlock(data: any) {
    this.tempBlock.next(data);
  }
  
  getUser(data: any) {
    this.user.next(data);
  }
  
  getStepName(data: any) {
    this.getStep.next(data);
  }
  
  getLanguage(language: any) {
    this.setLanguage.next(language);
  }
  
  getApplicationChenge(change: any) {
    this.applicationChange.next(change);
  }

  gettextIncomingId(textId: string, stepId: string, getApplicant: number){
    this.setTextIncomingId.next({ textId, stepId, getApplicant });
  }

  setApplicationVariable(value: any): void {
    this.initialApplicationLoad = value;
  }
  getApplicationVariable(): any {
    return this.initialApplicationLoad;
  }
  
  setActiveScenarioText(value: any): void {
    this.activeScenarioText = value;
  }
  getActiveScenarioText(): any {
    return this.activeScenarioText;
  }
}



