<div [ngClass]="{'c-pe-none': (user?.role?.name === 'applicant' && userStatus) || tempBlock}">
    <p *ngIf="applicantActive" id="applicant" class="c-text pt-3"></p>
    <div #applicantsPara *ngIf="applicantsActive" class="c-text">
        <!-- <p *ngFor="let applicant of application.applicants; let i = index" [id]="'applicantsText_' + i" class=" pt-3"></p> -->
    </div>
    <p *ngIf="hasSubjectproperty" id="subjectproperty" class="c-text pt-3"></p>
    <p *ngIf="hasTotalMorgage" id="totalMorgage" class="c-text pt-3"></p>
</div>


