<div class="footer-main">
    <ngx-fire-work-animation *ngIf="fireworkStatus"></ngx-fire-work-animation>
    <div class="footer-content d-flex c-opacity-0">
        <div class="row">
            <div class="d-flex flex-column my-auto col-12">
                <span class="footer-heading-font font-9">{{ 'MORTGAGE_CAPACITY' | translate }}</span>
                <span class="footer-heading-font" style="font-size: 13px;">$ {{ mortgageCapacity | number:'1.2-2' }}</span>
            </div>
            <div class="d-flex flex-column my-auto col-12">
                <span class="footer-heading-font font-9">{{ 'MONTHLY_PAYMENTS' | translate }}</span>
                <span class="footer-heading-font" style="font-size: 13px;">$ {{ monthlyMortgageAmount | number:'1.2-2' }}</span>
            </div>
        </div> 
        <div class="row">
            <div class="d-flex col-12 c-height-fc">
                <div class="position-relative">
                    <div class="chart-per-inside font-9">{{ gds_simple }}%</div>
                    <div>
                        <canvas id="doughnutChart" width="30" height="30"></canvas>
                    </div>
                </div>
                <span class="c-text-font pl-2 my-auto font-9">{{ 'BORROWING_CAPACITY' | translate }}</span>
            </div> 
            <div class="d-flex col-12 c-height-fc">
                <div class="position-relative">
                    <div class="chart-per-inside font-9">{{ tds_simple }}%</div>
                    <div>
                        <canvas id="doughnutChartTwo" width="30" height="30"></canvas>
                    </div>
                </div>
                <span class="c-text-font pl-2 my-auto font-9">{{ 'BORROWING_CAPACITY' | translate }}</span>
            </div>
        </div>
    </div>
    <div class="footer-content w-100">

        <div class="d-flex w-100 position-absolute c-top-0" [ngClass]="graphTdsCloseFooter ? 'c-opacity-1' : 'c-opacity-0'">
            <div class="row">
                <div class="d-flex flex-column my-auto col-12">
                    <span class="footer-heading-font" *ngIf="application.type == 'preapproval'">{{ 'MORTGAGE_CAPACITY' | translate }}</span>
                    <span class="footer-heading-font" *ngIf="application.type == 'purchase' || application.type == 'refinance' || application.type == 'renewal'">{{ 'MORTGAGE_AMOUNT' | translate }}</span>
                    <span class="footer-heading-font" style="font-size: 13px;" [ngClass]="{'highlight': highlightMortgageCapacity}">$ {{ mortgageCapacity | number:'1.2-2' }}</span>
                </div>
                <div class="d-flex flex-column my-auto col-12">
                    <span class="footer-heading-font">{{ 'MONTHLY_PAYMENTS' | translate }}</span>
                    <span class="footer-heading-font" style="font-size: 13px;" [ngClass]="{'highlight': highlightMonthlyMortgageAmount}">$ {{ monthlyMortgageAmount | number:'1.2-2' }}</span>
                </div>
            </div> 
            <div class="row">
                <div class="d-flex col-12 c-height-fc">
                    <div class="position-relative my-auto">
                        <div class="chart-per-inside" [ngClass]="tds_simple_colorValue >= 100 ? 'c-color-red' : 'inside-color'">{{ tds_simple }}%</div>
                        <div>
                            <canvas id="doughnutChartTwoSimple" width="70" height="70"></canvas>
                        </div>
                    </div>
                    <span class="c-text-font pl-2 my-auto">{{ 'MORTGAGE_CAPACITY' | translate }}</span>
                </div>
            </div> 
        </div>

        <div class="d-flex w-100 position-absolute c-top-0" [ngClass]="graphGdsCloseFooter ? 'c-opacity-1' : 'c-opacity-0'">
            <div class="row">
                <div class="d-flex flex-column my-auto col-12">
                    <span class="footer-heading-font">{{ 'MORTGAGE_CAPACITY' | translate }}</span>
                    <span class="footer-heading-font" style="font-size: 13px;" [ngClass]="{'highlight': highlightMortgageCapacity}">$ {{ mortgageCapacity | number:'1.2-2' }}</span>
                </div>
                <div class="d-flex flex-column my-auto col-12">
                    <span class="footer-heading-font">{{ 'MONTHLY_PAYMENTS' | translate }}</span>
                    <span class="footer-heading-font" style="font-size: 13px;" [ngClass]="{'highlight': highlightMonthlyMortgageAmount}">$ {{ monthlyMortgageAmount | number:'1.2-2' }}</span>
                </div>
            </div> 
            <div class="row">
                <div class="d-flex col-12 c-height-fc">
                    <div class="position-relative my-auto">
                        <div class="chart-per-inside" [ngClass]="gds_simple_colorValue >= 100 ? 'c-color-red' : 'inside-color'">{{ gds_simple }}%</div>
                        <div>
                            <canvas id="doughnutChartSimple1" width="70" height="70"></canvas>
                        </div>
                    </div>
                    <span class="c-text-font pl-2 my-auto">{{ 'MORTGAGE_CAPACITY' | translate }}</span>
                </div> 
            </div> 
        </div>
        
    </div>

</div>

<div class="my-4">
    <img
    class="rounded mx-auto d-block w-50"
    src="assets/images/house_0.png"
    *ngIf="house === 1"
    alt="logo"
    style="width: 100%"
  />
  <img
    class="rounded mx-auto d-block w-50"
    src="assets/images/house_1.png"
    *ngIf="house === 2"
    alt="logo"
    style="width: 100%"
  />
  <img
    class="rounded mx-auto d-block w-50"
    src="assets/images/house_2.png"
    *ngIf="house === 3"
    alt="logo"
    style="width: 100%"
  />
  <img
    class="rounded mx-auto d-block w-50"
    src="assets/images/house_3.png"
    *ngIf="house === 4"
    alt="logo"
    style="width: 100%"
  />
  <img
    class="rounded mx-auto d-block w-50"
    src="assets/images/house_4.png"
    *ngIf="house === 5"
    alt="logo"
    style="width: 100%"
    />
</div>

<div class="row">
    <div class="d-flex col-6">
        <div class="position-relative">
            <div class="chart-per-inside" [ngClass]="gds_colorValue >= 39 ? 'c-color-red' : 'inside-color'">{{ gds }}%</div>
            <div>
                <canvas id="doughnutChartCopy" width="60" height="60"></canvas>
            </div>
        </div>
        <span class="c-text-font pl-2 my-auto">{{ 'PROPERTY_DEBT_RATIO' | translate }}</span>
    </div> 
    <div class="d-flex col-6">
        <div class="position-relative">
            <div class="chart-per-inside" [ngClass]="tds_colorValue >= 44 ? 'c-color-red' : 'inside-color'">{{ tds }}%</div>
            <div>
                <canvas id="doughnutChartTwoCopy" width="60" height="60"></canvas>
            </div>
        </div>
        <span class="c-text-font pl-2 my-auto">{{ 'TOTAL_DEBT_RATIO' | translate }}</span>
    </div>
</div>