import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThousandSeparatorService {

  constructor() { }

  convertValueToString(getApplication: any): any {
    const applicants = getApplication.applicants;
    const subjectProperties = getApplication.subjectproperty;
    const incomePattern =  /^\d{1,3}(\s\d{3})*(\.\d+)?$/;
    for (let applicant of applicants) {
        for (let income of applicant.incomes) {
          // Assuming the 'value' property is a number and needs formatting
          if (income && income.value != null) {
              income.value = income.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
          }
        }
        for (let revenue of applicant.revenues) {
          if (revenue && revenue.value != null) {
              revenue.value = revenue.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
          }
        }
        for (let liability of applicant.liabilities) {
          if(typeof liability.active === 'string'){
            liability.active = JSON.parse(liability.active.toLowerCase());
          } else {
            liability.active = liability.active;
          }
          if (liability && (liability.balance != null || liability.limit != null || liability.payment != null)) {
            if(liability.balance != null){
              liability.balance = liability.balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(liability.limit != null){
              liability.limit = liability.limit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(liability.payment != null){
              liability.payment = liability.payment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
          }
        }
        for (let property of applicant.properties) {
          if (property && (property.annualpropertytax != null || property.generalExpense != null || property.homeInsurance != null || property.propertyValue != null || property.hyrdoCost != null || property.interestCharges != null 
            || property.managementExpense != null || property.monthlycondofee != null || property.monthlyenergycost != null || property.payment != null || property.rentalincome != null || property.repairCosts != null)) {
              if(property.annualpropertytax != null){
                property.annualpropertytax = property.annualpropertytax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
              }
              if(property.generalExpense != null){
                property.generalExpense = property.generalExpense.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
              }
              if(property.homeInsurance != null){
                property.homeInsurance = property.homeInsurance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
              }
              if(property.propertyValue != null){
                property.propertyValue = property.propertyValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
              }
              if(property.hyrdoCost != null){
                property.hyrdoCost = property.hyrdoCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
              }
              if(property.interestCharges != null){
                property.interestCharges = property.interestCharges.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
              }
              if(property.managementExpense != null){
                property.managementExpense = property.managementExpense.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
              }
              if(property.monthlycondofee != null){
                property.monthlycondofee = property.monthlycondofee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
              }
              if(property.monthlyenergycost != null){
                property.monthlyenergycost = property.monthlyenergycost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
              }
              if(property.payment != null){
                property.payment = property.payment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
              }
              if(property.rentalincome != null){
                property.rentalincome = property.rentalincome.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
              }
              if(property.repairCosts != null){
                property.repairCosts = property.repairCosts.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
              }
          }
        }
    }
    for(let subjectProperty of subjectProperties){
      if (subjectProperty && (subjectProperty.annualPropertyTax != null || subjectProperty.downPayment != null || subjectProperty.generalExpense != null || subjectProperty.homeInsurance != null
        || subjectProperty.hyrdoCost != null || subjectProperty.insurance != null || subjectProperty.interestCharges != null || subjectProperty.managementExpense != null || subjectProperty.monthlyCondoFee != null 
        || subjectProperty.monthlyEnergyCost != null || subjectProperty.monthlyRentalIncome != null || subjectProperty.propertyValue != null || subjectProperty.repairCosts != null || subjectProperty.estimated_value != null
        || subjectProperty.eto != null || subjectProperty.inferred_equity != null)) {

        if(subjectProperty.annualPropertyTax != null){
          subjectProperty.annualPropertyTax = subjectProperty.annualPropertyTax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        }
        if(subjectProperty.downPayment != null){
          if(subjectProperty.downPayment_type === 'dollar'){
            subjectProperty.downPayment = subjectProperty.downPayment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
          } else {
            subjectProperty.downPayment = subjectProperty.downPayment.toString().replace(/\s/g, '');
          }
        }
        if(subjectProperty.generalExpense != null){
          subjectProperty.generalExpense = subjectProperty.generalExpense.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        }
        if(subjectProperty.homeInsurance != null){
          subjectProperty.homeInsurance = subjectProperty.homeInsurance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        }
        if(subjectProperty.hyrdoCost != null){
          subjectProperty.hyrdoCost = subjectProperty.hyrdoCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        }
        if(subjectProperty.insurance != null){
          subjectProperty.insurance = subjectProperty.insurance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        }
        if(subjectProperty.interestCharges != null){
          subjectProperty.interestCharges = subjectProperty.interestCharges.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        }
        if(subjectProperty.managementExpense != null){
          subjectProperty.managementExpense = subjectProperty.managementExpense.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        }
        if(subjectProperty.monthlyCondoFee != null){
          subjectProperty.monthlyCondoFee = subjectProperty.monthlyCondoFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        }
        if(subjectProperty.monthlyEnergyCost != null){
          subjectProperty.monthlyEnergyCost = subjectProperty.monthlyEnergyCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        }
        if(subjectProperty.monthlyRentalIncome != null){
          subjectProperty.monthlyRentalIncome = subjectProperty.monthlyRentalIncome.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        }
        if(subjectProperty.propertyValue != null){
          subjectProperty.propertyValue = subjectProperty.propertyValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        }
        if(subjectProperty.repairCosts != null){
          subjectProperty.repairCosts = subjectProperty.repairCosts.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        }
        if(subjectProperty.estimated_value != null){
          subjectProperty.estimated_value = subjectProperty.estimated_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        }
        if(subjectProperty.eto != null){
          subjectProperty.eto = subjectProperty.eto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        }
        if(subjectProperty.inferred_equity != null){
          subjectProperty.inferred_equity = subjectProperty.inferred_equity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        }
      }
    }
 
    return getApplication;
  }

  unFormattedVulues(getApplication: any){
    const application = getApplication
    const applicants = application.applicants;
    const subjectProperties = application.subjectproperty;
    const incomePattern =  /^\d{1,3}(\s\d{3})*(\.\d+)?$/;
    for(let applicant of applicants){
      for(let income of applicant.incomes){
        if (income && income.value != null) {
          if(!incomePattern.test(income.value)){
            if(income.value != null){
            income.value = income.value.replace(/\s/g, '');
            income.value = income.value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
          } 
          if (incomePattern.test(income.value)) {
            if(income.value != null){
              income.value = parseFloat(income.value.toString().replace(/\s/g, ''));
              if(isNaN(income.value)){
                income.value = 0;
              } else{
                income.value =  income.value;
              }
            }
          }
        }
      }
      for(let revenue of applicant.revenues){
        if (revenue && revenue.value != null) {
          if(!incomePattern.test(revenue.value)){
            if(revenue.value != null){
            revenue.value = revenue.value.replace(/\s/g, '');
            revenue.value = revenue.value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
          } 
          if (incomePattern.test(revenue.value))  {
            if(revenue.value != null){
              revenue.value = parseFloat(revenue.value.toString().replace(/\s/g, ''));
              if(isNaN(revenue.value)){
                revenue.value = 0;
              } else{
                revenue.value =  revenue.value;
              }
            }
          }
        }
      }
      for(let liability of applicant.liabilities){
        if(typeof liability.active === 'string'){
          liability.active = JSON.parse(liability.active.toLowerCase());
        } else {
          liability.active = liability.active;
        }
        if (liability && (liability.balance != null || liability.limit != null || liability.payment != null)) {
          if(!incomePattern.test(liability.balance) || !incomePattern.test(liability.limit) || !incomePattern.test(liability.payment)){
            if(liability.balance != null){
              liability.balance = liability.balance.replace(/\s/g, '');
              liability.balance = liability.balance.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(liability.limit != null){
              liability.limit = liability.limit.replace(/\s/g, '');
              liability.limit = liability.limit.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(liability.payment != null){
              liability.payment = liability.payment.replace(/\s/g, '');
              liability.payment = liability.payment.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
          } 
          if (incomePattern.test(liability.balance) || incomePattern.test(liability.limit) || incomePattern.test(liability.payment))  {
            if(liability.balance != null){
              liability.balance = parseFloat(liability.balance.toString().replace(/\s/g, ''));
              if(isNaN(liability.balance)){
                liability.balance = 0;
              } else{
                liability.balance = liability.balance;
              }
            }
            if(liability.limit != null){
              liability.limit = parseFloat(liability.limit.toString().replace(/\s/g, ''));
              if(isNaN(liability.limit)){
                liability.limit = 0;
              } else{
                liability.limit = liability.limit;
              }
            }
            if(liability.payment != null){
              liability.payment = parseFloat(liability.payment.toString().replace(/\s/g, ''));
              if(isNaN(liability.payment)){
                liability.payment = 0;
              } else{
                liability.payment = liability.payment;
              }
            }
            console.log("liability=>>>>>", liability.balance, "2", liability.limit, "3", liability.payment);
          }
        }
      }
      for(let property of applicant.properties){
        if (property && (property.annualpropertytax != null || property.generalExpense != null || property.homeInsurance != null  || property.propertyValue != null || property.hyrdoCost != null || property.interestCharges != null 
          || property.managementExpense != null || property.monthlycondofee != null || property.monthlyenergycost != null || property.payment != null || property.rentalincome != null || property.repairCosts != null)) {
          if(!incomePattern.test(property.annualpropertytax) || !incomePattern.test(property.generalExpense) || !incomePattern.test(property.homeInsurance) || !incomePattern.test(property.propertyValue) || !incomePattern.test(property.hyrdoCost)
          || !incomePattern.test(property.interestCharges) || !incomePattern.test(property.managementExpense) || !incomePattern.test(property.monthlycondofee) || !incomePattern.test(property.monthlyenergycost)
          || !incomePattern.test(property.payment) || !incomePattern.test(property.rentalincome) || !incomePattern.test(property.repairCosts)){
            if(property.annualpropertytax != null){
              property.annualpropertytax = property.annualpropertytax.replace(/\s/g, '');
              property.annualpropertytax = property.annualpropertytax.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(property.generalExpense != null){
              property.generalExpense = property.generalExpense.replace(/\s/g, '');
              property.generalExpense = property.generalExpense.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(property.homeInsurance != null){
              property.homeInsurance = property.homeInsurance.replace(/\s/g, '');
              property.homeInsurance = property.homeInsurance.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(property.propertyValue != null){
              property.propertyValue = property.propertyValue.replace(/\s/g, '');
              property.propertyValue = property.propertyValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(property.hyrdoCost != null){
              property.hyrdoCost = property.hyrdoCost.replace(/\s/g, '');
              property.hyrdoCost = property.hyrdoCost.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(property.interestCharges != null){
              property.interestCharges = property.interestCharges.replace(/\s/g, '');
              property.interestCharges = property.interestCharges.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(property.managementExpense != null){
              property.managementExpense = property.managementExpense.replace(/\s/g, '');
              property.managementExpense = property.managementExpense.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(property.monthlycondofee != null){
              property.monthlycondofee = property.monthlycondofee.replace(/\s/g, '');
              property.monthlycondofee = property.monthlycondofee.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(property.monthlyenergycost != null){
              property.monthlyenergycost = property.monthlyenergycost.replace(/\s/g, '');
              property.monthlyenergycost = property.monthlyenergycost.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(property.payment != null){
              property.payment = property.payment.replace(/\s/g, '');
              property.payment = property.payment.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(property.rentalincome != null){
              property.rentalincome = property.rentalincome.replace(/\s/g, '');
              property.rentalincome = property.rentalincome.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(property.repairCosts != null){
              property.repairCosts = property.repairCosts.replace(/\s/g, '');
              property.repairCosts = property.repairCosts.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
          } 
          if (incomePattern.test(property.annualpropertytax) || incomePattern.test(property.generalExpense) || incomePattern.test(property.homeInsurance)  || incomePattern.test(property.propertyValue) || incomePattern.test(property.hyrdoCost)
          || incomePattern.test(property.interestCharges) || incomePattern.test(property.managementExpense) || incomePattern.test(property.monthlycondofee) || incomePattern.test(property.monthlyenergycost)
          || incomePattern.test(property.payment) || incomePattern.test(property.rentalincome) || incomePattern.test(property.repairCosts)) {
            if(property.annualpropertytax != null){
              property.annualpropertytax = parseFloat(property.annualpropertytax.toString().replace(/\s/g, ''));
              if(isNaN(property.annualpropertytax)){
                property.annualpropertytax = 0;
              } else{
                property.annualpropertytax = property.annualpropertytax;
              }
            }
            if(property.generalExpense != null){
              property.generalExpense = parseFloat(property.generalExpense.toString().replace(/\s/g, ''));
              if(isNaN(property.annualpropertytax)){
                property.annualpropertytax = 0;
              } else{
                property.annualpropertytax = property.annualpropertytax;
              }
            }
            if(property.homeInsurance != null){
              property.homeInsurance = parseFloat(property.homeInsurance.toString().replace(/\s/g, ''));
              if(isNaN(property.generalExpense)){
                property.generalExpense = 0;
              } else{
                property.generalExpense = property.generalExpense;
              }
            }
            if(property.propertyValue != null){
              property.propertyValue = parseFloat(property.propertyValue.toString().replace(/\s/g, ''));
              if(isNaN(property.generalExpense)){
                property.generalExpense = 0;
              } else{
                property.generalExpense = property.generalExpense;
              }
            }
            if(property.hyrdoCost != null){
              property.hyrdoCost = parseFloat(property.hyrdoCost.toString().replace(/\s/g, ''));
              if(isNaN(property.hyrdoCost)){
                property.hyrdoCost = 0;
              } else{
                property.hyrdoCost = property.hyrdoCost;
              }
            }
            if(property.interestCharges != null){
              property.interestCharges = parseFloat(property.interestCharges.toString().replace(/\s/g, ''));
              if(isNaN(property.interestCharges)){
                property.interestCharges = 0;
              } else{
                property.interestCharges = property.interestCharges;
              }
            }
            if(property.managementExpense != null){
              property.managementExpense = parseFloat(property.managementExpense.toString().replace(/\s/g, ''));
              if(isNaN(property.managementExpense)){
                property.managementExpense = 0;
              } else{
                property.managementExpense = property.managementExpense;
              }
            }
            if(property.monthlycondofee != null){
              property.monthlycondofee = parseFloat(property.monthlycondofee.toString().replace(/\s/g, ''));
              if(isNaN(property.monthlycondofee)){
                property.monthlycondofee = 0;
              } else{
                property.monthlycondofee = property.monthlycondofee;
              }
            }
            if(property.monthlyenergycost != null){
              property.monthlyenergycost = parseFloat(property.monthlyenergycost.toString().replace(/\s/g, ''));
              if(isNaN(property.monthlyenergycost)){
                property.monthlyenergycost = 0;
              } else{
                property.monthlyenergycost = property.monthlyenergycost;
              }
            }
            if(property.payment != null){
              property.payment = parseFloat(property.payment.toString().replace(/\s/g, ''));
              if(isNaN(property.payment)){
                property.payment = 0;
              } else{
                property.payment = property.payment;
              }
            }
            if(property.rentalincome != null){
              property.rentalincome = parseFloat(property.rentalincome.toString().replace(/\s/g, ''));
              if(isNaN(property.rentalincome)){
                property.rentalincome = 0;
              } else{
                property.rentalincome = property.rentalincome;
              }
            }
            if(property.repairCosts != null){
              property.repairCosts = parseFloat(property.repairCosts.toString().replace(/\s/g, ''));
              if(isNaN(property.repairCosts)){
                property.repairCosts = 0;
              } else{
                property.repairCosts = property.repairCosts;
              }
            }
          }
        }
      }
    }
    for(let subjectProperty of subjectProperties){
      if (subjectProperty && (subjectProperty.annualPropertyTax != null || subjectProperty.downPayment != null || subjectProperty.generalExpense != null || subjectProperty.homeInsurance != null
        || subjectProperty.hyrdoCost != null || subjectProperty.insurance != null || subjectProperty.interestCharges != null || subjectProperty.managementExpense != null || subjectProperty.monthlyCondoFee != null 
        || subjectProperty.monthlyEnergyCost != null || subjectProperty.monthlyRentalIncome != null || subjectProperty.propertyValue != null || subjectProperty.repairCosts != null || subjectProperty.estimated_value != null
        || subjectProperty.eto != null || subjectProperty.inferred_equity != null)) {
          if(!incomePattern.test(subjectProperty.annualPropertyTax) || !incomePattern.test(subjectProperty.downPayment) || !incomePattern.test(subjectProperty.generalExpense) || !incomePattern.test(subjectProperty.homeInsurance) 
            || !incomePattern.test(subjectProperty.hyrdoCost) || !incomePattern.test(subjectProperty.insurance) || !incomePattern.test(subjectProperty.interestChargese) || !incomePattern.test(subjectProperty.managementExpense) || !incomePattern.test(subjectProperty.monthlyCondoFee) 
            || !incomePattern.test(subjectProperty.monthlyEnergyCost) || !incomePattern.test(subjectProperty.monthlyRentalIncome) || !incomePattern.test(subjectProperty.propertyValue) || !incomePattern.test(subjectProperty.repairCosts) || !incomePattern.test(subjectProperty.estimated_value)
            || !incomePattern.test(subjectProperty.eto) || !incomePattern.test(subjectProperty.inferred_equity)){
            if(subjectProperty.annualPropertyTax != null){
              subjectProperty.annualPropertyTax = subjectProperty.annualPropertyTax.toString().replace(/\s/g, '');
              subjectProperty.annualPropertyTax = subjectProperty.annualPropertyTax.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(subjectProperty.downPayment != null){
              if(subjectProperty.downPayment_type === 'dollar'){
                subjectProperty.downPayment = subjectProperty.downPayment.toString().replace(/\s/g, '');
                subjectProperty.downPayment = subjectProperty.downPayment.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
              } else {
                subjectProperty.downPayment = subjectProperty.downPayment.toString().replace(/\s/g, '');
              }
            }
            if(subjectProperty.generalExpense != null){
              subjectProperty.generalExpense = subjectProperty.generalExpense.toString().replace(/\s/g, '');
              subjectProperty.generalExpense = subjectProperty.generalExpense.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(subjectProperty.homeInsurance != null){
              subjectProperty.homeInsurance = subjectProperty.homeInsurance.toString().replace(/\s/g, '');
              subjectProperty.homeInsurance = subjectProperty.homeInsurance.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(subjectProperty.hyrdoCost != null){
              subjectProperty.hyrdoCost = subjectProperty.hyrdoCost.toString().replace(/\s/g, '');
              subjectProperty.hyrdoCost = subjectProperty.hyrdoCost.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(subjectProperty.insurance != null){
              subjectProperty.insurance = subjectProperty.insurance.toString().replace(/\s/g, '');
              subjectProperty.insurance = subjectProperty.insurance.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(subjectProperty.interestCharges != null){
              subjectProperty.interestCharges = subjectProperty.interestCharges.toString().replace(/\s/g, '');
              subjectProperty.interestCharges = subjectProperty.interestCharges.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(subjectProperty.managementExpense != null){
              subjectProperty.managementExpense = subjectProperty.managementExpense.toString().replace(/\s/g, '');
              subjectProperty.managementExpense = subjectProperty.managementExpense.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(subjectProperty.monthlyCondoFee != null){
              subjectProperty.monthlyCondoFee = subjectProperty.monthlyCondoFee.toString().replace(/\s/g, '');
              subjectProperty.monthlyCondoFee = subjectProperty.monthlyCondoFee.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(subjectProperty.monthlyEnergyCost != null){
              subjectProperty.monthlyEnergyCost = subjectProperty.monthlyEnergyCost.toString().replace(/\s/g, '');
              subjectProperty.monthlyEnergyCost = subjectProperty.monthlyEnergyCost.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(subjectProperty.monthlyRentalIncome != null){
              subjectProperty.monthlyRentalIncome = subjectProperty.monthlyRentalIncome.toString().replace(/\s/g, '');
              subjectProperty.monthlyRentalIncome = subjectProperty.monthlyRentalIncome.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(subjectProperty.propertyValue != null){
              subjectProperty.propertyValue = subjectProperty.propertyValue.toString().replace(/\s/g, '');
              subjectProperty.propertyValue = subjectProperty.propertyValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(subjectProperty.repairCosts != null){
              subjectProperty.repairCosts = subjectProperty.repairCosts.toString().replace(/\s/g, '');
              subjectProperty.repairCosts = subjectProperty.repairCosts.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(subjectProperty.estimated_value != null){
              subjectProperty.estimated_value = subjectProperty.estimated_value.toString().replace(/\s/g, '');
              subjectProperty.estimated_value = subjectProperty.estimated_value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(subjectProperty.eto != null){
              subjectProperty.eto = subjectProperty.eto.toString().replace(/\s/g, '');
              subjectProperty.eto = subjectProperty.eto.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            if(subjectProperty.inferred_equity != null){
              subjectProperty.inferred_equity = subjectProperty.inferred_equity.toString().replace(/\s/g, '');
              subjectProperty.inferred_equity = subjectProperty.inferred_equity.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
          }
        if(incomePattern.test(subjectProperty.annualPropertyTax) || incomePattern.test(subjectProperty.downPayment) || incomePattern.test(subjectProperty.generalExpense) || incomePattern.test(subjectProperty.homeInsurance) || incomePattern.test(subjectProperty.hyrdoCost) 
          || incomePattern.test(subjectProperty.insurance) || incomePattern.test(subjectProperty.interestChargese) || incomePattern.test(subjectProperty.managementExpense) || incomePattern.test(subjectProperty.monthlyCondoFee) || incomePattern.test(subjectProperty.monthlyEnergyCost) 
          || incomePattern.test(subjectProperty.monthlyRentalIncome) || incomePattern.test(subjectProperty.propertyValue) || incomePattern.test(subjectProperty.repairCosts) || !incomePattern.test(subjectProperty.estimated_value)
          || !incomePattern.test(subjectProperty.eto) || !incomePattern.test(subjectProperty.inferred_equity)){
          if(subjectProperty.annualPropertyTax != null){
            subjectProperty.annualPropertyTax = parseFloat(subjectProperty.annualPropertyTax.toString().replace(/\s/g, ''));
            if(isNaN(subjectProperty.annualPropertyTax)){
              subjectProperty.annualPropertyTax = 0;
            } else{
              subjectProperty.annualPropertyTax = subjectProperty.annualPropertyTax;
            }
          }
          if(subjectProperty.downPayment != null){
            subjectProperty.downPayment = parseFloat(subjectProperty.downPayment.toString().replace(/\s/g, ''));
            if(isNaN(subjectProperty.downPayment)){
              subjectProperty.downPayment = 0;
            } else{
              subjectProperty.downPayment = subjectProperty.downPayment;
            }
          }
          if(subjectProperty.generalExpense != null){
            subjectProperty.generalExpense = parseFloat(subjectProperty.generalExpense.toString().replace(/\s/g, ''));
            if(isNaN(subjectProperty.generalExpense)){
              subjectProperty.generalExpense = 0;
            } else{
              subjectProperty.generalExpense = subjectProperty.generalExpense;
            }
          }
          if(subjectProperty.homeInsurance != null){
            subjectProperty.homeInsurance = parseFloat(subjectProperty.homeInsurance.toString().replace(/\s/g, ''));
            if(isNaN(subjectProperty.homeInsurance)){
              subjectProperty.homeInsurance = 0;
            } else{
              subjectProperty.homeInsurance = subjectProperty.homeInsurance;
            }
          }
          if(subjectProperty.hyrdoCost != null){
            subjectProperty.hyrdoCost = parseFloat(subjectProperty.hyrdoCost.toString().replace(/\s/g, ''));
            if(isNaN(subjectProperty.hyrdoCost)){
              subjectProperty.hyrdoCost = 0;
            } else{
              subjectProperty.hyrdoCost = subjectProperty.hyrdoCost;
            }
          }
          if(subjectProperty.insurance != null){
            subjectProperty.insurance = parseFloat(subjectProperty.insurance.toString().replace(/\s/g, ''));
            if(isNaN(subjectProperty.insurance)){
              subjectProperty.insurance = 0;
            } else{
              subjectProperty.insurance = subjectProperty.insurance;
            }
          }
          if(subjectProperty.interestCharges != null){
            subjectProperty.interestCharges = parseFloat(subjectProperty.interestCharges.toString().replace(/\s/g, ''));
            if(isNaN(subjectProperty.interestCharges)){
              subjectProperty.interestCharges = 0;
            } else{
              subjectProperty.interestCharges = subjectProperty.interestCharges;
            }
          }
          if(subjectProperty.managementExpense != null){
            subjectProperty.managementExpense = parseFloat(subjectProperty.managementExpense.toString().replace(/\s/g, ''));
            if(isNaN(subjectProperty.annualPropertyTax)){
              subjectProperty.managementExpense = 0;
            } else{
              subjectProperty.managementExpense = subjectProperty.managementExpense;
            }
          }
          if(subjectProperty.monthlyCondoFee != null){
            subjectProperty.monthlyCondoFee = parseFloat(subjectProperty.monthlyCondoFee.toString().replace(/\s/g, ''));
            if(isNaN(subjectProperty.monthlyCondoFee)){
              subjectProperty.monthlyCondoFee = 0;
            } else{
              subjectProperty.monthlyCondoFee = subjectProperty.monthlyCondoFee;
            }
          }
          if(subjectProperty.monthlyEnergyCost != null){
            subjectProperty.monthlyEnergyCost = parseFloat(subjectProperty.monthlyEnergyCost.toString().replace(/\s/g, ''));
            if(isNaN(subjectProperty.monthlyEnergyCost)){
              subjectProperty.monthlyEnergyCost = 0;
            } else{
              subjectProperty.monthlyEnergyCost = subjectProperty.monthlyEnergyCost;
            }
          }
          if(subjectProperty.monthlyRentalIncome != null){
            subjectProperty.monthlyRentalIncome = parseFloat(subjectProperty.monthlyRentalIncome.toString().replace(/\s/g, ''));
            if(isNaN(subjectProperty.monthlyRentalIncome)){
              subjectProperty.monthlyRentalIncome = 0;
            } else{
              subjectProperty.monthlyRentalIncome = subjectProperty.monthlyRentalIncome;
            }
          }
          if(subjectProperty.propertyValue != null){
            subjectProperty.propertyValue = parseFloat(subjectProperty.propertyValue.toString().replace(/\s/g, ''));
            if(isNaN(subjectProperty.propertyValue)){
              subjectProperty.propertyValue = 0;
            } else{
              subjectProperty.propertyValue = subjectProperty.propertyValue;
            }
          }
          if(subjectProperty.repairCosts != null){
            subjectProperty.repairCosts = parseFloat(subjectProperty.repairCosts.toString().replace(/\s/g, ''));
            if(isNaN(subjectProperty.repairCosts)){
              subjectProperty.repairCosts = 0;
            } else{
              subjectProperty.repairCosts = subjectProperty.repairCosts;
            }
          }
          if(subjectProperty.estimated_value != null){
            subjectProperty.estimated_value = parseFloat(subjectProperty.estimated_value.toString().replace(/\s/g, ''));
            if(isNaN(subjectProperty.estimated_value)){
              subjectProperty.estimated_value = 0;
            } else{
              subjectProperty.estimated_value = subjectProperty.estimated_value;
            }
          }
          if(subjectProperty.eto != null){
            subjectProperty.eto = parseFloat(subjectProperty.eto.toString().replace(/\s/g, ''));
            if(isNaN(subjectProperty.eto)){
              subjectProperty.eto = 0;
            } else{
              subjectProperty.eto = subjectProperty.eto;
            }
          }
          if(subjectProperty.inferred_equity != null){
            subjectProperty.inferred_equity = parseFloat(subjectProperty.inferred_equity.toString().replace(/\s/g, ''));
            if(isNaN(subjectProperty.inferred_equity)){
              subjectProperty.inferred_equity = 0;
            } else{
              subjectProperty.inferred_equity = subjectProperty.inferred_equity;
            }
          }
        }
      }
    }
    
    return getApplication;
  }
  
  unFormattedVuluesUpdate(getApplication: any){
    const application = getApplication
    const applicants = application.applicants;
    const subjectProperties = application.subjectproperty;
    const incomePattern = /^\d{1,3}(\s\d{3})*(\.\d+)?$/;
    for(let applicant of applicants){
      for(let income of applicant.incomes){
        if (income && income.value != null) {
          if(incomePattern.test(income.value)){
            income.value = parseFloat(income.value.toString().replace(/\s/g, ''));
          }
        }
      }
      for(let revenue of applicant.revenues){
        if (revenue && revenue.value != null) {
          if(incomePattern.test(revenue.value)){
            revenue.value = parseFloat(revenue.value.toString().replace(/\s/g, ''));
          }
        }
      }
      for(let liability of applicant.liabilities){
        if(typeof liability.active === 'string'){
          liability.active = JSON.parse(liability.active.toLowerCase());
        } else {
          liability.active = liability.active;
        }
        if (liability && (liability.balance != null || liability.limit != null || liability.payment != null)) {
          if(incomePattern.test(liability.balance) || incomePattern.test(liability.limit) || incomePattern.test(liability.payment)){
            if(liability.balance != null){
              liability.balance = parseFloat(liability.balance.toString().replace(/\s/g, ''));
            }
            if(liability.limit != null){
              liability.limit = parseFloat(liability.limit.toString().replace(/\s/g, ''));
            }
            if(liability.payment != null){
              liability.payment = parseFloat(liability.payment.toString().replace(/\s/g, ''));
            }
          }
        }
      }
      for(let property of applicant.properties){
        if (property && (property.annualpropertytax != null || property.generalExpense != null || property.homeInsurance != null || property.propertyValue != null || property.hyrdoCost != null || property.interestCharges != null 
          || property.managementExpense != null || property.monthlycondofee != null || property.monthlyenergycost != null || property.payment != null || property.rentalincome != null || property.repairCosts != null)) {
          if (incomePattern.test(property.annualpropertytax) || incomePattern.test(property.generalExpense) || incomePattern.test(property.homeInsurance) || incomePattern.test(property.propertyValue) || incomePattern.test(property.hyrdoCost)
          || incomePattern.test(property.interestCharges) || incomePattern.test(property.managementExpense) || incomePattern.test(property.monthlycondofee) || incomePattern.test(property.monthlyenergycost)
          || incomePattern.test(property.payment) || incomePattern.test(property.rentalincome) || incomePattern.test(property.repairCosts)) {
            if(property.annualpropertytax != null){
              property.annualpropertytax = parseFloat(property.annualpropertytax.toString().replace(/\s/g, ''));
            }
            if(property.generalExpense != null){
              property.generalExpense = parseFloat(property.generalExpense.toString().replace(/\s/g, ''));
            }
            if(property.homeInsurance != null){
              property.homeInsurance = parseFloat(property.homeInsurance.toString().replace(/\s/g, ''));
            }
            if(property.propertyValue != null){
              property.propertyValue = parseFloat(property.propertyValue.toString().replace(/\s/g, ''));
            }
            if(property.hyrdoCost != null){
              property.hyrdoCost = parseFloat(property.hyrdoCost.toString().replace(/\s/g, ''));
            }
            if(property.interestCharges != null){
              property.interestCharges = parseFloat(property.interestCharges.toString().replace(/\s/g, ''));
            }
            if(property.managementExpense != null){
              property.managementExpense = parseFloat(property.managementExpense.toString().replace(/\s/g, ''));
            }
            if(property.monthlycondofee != null){
              property.monthlycondofee = parseFloat(property.monthlycondofee.toString().replace(/\s/g, ''));
            }
            if(property.monthlyenergycost != null){
              property.monthlyenergycost = parseFloat(property.monthlyenergycost.toString().replace(/\s/g, ''));
            }
            if(property.payment != null){
              property.payment = parseFloat(property.payment.toString().replace(/\s/g, ''));
            }
            if(property.rentalincome != null){
              property.rentalincome = parseFloat(property.rentalincome.toString().replace(/\s/g, ''));
            }
            if(property.repairCosts != null){
              property.repairCosts = parseFloat(property.repairCosts.toString().replace(/\s/g, ''));
            }
          }
        }
      }
    }
    for(let subjectProperty of subjectProperties){
      if (subjectProperty && (subjectProperty.annualPropertyTax != null || subjectProperty.downPayment != null || subjectProperty.generalExpense != null || subjectProperty.homeInsurance != null
        || subjectProperty.hyrdoCost != null || subjectProperty.insurance != null || subjectProperty.interestCharges != null || subjectProperty.managementExpense != null || subjectProperty.monthlyCondoFee != null 
        || subjectProperty.monthlyEnergyCost != null || subjectProperty.monthlyRentalIncome != null || subjectProperty.propertyValue != null || subjectProperty.repairCosts != null || subjectProperty.estimated_value != null
        || subjectProperty.eto != null || subjectProperty.inferred_equity != null)) {
        if(incomePattern.test(subjectProperty.annualPropertyTax) || incomePattern.test(subjectProperty.downPayment) || incomePattern.test(subjectProperty.generalExpense) || incomePattern.test(subjectProperty.homeInsurance) || incomePattern.test(subjectProperty.hyrdoCost) || incomePattern.test(subjectProperty.insurance) || incomePattern.test(subjectProperty.interestChargese) || incomePattern.test(subjectProperty.managementExpense) 
          || incomePattern.test(subjectProperty.monthlyCondoFee) || incomePattern.test(subjectProperty.monthlyEnergyCost) || incomePattern.test(subjectProperty.monthlyRentalIncome) || incomePattern.test(subjectProperty.propertyValue) || incomePattern.test(subjectProperty.repairCosts) ||!incomePattern.test(subjectProperty.estimated_value)
        || !incomePattern.test(subjectProperty.eto) || !incomePattern.test(subjectProperty.inferred_equity)){
          if(subjectProperty.annualPropertyTax != null){
            subjectProperty.annualPropertyTax = parseFloat(subjectProperty.annualPropertyTax.toString().replace(/\s/g, ''));
          }
          if(subjectProperty.downPayment != null){
            subjectProperty.downPayment = parseFloat(subjectProperty.downPayment.toString().replace(/\s/g, ''));
          }
          if(subjectProperty.generalExpense != null){
            subjectProperty.generalExpense = parseFloat(subjectProperty.generalExpense.toString().replace(/\s/g, ''));
          }
          if(subjectProperty.homeInsurance != null){
            subjectProperty.homeInsurance = parseFloat(subjectProperty.homeInsurance.toString().replace(/\s/g, ''));
          }
          if(subjectProperty.hyrdoCost != null){
            subjectProperty.hyrdoCost = parseFloat(subjectProperty.hyrdoCost.toString().replace(/\s/g, ''));
          }
          if(subjectProperty.insurance != null){
            subjectProperty.insurance = parseFloat(subjectProperty.insurance.toString().replace(/\s/g, ''));
          }
          if(subjectProperty.interestCharges != null){
            subjectProperty.interestCharges = parseFloat(subjectProperty.interestCharges.toString().replace(/\s/g, ''));
          }
          if(subjectProperty.managementExpense != null){
            subjectProperty.managementExpense = parseFloat(subjectProperty.managementExpense.toString().replace(/\s/g, ''));
          }
          if(subjectProperty.monthlyCondoFee != null){
            subjectProperty.monthlyCondoFee = parseFloat(subjectProperty.monthlyCondoFee.toString().replace(/\s/g, ''));
          }
          if(subjectProperty.monthlyEnergyCost != null){
            subjectProperty.monthlyEnergyCost = parseFloat(subjectProperty.monthlyEnergyCost.toString().replace(/\s/g, ''));
          }
          if(subjectProperty.monthlyRentalIncome != null){
            subjectProperty.monthlyRentalIncome = parseFloat(subjectProperty.monthlyRentalIncome.toString().replace(/\s/g, ''));
          }
          if(subjectProperty.propertyValue != null){
            subjectProperty.propertyValue = parseFloat(subjectProperty.propertyValue.toString().replace(/\s/g, ''));
          }
          if(subjectProperty.repairCosts != null){
            subjectProperty.repairCosts = parseFloat(subjectProperty.repairCosts.toString().replace(/\s/g, ''));
          }
          if(subjectProperty.estimated_value != null){
            subjectProperty.estimated_value = parseFloat(subjectProperty.estimated_value.toString().replace(/\s/g, ''));
          }
          if(subjectProperty.eto != null){
            subjectProperty.eto = parseFloat(subjectProperty.eto.toString().replace(/\s/g, ''));
          }
          if(subjectProperty.inferred_equity != null){
            subjectProperty.inferred_equity = parseFloat(subjectProperty.inferred_equity.toString().replace(/\s/g, ''));
          }
        }
      }
    }

    return getApplication;
  }

}
