import { Injectable } from '@angular/core';

export interface ToastInfo {
  title: String;
  message: String;
  id?: number;
  timeoutId?: any;
  toasterType: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  toasts: ToastInfo[] = [];
  toastCounter = 0;
  toasterType = false;

  constructor() { }

  showToast(title: String, message: String, type: boolean) {
    this.toasterType = type;
    const toastId = this.toastCounter++;


    const toast = {
      id: toastId,
      title: title,
      message: message,
      toasterType: this.toasterType,
      timeoutId: setTimeout(() => {
        this.removeToast(toastId);
      }, 3000)
    };

    this.toasts.push(toast);
    setTimeout(() => {
      toast['show'] = true;
    }, 0);
  }

  removeToast(id: number) {
    const toastIndex = this.toasts.findIndex(toast => toast.id === id);
    if (toastIndex !== -1) {
      const removedToast = this.toasts.splice(toastIndex, 1)[0];
      clearTimeout(removedToast.timeoutId);
    }
  }


  success(title: String, message: String) {
    this.toasterType = true;
    this.showToast(title, message, this.toasterType);
  }

  danger(title: String, message: String) {
    this.toasterType = false;
    this.showToast(title, message, this.toasterType);
  }

  remove(toast: ToastInfo) {
    this.toasts = this.toasts.filter(t => t != toast);
  }

}
