import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';
import { TypeWriterTextService } from './type-writer-text.service';
import { ThousandSeparatorService } from '../../services/thousandSeparator/thousand-separator.service';

@Component({
  selector: 'ngx-type-writer-text',
  templateUrl: './type-writer-text.component.html',
  styleUrls: ['./type-writer-text.component.scss']
})
export class TypeWriterTextComponent implements OnInit, OnChanges {
  @ViewChild('applicantsPara') applicantsPara: ElementRef;
  @Input() application: any = '';
  @Input() output: any = '';
  @Input() loggedInUpdaterValue: boolean = false;
  private subscription: Subscription;
  private tabNameSubscription: Subscription;
  private stepNameSubscription: Subscription;
  private languageSubscription: Subscription;
  private userStatusSubscription: Subscription;
  private tempBlockSubscription: Subscription;
  private userSubscription: Subscription;
  i = 0;
  txt = '';
  subject_property_txt = '';
  speed = 10;
  stepTwoTypedText = '';
  loadStepData ={};
  stepsData = {
    isOpen1: true,
    isOpen2: true,
    isOpen3: true,
    isOpen4: true,
  };
  tabName: string;
  applicantName = '';
  sumOfIncomes = 0;
  sumOfLiabilities = 0;
  sumOfPayment = 0;
  sumOfPropertiesPayment = 0;
  sumOfRevenues = 0;
  applicantActive = false;
  applicantsActive = false;
  hasSubjectproperty = false;
  hasTotalMorgage = false;
  typingInProgress = false;
  mortgageCapacity = null;
  monthlyMortgageAmount = null;
  checkApplicantData = {};
  checkTotalMortgage = {};
  initialApplicationLoad = false;
  getCloneApplication: any;
  private typingTasksCount = 0;
  mobileStepActive = '';
  checkMobileStepActive = '';
  loggedIn = true;
  applicationChange = false;
  applicationId = 0;
  initialChecker = false;
  currentLanguage = '';
  initialCurrentLanguage = '';
  getmobileStep= '';
  userStatus = false;
  user: any = null;
  tempBlock = false;
  mortgageIndex = 0;
  subjectPropertyInsurance = 0;
  activeScenarioText = false;
  typingInitialText = false;
  applicationLoaded = false;
  applicantsData = {
    index: 0,
    applicantName: '',
    paymentAmount: 0,
    paymentFrequency: '',
    annualPropertyTax: 0,
    monthlyEnergyCost: 0,
    rentalIncome: 0,
    rentalPercentage: 0
  };

  subjectProperty = {
    transactionType: '',
    propertyValue: 0,
    downPayment: 0,
    downPayment_type: '',
    propertyType: '',
    annualPropertyTax: 0,
    monthlyEnergyCost: 0,
    occupancyType: 0
  }

  totalMortgage = {
    mortgageCapacity: 0,
    insurance: 0,
    monthlyMortgageAmount: 0,
    amortizationYears: 0,
    mortgageRate: 0,
    mortgageTerm: 0
  }

  constructor(private typeWriterTextServices: TypeWriterTextService, private thousandSeparatorService: ThousandSeparatorService, private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit(): void {
    this.subscription = this.typeWriterTextServices.stepData$.subscribe(data => {
      if (Object.values(data).some(value => value === true)) {
        this.stepsData = data;
        console.log("initialApplicationLoad STEP", this.stepsData);
        if(this.getCloneApplication){
          if(this.initialChecker){
            this.checkApplication();
          }
          this.getApplicationChange();
        }
      }
    });
    this.tabNameSubscription = this.typeWriterTextServices.tabName$.subscribe((newTabName) => {
      this.tabName = newTabName;
    });
    
    this.userStatusSubscription = this.typeWriterTextServices.userStatus$.subscribe((newUserStatus) => {
      this.userStatus = newUserStatus;
    });
    
    this.tempBlockSubscription = this.typeWriterTextServices.tempBlock$.subscribe((newtempBlock) => {
      this.userStatus = newtempBlock;
    });
    
    this.userSubscription = this.typeWriterTextServices.user$.subscribe((newUser) => {
      this.user = newUser;
    });
    
    this.stepNameSubscription = this.typeWriterTextServices.stepName$.subscribe((newStepName) => {
      // this.initialApplicationLoad = false;
      // if(newStepName === 'stepOne'){
      //   this.initialApplicationLoad = true;
      // }
      this.getmobileStep = newStepName
      this.getStepInfo(newStepName);
    });
    
    this.languageSubscription = this.typeWriterTextServices.setLanguage$.subscribe((language) => {
      this.currentLanguage = language;
      if(this.currentLanguage !== this.initialCurrentLanguage){
        this.initialCurrentLanguage = this.currentLanguage;
        if(this.getCloneApplication){
          this.initialApplicationLoad = true;
          this.getApplicationChange();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.tabNameSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
     if (changes.application && !changes.application.firstChange) {
      this.typeWriterTextServices.getApplicationChenge(true);
      const cloneApplication = cloneDeep(this.application);
      this.getCloneApplication = this.thousandSeparatorService.unFormattedVulues(cloneApplication);
      // this.initialApplicationLoad = this.typeWriterTextServices.getApplicationVariable();
      this.activeScenarioText = this.typeWriterTextServices.getActiveScenarioText();
      // if(this.stepsData.isOpen1 || this.getmobileStep === 'stepOne'){
      //   this.initialApplicationLoad = true;
      // }

        this.checkApplication();
      if(this.initialApplicationLoad){
        this.initialChecker = true;
        console.log("initialApplicationLoad 33.32", this.initialApplicationLoad, "typingInitialText", this.typingInitialText)
        this.getApplicationChange();
      } else {
        this.initialApplicationLoad = false;
        console.log("initialApplicationLoad 332", this.initialApplicationLoad, "typingInitialText", this.typingInitialText)
        this.getApplicationChange();
      }
      if(this.activeScenarioText && !this.typingInProgress){
        console.log("initialApplicationLoad 33.22", this.initialApplicationLoad, "typingInitialText", this.typingInitialText)
        this.getApplicationChange();
      }
    }
    if (changes.output && !changes.output.firstChange) {
      this.mortgageCapacity = this.output.mortgageCapacity;
      this.monthlyMortgageAmount = this.output.monthlyMortgageAmount;
    }
    if(changes.loggedInUpdaterValue){
      this.loggedIn = this.loggedInUpdaterValue;
      if(!this.loggedIn){
        this.applicantActive = false;
        this.applicantsActive = false;
        this.hasSubjectproperty = false;
        this.hasTotalMorgage = false;
      }
    }
  }

  checkApplication(){
    if(this.applicationId !== this.getCloneApplication.id){
      this.applicationId = this.getCloneApplication.id
      this.txt = '';
      this.subject_property_txt = '';
      this.applicationChange = true;
      this.initialApplicationLoad = true;
      this.typingInProgress = false;
      this.applicationLoaded = false;
    } else {
      this.applicationLoaded = true;
      this.typingInitialText  = true;
      this.initialApplicationLoad = false;
    }
  }

  getStepInfo(stepName: string){
    if(stepName !== this.checkMobileStepActive){
      this.checkMobileStepActive = stepName;
      if(stepName === 'stepOne'){
        this.stepsData = {
          isOpen1: true,
          isOpen2: false,
          isOpen3: false,
          isOpen4: false,
        };
      } else if(stepName === 'stepTwo'){
        this.stepsData = {
          isOpen1: false,
          isOpen2: true,
          isOpen3: false,
          isOpen4: false,
        };
        // this.getApplicationChange();
      } else if(stepName === 'stepThree'){
        this.stepsData = {
          isOpen1: false,
          isOpen2: false,
          isOpen3: true,
          isOpen4: false,
        };
        // this.getApplicationChange();
      } else if(stepName === 'stepFour'){
        this.stepsData = {
          isOpen1: false,
          isOpen2: false,
          isOpen3: false,
          isOpen4: true,
        };
        // this.getApplicationChange();
      }
    }
  }

  getApplicationChange(){
    console.log("initialApplicationLoad 1.1", this.initialApplicationLoad, "typingInitialText", this.typingInitialText, "applicationLoaded",this.applicationLoaded)
    if(this.initialApplicationLoad && !this.applicationLoaded){
      this.typingInitialText = false;
      console.log("initialApplicationLoad 2", this.initialApplicationLoad, "typingInitialText", this.typingInitialText)
      if (!this.typingInProgress) {
        if(this.applicationChange){
          this.applicationChange = false;
          setTimeout(() => {
            if(this.getCloneApplication){
              this.applicationData(this.getCloneApplication);
            }
          }, 100);
        } else{
          if(this.getCloneApplication){
            this.applicationData(this.getCloneApplication);
          }
        }
      }
    } else if(this.activeScenarioText){
      console.log("initialApplicationLoad 4", this.initialApplicationLoad, "typingInitialText", this.typingInitialText)
      if (!this.typingInProgress) {
        this.typeMortgageProperty(this.getCloneApplication);
      }
    } else {
    this.typingInitialText = true;
    this.applicationData(this.getCloneApplication);
    console.log("initialApplicationLoad 5", this.initialApplicationLoad, "typingInitialText", this.typingInitialText)
    } 
  }

  applicationData(data: any){
    if(data.applicants){
      if(data.applicants.length === 1){
        this.applicantActive = true;
        this.applicantsActive = false;
        setTimeout(() => {
          if (!this.typingInProgress) {
            this.typingInProgress = true;
            this.processApplicant(data);
          }
        }, 100);
      } else {
        this.applicantsActive = true;
        this.applicantActive =  false
        setTimeout(() => {
          if (!this.typingInProgress) {
            this.typingInProgress = true;
            this.processApplicants(data);
          }
        }, 100);
      }
    }
  }

  async processApplicant(data:any){
    this.applicantName = ''
    this.sumOfIncomes = 0;
    this.sumOfLiabilities = 0;
    this.sumOfPayment = 0;
    this.sumOfRevenues = 0;
    if(this.typingInitialText || this.initialApplicationLoad){
      if(!this.typingInitialText){
        this.clearContent('applicant');
      }
      for(let applicant of data.applicants){
        this.applicantName = applicant.first_name;
        for(let income of applicant.incomes){
          if((this.tabName === 'simple' && income.mode_type  === 'simple') || this.tabName === 'advanced'){
            if (income.frequency === 'monthly') {
              const convertYearly = income.value * 12;
              this.sumOfIncomes += parseFloat(convertYearly.toFixed(0));
            } else if (income.frequency === 'yearly') {
              this.sumOfIncomes += income.value;
            }
          }
        }
        for(let revenue of applicant.revenues){
          if(this.tabName === 'advanced' && revenue.mode_type === this.tabName){
            if (revenue.frequency === 'monthly') {
              this.sumOfRevenues += revenue.value;
            } else if (revenue.frequency === 'yearly') {
              const convertMonthly = revenue.value / 12;
              this.sumOfRevenues += parseFloat(convertMonthly.toFixed(0));
            }
          }
        }
        for(let liability of applicant.liabilities){
          if(this.tabName === 'simple' && liability.mode_type === this.tabName){
            this.sumOfLiabilities += liability.balance
            this.sumOfPayment += liability.payment
          } else if((this.tabName === 'simple' && liability.mode_type  === 'simple') || this.tabName === 'advanced'){
            if(liability.type === 'creditcard'|| liability.type === 'unsecuredcredit'){
              this.sumOfLiabilities += liability.balance
            } else {
              if (liability.frequency === 'monthly') {
                this.sumOfPayment += liability.payment;
              } else if (liability.frequency === 'yearly') {
                const convertMonthly = liability.payment / 12;
                this.sumOfPayment += parseFloat(convertMonthly.toFixed(0));
              }
            }
          }
        }
      }
      if(this.sumOfIncomes !== 0 || this.sumOfRevenues !== 0 || this.sumOfLiabilities !== 0 || this.sumOfPayment !== 0){
        const name = 'textArray_0';
        const textArray = [];
        if(this.sumOfIncomes !== 0){textArray.push('sourceIncome_txt');}
        if(this.sumOfRevenues !== 0){textArray.push('advancedRevenue_txt');}
        if(this.sumOfLiabilities !== 0){textArray.push('advancedLiability_txt');}
        if(this.sumOfPayment !== 0){textArray.push('advancedLiability_txt');}
        const textArrayWithName = { stepId: 'stepTwo', getApplicant: 0, [name]: textArray };
        await this.textSelectionSingleStepTwo(this.tabName, 'applicant')
        await this.addClickableNumbers(this.txt, 'applicant', textArrayWithName[name], textArrayWithName.stepId, textArrayWithName.getApplicant)
        if(this.initialApplicationLoad){
          await this.getMortgageProperty(data);
          this.initialApplicationLoad = false;
          this.typeWriterTextServices.setApplicationVariable(this.initialApplicationLoad);
        } else if(this.typingInitialText){
          await this.getMortgageProperty(data);
        }
      } else {
        if(this.initialApplicationLoad){
          await this.getMortgageProperty(data);
          this.initialApplicationLoad = false;
          this.typeWriterTextServices.setApplicationVariable(this.initialApplicationLoad);
        } else if(this.typingInitialText){
          await this.getMortgageProperty(data);
        }
      }
    } else{
      this.typingInProgress = false;
    }
  }

  async processApplicants(data: any) {
    let typingChecker = false;
    if(this.typingInitialText || this.initialApplicationLoad){
      // if(!this.typingInitialText){
      // }
      this.clearContent(data);
      for (let [index, applicant] of data.applicants.entries()) {
        this.sumOfPropertiesPayment = 0;
        let sumRentalIncome = 0;
        let sumAnnualPropertyTax = 0;
        let sumMonthlyEnergyCost= 0;
        let additionalText = '';
        let propertyOwner = false;
        if(applicant.mode === 'simple'){
          await this.calculateApplicantsSimple(data, applicant.mode, index);
          if(this.sumOfIncomes !== 0 || this.sumOfRevenues !== 0 || this.sumOfLiabilities !== 0 || this.sumOfPayment !== 0){
            const name = `textArray_${index}`;
            const textArray = [];
            if(this.sumOfIncomes !== 0 && index == 0){textArray.push('sourceIncome_txt');}
            if(this.sumOfRevenues !== 0 && index == 0){textArray.push('advancedRevenue_txt');}
            if(this.sumOfLiabilities !== 0 && index == 0){textArray.push('advancedLiability_txt');}
            if(this.sumOfPayment !== 0 && index == 0){textArray.push('advancedLiability_txt');}
            const textArrayWithName = { stepId: 'stepTwo', getApplicant: index, [name]: textArray };
            const setId = 'applicantsText_' + index;
            const element = document.getElementById(setId);
            if(element){
              element.remove();
            }
            if(document.getElementById(setId) === null){
              const para = document.createElement("p");
              para.classList.add("pt-3");
              para.style.cssText = "color: #363636; font-family: Roboto; font-size: 13px; font-style: normal; font-weight: 300; line-height: 150%;";
              para.setAttribute("id", setId);
              this.applicantsPara.nativeElement.appendChild(para);
              await this.textSelectionSingleStepTwo(applicant.mode, setId);
              await this.addClickableNumbers(this.txt, setId, textArrayWithName[name], textArrayWithName.stepId, textArrayWithName.getApplicant)
            } else {
              await this.textSelectionSingleStepTwo(applicant.mode, setId);
              await this.addClickableNumbers(this.txt, setId, textArrayWithName[name], textArrayWithName.stepId, textArrayWithName.getApplicant)
            }
          }
        } else if(applicant.mode === 'advanced'){
          if(index == 0){
            await this.calculateApplicantsSimple(data, applicant.mode, index);
            additionalText = this.generateAdavanceSentence();
            if(additionalText){
              typingChecker = true;
            } else {
              typingChecker = false;
            }
          }
          for (let property of applicant.properties) {
            if (property.occupancy === 'owner' || property.occupancy === 'ownerRental') {
              propertyOwner = true;
              const checkPropertyValue = property.payment !== 0 && property.payment !== null;
              const checkPropertyTaxValue = property.annualpropertytax !== 0 && property.annualpropertytax !== null;
              const checkEnergyCostValue = property.monthlyenergycost !== 0 && property.monthlyenergycost !== null;
              const checkRentIncomeValue = property.rentalincome !== 0 && property.rentalincome !== null ;
              if(checkPropertyValue || checkPropertyTaxValue || checkEnergyCostValue || checkRentIncomeValue){
                this.applicantsData = {
                  index: index,
                  applicantName: applicant.first_name,
                  paymentAmount: property.payment,
                  paymentFrequency: property.frequency,
                  annualPropertyTax: property.annualpropertytax,
                  monthlyEnergyCost: property.monthlyenergycost,
                  rentalIncome: property.rentalincome,
                  rentalPercentage: property.rentalPercentage
                };
                if(this.applicantsData.rentalIncome === null){
                  this.applicantsData.rentalIncome = 0;
                } else {
                  sumRentalIncome += this.applicantsData.rentalIncome;
                }
    
                if(this.applicantsData.annualPropertyTax === null){
                  this.applicantsData.annualPropertyTax = 0;
                } else {
                  sumAnnualPropertyTax += this.applicantsData.annualPropertyTax;
                }
    
                if(this.applicantsData.monthlyEnergyCost === null){
                  this.applicantsData.monthlyEnergyCost = 0;
                } else {
                  sumMonthlyEnergyCost += this.applicantsData.monthlyEnergyCost;
                }
                if (this.applicantsData.paymentFrequency === 'monthly') {
                  this.sumOfPropertiesPayment += this.applicantsData.paymentAmount;
                } else if (this.applicantsData.paymentFrequency === 'yearly') {
                  this.sumOfPropertiesPayment += this.applicantsData.paymentAmount / 12;
                } else if (this.applicantsData.paymentFrequency === 'biweekly') {
                  this.sumOfPropertiesPayment += (this.applicantsData.paymentAmount * 26) / 12;
                } else if (this.applicantsData.paymentFrequency === 'weekly') {
                  this.sumOfPropertiesPayment += (this.applicantsData.paymentAmount * 52) / 12;
                }
                
                typingChecker = true;
              } else{
                  this.typingInProgress = false;
                  typingChecker = false;
              }  
            }
          }
          if(typingChecker){
            typingChecker = false;
            let multipleText = '';
            if(index == 0){
              if(propertyOwner){
                if(this.currentLanguage === 'en' && this.initialCurrentLanguage === 'en'){
                  multipleText = additionalText + ` Own a property that costs $${this.sumOfPropertiesPayment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} every month with $${sumAnnualPropertyTax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} every year in Property tax and $${sumMonthlyEnergyCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} in monthly heating costs.`;
                } else{
                  multipleText = additionalText + ` Posséder une propriété qui coûte $${this.sumOfPropertiesPayment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} chaque mois avec $${sumAnnualPropertyTax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} chaque année en Taxe foncière et $${sumMonthlyEnergyCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} en frais de chauffage mensuels.`;
                }
                if(sumRentalIncome > 0) {
                  if(this.currentLanguage === 'en' && this.initialCurrentLanguage === 'en'){
                    multipleText +=` It is rented for $${sumRentalIncome.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} every month with ${this.applicantsData.rentalPercentage}%.`;
                  }else{
                    multipleText +=` Il est loué pour $${sumRentalIncome.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} chaque mois avec ${this.applicantsData.rentalPercentage}%.`;
                  }
                }
              } else {
                multipleText = additionalText;
              }
            } else{
              if(this.currentLanguage === 'en' && this.initialCurrentLanguage === 'en'){
                multipleText = `${this.applicantsData.applicantName} own a property that costs $${this.sumOfPropertiesPayment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} every month with $${sumAnnualPropertyTax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} every year in Property tax and $${sumMonthlyEnergyCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} in monthly heating costs.`;
              }else{
                multipleText = `${this.applicantsData.applicantName} posséder une propriété qui coûte $${this.sumOfPropertiesPayment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} chaque mois avec $${sumAnnualPropertyTax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} chaque année en Taxe foncière et $${sumMonthlyEnergyCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} en frais de chauffage mensuels.`;
              }
              if(sumRentalIncome > 0) {
                if(this.currentLanguage === 'en' && this.initialCurrentLanguage === 'en'){
                  multipleText +=` It is rented for $${sumRentalIncome.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} every month with ${this.applicantsData.rentalPercentage}%.`;
                }else{
                  multipleText +=` Il est loué pour $${sumRentalIncome.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} chaque mois avec ${this.applicantsData.rentalPercentage}%.`;
                }
              }
            }
            const name = `textArray_${index}`;
            const textArray = [];
            if(this.sumOfIncomes !== 0 && index == 0){textArray.push('sourceIncome_txt');}
            if(this.sumOfRevenues !== 0 && index == 0){textArray.push('advancedRevenue_txt');}
            if(this.sumOfLiabilities !== 0 && index == 0){textArray.push('advancedLiability_txt');}
            if(this.sumOfPayment !== 0 && index == 0){textArray.push('advancedLiability_txt');}
            if(this.sumOfPropertiesPayment !== 0){textArray.push('property_txt');}
            if(sumAnnualPropertyTax !== 0){textArray.push('property_txt');}
            if(sumMonthlyEnergyCost !== 0){textArray.push('property_txt');}
            if(sumRentalIncome !== 0){textArray.push('rentalIncome_txt');}
            if(this.applicantsData.rentalPercentage !== 0){textArray.push('rentalIncome_txt');}
            const textArrayWithName = { stepId: 'stepTwo', getApplicant: index, [name]: textArray };
            const setId = 'applicantsText_' + index;
            const element = document.getElementById(setId);
            if(element){
              element.remove();
            }
            if(document.getElementById(setId) === null){
              const para = document.createElement("p");
              para.classList.add("pt-3");
              para.style.cssText = "color: #363636; font-family: Roboto; font-size: 13px; font-style: normal; font-weight: 300; line-height: 150%;";
              para.setAttribute("id", setId);
              this.applicantsPara.nativeElement.appendChild(para);
              await this.typeWriter(setId, multipleText);
              await this.addClickableNumbers(multipleText, setId, textArrayWithName[name], textArrayWithName.stepId, textArrayWithName.getApplicant) 
            }
          }
        }
      }
      if(this.initialApplicationLoad){
        this.initialApplicationLoad = false;
        this.typeWriterTextServices.setApplicationVariable(this.initialApplicationLoad);
        this.getMortgageProperty(data);
      } else if(this.typingInitialText){
        this.getMortgageProperty(data);
      }
      if (Object.values(this.applicantsData).some((value, index) => value !== Object.values(this.checkApplicantData)[index])) {
        this.checkApplicantData = this.applicantsData;
        this.initialApplicationLoad = false;
        this.typeWriterTextServices.setApplicationVariable(this.initialApplicationLoad);
      }
    } else{
      this.typingInProgress = false;
    }
  }

  typeMortgageProperty(data:any){
    if(!this.typingInitialText || !this.initialApplicationLoad){
      setTimeout(() => {
        if (!this.typingInProgress) {
          this.typingInProgress = true;
          this.getMortgageProperty(data);
        }
      }, 100);
    }
  }

  async calculateApplicantsSimple(data: any, tabName = '', index = 0){
    this.applicantName = ''
    this.sumOfIncomes = 0;
    this.sumOfLiabilities = 0;
    this.sumOfPayment = 0;
    this.sumOfRevenues = 0;
    const applicant = data.applicants[index];
    this.applicantName = applicant.first_name;
    for(let income of applicant.incomes){
      if((tabName === 'simple' && income.mode_type  === 'simple') || tabName === 'advanced'){
        if (income.frequency === 'monthly') {
          const convertYearly = income.value * 12;
          this.sumOfIncomes += parseFloat(convertYearly.toFixed(0));
        } else if (income.frequency === 'yearly') {
          this.sumOfIncomes += income.value;
        }
      }
    }
    for(let revenue of applicant.revenues){
      if(tabName === 'advanced' && revenue.mode_type === tabName){
        if (revenue.frequency === 'monthly') {
          this.sumOfRevenues += revenue.value;
        } else if (revenue.frequency === 'yearly') {
          const convertMonthly = revenue.value / 12;
          this.sumOfRevenues += parseFloat(convertMonthly.toFixed(0));
        }
      }
    }
    for(let liability of applicant.liabilities){
      if(tabName === 'simple' && liability.mode_type === tabName){
        this.sumOfLiabilities += liability.balance
        this.sumOfPayment += liability.payment
      } else if((tabName === 'simple' && liability.mode_type  === 'simple') || tabName === 'advanced'){
        if(liability.type === 'creditcard'|| liability.type === 'unsecuredcredit'){
          this.sumOfLiabilities += liability.balance
        } else {
          if (liability.frequency === 'monthly') {
            this.sumOfPayment += liability.payment;
          } else if (liability.frequency === 'yearly') {
            const convertMonthly = liability.payment / 12;
            this.sumOfPayment += parseFloat(convertMonthly.toFixed(0));
          }
        }
      }
    }
  }

  async getMortgageProperty(data: any){
    if(!this.typingInitialText){
      this.clearContent("subjectproperty");
    }
    let addDollar = '';
    let addPercent = '';
    let subjectpropertyText ='';
    let subjectPropertyType = 0;
    let subjectPropertyTypeText = '';
    for(let subjectproperty of data.subjectproperty){
      if(subjectproperty.active){
        const checkPropertyValue = subjectproperty.propertyValue !== 0 && subjectproperty.propertyValue !== null ;
        const checkDownPaymentValue = subjectproperty.downPayment !== 0 && subjectproperty.downPayment !== null;
        const checkPropertyTaxValue = subjectproperty.annualPropertyTax !== 0 && subjectproperty.annualPropertyTax !== null;
        if(checkPropertyValue || checkDownPaymentValue || checkPropertyTaxValue){
          if(data.type === 'refinance'){
            if(subjectproperty.eto !== null){
              subjectPropertyType = subjectproperty.eto;
            } else{
              subjectPropertyType = 0;
            }
            if(this.currentLanguage === 'en' && this.initialCurrentLanguage === 'en'){
              subjectPropertyTypeText = 'equity takeout';
            } else {
              subjectPropertyTypeText = 'retrait de capitaux propres';
            }
          } else if(data.type === 'renewal'){
            if(subjectproperty.inferred_equity !== null){
              subjectPropertyType = subjectproperty.inferred_equity;
            } else{
              subjectPropertyType = 0;
            }
            if(this.currentLanguage === 'en' && this.initialCurrentLanguage === 'en'){
              subjectPropertyTypeText = 'inferred equity';
            } else {
              subjectPropertyTypeText = 'capitaux propres déduits';
            }
          } else{
            subjectPropertyType = subjectproperty.downPayment;
            if(this.currentLanguage === 'en' && this.initialCurrentLanguage === 'en'){
              subjectPropertyTypeText = 'down payment';
            } else{
              subjectPropertyTypeText = 'acompte';
            }
          }
          this.subjectProperty = {
            transactionType: data.type,
            propertyValue: subjectproperty.propertyValue,
            downPayment: subjectPropertyType,
            downPayment_type: subjectproperty.downPayment_type,
            propertyType: subjectproperty.type,
            annualPropertyTax: subjectproperty.annualPropertyTax,
            monthlyEnergyCost: subjectproperty.monthlyEnergyCost,
            occupancyType: subjectproperty.occupancy
          }
          this.subjectPropertyInsurance = subjectproperty.insurance;
          if(this.subjectProperty.downPayment_type === 'dollar' || data.type === 'refinance' || data.type === 'renewal'){
            addDollar = ' $'
            addPercent = ''
            this.subjectProperty.downPayment = subjectPropertyType;
          } else if(data.type === 'purchase' || data.type === 'preapproval'){
            addDollar = ' '
            addPercent = '%'
            this.subjectProperty.downPayment = subjectproperty.downPayment.toFixed(0);
          }
          const genratedMortgagePropertyText = this.genrateMortgagePropertyText(subjectPropertyTypeText, addDollar, addPercent)
          if(this.currentLanguage === 'en' && this.initialCurrentLanguage === 'en'){
            subjectpropertyText = genratedMortgagePropertyText + `, a property type of ${this.subjectProperty.propertyType} with total annual property tax of $${this.subjectProperty.annualPropertyTax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} and $${this.subjectProperty.monthlyEnergyCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} in month heating costs. Occupancy is type of ${this.subjectProperty.occupancyType}.`
          }else{
            subjectpropertyText = genratedMortgagePropertyText + `, un type de propriété de ${this.subjectProperty.propertyType} avec une taxe foncière annuelle totale de $${this.subjectProperty.annualPropertyTax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} et $${this.subjectProperty.monthlyEnergyCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} en mois les frais de chauffage. L'occupation est en quelque sorte ${this.subjectProperty.occupancyType}.`
          }
          this.subject_property_txt = subjectpropertyText;
          this.hasSubjectproperty = true;
          setTimeout(() => {
            this.typingMortgageProperty(data);
          }, 100)
          
        } else{
          this.hasSubjectproperty = false;
          this.hasTotalMorgage = false;
          this.typingInProgress = false;
        }
      }
    }
  }

  genrateMortgagePropertyText(subjectPropertyTypeText:any, addDollar:string, addPercent:string){
    let sentence = '';
    if(this.currentLanguage === 'en' && this.initialCurrentLanguage === 'en'){
      sentence = `Today we ${this.subjectProperty.transactionType}`;
  
      if (this.subjectProperty.propertyValue !== 0) {
          sentence += ` for a total of $${this.subjectProperty.propertyValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
      }
  
      if (this.subjectProperty.downPayment !== 0) {
          sentence += ` with a `+ subjectPropertyTypeText +` of`+ addDollar +`${this.subjectProperty.downPayment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` + addPercent;
  
      }
    }else{
      sentence = `Aujourd'hui nous ${this.subjectProperty.transactionType}`;
  
      if (this.subjectProperty.propertyValue !== 0) {
          sentence += ` pour un total de $${this.subjectProperty.propertyValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
      }
  
      if (this.subjectProperty.downPayment !== 0) {
          sentence += ` avec un `+ subjectPropertyTypeText +` de`+ addDollar +`${this.subjectProperty.downPayment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` + addPercent;
  
      }
    }
    return sentence;
  }
  
  async typingMortgageProperty(data: any){
    await this.typeWriter("subjectproperty", this.subject_property_txt);
    const name = "subjectPropertyArray";
    const textArray = [];
    if(this.subjectProperty.propertyValue !== 0){textArray.push('subjectProperty_purchase_txt');}
    if(this.subjectProperty.downPayment !== 0){textArray.push('subjectProperty_downPayment_txt');}
    if(this.subjectProperty.annualPropertyTax !== 0){textArray.push('subjectProperty_propertyTax_txt');}
    if(this.subjectProperty.monthlyEnergyCost !== 0){textArray.push('subjectProperty_heatingCost_txt');}
    const textArrayWithName = { stepId: 'stepThree', getApplicant: null, [name]: textArray };
    await this.addClickableNumbers(this.subject_property_txt, "subjectproperty", textArrayWithName[name], textArrayWithName.stepId, textArrayWithName.getApplicant);
    this.hasTotalMorgage = true;
    setTimeout(() => {
      this.setMortgageTotal(data)
    }, 100);
  }

  async setMortgageTotal(data: any){
    await this.getMortgageTotal(data)
  }

  async getMortgageTotal(data: any){
    if(!this.typingInitialText){
      this.clearContent("totalMorgage");
    }
    let totalMortgageText = '';
    for(let [index, mortgage] of data.mortgage.entries()){
      if(mortgage.active){
        this.mortgageIndex = index;
      }
    }
    this.totalMortgage = {
      mortgageCapacity: this.mortgageCapacity.toFixed(2),
      insurance: this.subjectPropertyInsurance,
      monthlyMortgageAmount: this.monthlyMortgageAmount.toFixed(2),
      amortizationYears: data.mortgage[this.mortgageIndex].amortizationYears,
      mortgageRate: data.mortgage[this.mortgageIndex].mortgageRate,
      mortgageTerm: data.mortgage[this.mortgageIndex].term
    }
    if(this.currentLanguage === 'en' && this.initialCurrentLanguage === 'en'){
      totalMortgageText = `The total mortgage will be $${this.totalMortgage.mortgageCapacity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} `
      if(this.totalMortgage.insurance !== 0){
        totalMortgageText += `including a mortgage loan insurance of $${this.totalMortgage.insurance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} `
      }
      totalMortgageText += `and cost $${this.totalMortgage.monthlyMortgageAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} per month on ${this.totalMortgage.amortizationYears} years at ${this.totalMortgage.mortgageRate}% mortgage rate for ${this.totalMortgage.mortgageTerm} years.`;
    }else{
      totalMortgageText = `Le montant total de l'hypothèque sera $${this.totalMortgage.mortgageCapacity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} `
      if(this.totalMortgage.insurance !== 0){
        totalMortgageText += `incluant une assurance prêt hypothécaire de $${this.totalMortgage.insurance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} `
      }
      totalMortgageText += `et le coût $${this.totalMortgage.monthlyMortgageAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} par mois sur ${this.totalMortgage.amortizationYears} années à ${this.totalMortgage.mortgageRate}% taux hypothécaire pour ${this.totalMortgage.mortgageTerm} années.`;
    }
    this.txt = totalMortgageText;
    await this.typeWriter("totalMorgage", this.txt);
    const name = "totalMorgageArray";
    const textArray = [];
    if(this.totalMortgage.mortgageCapacity !== 0){textArray.push('subjectProperty_mortgageCapacity_txt');}
    if(this.totalMortgage.insurance !== 0){textArray.push('subjectProperty_insurance_txt');}
    if(this.totalMortgage.monthlyMortgageAmount !== 0){textArray.push('subjectProperty_monthlyMortgageAmount_txt');}
    if(this.totalMortgage.amortizationYears !== 0){textArray.push('mortgage_amortizationYears_txt');}
    if(this.totalMortgage.mortgageRate !== 0){textArray.push('mortgage_mortgageRate_txt');}
    if(this.totalMortgage.mortgageTerm !== 0){textArray.push('mortgage_mortgageTerm_txt');}
    const textArrayWithName = { stepId: 'stepThree', getApplicant: null, [name]: textArray };
    await this.addClickableNumbers(this.txt, "totalMorgage", textArrayWithName[name], textArrayWithName.stepId, textArrayWithName.getApplicant);
    if (Object.values(this.totalMortgage).some((value, index) => value !== Object.values(this.checkTotalMortgage)[index])) {
      this.checkTotalMortgage = this.totalMortgage;
      this.activeScenarioText = false;
      this.typeWriterTextServices.setActiveScenarioText(this.activeScenarioText);
    } else if(this.activeScenarioText){
      this.activeScenarioText = false;
      this.typeWriterTextServices.setActiveScenarioText(this.activeScenarioText);
    }
    this.typingInitialText = true;
    console.log("initialApplicationLoad 6", this.initialApplicationLoad, "typingInitialText", this.typingInitialText)
  }
  

  async textSelectionSingleStepTwo(mode:any, id = ''){
    if(!this.typingInitialText){
      this.clearContent("applicant");
    }
    this.stepTwoTypedText ='';
    this.i = 0;
    if(mode === 'simple' && (this.sumOfIncomes !== 0 || this.sumOfLiabilities !== 0 || this.sumOfPayment !== 0)){
      const applicantSimpleMode = this.generateSimpleSentence();
      this.txt = applicantSimpleMode;
      if(id === 'applicant'){
        await this.typeWriter("applicant", this.txt);
      } else {
        await this.typeWriter(id, this.txt);
      }
    } else if(mode === 'advanced' && (this.sumOfIncomes !== 0 || this.sumOfRevenues !== 0 || this.sumOfLiabilities !== 0 || this.sumOfPayment !== 0)){
      const applicantAdvanceMode = this.generateAdavanceSentence();
      this.txt = applicantAdvanceMode;
      await this.typeWriter("applicant", this.txt)
    } else{
      this.typingInProgress = false;
    }
  }
  

  generateSimpleSentence() {
    let sentence = '';
    if(this.currentLanguage === 'en' && this.initialCurrentLanguage === 'en'){
      sentence = `${this.applicantName} is an employee`;
  
      if (this.sumOfIncomes !== 0) {
          sentence += ` with an annual income of $${this.sumOfIncomes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
      }
  
      if (this.sumOfLiabilities !== 0) {
          sentence += ` debts consist of a $${this.sumOfLiabilities.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  
      }
      if (this.sumOfPayment !== 0) {
          sentence += ` with a payment of $${this.sumOfPayment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
      }
    }else{
      sentence = `${this.applicantName} est un employé`;
  
      if (this.sumOfIncomes !== 0) {
          sentence += ` avec un revenu annuel de $${this.sumOfIncomes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
      }
  
      if (this.sumOfLiabilities !== 0) {
          sentence += ` les dettes consistent en un $${this.sumOfLiabilities.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  
      }
      if (this.sumOfPayment !== 0) {
          sentence += ` avec un paiement de $${this.sumOfPayment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
      }
    }
    return sentence;
  }

  generateAdavanceSentence() {
    let sentence = '';
    if(this.currentLanguage === 'en' && this.initialCurrentLanguage === 'en'){
      sentence = `${this.applicantName} is an employee`;
      let pointer = true;
      if (this.sumOfIncomes !== 0) {
          sentence += ` with an annual income of $${this.sumOfIncomes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
      }
      if (this.sumOfRevenues !== 0) {
          sentence += ` combined with a Revenue Amount $${this.sumOfRevenues.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} every month.`;
      } else{
        pointer = false;
        sentence += `.`
      }
      if (this.sumOfLiabilities !== 0) {
          sentence += ` Debts consist of a "Liability Type credit card & lines of credits" with a balance of $${this.sumOfLiabilities.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
          pointer = true;
      }
      if (this.sumOfPayment !== 0) {
          sentence += ` and a "Other Liability Types" that costs $${this.sumOfPayment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} every month but it will be paid "From the mortgage". `;
      } else if(pointer){
        sentence += `.`
      }
    }else{
      sentence = `${this.applicantName} est un employé`;
      let pointer = true;
      if (this.sumOfIncomes !== 0) {
          sentence += ` avec un revenu annuel de $${this.sumOfIncomes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
      }
      if (this.sumOfRevenues !== 0) {
          sentence += ` combiné avec un montant de revenu $${this.sumOfRevenues.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} chaque moish.`;
      } else{
        pointer = false;
        sentence += `.`
      }
      if (this.sumOfLiabilities !== 0) {
          sentence += ` Les dettes se composent d'un "Type de passif carte de crédit et marges de crédit" avec un solde de $${this.sumOfLiabilities.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
          pointer = true;
      }
      if (this.sumOfPayment !== 0) {
          sentence += ` et un "Autres types de responsabilité" ça coûte $${this.sumOfPayment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} tous les mois mais ce sera payé "De l'hypothèque".`;
      } else if(pointer){
        sentence += `.`
      }
    }
    return sentence;
  }


  async clearContent(data: any) {
    if(data === 'applicant'){
      const element = document.getElementById('applicant');
        if (element) {
          element.innerHTML = '';
        }
    } else if(data === 'subjectproperty'){
      const element = document.getElementById('subjectproperty');
        if (element) {
          element.innerHTML = '';
        }
    } else if(data === 'totalMorgage'){
      const element = document.getElementById('totalMorgage');
        if (element) {
          element.innerHTML = '';
        }
    } else{
      for (let index = 0; index < data.applicants.length; index++) {
        const setId = 'applicantsText_' + index;
        const element = document.getElementById(setId);
        if (element) {
          element.innerHTML = '';
        }
      }
    }
  }

  async typeWriter(id: string, text: string) {
    this.typingInProgress = true;
    this.typingTasksCount++;
    console.log("initialApplicationLoad 7", this.initialApplicationLoad, "typingInitialText", this.typingInitialText)

    return new Promise<void>((resolve) => {
        if (!this.initialApplicationLoad && this.typingInitialText) {
            document.getElementById(id).innerHTML = text;
            this.typingTasksCount--;
            if (this.typingTasksCount === 0) {
                this.typingInProgress = false;
            }
            resolve();
            return;
        }

        let i = 0;
        document.getElementById(id).innerHTML = '';
        const intervalId = setInterval(() => {
            if (!this.loggedIn || this.applicationChange) {
                clearInterval(intervalId);
                this.typingTasksCount--;
                if (this.typingTasksCount === 0) {
                    this.typingInProgress = false;
                }
                resolve();
                return;
            }

            if (i < text.length) {
                document.getElementById(id).innerHTML += text.charAt(i);
                i++;
            } else {
                clearInterval(intervalId);
                this.typingTasksCount--;
                if (this.typingTasksCount === 0) {
                    this.typingInProgress = false;
                }
                resolve();
            }
        }, this.speed);
    });
  }

  async addClickableNumbers(text: string, elementId: string, array:any, stepId: string, getApplicant: number) {
      const regex = /(?:\$\d{1,3},)?\d{3}(?:,\d{3})*(?:\.\d+)?|\$\d+|\b\d{1,3}\s*(?:years?|year)\b|\b\d{1,2}(\.\d{1,2})?%|(\b\d{1,2}(?:\.\d{1,2})?%)/g;
      const element = this.el.nativeElement.querySelector('#' + elementId);
      let idCounter = array;
      
      if (element) {
          let idIndex = 0;
          const replacedText = text.replace(regex, (match) => {
              const id = idCounter[idIndex++ % idCounter.length];
              return `<span value="${id}" style="cursor: pointer; font-weight: 500; text-decoration: underline;" class="clickable">${match}</span>`;
          });

          element.innerHTML = replacedText;

          const spans = element.querySelectorAll('.clickable');
          spans.forEach(span => {
              this.renderer.listen(span, 'click', () => {
                  const textContent = span.getAttribute('value'); ;
                  this.handleNumberClick(textContent, stepId, getApplicant);
              });
          });
      }
  }

  handleNumberClick(valueId: string, stepId: string, getApplicant: number) {
    const stepFourValue = ['subjectProperty_mortgageCapacity_txt', 'subjectProperty_monthlyMortgageAmount_txt']
    if(stepFourValue.includes(valueId)){
      let getValueId = valueId;
      this.typeWriterTextServices.gettextIncomingId(getValueId, 'stepFour', getApplicant);
    } else{
      let getValueId = valueId;
      this.typeWriterTextServices.gettextIncomingId(getValueId, stepId, getApplicant);
    }
  }

}
