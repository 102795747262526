<div *ngIf="getCsvStatus === 'boss_application'">
    <div>
        <p><strong>*{{ 'CREDENTIALS_REQUIRED_BOSS' | translate }}</strong></p>
        <div class="form-group mb-3">
            <label for="profileEmail">{{ 'BOSS_USERNAME' | translate }}</label>
            <input type="email" class="form-control input-white w-100 c-br-one-third" [(ngModel)]="credentials.bossUsername" placeholder="{{ 'BOSS_USERNAME' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
        </div>
        <div class="form-group mb-3">
            <label for="profileEmail">{{ 'BOSS_CLIENT_ID' | translate }}</label>
            <input type="email" class="form-control input-white w-100 c-br-one-third" [(ngModel)]="credentials.bossClientId" placeholder="{{ 'BOSS_CLIENT_ID' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
        </div>
        <div class="form-group mb-3">
            <label for="profileEmail">{{ 'BOSS_CLIENT_SECRET' | translate }}</label>
            <input type="email" class="form-control input-white w-100 c-br-one-third" [(ngModel)]="credentials.bossClientSecret" placeholder="{{ 'BOSS_CLIENT_SECRET' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
        </div>
    </div>
</div>
<div *ngIf="getCsvStatus === 'finmo_application'">
    <div>
        <p><strong>*{{ 'CREDENTIALS_REQUIRED_FINMO' | translate }}</strong></p>
        <div class="form-group mb-3">
            <label for="profileEmail">{{ 'FINMO_USER_NAME' | translate }}</label>
            <input type="email" class="form-control input-white w-100 c-br-one-third" [(ngModel)]="credentials.finmoUsername" placeholder="{{ 'FINMO_USER_NAME' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
        </div>
        <div class="form-group mb-3">
            <label for="profileEmail">{{ 'FINMO_TEAM_ID' | translate }}</label>
            <input type="email" class="form-control input-white w-100 c-br-one-third" [(ngModel)]="credentials.finmoTeamId" placeholder="{{ 'FINMO_TEAM_ID' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
        </div>
        <div class="form-group mb-3">
            <label for="profileEmail">{{ 'FINMO_BROKER_ID' | translate }}</label>
            <input type="email" class="form-control input-white w-100 c-br-one-third" [(ngModel)]="credentials.finmoBrokerId" placeholder="{{ 'FINMO_BROKER_ID' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
        </div>
        <div class="form-group mb-3">
            <label for="profileEmail">{{ 'FINMO_TOKEN' | translate }}</label>
            <input type="email" class="form-control input-white w-100 c-br-one-third" [(ngModel)]="credentials.finmoToken" placeholder="{{ 'FINMO_TOKEN' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
        </div>
    </div>
</div>
