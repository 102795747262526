 <div class="toaster-container">
    <div class="toaster c-d-grid" [ngClass]="toast.toasterType ? 'toaster-success' : 'toaster-error'" *ngFor="let toast of toastService.toasts">
        <div class="row pt-1">
            <div class="col-2 toaster-icon m-auto">
                <div>
                    <i *ngIf="!toast.toasterType" class="fas fa-exclamation-circle toaster-error-icon"></i>
                    <i *ngIf="toast.toasterType" class="fas fa-check-circle toaster-success-icon"></i>
                </div>
            </div>
            <div class="col-10 m-auto">
                <p class="toaster-header"><strong>{{toast.title}}</strong></p>
                <p class="toaster-text">{{toast.message}}</p>
            </div>
        </div>
        <div class="toaster-bar" [ngClass]="toast.toasterType ? 'success-bar' : 'error-bar'"></div>
    </div>
 </div>
