import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  VERSION,
  ViewChild
} from "@angular/core";
import {
  random,
  calculateDistance,
  Firework,
  Particle
} from "./firework-helpers";
import { Observable, fromEvent } from "rxjs";

@Component({
  selector: 'ngx-fire-work-animation',
  templateUrl: './fire-work-animation.component.html',
  styleUrls: ['./fire-work-animation.component.scss']
})
export class FireWorkAnimationComponent implements OnInit, AfterViewInit, OnDestroy {
  name: String = "mouse event is cool";
  public show = true;

  private contactBar: HTMLCollectionOf<Element>;
  @ViewChild("canvas", { static: false }) canvas: ElementRef;
  @HostListener("window:mouse", ["$event"])
  ctx: CanvasRenderingContext2D;
  requestId;
  interval;
  FPS = 80; // Frames per second

  options = { autoHide: false };

  // full screen dimensions
  cw = window.innerWidth;
  ch = window.innerHeight;
  // firework collection
  fireworks: Array<Firework> = [];
  // particle collection
  particles: Array<Particle> = [];
  // starting hue
  hue = 120;
  // when launching fireworks with a click, too many get launched at once without a limiter, one launch per 5 loop ticks
  limiterTotal = 15;
  limiterTick = 0;
  // this will time the auto launches of fireworks, one launch per 80 loop ticks
  timerTotal = 5;
  timerTick = 0;
  mousedown = false;
  // mouse x coordinate,
  mx;
  // mouse y coordinate
  my;
  // myFirework = new Firework(0, 0, 0, 0);
  // myParticle = new Particle(0, 0, 0);

  constructor(private ngZone: NgZone) {}
  ngOnInit() {
    this.contactBar = document.getElementsByClassName("contact-bar");
  }

  toggle() {
    this.show = !this.show;
  }

  ngAfterViewInit() {
    // Push stars to array
    this.canvas.nativeElement.width = this.contactBar[0].clientWidth;
    this.canvas.nativeElement.height = this.contactBar[0].clientHeight;
    this.ctx = this.canvas.nativeElement.getContext("2d");
    // decrease the alpha property to create more prominent trails
    // this.ctx.fillStyle = "rgba(255, 0, 0, 1)";
    // this.ctx.fillRect(0, 0, 500, 500);
    this.ngZone.runOutsideAngular(() => this.tick());
    setInterval(() => {
      this.tick();
    }, 1000 / this.FPS);
    // console.log(this.ctx);

    // mouse event bindings
    // update the mouse coordinates on mousemove
    this.canvas.nativeElement.addEventListener("mousemove", function(e) {
      // this.mx = e.pageX - this.contactBar[0].clientWidth;
      // this.my = e.pageY - this.ctx.offsetTop;
    });
  }
  mouseDown(event: MouseEvent): void {
    event.preventDefault();
    this.mousedown = true;
  }

  mouseUp(event: MouseEvent): void {
    event.preventDefault();
    this.mousedown = false;
  }

  coordinates(event: MouseEvent): void {
    this.mx = event.pageX - this.canvas.nativeElement.offsetLeft;
    this.my = event.pageY - this.canvas.nativeElement.offsetTop;
  }
  tick() {
    // this.hue = random(0, 360);
    // normally, clearRect() would be used to clear the canvas
    // we want to create a trailing effect though
    // setting the composite operation to destination-out will allow us to clear the canvas at a specific opacity, rather than wiping it entirely
    this.ctx.globalCompositeOperation = "destination-out";
    // decrease the alpha property to create more prominent trails
    this.ctx.fillStyle = "rgba(0, 0, 0, 0.3)";
    this.ctx.fillRect(0, 0, this.cw, this.ch);

    this.ctx.globalCompositeOperation = "lighter";
    // loop over each firework, draw it, update it
    var i = this.fireworks.length;

    while (i--) {
      this.fireworks[i].draw(this.ctx, this.hue);
      this.fireworks[i].update(i, this.fireworks, this.particles);
    }

    // loop over each particle, draw it, update it
    var i = this.particles.length;
    while (i--) {
      this.particles[i].draw(this.ctx, this.hue);
      this.particles[i].update(i, this.particles);
    }

    // launch fireworks automatically to random coordinates, when the mouse isn't down
    if (this.timerTick >= this.timerTotal) {
      if (!this.mousedown) {
        // start the firework at the bottom middle of the screen, then set the random target coordinates, the random y coordinates will be set within the range of the top half of the screen
        this.fireworks.push(
          new Firework(
            this.cw / 2,
            this.ch,
            random(0, this.cw),
            random(0, this.ch / 2)
          )
        );
        this.hue = random(0, 360);
        this.timerTick = 0;
      }
    } else {
      this.timerTick++;
    }

    // limit the rate at which fireworks get launched when mouse is down
    if (this.limiterTick >= this.limiterTotal) {
      if (this.mousedown) {
        // start the firework at the bottom middle of the screen, then set the current mouse coordinates as the target
        this.fireworks.push(
          new Firework(this.cw / 2, this.ch, this.mx, this.my)
        );
        this.limiterTick = 0;
      }
    } else {
      this.limiterTick++;
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    cancelAnimationFrame(this.requestId);
  }
}

