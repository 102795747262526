<div class="menu-content">
    <!-- <div class="small-logo">
        <img src="assets/images/logo_small.png" alt="logo" style="width: 45px;"/>
    </div> -->
    
    <div class="large-logo w-fit-content" style="padding:auto">
        <img [src]="getBrokerProfile" [ngClass]="checkBrokerProfile ? 'c-img-thumbnail down-animation' : 'w-100'" alt="logo"/>
    </div>
</div>
<!-- <div><a (click)="languageSwitcher('fr')">switch language to french</a></div>
<div><a (click)="languageSwitcher('en')">switch language to english</a></div> -->
<div class="c-menu-content" *ngIf="!isLoggedIn">

    <!-- BASIC HOME PAGE NOT LOGGED IN-->
    <div class="row padding-class">
        <div class="col-12">
          <span class="heading-font">{{ 'FUTURE_MORTGAGE_FINANCING' | translate }}</span><br>
        </div>
        <div class="col-12">
          <span class="sub-font">{{ 'MORTGAGE_FINANCING_SIMPLIFICATION' | translate }}</span>
          <!-- <div class="pt-4" *ngIf="!loginActive && !registerActive && !setResetPassword && !resetPassEmailSection && !resetPassOTP_Section">
            <button class="btn btn-outline-dark lsb-btn-font" (click)="openLogin()">{{ 'LOGIN_TO_PORTAL' | translate }}</button>
          </div>
          <div class="pt-4" *ngIf="!loginActive && !registerActive && !setResetPassword && !resetPassEmailSection && !resetPassOTP_Section">
            <button class="btn btn-outline-dark lsb-btn-font" (click)="openRegister()">{{ 'CREATE_ACCOUNT' | translate }}</button>
          </div> -->
        </div>
    </div>

    <div class="row">
        <div class="col-md-6 col-lg-12">
            <!-- LOGIN PAGE -->
            <div class="pt-4" *ngIf="loginActive && !registerActive">
                <button class="btn btn-outline-dark lsb-btn-font w-100 mb-1 rounded-pill" (click)="toggleMediaModal()">
                  <div>
                    <img class="social-media-icons" src="assets/images/icons-facebook.png" alt="Social Media">
                    <img class="social-media-icons" src="assets/images/icons-windows.png">
                    <img class="social-media-icons" src="assets/images/icon-apple.png">
                    <img class="social-media-icons" src="assets/images/icons-linkedin.png">
                    <img class="social-media-icons" src="assets/images/icons-google.png">
                  </div>
                </button>
                <div class="my-3">
                  <div class="c-separator c-font-12">OR</div>
                </div>
                <div class="mb-3">
                    <input type="email" class="form-control input-white c-br-one-third" [(ngModel)]="email" placeholder="{{ 'PLACE_HOLDER_EMAIL' | translate }}" (keyup.enter)="login()">
                </div>
                <div class="mb-3">
                    <input type="password" class="form-control input-white c-br-one-third" [(ngModel)]="password" placeholder="{{ 'PLACE_HOLDER_PASSWORD' | translate }}" (keyup.enter)="login()">
                </div>

                <div class="input-group mt-3 mb-1">
                    <span class="sub-font c-font-12">{{ 'FORGOT_PASSWORD' | translate }}&nbsp;</span>
                    <span class="sub-font c-pointer c-font-12" style="text-decoration: underline" (click)="openResetPasswordEamil()">{{ 'RESET_PASSWORD' | translate }}</span>
                    <span class="sub-font c-font-12">?</span>
                </div>
                <div class="input-group mt-1 mb-3">
                    <span class="sub-font c-font-12">{{ 'NEW_TO_KINGSTATE' | translate }}?&nbsp;</span>
                    <span class="sub-font c-font-12 c-pointer" style="text-decoration: underline" (click)="openRegister()">{{ 'CREATE_ACCOUNT' | translate }}</span>
                </div>

                <button class="btn btn-outline-dark lsb-btn-font w-100 mb-3 rounded-pill" (click)="login()" (keyup.enter)="login()">{{ 'LOGIN' | translate }}</button>
            </div>
        
            <!-- REGISTER PAGE -->
            <div class="pt-4" *ngIf="!loginActive && registerActive">
                <button class="btn btn-outline-dark lsb-btn-font w-100 mb-1 rounded-pill" (click)="toggleMediaModal()">
                  <div>
                    <img class="social-media-icons" src="assets/images/icons-facebook.png" alt="Social Media">
                    <img class="social-media-icons" src="assets/images/icons-windows.png">
                    <img class="social-media-icons" src="assets/images/icon-apple.png">
                    <img class="social-media-icons" src="assets/images/icons-linkedin.png">
                    <img class="social-media-icons" src="assets/images/icons-google.png">
                  </div>
                </button>
                <div class="my-3">
                  <div class="c-separator c-font-12">OR</div>
                </div>
                <div class="input-group mb-3">
                  <span class="sub-font">{{ 'CREATE_ACCOUNT_DETAILS' | translate }}</span>
                </div>
                <div class="mb-3">
                  <input type="text" class="form-control input-white c-br-one-third" [(ngModel)]="name" [placeholder]="'NAME' | translate" style="height: 42px">
                </div>
                <div class="mb-3">
                  <input type="email" class="form-control input-white c-br-one-third" [(ngModel)]="email" [placeholder]="'EMAIL_ADDRESS' | translate" style="height: 42px">
                </div>
                <div class="mb-3">
                  <input type="password" class="form-control input-white c-br-one-third" [(ngModel)]="password" [placeholder]="'PASSWORD' | translate" style="height: 42px">
                </div>
                <div class="input-group mt-3 mb-4">
                  <span class="sub-font c-pointer c-font-12" style="text-decoration: underline" (click)="openLogin()">{{ 'EXISTING_ACCOUNT_LINK' | translate }}</span>
                </div>
                <button class="btn btn-outline-dark lsb-btn-font w-100 mb-3 rounded-pill" (click)="register()">{{ 'REGISTER_BUTTON' | translate }}</button>
              </div>

              <!-- OTP EMAIL PAGE -->
            <div class="pt-4" *ngIf="resetPassEmailSection">
              <div class="input-group mb-3">
                <span class="sub-font">{{ 'CREATE_ACCOUNT_DETAILS' | translate }}</span>
              </div>
              <div class="mb-3">
                <input type="email" class="form-control input-white c-br-one-third" [(ngModel)]="getResetPasswordEmail" [placeholder]="'PLACE_HOLDER_EMAIL' | translate" style="height: 42px">
              </div>
              <button class="btn btn-outline-dark lsb-btn-font w-100 mb-3 rounded-pill" (click)="sendOTP()">{{ 'SEND_OTP' | translate }}</button>
              <div class="input-group my-3">
                <span class="sub-font c-pointer c-font-12" style="text-decoration: underline" (click)="openLogin()">{{ 'EXISTING_ACCOUNT_LINK' | translate }}</span>
              </div>
            </div>

            <!-- VERIFY OTP PAGE -->
            <div class="pt-4" *ngIf="resetPassOTP_Section">
              <div class="input-group mb-3">
                <span class="sub-font">{{ 'CREATE_ACCOUNT_DETAILS' | translate }}</span>
              </div>
              <div class="mb-3">
                <input type="text" inputmode="decimal" class="form-control input-white c-br-one-third" [(ngModel)]="getResetPasswordOTP" [placeholder]="'OTP_CODE' | translate" style="height: 42px">
              </div>
              <button class="btn btn-outline-dark lsb-btn-font w-100 mb-3 rounded-pill" (click)="verifyOTP()">{{ 'VERIFY_OTP' | translate }}</button>
            </div>

              <!-- RESET PASSWORD PAGE -->
              <div class="pt-4"  *ngIf="!loginActive && !registerActive && setResetPassword">
                <div class="input-group mb-3">
                  <span class="sub-font">{{ 'CREATE_ACCOUNT_DETAILS' | translate }}</span>
                </div>
                <div class="mb-3">
                  <input type="password" class="form-control input-white c-br-one-third" [(ngModel)]="getResetPassword.newPassword" [placeholder]="'NEW_PASSWORD' | translate" style="height: 42px">
                </div>
                <div class="mb-3">
                  <input type="password" class="form-control input-white c-br-one-third" [(ngModel)]="getResetPassword.newPasswordConfirm" [placeholder]="'NEW_PASSWORD_CONFIRM' | translate" style="height: 42px">
                </div>
                <button class="btn btn-outline-dark lsb-btn-font w-100 mb-3 rounded-pill" (click)="resetPassword()">{{ 'RESET_PASSWORD' | translate }}</button>
              </div>
        </div>
    </div>
</div>



<!-- BASIC HOME PAGE LOGGED IN-->
<div class="menu-content" *ngIf="isLoggedIn">
    <div class="row padding-class">
        <div class="col-md-12">
          <span class="heading-font c-font-20">{{ 'GREETING' | translate }}!</span>
          <div class="user-name mb-3"> {{ user.name }} {{ user.last_name }}</div>
          <span class="sub-font">{{ 'WHAT_TO_DO_TODAY' | translate }}</span>
        </div>
    </div>

    <!-- Options for Applicant -->
    <div *ngIf="user.role.name === 'applicant'">
        <div *ngIf="mainOptions">
          <div style="margin-top: 10px">
            <div class="left-menu-btn">
              <div class="border-box c-pointer" (click)="showProfileFunctionApplicant()">
                <div class="left-menu-btn-con">
                  <i class="fa fa-user left-menu-icon"></i>
                  <span class="sub-font left-menu-text" [ngClass]="{'my-2': !tabScreen}">{{ 'MODIFY_MY_PROFILE' | translate }}</span>
                </div>
                <i class="fa fa-arrow-right left-menu-icon-sec" aria-hidden="true"></i>
              </div>
              <div class="border-box c-pointer" (click)="openEditApplications()">
                <div class="left-menu-btn-con">
                  <i class="fas fa-edit left-menu-icon" aria-hidden="true"></i>
                  <span class="sub-font left-menu-text" [ngClass]="{'my-2': !tabScreen}">{{ 'CONTINUE_APPLICATION' | translate }}</span>
                </div>
                <i class="fa fa-arrow-right left-menu-icon-sec" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="seeApplications">
            <div class="row padding-class" style="margin-top: 20px">
                <div class="col-md-12 c-pointer" (click)="backToPerferencesApplicant()">
                    <span class="sub-font">
                    <i class="fas fa-arrow-left c-pointer" style="margin-right: 10px"></i>
                    {{ 'BACK_TO_PREFERENCES' | translate }} / {{ 'PROFILE' | translate }}
                    </span>
                </div>
            </div>
            <div *ngIf="!appDetailShow" class="mb-2">
              <div>
                <button class="btn btn-outline-dark lsb-btn-font my-3" (click)="addApplicationApplicant()">
                  <i class="fas fa-plus"></i> {{ 'ADD_NEW_APPLICATION' | translate }}
                </button>
              </div>
              <label class="m-1 c-font-10" for="sortOption">{{ 'SORT_BY' | translate }}:</label>
              <select class="c-select c-font-10" id="sortOption" [(ngModel)]="selectedOptionApplications" (change)="onOptionChangeApplications($event.target.value)">
                <option value="application_name">{{ 'NAME' | translate }}</option>
                <option value="created_at">{{ 'SORT_BY_CREATED' | translate }}</option>
                <option value="updated_at">{{ 'SORT_BY_UPDATE' | translate }}</option>
              </select>
            </div>
          <div *ngIf="!appDetailShow" class="applications-viewer">
            <div class="application-tab c-pointer" *ngFor="let application of applications; let i = index" (click)="sendApplication(application.id, application.application_name)">
              <div (click)="toggleSidebar()" class="row" style="padding-left: 10px">
                <div class="col-2">
                  <i class="fa fa-home" style="font-size: 20px" aria-hidden="true"></i>
                </div>
                <div class="col-8">
                  <span class="application-tab-text">
                    {{ application.application_name }}
                  </span>
                </div>
                <div class="col-2">
                  <i class="fa fa-arrow-right float-right" style="font-size: 20px;" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="appDetailShow" class="pt-3 sub-font">
            <span>{{ 'ENTER_DETAIL_RIGHT' | translate }} </span>
            <div *ngIf="mobileScreen" class="my-3">
              <span *ngIf="application_name" class="heading-font c-font-20">{{ application_name }}</span>
            </div>
          </div>
        </div>
        <div *ngIf="showProfileApplicant">
            <div class="row padding-class" style="margin-top: 20px">
              <div class="col-md-12 c-pointer" (click)="backToPerferencesApplicant()">
                <span class="sub-font">
                  <i class="fas fa-arrow-left c-pointer" style="margin-right: 10px"></i>
                  {{ 'BACK_TO_PREFERENCES' | translate }} / {{ 'PROFILE' | translate }} <span *ngIf="setNewPassword">/ {{ 'UPDATE_PASSWORD' | translate }}</span>
                </span>
              </div>
            </div>
            <div *ngIf="!setNewPassword" class="row" style="padding-top:20px;">
              <div class="col-md-6 col-lg-12" style="padding-top: 0px">
                <ngx-user-update (updateCheckLogin)="updateSendUser($event)" (getUpdatePassword)="openUpdatePassword($event)"></ngx-user-update>
              </div>
            </div>
            <div *ngIf="setNewPassword" class="row" style="padding-top:20px;">
              <div class="col-md-6 col-lg-12" style="padding-top: 0px">
                <div class="steps-inner">
                  <div class="form-group mb-3">
                    <label for="firstName">{{ 'NEW_PASSWORD' | translate }}</label>
                    <input type="password" class="form-control input-select input-white w-100" [(ngModel)]="updatePassword.newPassword" placeholder="{{ 'NEW_PASSWORD' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                  </div>
                  <div class="form-group mb-3">
                    <label for="lastName">{{ 'NEW_PASSWORD_CONFIRM' | translate }}</label>
                    <input type="password" class="form-control input-select input-white w-100" [(ngModel)]="updatePassword.newPasswordConfirm" placeholder="{{ 'NEW_PASSWORD_CONFIRM' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                  </div>
                  <button class="btn btn-outline-dark lsb-btn-font btn-sm w-50"  (click)="newPassword()"> {{ 'UPDATE' | translate }} </button>
                </div>
              </div>
            </div>
          </div>
      </div>

    <!-- Options for Broker -->
    <div *ngIf="user.role.name === 'broker'">
        <div *ngIf="mainOptions">
            <div style="margin-top: 10px">
              <div class="left-menu-btn">
                <div class="border-box c-pointer" (click)="showApplicantsFunction()">
                  <div class="left-menu-btn-con">
                    <i class="fas fa-edit left-menu-icon"></i>
                    <span class="sub-font left-menu-text" [ngClass]="{'my-2': !tabScreen}">{{ 'CONTINUE_APPLICATION' | translate }}</span>
                  </div>
                  <i class="fa fa-arrow-right left-menu-icon-sec" aria-hidden="true"></i>
                </div>
                <div class="border-box c-pointer" (click)="showPerferencesFunction()">
                  <div class="left-menu-btn-con">
                    <i class="fa fa-solid fa-cogs left-menu-icon" style="font-weight: 600;" aria-hidden="true"></i>
                    <span class="sub-font left-menu-text" [ngClass]="{'my-2': !tabScreen}">{{ 'MANAGE_PREFERENCES' | translate }}</span>
                  </div>
                  <i class="fa fa-arrow-right left-menu-icon-sec" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
        <!-- Option Number 1 -->
        <div *ngIf="showApplicants">
            <div class="row padding-class" style="margin-top: 20px">
              <div class="col-md-12 c-pointer" (click)="backToMainFrom1()">
                <span class="sub-font">
                  <i class="fas fa-arrow-left c-pointer" style="margin-right: 10px"></i>
                  {{ 'BACK_TO_MAIN' | translate }} / {{ 'LEADS' | translate }}
                </span>
              </div>
            </div>
            <div class="mb-2">
              <div class="d-flex my-1 justify-content-end">
                <span class="c-pointer" (click)="getCsvModel('open', 'applicants')"><i class="fas fa-share-alt"></i></span>
              </div>
              <button class="btn btn-outline-dark lsb-btn-font mb-3 mt-2" (click)="addApplicant()">
                <i class="fas fa-plus"></i> {{ 'ADD_NEW_APPLICANT' | translate }}
              </button>
              <div>
                <label class="m-1 c-font-10" for="sortOptionApplicants">{{ 'SORT_BY' | translate }}:</label>
                <select class="c-select c-font-10" id="sortOptionApplicants" [(ngModel)]="selectedOptionApplicants" (change)="onOptionChangeApplicants($event.target.value)">
                  <option value="name">{{ 'NAME' | translate }}</option>
                  <option value="created_at">{{ 'SORT_BY_CREATED' | translate }}</option>
                  <option value="updated_at">{{ 'SORT_BY_UPDATE' | translate }}</option>
                </select>
              </div>
            </div>
            <div class="applications-viewer">
              <div class="application-tab p-1 c-pointer" *ngFor="let applicant of applicants" (click)="getApplicant(applicant.id)">
                <div class="row">
                  <div class="col-2">
                    <i class="fas fa-home" style="font-size: 20px" aria-hidden="true"></i>
                  </div>
                  <div class="col-8">
                    <span class="application-tab-text" style="font-size: 15px">{{ applicant.name }} {{applicant.last_name}}</span>
                    <span class="application-tab-text" style="font-size: 15px" *ngIf="!applicant.name && applicant.phone">{{ applicant.phone }}</span>
                    <span class="application-tab-text" style="font-size: 15px" *ngIf="!applicant.name && !applicant.phone">[ No Information ]</span>
                  </div>
                  <div class="col-2">
                    <i class="fa fa-arrow-right float-right" style="font-size: 20px;" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div *ngIf="applicantDetails  && !appDetailShow">
            <div class="row padding-class" style="margin-top: 20px">
              <div class="col-md-12 c-pointer" (click)="backTo1FromApplicant()">
                <span class="sub-font">
                  <i class="fas fa-arrow-left c-pointer" style="margin-right: 10px"></i>
                  {{ 'BACK_TO_MAIN' | translate }} / {{ 'LEADS' | translate }} / {{ 'DETAIL' | translate }}
                </span>
              </div>
            </div>
            <div *ngIf="!editApplicantInfo" class="row">
              <div class="col-md-12">
                <span class="heading-font c-font-20" *ngIf="selectedApplicant.name">{{ selectedApplicant.name }} {{ selectedApplicant.last_name }}<span class="pl-1 c-pointer" (click)="editApplicant('open')"><i class="fas fa-edit"  style="font-size: 15px"></i></span></span>
                <span class="heading-font c-font-20" *ngIf="!selectedApplicant.name">{{ 'NO_NAME' | translate }}</span><br>
              </div>
              <div class="col-md-12 mb-3">
                <div class="sub-font"><strong>{{ 'MOBILE' | translate }}: </strong>(+1){{ selectedApplicant.phone }}</div>
                <div class="sub-font"><strong>{{ 'EMAIL' | translate }}: </strong>{{ selectedApplicant.email }}</div>
              </div>
            </div>
            <div *ngIf="editApplicantInfo" class="row py-3">
              <div class="col-md-6 col-lg-12" style="padding-top: 0px">
                <div class="pl-1 c-pointer d-flex justify-content-end" (click)="editApplicant('close')"><i class="fas fa-times"  style="font-size: 15px"></i></div>
                <div class="steps-inner">
                  <div class="form-group mb-3">
                    <label for="firstName">{{ 'FIRST_NAME' | translate }}</label>
                    <input type="text" class="form-control input-select input-white w-100" (input)="onInputChange()" [(ngModel)]="selectedApplicant.name" placeholder="{{ 'FIRST_NAME' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                  </div>
                  <div class="form-group mb-3">
                    <label for="lastName">{{ 'LAST_NAME' | translate }}</label>
                    <input type="text" class="form-control input-select input-white w-100" (input)="onInputChange()" [(ngModel)]="selectedApplicant.last_name" placeholder="{{ 'LAST_NAME' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                  </div>
                  <div class="form-group mb-3">
                    <label for="profileEmail">{{ 'EMAIL' | translate }}</label>
                    <input type="email" class="form-control input-select input-white w-100" (input)="onInputChange()" [(ngModel)]="selectedApplicant.email" placeholder="{{ 'EMAIL_ADDRESS' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                  </div>
                  <div class="form-group mb-3">
                    <label for="exampleInputEmail1">{{ 'MOBILE' | translate }}</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><img src="../../assets/images/ca.png" alt="CA" height="10px" width="15px" style="margin-right: 5px;">+1</span>
                      </div>
                      <input type="text" inputmode="decimal" maxlength="12" (input)="onInputChange()" class="form-control input-select input-white" [(ngModel)]="selectedApplicant.phone" (keypress)="allowDecimal($event)" placeholder="{{ 'MOBILE' | translate }}" style="height: 42px" onkeypress="return event.charCode >= 48 && event.charCode <= 57" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxPhoneNumberFormatter>
                    </div>
                  </div>
                  <button *ngIf="isUpdateButtonEnabled" class="btn btn-outline-dark lsb-btn-font btn-sm w-100" [disabled]="updatingLoader"  (click)="updateApplicant()">     
                    <span *ngIf="!updatingLoader">{{ 'UPDATE' | translate }}</span>
                   <span *ngIf="updatingLoader">
                     <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                     {{ 'UPDATING' | translate }}
                   </span>
                 </button>
                </div>
              </div>
            </div>
            <div *ngIf="!appDetailShow" class="mb-2">
              <div>
                <div class="d-flex my-1 justify-content-end">
                  <span class="c-pointer" (click)="getCsvModel('open', 'applicant')"><i class="fas fa-share-alt"></i></span>
                </div>
                <button class="btn btn-outline-dark lsb-btn-font  mb-3 mt-2" (click)="addApplication()">
                  <i class="fas fa-plus"></i> {{ 'ADD_NEW_APPLICATION' | translate }}
                </button>
              </div>
              <label class="m-1 c-font-10" for="sortOption">{{ 'SORT_BY' | translate }}:</label>
              <select class="c-select c-font-10" id="sortOption" [(ngModel)]="selectedOptionApplications" (change)="onOptionChangeApplicantApplications($event.target.value)">
                <option value="application_name">{{ 'NAME' | translate }}</option>
                <option value="created_at">{{ 'SORT_BY_CREATED' | translate }}</option>
                <option value="updated_at">{{ 'SORT_BY_UPDATE' | translate }}</option>
              </select>
            </div>
            <div *ngIf="!appDetailShow" class="applications-viewer c-mb-5">
              <div class="application-tab p-1 c-pointer" *ngFor="let appApp of applicantsApplications; let i = index" (click)="sendApplicationShareable(appApp.id, appApp.application_name)">
                <div (click)="toggleSidebar()" class="row">
                  <div class="col-2 d-flex m-auto">
                    <i class="fas fa-home" style="font-size: 20px" aria-hidden="true"></i>
                  </div>
                  <div class="col-8">
                    <span class="application-tab-text" style="font-size: 15px">
                      {{ appApp.application_name }}
                    </span>
                  </div>
                  <div class="col-2 d-flex m-auto justify-content-end">
                    <i class="fa fa-arrow-right float-right" style="font-size: 20px;" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="row padding-class" style="margin-top: 20px">
              <div class="col-md-12">
                <button class="btn btn-outline-dark lsb-btn-font" (click)="getApplicantsApplications(selectedApplicant.id)">
                  <i class="fas fa-edit"></i>
                  <span style="padding-left: 10px; padding-right: 10px">{{ 'SHOW_APPLICATION' | translate }}</span>
                  <i class="fa fa-arrow-right" style="font-size: 20px;" aria-hidden="true"></i>
                </button>
              </div>
            </div> -->
        </div>
        <div *ngIf="showApplicantsApplications">
            <div class="row padding-class" style="margin-top: 20px">
              <div class="col-md-12 c-pointer" (click)="backToApplicantFromApplicantsApplications(); applicantOriginalData()">
                <span class="sub-font">
                  <i class="fas fa-arrow-left c-pointer" style="margin-right: 10px"></i>
                  {{ 'BACK_TO_MAIN' | translate }} / {{ 'LEADS' | translate }} / {{ 'DETAIL' | translate }} / {{ 'REQUESTED' | translate }} 
                </span>
              </div>
            </div>
            <!-- <div *ngIf="!appDetailShow" class="applications-viewer">
              <button class="btn btn-outline-dark lsb-btn-font my-3" (click)="addApplication()">
                <i class="fas fa-plus"></i> {{ 'ADD_NEW_APPLICATION' | translate }}
              </button>
              <div class="application-tab p-1 c-pointer" *ngFor="let appApp of applicantsApplications; let i = index" (click)="sendApplicationShareable(appApp.id)">
                <div (click)="toggleSidebar()" class="row">
                  <div class="col-2">
                    <i class="fas fa-home" style="font-size: 20px" aria-hidden="true"></i>
                  </div>
                  <div class="col-8">
                    <span class="application-tab-text" style="font-size: 15px">
                      {{ appApp.application_name }}
                    </span>
                  </div>
                  <div class="col-2">
                    <i class="fa fa-arrow-right float-right" style="font-size: 20px;" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div> -->
            <div *ngIf="appDetailShow" class="pt-3 sub-font">
              <span>{{ 'ENTER_DETAIL_RIGHT' | translate }} </span>

              <div *ngIf="mobileScreen" class="my-3">
                <span *ngIf="application_name" class="heading-font c-font-20">{{ application_name }}</span>
              </div>

              <!-- Rest of the stuff goes here -->
              <div class="col-12">
                <!-- <div class="form-group c-text-area-bg pt-2">
                  <button class="float-right c-tooltip border-0" (click)="copyToClipboardUrl()"><i class="far fa-copy mx-3 mb-2"></i><span class="c-tooltiptext">{{ tooltipText }}</span></button>
                  <textarea class="form-control c-text-area" rows="8" id="comment" readonly>{{ broker_hash_url }}</textarea>
                </div> -->
                <div class="my-4">
                  <div>
                    <div class="d-flex my-1 justify-content-end">
                      <span class="c-pointer" (click)="getCsvModel('open', 'boss_application')"><i class="fas fa-share-alt"></i></span>
                    </div>
                  </div>
                  <div>
                    <label for="shareLink"><strong>{{ 'SHARE_LINK' | translate }}</strong></label>
                    <div class="input-group mb-3">
                      <input type="text" class="form-control" placeholder="{{ 'APPLICANT_EMAIL' | translate }}" aria-label="Recipient's username" aria-describedby="basic-addon2" value="{{ broker_hash_url }}">
                      <div class="input-group-append c-pointer" (click)="copyToClipboardUrl()">
                        <span class="input-group-text float-right c-tooltip" id="basic-addon2"><i class="far fa-copy"></i><span class="c-tooltiptext">{{ tooltipText }}</span></span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label for="shareVia"><strong>{{ 'SHARE_VIA' | translate }}</strong></label>
                    <div class="input-group mb-3">
                      <input type="text" class="form-control" placeholder="{{ 'APPLICANT_EMAIL' | translate }}" aria-label="Recipient's username" aria-describedby="basic-addon2" [(ngModel)]="selectedApplicant.email">
                      <div class="input-group-append c-pointer" (click)="triggerEmail()">
                        <span class="input-group-text float-right c-tooltip" id="basic-addon2"><i class="fas fa-envelope"></i><span class="c-tooltiptext">{{ 'EMAIL_TOOLTIP' | translate }}</span></span>
                      </div>
                    </div>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><img src="../../assets/images/ca.png" alt="CA" height="10px" width="15px" style="margin-right: 5px;">+1</span>
                      </div>
                      <input type="text" inputmode="decimal" maxlength="12" class="form-control" placeholder="{{ 'APPLICANT_PHONE' | translate }}" aria-label="Recipient's username" (keypress)="allowDecimal($event)" aria-describedby="basic-addon2" [(ngModel)]="selectedApplicant.phone" ngxPhoneNumberFormatter>
                      <div class="input-group-append c-pointer" (click)="unformattedPhoneNumber(selectedApplicant.phone)">
                        <span class="input-group-text float-right c-tooltip" id="basic-addon2"><i class="fas fa-sms"></i><span class="c-tooltiptext">{{ 'PHONE_TOOLTIP' | translate }}</span></span>
                      </div>
                    </div>
                  </div>
                  <button *ngIf="mobileScreen" class="btn btn-outline-dark lsb-btn-font my-5 w-100" (click)="goToApplication()">{{ 'BACK_TO_APPLICATION' | translate }}</button>
                </div>
              </div>

            </div>
        </div>
        <div *ngIf="newApplicantForm">
            <div class="row padding-class" style="margin-top: 20px">
              <div class="col-md-12 c-pointer" (click)="backToApplicantsFromNewApplicant()">
                <span class="sub-font">
                  <i class="fas fa-arrow-left c-pointer" style="margin-right: 10px"></i>
                  {{ 'BACK_TO_MAIN' | translate }} / {{ 'LEADS' | translate }} / {{ 'NEW_APPLICANT' | translate }}
                </span>
              </div>
            </div>
            <div class="row" style="padding-top: 20px;">
              <div class="col-md-6 col-lg-12" style="padding-top: 0px">
                <div class="steps-inner">
                  <div class="input-group mb-3">
                    <span class="sub-font">{{ 'ENTER_DETAILS' | translate }}</span>
                  </div>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control input-select input-white" [(ngModel)]="new_name" placeholder="{{ 'FIRST_NAME' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                  </div> 
                  <div class="input-group mb-3">
                    <input type="text" class="form-control input-select input-white" [(ngModel)]="new_lastName" placeholder="{{ 'LAST_NAME' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                  </div>
                  <div class="input-group mb-3">
                    <input type="email" class="form-control input-select input-white" [(ngModel)]="new_email" placeholder="{{ 'EMAIL_ADDRESS' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                  </div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><img src="../../assets/images/ca.png" alt="CA" height="10px" width="15px" style="margin-right: 5px;">+1</span>
                    </div>
                    <input type="text" inputmode="decimal" maxlength="12" class="form-control input-select input-white" [(ngModel)]="new_phone" placeholder="{{ 'PHONE' | translate }}" (keypress)="allowDecimal($event)" style="height: 42px" onkeypress="return event.charCode >= 48 && event.charCode <= 57" style="-moz-appearance: textfield; -webkit-appearance: textfield;" ngxPhoneNumberFormatter>
                  </div>
                  <div class="input-group mb-3" *ngIf="false">
                    <input type="password" class="form-control input-select input-white" [(ngModel)]="new_password" placeholder="{{ 'PASSWORD' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                  </div>
                  <button class="btn btn-outline-dark lsb-btn-font btn-sm w-100" (click)="createApplicant()"> {{ 'CREATE_BUTTON' | translate }} </button>
                </div>
              </div>
            </div>
        </div>
        <div *ngIf="newApplication">
            <div class="row padding-class" style="margin-top: 20px">
              <div class="col-md-12 c-pointer" (click)="backToApplicantsApplicationsFromNewApplication()">
                <span class="sub-font">
                  <i class="fas fa-arrow-left c-pointer" style="margin-right: 10px"></i>
                  {{ 'BACK_TO_MAIN' | translate }} / {{ 'LEADS' | translate }} / {{ 'NEW_APPLICATION' | translate }}
                </span>
              </div>
            </div>
            <div class="row" style="padding-top: 20px;">
              <div class="col-md-12" style="padding-top: 0px">
                <div class="steps-inner">
                  <div class="input-group mb-3">
                    <span class="sub-font"> {{ 'ENTER_APP_DETAILS' | translate }} </span>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <!-- Option Number 2 -->
        <div *ngIf="seeApplications">
            <div class="row padding-class" style="margin-top: 20px">
                <div class="col-md-12 c-pointer" (click)="backToMainFrom1()">
                    <span class="sub-font">
                    <i class="fas fa-arrow-left c-pointer" style="margin-right: 10px"></i>
                    {{ 'BACK_TO_PREFERENCES' | translate }} / {{ 'APPLICATIONS' | translate }}
                    </span>
                </div>
           </div>
          <div *ngIf="!appDetailShow" class="applications-viewer">
            <button class="btn btn-outline-dark lsb-btn-font my-3" (click)="addApplication()">
                <i class="fas fa-plus"></i> {{ 'ADD_NEW_APPLICATION' | translate }}
            </button>
            <div class="application-tab c-pointer" *ngFor="let application of applications; let i = index" (click)="sendApplication(application.id, application.application_name)">
              <div (click)="toggleSidebar()" class="row" style="padding-left: 10px">
                <div class="col-2">
                  <i class="fa fa-home" style="font-size: 20px" aria-hidden="true"></i>
                </div>
                <div class="col-8">
                  <span class="application-tab-text">
                    {{ application.application_name }}
                  </span>
                </div>
                <div class="col-2">
                  <i class="fa fa-arrow-right float-right" style="font-size: 20px;" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="appDetailShow" class="pt-3 sub-font">
            <span>{{ 'ENTER_DETAIL_RIGHT' | translate }}</span>
          </div>
        </div>
        <div *ngIf="newApplicationBroker">
            <div class="row padding-class" style="margin-top: 20px">
              <div class="col-md-12 c-pointer" (click)="backToApplicantFromApplicantsApplications()">
                <span class="sub-font">
                  <i class="fas fa-arrow-left c-pointer" style="margin-right: 10px"></i>
                  {{ 'BACK_TO_MAIN' | translate }} / {{ 'NEW_APPLICATION' | translate }}
                </span>
              </div>
            </div>
            <div class="row" style="padding-top: 20px;">
              <div class="col-md-12" style="padding-top: 0px">
                <div class="steps-inner">
                  <div class="input-group mb-3">
                    <span class="sub-font"> {{ 'ENTER_APP_DETAILS' | translate }} </span>
                    <div class="col-12 sub-font" *ngIf="appExist && selectedApplicant">

                      <div class="my-4">
                        <div>
                          <div class="d-flex my-1 justify-content-end">
                            <span class="c-pointer" (click)="getCsvModel('open', 'boss_application')"><i class="fas fa-share-alt"></i></span>
                          </div>
                        </div>
                        <div>
                          <label for="shareLink"><strong>{{ 'SHARE_LINK' | translate }}</strong></label>
                          <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="{{ 'APPLICANT_EMAIL' | translate }}" aria-label="Recipient's username" aria-describedby="basic-addon2" value="{{ broker_hash_url }}">
                            <div class="input-group-append c-pointer" (click)="copyToClipboardUrl()">
                              <span class="input-group-text float-right c-tooltip" id="basic-addon2"><i class="far fa-copy"></i><span class="c-tooltiptext">{{ tooltipText }}</span></span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <label for="shareVia"><strong>{{ 'SHARE_VIA' | translate }}</strong></label>
                          <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="{{ 'APPLICANT_EMAIL' | translate }}" aria-label="Recipient's username" aria-describedby="basic-addon2" [(ngModel)]="selectedApplicant.email">
                            <div class="input-group-append c-pointer" (click)="triggerEmail()">
                              <span class="input-group-text float-right c-tooltip" id="basic-addon2"><i class="fas fa-envelope"></i><span class="c-tooltiptext">{{ 'EMAIL_TOOLTIP' | translate }}</span></span>
                            </div>
                          </div>
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><img src="../../assets/images/ca.png" alt="CA" height="10px" width="15px" style="margin-right: 5px;">+1</span>
                            </div>
                            <input type="text" inputmode="decimal" maxlength="12" class="form-control" placeholder="{{ 'APPLICANT_PHONE' | translate }}" aria-label="Recipient's username" (keypress)="allowDecimal($event)" aria-describedby="basic-addon2" [(ngModel)]="selectedApplicant.phone" ngxPhoneNumberFormatter>
                            <div class="input-group-append c-pointer" (click)="unformattedPhoneNumber(selectedApplicant.phone)">
                              <span class="input-group-text float-right c-tooltip" id="basic-addon2"><i class="fas fa-sms"></i><span class="c-tooltiptext">{{ 'PHONE_TOOLTIP' | translate }}</span></span>
                            </div>
                          </div>
                        </div>
                        <button *ngIf="mobileScreen" class="btn btn-outline-dark lsb-btn-font my-5 w-100" (click)="goToApplication()">{{ 'BACK_TO_APPLICATION' | translate }}</button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <!-- Option Number 4 -->
        <div *ngIf="showPerferences">
            <div class="row padding-class" style="margin-top: 20px">
              <div class="col-md-12 c-pointer" (click)="backToMainFrom1()">
                <span class="sub-font">
                  <i class="fas fa-arrow-left c-pointer" style="margin-right: 10px"></i>
                  {{ 'BACK_TO_MAIN' | translate }} / {{ 'PREFERENCES' | translate }}
                </span>
              </div>
            </div>
            <div style="margin-top: 10px">
              <div class="c-left-menu-btn">
                <div class="c-menu-lft-tab-box c-pointer" (click)="showProfileFunction()">
                  <div class="c-left-menu-btn-con">
                    <i class="fa fa-user c-left-menu-icon" style="font-weight: 600;" aria-hidden="true"></i>
                    <span class="sub-font c-left-menu-text">{{ 'PROFILE' | translate }}</span>
                  </div>
                  <i class="fa fa-arrow-right c-left-menu-icon-sec" aria-hidden="true"></i>
                </div>
                <div class="c-menu-lft-tab-box c-pointer" (click)="showIdentityProofFunction()">
                  <div class="c-left-menu-btn-con">
                    <i class="fa fa-code c-left-menu-icon"  style="font-weight: 600;"></i>
                    <span class="sub-font c-left-menu-text">{{ 'EMBEDDED_CODE' | translate }}</span>
                  </div>
                  <i class="fa fa-arrow-right c-left-menu-icon-sec" aria-hidden="true"></i>
                </div>
                <div class="c-menu-lft-tab-box c-pointer" (click)="showBusinessInfoSection()">
                  <div class="c-left-menu-btn-con">
                    <i class="fa fa-briefcase c-left-menu-icon"  style="font-weight: 600;"></i>
                    <span class="sub-font c-left-menu-text">{{ 'BUSINESS_INFOMATION' | translate }}</span>
                  </div>
                  <i class="fa fa-arrow-right c-left-menu-icon-sec" aria-hidden="true"></i>
                </div>
                <!-- <div class="c-menu-lft-tab-box c-pointer c-disabledbutton" (click)="showSubscriptionsFunction()"> -->
                <div class="c-menu-lft-tab-box c-pointer c-disabledbutton" *ngIf="false">
                  <div class="c-left-menu-btn-con">
                    <i class="fa fa-user-cog c-left-menu-icon" style="font-weight: 600;" aria-hidden="true"></i>
                    <span class="sub-font c-left-menu-text">{{ 'SUBSCRIPTIONS' | translate }}</span>
                  </div>
                  <i class="fa fa-arrow-right c-left-menu-icon-sec" aria-hidden="true"></i>
                </div>
                <!-- <div class="c-menu-lft-tab-box c-pointer c-disabledbutton" (click)="showBillingFunction()"> -->
                <div class="c-menu-lft-tab-box c-pointer" (click)="showBillingFunction()" *ngIf="!user.account_status">
                  <div class="c-left-menu-btn-con">
                    <i class="fas fa-file-invoice-dollar c-left-menu-icon" style="font-weight: 600;"></i>
                    <span class="sub-font c-left-menu-text">{{ 'BILLING' | translate }}</span>
                  </div>
                  <i class="fa fa-arrow-right c-left-menu-icon-sec" aria-hidden="true"></i>
                </div>

                <div class="c-menu-lft-tab-box c-pointer" (click)="showBillingFunction()" *ngIf="user.account_status && user.account_status.status == 'active'">
                  <div class="c-left-menu-btn-con">
                    <i class="fas fa-file-invoice-dollar c-left-menu-icon" style="font-weight: 600;"></i>
                    <span class="sub-font c-left-menu-text">{{ 'BILLING_AND_SUBSCRIPTION' | translate }}</span>
                  </div>
                  <i class="fa fa-arrow-right c-left-menu-icon-sec" aria-hidden="true"></i>
                </div>
              </div>
            </div>
        </div>
        <!-- Profil -->
        <div *ngIf="showProfile">
            <div class="row padding-class" style="margin-top: 20px">
              <div class="col-md-12 c-pointer" (click)="backToPerferences()">
                <span class="sub-font">
                  <i class="fas fa-arrow-left c-pointer" style="margin-right: 10px"></i>
                  {{ 'BACK_TO_PREFERENCES' | translate }} / {{ 'PROFILE' | translate }} <span *ngIf="setNewPassword">/ {{ 'UPDATE_PASSWORD' | translate }}</span>
                </span>
              </div>
            </div>
            <div *ngIf="!setNewPassword" class="row" style="padding-top:20px;">
              <div class="col-md-6 col-lg-12" style="padding-top: 0px">
                <ngx-user-update (updateCheckLogin)="updateSendUser($event)" (getUpdatePassword)="openUpdatePassword($event)" (tiggerCheckLogin)="checkLoggedIn()"></ngx-user-update>
              </div>
            </div>
            <div *ngIf="setNewPassword" class="row" style="padding-top:20px;">
              <div class="col-md-6 col-lg-12" style="padding-top: 0px">
                <div class="steps-inner">
                  <div class="form-group mb-3">
                    <label for="firstName">{{ 'NEW_PASSWORD' | translate }}</label>
                    <input type="password" class="form-control input-select input-white w-100" [(ngModel)]="updatePassword.newPassword" placeholder="{{ 'NEW_PASSWORD' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                  </div>
                  <div class="form-group mb-3">
                    <label for="lastName">{{ 'NEW_PASSWORD_CONFIRM' | translate }}</label>
                    <input type="password" class="form-control input-select input-white w-100" [(ngModel)]="updatePassword.newPasswordConfirm" placeholder="{{ 'NEW_PASSWORD_CONFIRM' | translate }}" style="height: 42px" style="-moz-appearance: textfield; -webkit-appearance: textfield;">
                  </div>
                  <button class="btn btn-outline-dark lsb-btn-font btn-sm w-50"  (click)="newPassword()"> {{ 'UPDATE' | translate }} </button>
                </div>
              </div>
            </div>
          </div>
          

        <!-- Code intégré -->
        <div *ngIf="showIdentityProof" class="ec-width c-mb-5">
            <div class="row padding-class" style="margin-top: 20px">
              <div class="col-md-12 mb-3 c-pointer" (click)="backToPerferences()">
                <span class="sub-font"> 
                  <i class="fas fa-arrow-left c-pointer" style="margin-right: 10px"></i>
                  {{ 'BACK_TO_PREFERENCES' | translate }} / {{ 'EMBEDDED_CODE' | translate }}
                </span>
              </div>
              <ngx-embedded-code class="w-100"></ngx-embedded-code>
            </div>
        </div>
        
        <!-- business Info Section -->
        <div *ngIf="businessInfoSection">
            <div class="row padding-class" style="margin-top: 20px">
              <div class="col-md-12 mb-3 c-pointer" (click)="backToPerferences()">
                <span class="sub-font"> 
                  <i class="fas fa-arrow-left c-pointer" style="margin-right: 10px"></i>
                  {{ 'BACK_TO_PREFERENCES' | translate }} / {{ 'BUSINESS_INFOMATION' | translate }}
                </span>
              </div>
            </div>
            <div class="row" style="padding-top:20px;">
              <div class="col-md-6 col-lg-12" style="padding-top: 0px">
                <ngx-business-information (updateCheckLogin)="updateSendUser($event)"  (tiggerCheckLogin)="checkLoggedIn()"></ngx-business-information>
              </div>
            </div>
        </div>

        <!-- Abonnements -->
        <div *ngIf="showSubscriptions" class="subscription-width">
            <div class="row padding-class mb-5">
                <div class="col-md-12 c-pointer" (click)="backToPerferences()">
                  <span class="sub-font"> 
                    <i class="fas fa-arrow-left c-pointer" style="margin-right: 10px"></i>
                    {{ 'BACK_TO_PREFERENCES' | translate }} / {{ 'SUBSCRIPTION' | translate }}
                  </span>
                </div>
                <div class="col-12 mt-4">
                  <p>{{ 'PAYMENT_SECURITY_MESSAGE' | translate }}</p>
                  <button type="button" (click)="setPaymentMode()" class="btn btn-dark border-0" [ngClass]="{'d-none': paymentMode}">{{ 'CONFIGURE_PAYMENT_METHOD' | translate }}</button>
                  <div *ngIf="paymentMode">
                    <ngx-stripe-element></ngx-stripe-element>
                  </div>
                </div>
              </div>
            <!-- <div class="c-left-menu-btn">
                <div class="c-menu-lft-tab-box">
                    <div class="c-left-menu-btn-con">
                        <i class="fa fa-user-shield c-left-menu-icon" style="font-weight: 600;" aria-hidden="true"></i>
                        <span class="sub-font c-left-menu-text">Production de pistes</span>
                    </div>
                    <i class="fa fa-arrow-right c-left-menu-icon-sec" aria-hidden="true"></i>
                </div> 
                <div class="c-menu-lft-tab-box">
                    <div class="c-left-menu-btn-con">
                        <i class="fas fa-gem c-left-menu-icon" style="font-weight: 600;"></i>
                        <span class="sub-font c-left-menu-text">Kingstate Premium</span>
                    </div>
                    <i class="fa fa-arrow-right c-left-menu-icon-sec" aria-hidden="true"></i>
                </div>
            </div> -->
        </div>

            <!-- Production de pistes -->
            <!-- <div>
                <div class="row padding-class mb-5">
                    <div class="col-md-12">
                        <span class="sub-font c-pointer"> 
                            <i class="fas fa-arrow-left c-pointer" style="margin-right: 10px" (click)="backToPerferences()"></i>
                            Menu Principal / Préférences / Abonnement / Pistes
                        </span>
                    </div>
                </div>
                <div>
                    <h3 class="font-weight-light">Abonnement à la production de pistes</h3>
                    <p>La production de piste vous permet de recevoir des pistes de vente produites par Kingstate selon le sérieux des demandes reçues.</p>
                    <p>Vous ne recevez que les pistes qui sont assez sérieuses pour vous et vous payez seulement lorsque vous recevez une piste.</p>
                    <p><strong> Vérification d’identité nécessaire. Utilisez le menu ‘Preuve d’identité’ pour confirmer votre identité auprès de l’équipe Kingstate.</strong></p>
                </div>
                <div class="c-left-menu-btn">
                    <div class="c-menu-lft-tab-box">
                        <div class="c-left-menu-btn-con">
                            <i class="far fa-id-card c-left-menu-icon"></i>
                            <span class="sub-font c-left-menu-text">Preuve d’identité</span>
                        </div>
                        <i class="fa fa-arrow-right c-left-menu-icon-sec" aria-hidden="true"></i>
                    </div> 
                </div>
                <div class="c-left-menu-btn">
                    <div class="c-menu-lft-tab-box">
                        <div class="c-left-menu-btn-con">
                            <div class="round">
                                <input type="checkbox" checked id="checkbox" />
                                <label for="checkbox"></label>
                            </div>
                        </div>
                    </div> 
                </div>
            </div> -->

        <!-- Facturation -->
        <div *ngIf="showBilling">
            <div class="row padding-class mb-5">
              <div class="col-md-12 c-pointer" (click)="backToPerferences()">
                <span class="sub-font"> 
                  <i class="fas fa-arrow-left c-pointer" style="margin-right: 10px"></i>
                  {{ 'BACK_TO_PREFERENCES' | translate }} / {{ 'BILLING' | translate }}
                </span>
              </div>
            </div>
            <div class="c-left-menu-btn" (click)="openPortal()">
              <div class="c-menu-lft-tab-box c-pointer">
                <div class="c-left-menu-btn-con">
                  <i class="fab fa-stripe c-left-menu-icon" style="font-weight: 600;" aria-hidden="true"></i>
                  <span class="sub-font c-left-menu-text">{{ 'STRIPE_PORTAL' | translate }}</span>
                </div>
                <i class="fa fa-link c-left-menu-icon-sec" aria-hidden="true"></i>
              </div> 
            </div>
        </div>
    </div>
</div>
<!-- LOGOUT SECTION -->
<!-- <div class="menu-content" style="position: fixed; bottom: 5%;" *ngIf="isLoggedIn">
    <div class="pt-4">
        <button class="btn btn-outline-dark lsb-btn-font"  (click)="logout()">
            <i class="fas fa-sign-out-alt"></i> Se déconnecter 
        </button>
    </div>
</div> -->
<div id="leftBarBottomBtn" class="fix-menu-btn pl-3 pr-2 bottom-btn-pos" style="position: fixed; left: 0;"  *ngIf="isLoggedIn && mainOptions">
    <div *ngIf="user.role.name == 'applicant' && user.broker" class="broker-info-box m-2">
      <div class="p-2">
        <h4 style="font-weight: 600">{{ user.broker.name }} {{ user.broker?.last_name }}</h4>
        <p class="my-1">{{ 'MORTGAGE_BROKER' | translate }}</p>
        <hr>
        <p class="my-2"><i class="fas fa-envelope" style="font-size: 18px; font-weight: 100; margin: auto"></i> {{ user.broker.email }}</p>
        <p class="my-2"><i class="fas fa-phone-alt" style="font-size: 18px; margin: auto"></i> (+1){{ user.broker.phone }}</p>
      </div>
    </div>
    <div *ngIf="user.role.name == 'applicant' && !user.broker">
        <div class="p-1 c-pointer" (click)="showNewsModal('open')" *ngIf="!user?.account_request || user?.account_request.status == 'approved'">
            <div class="border-box-bottom gradient text-light">
                <div class="d-flex">
                    <i class="fas fa-user-check" style="font-size: 25px" aria-hidden="true"></i>
                    <span class="sub-font fix-btn-w text-light" style="margin: auto 1rem; font-size: 11px;">
                    {{ 'REQUEST_BROKER_ACCOUNT' | translate }}
                    </span>
                </div>
                <i class="fa fa-arrow-right float-right" style="font-size: 20px; margin: auto 0;" aria-hidden="true"></i>
            </div>
        </div>
        <div class="p-1 c-pointer" (click)="showNewsModal('open')" *ngIf="user?.account_request && (user.account_request.status === 'pending' || user.account_request.status === 'onhold' || user.account_request.status === 'requested')">
            <div class="border-box-bottom menu-btn-bg">
                <div class="d-flex">
                    <i class="fas fa-user-clock" style="font-size: 25px" aria-hidden="true"></i>
                    <span class="sub-font fix-btn-w" style="margin: auto 1rem; font-size: 11px;">
                    {{ 'PENDING_BROKER_ACCOUNT' | translate }}
                    </span>
                </div>
                <i class="fa fa-arrow-right float-right" style="font-size: 20px; margin: auto 0;" aria-hidden="true"></i>
            </div>
        </div>
        <div class="p-1 c-pointer" (click)="showNewsModal('open')" *ngIf="user?.account_request && (user.account_request.status === 'rejected')">
            <div class="border-box-bottom danger-gradient text-light">
                <div class="d-flex">
                    <i class="fas fa-user-times" style="font-size: 25px" aria-hidden="true"></i>
                    <span class="sub-font fix-btn-w text-light" style="margin: auto 1rem; font-size: 11px;">
                    {{ 'REJECT_BROKER_ACCOUNT' | translate }}
                    </span>
                </div>
                <i class="fa fa-arrow-right float-right" style="font-size: 20px; margin: auto 0;" aria-hidden="true"></i>
            </div>
        </div>
    </div>
    <div class="p-1 c-pointer" (click)="logout()">
      <div class="border-box-bottom p-2 menu-btn-bg">
          <div class="d-flex">
              <i class="fas fa-sign-out-alt" style="font-size: 25px" aria-hidden="true"></i>
              <span class="sub-font fix-btn-w" style="margin: auto 1rem;">
                  {{ 'LOGOUT' | translate }}
              </span>
          </div>
          <i class="fa fa-arrow-right float-right" style="font-size: 20px; margin: auto 0;" aria-hidden="true"></i>
      </div>
  </div>
</div>
