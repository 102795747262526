import { Component, OnDestroy, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter, HostListener } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment'
import { Subject } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { TypeWriterTextService } from '../type-writer-text/type-writer-text.service';


declare global {
  interface Window {
    runBreadButter: (arg: string) => void;
  }
}
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() userData: any = null;
  @Input() brokerData: any = null;
  @Input() mobileViewer: any = null;
  @Input() bbModalValue: boolean = false;
  @Input() headerSize: boolean = false;
  @Input() loggedInUpdaterValue: boolean = false;
  @Input() mobileScreen: boolean = false;
  @Output() bbModalClose = new EventEmitter<boolean>();
  loggedIn = false;
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any = null;
  closed = false;
  broker = null;
  isIframe = false;
  baseUrl = null;
  showSocialLogin = true;
  scriptLoaded = false;
  setMobileScreen = false;
  setMobileViewer = null;
  setHeaderSize = false;
  getBrokerProfile: string = 'assets/images/kingstate_logo.png';
  checkBrokerProfile = false;
  tabScreen = false;
  businessInfoCheck = false;
  brokerAccountCheck = false;
  businessInformation ={
    name: '',
    address: '',
    email: '',
    phone: '',
  };
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userData && !changes.userData.firstChange) {
      this.user = this.userData;
      this.getBrokerProfile = '';
      setTimeout(() => {
        this.brokerProfile();
      }, 200);
      this.languageSettings();
    }
    if (changes.brokerData && !changes.brokerData.firstChange) {
      this.broker = this.brokerData;
      console.log(this.broker);
      this.socialPopup();
    }
    if (changes.mobileViewer && changes.mobileViewer.currentValue){
      this.setMobileViewer = this.mobileViewer;
    }
    if(changes.headerSize){
      this.setHeaderSize = this.headerSize;
    }
    if(changes.mobileScreen){
      this.setMobileScreen = this.mobileScreen;
    }
    if(changes.loggedInUpdaterValue){
      this.loggedIn = this.loggedInUpdaterValue;
      if(!this.loggedIn){
        this.businessInformation = null;
      } else {
        this.businessInformation ={
          name: '',
          address: '',
          email: '',
          phone: '',
        };
      }
      setTimeout(() => {
        this.brokerProfile();
      }, 200);
    }
  }

  languages = [
    {
      value: 'en',
      name: 'English',
      flag: 'assets/images/uk.png'
    },
    {
      value: 'fr',
      name: 'Français',
      flag: 'assets/images/france.png'
    },
  ];

  currentTheme = null;
  currentFlag = 'assets/images/france.png'

  userMenu = [ { title: 'Profile' }, { title: 'Log out' } ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private translate: TranslateService,
              private location: Location, 
              private titleService: Title,
              private http: HttpClient,
              private route: ActivatedRoute,
              private typeWriterTextService: TypeWriterTextService
              ) {
  }

  ngOnInit() {
    // this.currentTheme = this.themeService.currentTheme;
    this.currentTheme = 'fr';
    this.languageSettings();
    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);
    

    // const { xl } = this.breakpointService.getBreakpointsMap();
    // this.themeService.onMediaQueryChange()
    //   .pipe(
    //     map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
    //     takeUntil(this.destroy$),
    //   )
    //   .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    // this.themeService.onThemeChange()
    //   .pipe(
    //     map(({ name }) => name),
    //     takeUntil(this.destroy$),
    //   )
    //   .subscribe(themeName => this.currentTheme = themeName);
  }

  socialPopup() {
    if (window.self !== window.top) {
      console.log("This application is being opened inside an iframe.");
      this.baseUrl = window.location.origin;
      this.broker.sources.forEach(element => {
        if(element.status == 'added'){
          this.showSocialLogin = true;
        } 
      });
    } else {
      console.log("This application is not being opened inside an iframe.");
      this.showSocialLogin = true;
    }
  }

  languageSettings() {
    const languageUrl = this.route.snapshot.queryParamMap.get('lang');
    if(languageUrl){
      this.translate.use(languageUrl);
      this.currentTheme = languageUrl;
      console.log('language from params');
    }
    else if(this.user && this.user.language && !languageUrl){
      console.log('language from user');
      this.translate.use(this.user.language);
      this.currentTheme = this.user.language;
    }
    for (let lang of this.languages) {
      if (lang.value === this.currentTheme) {
        this.currentFlag = lang.flag;
      }
    }
    this.typeWriterTextService.getLanguage(this.currentTheme);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  changeLanguage(language: string) {
    this.translate.use(language);
    for (let lang of this.languages) {
      if (lang.value === language) {
        this.currentFlag = lang.flag;
      }
    }
    this.removeQueryParams();
    this.updateURLAndTitle(language);
    this.typeWriterTextService.getLanguage(this.currentTheme);
    this.http.post<any>(environment.apiBaseUrl+'set-language', {language: language}).subscribe(
      response => {
      },
      error => {
        console.error('Login error:', error);
      }
    );
  }

  closeTab() {
    this.closed = false;
    this.bbModalClose.emit(this.closed);
  }

  updateURLAndTitle(lang) {
    const newId = lang; // Replace with the desired parameter value
    const currentPath = this.location.path(); // Get the current path
  
    // Update the URL by appending the parameter
    const updatedUrl = `${currentPath}?lang=${newId}`;
    this.location.replaceState(updatedUrl);
  
    // Update the browser's title
    // this.titleService.setTitle(`Details Page | ID: ${newId}`);
  }

  // toggleSidebar(): boolean {
  //   this.sidebarService.toggle(true, 'menu-sidebar');
  //   this.layoutService.changeLayoutSize();

  //   return false;
  // }

  goToApplication(value: boolean){
    if(!value){
      this.toggleSidebar();
    }
  }

  toggleSidebar(): boolean {
    const sidebar = document.querySelector('.menu-sidebar'); // Assuming this selector matches the sidebar element
    const menuIcon = document.getElementById("menuIcon");
    const menuMobile = document.getElementById("mobile-menu");
    
  
    if (sidebar.classList.contains('small-sc')) {
      sidebar.classList.remove('small-sc');
      sidebar.classList.add('menu-show');
      menuMobile.classList.remove('mobile-menu-active');
      menuIcon.innerHTML = `<i class="fa fa-solid fa-times"></i>`;
    } else {
      sidebar.classList.add('small-sc');
      sidebar.classList.remove('menu-show');
      menuMobile.classList.add('mobile-menu-active');
      menuIcon.innerHTML = `<i class="fa fa-solid fa-bars"></i>`;
    }
  
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
  
    return false;
  }
  

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  removeQueryParams() {
    this.location.go(this.location.path().split('?')[0]); // Navigate to the same path without query parameters
  }

  callBreadButter() {
    // Call the function from index.html
    window.runBreadButter('open');
    this.scriptLoaded = true;
  }

  @HostListener('window:load', ['$event'])
  onLoad(event: Event) {
    const screenWidth = window.innerWidth;
    if (screenWidth < 1200) {
      this.tabScreen = true;
      this.brokerProfile();
    } else{
      this.tabScreen = false;
    }
  }

  brokerProfile() {
    if(this.user !== null){
      if(this.loggedIn && this.user.role.name === "broker"){
        this.brokerAccountCheck = true
        if(this.user.business_information !== null){
          if(this.user.business_information.logo !== null){
            const brokerProfileUrl = this.user.business_information.logo;
            this.getBrokerProfile = brokerProfileUrl;
            this.checkBrokerProfile = true;
          } else if(this.user.profile_picture !== null && this.user.business_information.logo === null){
            const brokerProfileUrl = this.user.profile_picture;
            this.getBrokerProfile = brokerProfileUrl;
            this.checkBrokerProfile = true;
          } else if(this.user.profile_picture === null && this.user.business_information.logo === null) {
            if(this.headerSize){
              if(this.user.business_information !== null){
                this.checkBrokerProfile = true;
                this.getBrokerProfile = 'assets/images/logo_small.png';
              } else{
                this.checkBrokerProfile = false;
                this.getBrokerProfile = 'assets/images/logo_small.png';
              }
            } else {
              if(this.user.business_information !== null){
                this.checkBrokerProfile = true;
                this.getBrokerProfile = 'assets/images/logo_small.png';
              } else{
                this.checkBrokerProfile = false;
                this.getBrokerProfile = 'assets/images/kingstate_logo.png';
              }
            }
          }
          if(this.user.business_information){
            this.businessInfo(this.user.business_information);
          } else {
            
            this.businessInformation ={
              name: '',
              address: '',
              email: '',
              phone: '',
            };
            this.businessInfo(this.businessInformation);
          }
        } else {
          if(this.user.profile_picture !== null && this.user.business_information === null){
            const brokerProfileUrl = this.user.profile_picture;
            this.getBrokerProfile = brokerProfileUrl;
            this.checkBrokerProfile = true;
          } else {
            if(this.headerSize){
              this.getBrokerProfile = 'assets/images/logo_small.png';
            } else {
              this.getBrokerProfile = 'assets/images/kingstate_logo.png';
            }
            this.checkBrokerProfile = false;
            this.businessInformation ={
              name: '',
              address: '',
              email: '',
              phone: '',
            };
            this.businessInfo(this.businessInformation);
          }
        }
      } else if(this.loggedIn && this.user.role.name === "applicant" && this.user.broker){
        // && this.tabScreen
        if(this.user.broker.business_information !== null){
          if(this.user.broker.business_information.logo !== null){
            const brokerProfileUrl = this.user.broker.business_information.logo;
            this.getBrokerProfile = brokerProfileUrl;
            this.checkBrokerProfile = true;
          } else if(this.user.broker.profile_picture !== null && this.user.broker.business_information.logo === null){
            const brokerProfileUrl = this.user.broker.profile_picture;
            this.getBrokerProfile = brokerProfileUrl;
            this.checkBrokerProfile = true;
          } else if(this.user.broker.profile_picture === null && this.user.broker.business_information.logo === null) {
            if(this.headerSize){
              if(this.user.broker.business_information !== null){
                this.checkBrokerProfile = true;
                this.getBrokerProfile = 'assets/images/logo_small.png';
              } else{
                this.checkBrokerProfile = false;
                this.getBrokerProfile = 'assets/images/logo_small.png';
              }
            } else {
              if(this.user.broker.business_information !== null){
                this.checkBrokerProfile = true;
                this.getBrokerProfile = 'assets/images/logo_small.png';
              } else{
                this.checkBrokerProfile = false;
                this.getBrokerProfile = 'assets/images/kingstate_logo.png';
              }
            }
          }
          if(this.user.broker.business_information){
            this.businessInfo(this.user.broker.business_information);
          } else {
            this.businessInformation ={
              name: '',
              address: '',
              email: '',
              phone: '',
            };
            this.businessInfo(this.businessInformation);
          }
        } else {
          if(this.user.broker.profile_picture !== null && this.user.broker.business_information === null){
            const brokerProfileUrl = this.user.broker.profile_picture;
            this.getBrokerProfile = brokerProfileUrl;
            this.checkBrokerProfile = true;
          } else {
            if(this.headerSize){
              this.getBrokerProfile = 'assets/images/logo_small.png';
            } else {
              this.getBrokerProfile = 'assets/images/kingstate_logo.png';
            }
            this.checkBrokerProfile = false;
          }
        }
      } else {
        if(this.headerSize){
          this.getBrokerProfile = 'assets/images/logo_small.png';
        } else {
          this.getBrokerProfile = 'assets/images/kingstate_logo.png';
        }
        this.checkBrokerProfile = false;
      }
    } else {
      if(this.headerSize){
        this.getBrokerProfile = 'assets/images/logo_small.png';
      } else {
        this.getBrokerProfile = 'assets/images/kingstate_logo.png';
      }
      this.checkBrokerProfile = false;
      this.businessInformation ={
        name: '',
        address: '',
        email: '',
        phone: '',
      };
      this.businessInfo(this.businessInformation);
    }
  }

  businessInfo(businessInfo: any){
    if(businessInfo !== null){
      const getBusinessInfo = businessInfo;
      const isBusinessFieldNotEmpty = Object.values(this.businessInformation).some(value => value !== null)
      if(getBusinessInfo.display_name){
        this.businessInformation.name = getBusinessInfo.display_name;
      } else {
        this.businessInformation.name = '';
      }
      
      if(getBusinessInfo.address){
        this.businessInformation.address = getBusinessInfo.address;
      } else {
        this.businessInformation.address = '';
      }

      if(getBusinessInfo.email){
        this.businessInformation.email = getBusinessInfo.email;
      } else {
        this.businessInformation.email = '';
      }

      if(getBusinessInfo.phone){
        this.businessInformation.phone = getBusinessInfo.phone;
        this.getBusinessFormattedPhoneNumber(this.businessInformation.phone);
      } else {
        this.businessInformation.phone = '';
      }

      if(isBusinessFieldNotEmpty){
        this.businessInfoCheck = true;
      } else{
        this.businessInfoCheck = false;
      }
    }
  }

  getBusinessFormattedPhoneNumber(value: string) {
    if(value !== null){
      let input = value.replace(/\D/g, '').substring(0, 10);
      if (input.length > 3) {
        input = input.substring(0, 3) + '-' + input.substring(3);
      }
      if (input.length > 7) {
        input = input.substring(0, 7) + '-' + input.substring(7);
      }
      this.businessInformation.phone = input
    }
  }

}
